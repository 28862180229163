import { Avatar, Grid } from "@mui/material";
import React from "react";
import theme from "../../theme/theme";

/**
 * @param {{ displayName: string; title?: string; photoURL?: string; }[]} users
 * @returns {JSX.Element}
 */
export function getDisplayUsersCellForTable(users) {
  return (
    <Grid container direction="column" gap={1}>
      {users.map(({ displayName, title, photoURL }, index) => {
        return (
          <Grid
            key={index}
            item
            container
            direction="row"
            alignItems="center"
            gap={1}
          >
            <Grid item>
              <Avatar
                alt={displayName}
                title={displayName}
                src={photoURL ? photoURL : undefined}
                sx={{
                  width: 24,
                  height: 24,
                  color: theme.palette.primary.contrastText,
                  fontSize: "12px",
                  backgroundColor: photoURL
                    ? theme.palette.primary.contrastText
                    : theme.palette.grey[800],
                }}
              />
            </Grid>

            <Grid item fontWeight="bold">
              {displayName}
            </Grid>

            <Grid item>{title !== undefined ? `(${title})` : ""}</Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
