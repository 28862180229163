import {
  faCirclePlus,
  faFilter,
  faPen,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import { default as React, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CanveoCircularProgress,
  ClauseLibraryList,
  DialogClause,
  DialogTemplate,
  Editor,
  FabStandard,
  Header,
  SelectLabels,
  ThumbAgr,
} from "../components";
import CoreDrawer from "../components/drawer/CoreDrawer";
import { getLegalDrawerItems } from "../components/drawer/getDrawerItems";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { trunc } from "../utils";
import { getCanveoTier } from "../utils/getCanveoTier";
import { ManagePlaybooks } from "./ManagePlaybooks";
import Labels from "./legal/Labels";
import Policy from "./legal/Policy";

export default function Templates() {
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const pageSizeTemplates = 8;
  const pageSizeTopics = 8;
  const navigate = useNavigate();
  const tid = useParams()?.tid || "";

  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [filters, setFilters] = React.useState({
    agrTypeID: "",
    reference: "",
    labels: [],
  });
  const [filteredTemplates, setFilteredTemplates] = React.useState(
    /** @type {*[]} */ ([])
  );
  const [filteredTopics, setFilteredTopics] = React.useState(
    /** @type {*[]} */ ([])
  );
  const [curPageTemplates, setCurPageTemplates] = React.useState(1);
  const [curPageTopics, setCurPageTopics] = React.useState(1);
  const [dialogTemplateOpen, setDialogTemplateOpen] = React.useState(false);
  const [dialogClauseOpen, setDialogClauseOpen] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [loading, setLoading] = React.useState(false);
  const [template, setTemplate] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [drawerItems, setDrawerItems] = useState(/** @type {*[]} */ ([]));

  useEffect(() => {
    const navigateAction = (/** @type {string} */ target) => navigate(target);

    const drawerItems = getLegalDrawerItems(
      tid,
      navigateAction,
      state?.user?.email
    );

    setDrawerItems(drawerItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  useEffect(() => {
    // Update the filteredTemplates array upon change of filters or Templates in state
    const ft = state.templates
      .filter((/** @type {{ deleted: any; }} */ t) => !t.deleted) // Filters out any recently deleted template
      //Commenting this code as i don't understand it and it's filtering out some templates, which shouldn't
      // .filter(
      //   (t) =>
      //     !state.templates.some(
      //       (temp) =>
      //         temp._id !== t._id &&
      //         temp.blueprintID === t.blueprintID &&
      //         (temp.active || (!t.active && temp.version > t.version))
      //     )
      // )
      .filter(
        (/** @type {{ agrTypeID: string; }} */ t) =>
          filters.agrTypeID === "" || t.agrTypeID === filters.agrTypeID
      ) // FILTER: agr types
      .filter(
        (/** @type {{ reference: string; }} */ t) =>
          filters.reference !== undefined &&
          t.reference.toLowerCase().includes(filters.reference.toLowerCase())
      ) // FILTER: reference
      .filter(
        (/** @type {{ labelIDs: string | never[]; }} */ t) =>
          filters.labels.length === 0 ||
          filters.labels.every((fl) => t.labelIDs.includes(fl))
      ) // FILTER: labels
      .sort(
        (
          /** @type {{ agrTitle: number; }} */ a,
          /** @type {{ agrTitle: number; }} */ b
        ) => (a.agrTitle > b.agrTitle ? 1 : -1)
      );

    setFilteredTemplates(ft);
    setCurPageTemplates(1);
  }, [state.templates, filters, tid]);

  useEffect(() => {
    const ft = state.clauseTypes
      .filter((/** @type {{ active: any; }} */ t) => t.active) // FILTER: labels
      .sort(
        (
          /** @type {{ name: number; }} */ a,
          /** @type {{ name: number; }} */ b
        ) => (a.name - b.name ? 1 : -1)
      );

    setFilteredTopics(ft);
    setCurPageTopics(1);
  }, [state.clauseTypes, dialogClauseOpen]);

  useEffect(() => {
    // Pull the entire template (incl. content) when you're loading a new template page

    if (Boolean(tid) && tid.length > 10 && tid.length < 30) {
      setLoading(true);

      axios
        .get(state.settings.api + "template/" + tid)
        .then((resTemp) => {
          if (resTemp.data.success) {
            dispatch({
              type: "INIT_WORKFLOWS",
              payload: resTemp.data.data.workflows,
            });
            dispatch({
              type: "INIT_PARAMS_DOC",
              payload: resTemp.data.data.params,
            });
            dispatch({
              type: "INIT_TEMPLATE",
              payload: resTemp.data.data.templates,
            });
            const template = resTemp.data.data.templates.find(
              (/** @type {{ _id: string | undefined; }} */ t) => t._id === tid
            );
            setTemplate(template);
            setLoading(false);
          } else {
            dispatch({
              type: "NEW_SNACKBAR",
              payload: {
                message: "Unable to open the template",
                severity: "error",
              },
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Unable to open the template",
              severity: "error",
            },
          });
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid]);

  const handleLabelSelect = (/** @type {any[]} */ newLabels) => {
    /**
     * @type {any[]}
     */
    let newLabs = [];
    newLabels.forEach((/** @type {{ _id: any; }} */ nl) => {
      newLabs.push(nl._id);
    });

    // @ts-ignore
    setFilters({ ...filters, labels: newLabs });
  };

  const closeTemplateDialog = (/** @type {string} */ action) => {
    if (["snackTemplateCreated"].includes(action)) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: { message: "The template was created", severity: "success" },
      });
    }
    setDialogTemplateOpen(false);
  };

  const closeClauseTypeDialog = (/** @type {string} */ action) => {
    if (
      [
        "clauseTypeGuidanceCreated",
        "clauseTypeGuidanceUpdated",
        "clauseTypeDeleted",
        "clauseLibUpdated",
        "clauseLibDeleted",
      ].includes(action)
    ) {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: ["clauseTypeGuidanceCreated"].includes(action)
            ? "Clause type created"
            : ["clauseTypeGuidanceUpdated"].includes(action)
            ? "Clause type updated"
            : ["clauseTypeDeleted"].includes(action)
            ? "Clause type deleted"
            : ["clauseLibUpdated"].includes(action)
            ? "Library clause updated"
            : ["clauseLibDeleted"].includes(action)
            ? "Clause removed from library"
            : "",
          severity: "success",
        },
      });
    }
    setDialogClauseOpen(null);
  };

  const handleEditClauseLibItem = (/** @type {any} */ item) => {
    setDialogClauseOpen({ type: "editlibrary", clitem: item });
  };

  return (
    <div>
      {loading ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <CanveoCircularProgress />
        </Grid>
      ) : // Template Editing Page
      !["main", "library", "topics", "labels", "playbook"].includes(tid) &&
        Boolean(template) /* && Boolean(template.content)*/ ? (
        <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
          {/* @ts-ignore */}
          <Editor page="Template" template={template} />

          {/*}
            <Box sx={{mt:15, pl:15}}>
              {tid}<br/><br/>

              Click into template : will bring you to an Editor Screen, to consider:<br/>
              - Activation / Deactivate / Copy into new / Export / Delete Template
              - Version Mgt<br/>
              - Agr Type / Reference<br/>
              - Effective Date<br/>
              - Default Children (Exhs)
            </Box>
            */}
        </Box>
      ) : // Default "Home" screen for Templates
      ["main", "library", "topics", "labels", "policy", "playbook"].includes(
          tid
        ) && !loading ? (
        <Box
          sx={{ display: "flex", width: "100%", margin: "0px", padding: "0px" }}
        >
          <Header page={"Templates"} />
          <CoreDrawer drawerItems={drawerItems} />
          {JSON.stringify(
            tid === "playbook" ? "main" : tid || "does not exist"
          )}
          <Box sx={{ my: 17, mx: isSmUp ? 3 : 2, width: "100%" }}>
            {["main"].includes(tid) ? (
              <>
                <Grid container justifyContent="center">
                  <Grid item display={"flex"} justifyContent={"center"} xs={8}>
                    <Typography variant="h4">Manage Templates</Typography>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"center"}
                    xs={8}
                    sx={{ m: 2 }}
                  >
                    <Typography variant="body1" textAlign={"center"}>
                      Active templates will show as available when creating new
                      agreements
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <FabStandard
                    click={(/** @type {any} */ e) =>
                      setDialogTemplateOpen(true)
                    }
                    text="New"
                    icon={faCirclePlus}
                    sx={{
                      left: "100px",
                      top: "80px",
                      right: "unset",
                    }}
                  />

                  <Grid
                    item
                    xs={12}
                    style={{ width: "100%", maxWidth: "980px" }}
                  >
                    <Box sx={{ my: 6, width: "100%" }}>
                      {getCanveoTier(state?.user?.email) === "experimental" && (
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Autocomplete
                              id="filter-agrType"
                              options={state.agrTypes
                                .filter(
                                  (/** @type {{ active: any; }} */ at) =>
                                    at.active
                                )
                                .filter((/** @type {{ _id: any; }} */ at) =>
                                  state.templates.some(
                                    (/** @type {{ agrTypeID: any; }} */ t) =>
                                      t.agrTypeID === at._id
                                  )
                                )
                                .sort(
                                  (
                                    /** @type {{ fullName: number[]; }} */ a,
                                    /** @type {{ fullName: number[]; }} */ b
                                  ) => (a.fullName[0] > b.fullName[0] ? 1 : -1)
                                )} // labels
                              onChange={(e, newVal) =>
                                setFilters({
                                  ...filters,
                                  agrTypeID: newVal === null ? "" : newVal._id,
                                })
                              }
                              getOptionLabel={(option) =>
                                trunc(option.fullName[0], 35)
                              }
                              filterSelectedOptions
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Agreement Type"
                                  placeholder="Agreement Type ..."
                                  //sx={{width: '200px'}}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <>
                                        <InputAdornment position="start">
                                          <FontAwesomeIcon icon={faFilter} />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                      </>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <TextField
                              label="Reference"
                              placeholder="Reference ..."
                              value={filters.reference}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  reference: e.target.value,
                                })
                              }
                              fullWidth
                              //sx={{width: '200px'}}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faFilter} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <SelectLabels
                              adornIcon={faFilter}
                              handleLabelSelect={handleLabelSelect}
                              selectedIDs={filters.labels}
                              options={state.labels
                                .filter(
                                  (/** @type {{ active: any; }} */ l) =>
                                    l.active
                                )
                                .sort(
                                  (
                                    /** @type {{ name: number; }} */ a,
                                    /** @type {{ name: number; }} */ b
                                  ) => (a.name > b.name ? 1 : -1)
                                )
                                .sort(
                                  (
                                    /** @type {{ type: { color: number; }; }} */ a,
                                    /** @type {{ type: { color: number; }; }} */ b
                                  ) => (a.type.color > b.type.color ? 1 : -1)
                                )} // labels
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Grid>

                  <Grid item>
                    {filteredTemplates.length > 0 ? (
                      <Grid
                        container
                        direction="row"
                        spacing={2}
                        sx={{
                          [theme.breakpoints.only("xs")]: { width: "340px" },
                          [theme.breakpoints.only("sm")]: { width: "410px" },
                          [theme.breakpoints.up("md")]: { width: "880px" },
                        }}
                      >
                        {filteredTemplates
                          .sort(
                            /**
                             * @param {{ lastUpdateDate: string }} previous
                             * @param {{ lastUpdateDate: string }} next
                             * @returns {number}
                             */
                            (previous, next) =>
                              new Date(next.lastUpdateDate).getTime() -
                              new Date(previous.lastUpdateDate).getTime()
                          )
                          .slice(
                            (curPageTemplates - 1) * pageSizeTemplates,
                            curPageTemplates * pageSizeTemplates
                          )
                          .map((t) => {
                            t.agrStatus = t.active ? "Active" : "Draft";
                            return (
                              <Grid item key={t._id} xs={6} md={3}>
                                <ThumbAgr
                                  ag={t}
                                  actionReq={!t.active}
                                  thumbClick={(/** @type {any} */ e) =>
                                    navigate("/templates/" + t._id)
                                  }
                                  showLogo={state.org.logoURL}
                                  primaryLegalName={t.reference}
                                  secondaryLegalName={"Version " + t.version}
                                  isTemplate
                                />
                              </Grid>
                            );
                          })}
                      </Grid>
                    ) : (
                      <Box sx={{ mx: 5, my: 5 }}>
                        <Typography align="center">
                          No templates yet.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={{ mt: 7 }}>
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          filteredTemplates.length / pageSizeTemplates
                        )}
                        defaultPage={1}
                        page={curPageTemplates}
                        onChange={(e, newVal) => setCurPageTemplates(newVal)}
                        siblingCount={0}
                        boundaryCount={2}
                        renderItem={(item) => <PaginationItem {...item} />}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </>
            ) : ["library"].includes(tid) ? (
              <>
                <Grid container justifyContent="center">
                  <Grid item display={"flex"} justifyContent={"center"} xs={8}>
                    <Typography variant="h4">Manage Clause Library</Typography>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"center"}
                    xs={8}
                    sx={{ m: 2, mb: 5 }}
                  >
                    <Typography variant="body1" textAlign={"center"}>
                      Clauses added to the clause library can be accessed and
                      used from any agreement
                    </Typography>
                  </Grid>
                </Grid>
                <ClauseLibraryList
                  type={"edit"}
                  onSelect={handleEditClauseLibItem}
                />
              </>
            ) : ["topics"].includes(tid) ? (
              <>
                <Grid container justifyContent="center">
                  <Grid item display={"flex"} justifyContent={"center"} xs={8}>
                    <Typography variant="h4">Manage Key Topics</Typography>
                  </Grid>
                  <Grid
                    item
                    display={"flex"}
                    justifyContent={"center"}
                    xs={8}
                    sx={{ m: 2 }}
                  >
                    <Typography variant="body1" textAlign={"center"}>
                      Define your Key Topics that you would like to check across
                      your contracts
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center">
                  <FabStandard
                    click={(/** @type {any} */ e) =>
                      setDialogClauseOpen({ type: "newtopic" })
                    }
                    text="New"
                    icon={faCirclePlus}
                    sx={{
                      left: "100px",
                      top: "80px",
                      right: "unset",
                    }}
                  />
                  <Grid item>
                    {state.clauseTypes.filter(
                      (/** @type {{ active: any; }} */ ct) => ct.active
                    ).length > 0 ? (
                      <Grid container direction="row" sx={{ mt: 2 }}>
                        <List
                          sx={{
                            width: isMdUp
                              ? "500px"
                              : isSmUp
                              ? "400px"
                              : "300px",
                          }}
                        >
                          {filteredTopics
                            .slice(
                              (curPageTopics - 1) * pageSizeTopics,
                              curPageTopics * pageSizeTopics
                            )
                            .map((topic, i) => {
                              //t.agrStatus = t.active ? "Active" : "Draft";
                              let guidance = state.clauseTypeGuides.filter(
                                (/** @type {{ ctid: any; }} */ ctg) =>
                                  ctg.ctid === topic._id
                              )[0];

                              return (
                                <ListItem
                                  key={topic._id}
                                  secondaryAction={
                                    <Tooltip
                                      placement="right"
                                      title="Edit guidance"
                                    >
                                      <IconButton
                                        edge="end"
                                        onClick={(e) =>
                                          setDialogClauseOpen({
                                            ...topic,
                                            type: "updatetopic",
                                          })
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faPen}
                                          style={{
                                            fontSize: "15px",
                                            padding: "5px",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      src={
                                        topic.orgID === "CANVEO"
                                          ? "https://storage.googleapis.com/cvt-images/canveo_transp.png"
                                          : state.org.logoURL
                                      }
                                      sx={{
                                        backgroundColor:
                                          theme.palette.primary.contrastText,
                                      }}
                                      style={
                                        Boolean(guidance) &&
                                        Boolean(guidance.guidance)
                                          ? {}
                                          : {
                                              filter: "grayscale(100%)",
                                              opacity: "0.6",
                                            }
                                      }
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <Typography style={{ fontWeight: "700" }}>
                                        {topic.name}
                                      </Typography>
                                    }
                                    secondary={
                                      Boolean(guidance) &&
                                      Boolean(guidance.guidance)
                                        ? trunc(guidance.guidance, 50)
                                        : "<none>"
                                    }
                                  />
                                </ListItem>
                              );
                            })}
                        </List>
                      </Grid>
                    ) : (
                      <Box sx={{ mx: 5, my: 5 }}>
                        <Typography align="center">
                          No clause types yet.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item sx={{ mt: 5 }}>
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          filteredTopics.length / pageSizeTopics
                        )}
                        defaultPage={1}
                        page={curPageTopics}
                        onChange={(e, newVal) => setCurPageTopics(newVal)}
                        siblingCount={0}
                        boundaryCount={2}
                        renderItem={(item) => <PaginationItem {...item} />}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </>
            ) : ["labels"].includes(tid) ? (
              <Labels />
            ) : ["policy"].includes(tid) ? (
              <Policy />
            ) : tid === "playbook" &&
              getCanveoTier(state?.user?.email) === "experimental" ? (
              <ManagePlaybooks />
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        ""
      )}

      <DialogTemplate
        open={Boolean(dialogTemplateOpen)}
        closeDialog={closeTemplateDialog}
      />

      <DialogClause
        open={Boolean(dialogClauseOpen)}
        details={dialogClauseOpen}
        closeDialog={closeClauseTypeDialog}
      />
    </div>
  );
}
