import {
  faCaretDown,
  faClone,
  faFont,
  faGear,
  faHome,
  faPaperPlane,
  faRightFromBracket,
  faUser,
  faUserSecret,
  faUserTie,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ChipButton, DialogDiff, FilterMenuButton } from ".";
import logo from "../assets/img/ct-logo.png";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { getCanveoTier } from "../utils/getCanveoTier";
import DialogSend from "./dialogs/DialogSend";
import DialogSigningProgress from "./dialogs/DialogSigningProgress";

/**
 * @typedef {*} HeaderProps
 */

/**
 * @param {HeaderProps} props
 * @returns {React.JSX.Element}
 */
export default function Header(props) {
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const styles = {
    avatar: { width: "30px", height: "30px" },
  };
  const navigate = useNavigate();
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [searchParams, setSearchParams] = useSearchParams();
  const taskType = searchParams.get("taskType");
  const taskId = searchParams.get("taskId");

  // Only open toolbar by default if we are in the context of an editor.
  const [showToolbar, setShowToolbar] = React.useState(
    props.showEditorToolbarByDefault
  );
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElModule, setAnchorElModule] = React.useState(null);
  const [dialogSendOpen, setDialogSendOpen] = React.useState(() => {
    if ((taskType === "Review" || taskType === "Approval") && taskId) {
      return "open";
    }

    return null;
  });
  const [dialogDiffOpen, setDialogDiffOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userRole] = useState(state.user?.role?.name);
  const hasModuleSelector =
    ["Admin", "Dashboard", "Templates"].includes(props.page) &&
    ["Admin", "Legal"].includes(userRole);
  const userHasPassword =
    userRole !== "Counterparty" || state.user.password.length > 20;
  const location = useLocation();
  const [openDialogSigningProgress, setOpenDialogSigningProgress] =
    useState(false);

  useEffect(
    () => {
      dispatch({
        type: "TOOLBAR_OPEN",
        payload: !isMdUp ? false : !!showToolbar,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showToolbar, dispatch]
  );

  const handleLogout = () => {
    setLoading(true);
    axios
      .post(state.settings.api + "auth/logout")
      .then(() => {
        dispatch({
          type: "RESET",
        });
        handleCloseUserMenu();
        setLoading(false);
        navigate("/");
      })
      .catch((err) => console.log("unable to logout", err));
  };

  const settings = [
    {
      name: userHasPassword ? "Account" : "Profile",
      route: "/account",
      icon: userHasPassword ? faGear : faUser,
    },
    // { name: 'Help', route: '/dashboard', icon: faCircleQuestion },
    {
      name: userHasPassword ? "Logout" : "Exit",
      click: handleLogout,
      icon: faRightFromBracket,
    },
  ];

  const currentModule = ["Admin", "Templates"].includes(props.page)
    ? props.page
    : "Dashboard";
  const modules = [
    {
      name: "Dashboard",
      displayName: "Dashboard",
      icon: faHome,
      route: "/dashboard",
    },
    ...(["Admin", "Legal"].includes(userRole)
      ? [
          {
            name: "Templates",
            displayName: "Legal",
            icon: faUserTie,
            route: "/templates/main",
          },
        ]
      : []),
    ...(["Admin"].includes(userRole)
      ? [
          {
            name: "Admin",
            displayName: "Admin",
            icon: faUserSecret,
            route: "/admin/profile",
          },
        ]
      : []),
  ];

  const handleOpenUserMenu = (/** @type {*} */ event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenModuleMenu = (/** @type {*} */ event) => {
    setAnchorElModule(event.currentTarget);
  };

  const handleCloseModuleMenu = () => {
    setAnchorElModule(null);
  };

  const isTemplating = ["Template"].includes(props.page);

  const mainAg = isTemplating
    ? props.template
    : state.agrs.find((/** @type {{ parentID: string; }} */ a) => !a.parentID);
  const isAgrExec = Boolean(state.agrExec) && Boolean(state.agrExec._id);
  const /** @type {boolean} */ isCurrentVersionOwner =
      !isAgrExec &&
      (isTemplating ||
        (!isTemplating &&
          Boolean(mainAg) &&
          mainAg.avOwners.some(
            (/** @type {string} */ owner) => owner === state.org._id
          )));

  const { aid } = useParams();
  let currentAgreementIsExhibit = false;

  if (aid) {
    currentAgreementIsExhibit = state.agrs.some(
      (/** @type {{ _id: string, parentID: string }} */ agreement) =>
        agreement.parentID && agreement._id === aid
    );
  }

  const showChipButton =
    !isCurrentVersionOwner &&
    !isAgrExec &&
    !["Execution", "InEffect"].includes(mainAg?.agrStatus);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: "rgba(255,255,255,0.7)",
        backdropFilter: "blur(10px)",
        zIndex: props.toolbar ? "1250" : "1000",
      }}
    >
      {props.loading ? (
        <Toolbar disableGutters sx={{ padding: "10px 0", ml: 3, mr: 3 }}>
          <Skeleton
            variant="circular"
            width={35}
            height={35}
            style={{ marginRight: "auto" }}
          />
          <Skeleton variant="circular" width={30} height={30} />
        </Toolbar>
      ) : (
        <Toolbar
          disableGutters
          sx={{
            pt: "10px",
            pb: "10px",
            ml: 3,
            mr: 3,
            justifyContent: "left",
            alignItems: "start",
          }}
        >
          <Grid
            item
            display="flex"
            sx={{
              alignItems: "center",
              pt: "10px",
              mr: "20px",
            }}
            onClick={() =>
              // Logo Click to Template overview if inside a Template, otherwise back to Dashboard.
              navigate(
                ["Template"].includes(props.page)
                  ? "/templates/main"
                  : "/dashboard"
              )
            }
          >
            <img
              alt="canveo logo"
              src={logo}
              style={{ height: "30px", cursor: "pointer" }}
            />
          </Grid>

          {
            // Header Body specific to the Agreement Screen.
            ["Agreement", "Template"].includes(props.page) ? (
              <Grid item>
                <Grid
                  container
                  sx={{
                    display: {
                      xs: "none",
                      md: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    sx={{
                      gap: "3px",
                      padding: "6px 0",
                    }}
                    alignItems="center"
                  >
                    {props.showToolbarBtn && (
                      <HeaderTooltip title="Format">
                        <span>
                          <IconButton
                            // disabled={state.user.role.name === "Counterparty"}
                            // variant="toolbar-btn-text"
                            disabled={!props.showEditorToolbarByDefault}
                            sx={{
                              ...(showToolbar && {
                                backgroundColor: "#0000000a",
                              }),
                            }}
                            onClick={() => setShowToolbar(!showToolbar)}
                          >
                            <FontAwesomeIcon icon={faFont} fontSize="14px" />
                          </IconButton>
                        </span>
                      </HeaderTooltip>
                    )}

                    {getCanveoTier(state?.user?.email) === "experimental" && (
                      <HeaderTooltip title="Compare">
                        <span>
                          <IconButton
                            // variant="toolbar-btn-text"
                            disabled
                            onClick={() => setDialogDiffOpen(true)}
                          >
                            <FontAwesomeIcon icon={faClone} fontSize="14px" />
                          </IconButton>
                        </span>
                        {/* span is for tooltips on disabled elements => remove when enable */}
                      </HeaderTooltip>
                    )}

                    {getCanveoTier(state?.user?.email) === "experimental" && (
                      <HeaderTooltip title="Filter">
                        <span>
                          <FilterMenuButton />
                        </span>
                      </HeaderTooltip>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    sx={{
                      gap: "3px",
                      ...(showToolbar && {
                        padding: "3px 0",
                      }),
                    }}
                  >
                    {showToolbar && props.toolbar}
                  </Grid>
                </Grid>
              </Grid>
            ) : // Pages where to show the "MODULE" selector, i.e., page navigator.
            hasModuleSelector ? (
              <Box sx={{ mt: 1.2 }}>
                <ChipButton
                  icon={faCaretDown}
                  iconPos="right"
                  text={currentModule === "Templates" ? "Legal" : currentModule}
                  color="lightGrey"
                  variant="text"
                  buttonClick={handleOpenModuleMenu}
                />
                <Menu
                  sx={{ mt: 4, zIndex: "2000" }}
                  id="menu-appbar-module"
                  anchorEl={anchorElModule}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElModule)}
                  onClose={handleCloseModuleMenu}
                >
                  {modules.map((m, i) => (
                    <MenuItem
                      key={i}
                      onClick={() => navigate(m.route)}
                      selected={m.name === currentModule}
                      style={{ width: "170px" }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon
                          icon={m.icon}
                          style={
                            m.name === currentModule
                              ? {}
                              : { visibility: "hidden" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <span
                          style={{
                            fontWeight:
                              m.name === currentModule ? "600" : "300",
                          }}
                        >
                          {m.displayName}
                        </span>
                      </ListItemText>

                      {/* <ListItemText sx={{ textAlign: "center" }}>
                        <Typography
                          style={{ fontWeight: "600", padding: "4px 15px" }}
                        >
                          {m.name}
                        </Typography>
                      </ListItemText> */}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : (
              ""
            )
          }

          <Grid
            item
            display="flex"
            sx={{
              ml: "auto",
              gap: 1,
              mr: 0,
              alignItems: "center",
              alignSelf: "start",
            }}
          >
            {["Agreement", "Template"].includes(props.page) && (
              // props.isOwner &&
              <>
                {/* <AvatarGroup /> */}

                {["Agreement"].includes(props.page) &&
                !props.isInEffect &&
                !showChipButton ? (
                  <>
                    <ChipButton
                      icon={faPaperPlane}
                      iconPos="right"
                      text={"Continue here"}
                      color="secondary"
                      variant="contained"
                      buttonClick={() => {
                        setDialogSendOpen("sendtocpty");
                      }}
                      disabled={currentAgreementIsExhibit}
                      {...(currentAgreementIsExhibit
                        ? {
                            toolttipTitle:
                              "Switch to the main agreement to perform this action",
                          }
                        : {})}
                    />
                  </>
                ) : ["Agreement"].includes(props.page) && props.isInEffect ? (
                  <Box sx={{ mr: 2, display: "inline-block" }}>
                    <Chip
                      color="primary"
                      label={
                        props.mainAg.agrStatus === "InEffect"
                          ? "In Effect"
                          : "Being Signed"
                      }
                      sx={{
                        fontWeight: 600,
                        px: 1,
                      }}
                    />
                  </Box>
                ) : ["Template"].includes(props.page) &&
                  Boolean(props.templateVersion) ? (
                  <Box sx={{ mr: 2, display: "inline-block" }}>
                    {props.templateVersion}
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}

            {loading ? <CircularProgress size={15} sx={{ mr: 2 }} /> : ""}

            {state.user.role.name !== "Counterparty" &&
              (matchPath(location.pathname, "/agreements") ||
                matchPath(location.pathname, "/tasks") ||
                !["Agreement", "Template", "Admin", "Templates"].includes(
                  props.page
                )) && (
                <>
                  <Box component="div">
                    <Button
                      variant="text"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => navigate("/agreements")}
                      sx={{
                        borderRadius: "0",
                        [`${
                          matchPath(location.pathname, "/agreements")
                            ? ""
                            : "&:hover"
                        }&::after`]: {
                          content: '""',
                          position: "absolute",
                          width: "100px",
                          top: "49px",
                          borderBottom: `${theme.palette.primary.main} 2px solid`,
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        },
                        "&:hover p": {
                          color: theme.palette.primary.main,
                        },
                      }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Typography
                        color={
                          matchPath(location.pathname, "/agreements")
                            ? theme.palette.primary.main
                            : theme.palette.grey[800]
                        }
                        fontSize={"13px"}
                        fontWeight={400}
                      >
                        Agreements
                      </Typography>
                    </Button>
                  </Box>

                  <Box component="div">
                    <Button
                      variant="text"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => navigate("/tasks")}
                      sx={{
                        borderRadius: "0",
                        [`${
                          matchPath(location.pathname, "/tasks")
                            ? ""
                            : "&:hover"
                        }&::after`]: {
                          content: '""',
                          position: "absolute",
                          width: "60px",
                          top: "49px",
                          borderBottom: `${theme.palette.primary.main} 2px solid`,
                          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                        },
                        "&:hover p": {
                          color: theme.palette.primary.main,
                        },
                      }}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <Typography
                        color={
                          matchPath(location.pathname, "/tasks")
                            ? theme.palette.primary.main
                            : theme.palette.grey[800]
                        }
                        fontSize={"13px"}
                        fontWeight={400}
                      >
                        Tasks
                      </Typography>
                    </Button>
                  </Box>
                </>
              )}

            <Tooltip title="Open settings" placement="bottom" arrow>
              <IconButton onClick={handleOpenUserMenu}>
                <Avatar
                  sx={styles.avatar}
                  alt="Logged in User"
                  src={state.user.photoURL}
                />
              </IconButton>
            </Tooltip>

            <Menu
              sx={{ mt: "45px", zIndex: "2000" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting, i) => (
                <MenuItem
                  key={i}
                  onClick={
                    setting.click !== undefined && setting.click !== null
                      ? setting.click
                      : () => navigate(setting.route)
                  }
                >
                  <ListItemIcon>
                    <FontAwesomeIcon icon={setting.icon} />
                  </ListItemIcon>
                  <ListItemText>{setting.name}</ListItemText>
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Toolbar>
      )}

      {["Agreement"].includes(props.page) ? (
        <>
          {dialogSendOpen && (
            <DialogSend
              open={Boolean(dialogSendOpen)}
              handleClose={(type) => {
                setDialogSendOpen(null);
                setSearchParams({});

                if (type === "preparingYourDocumentForSignatureSetup") {
                  setOpenDialogSigningProgress(true);
                }
              }}
              agreement={props.mainAg}
              taskType={taskType}
              taskId={taskId}
            />
          )}

          {dialogDiffOpen && (
            <DialogDiff
              open={dialogDiffOpen}
              closeDialog={() => setDialogDiffOpen(false)}
              trigger={dialogDiffOpen}
              // mainAg={props.mainAg}
            />
          )}
        </>
      ) : (
        ""
      )}

      {openDialogSigningProgress && (
        <DialogSigningProgress
          open={openDialogSigningProgress}
          close={() => {}}
        />
      )}
    </AppBar>
  );
}

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
function HeaderTooltip({ title, children }) {
  return <Tooltip title={title}>{children}</Tooltip>;
}
