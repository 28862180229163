import { Chip } from "@mui/material";
import React from "react";
import { getStatusTaskStyles } from "./getStatusTaskStyles";

/**
 * @param {import("./constants").TaskStatus} taskStatus
 * @returns {JSX.Element}
 */
export function getStatusChip(taskStatus) {
  const styles = getStatusTaskStyles(taskStatus);

  return (
    <Chip
      size="small"
      label={taskStatus}
      sx={{
        ...styles,
        fontWeight: "bold",
      }}
    />
  );
}
