import {
  faArrowLeft,
  faArrowRight,
  faBuildings,
  faClock,
  faPaperPlane,
  faSignature,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  DialogActions,
  Grid,
  MobileStepper,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAgreementData from "../../../../hooks/useAgreementData";
import { globalStore } from "../../../../state/store";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import CanveoCircularProgress from "../../../CanveoCircularProgress";
import CreateUserSection from "../../SendComponents/CreateUserSection";
import PartyManagementSection from "../../SendComponents/PartyManagementSection";
import ReadySignSection from "../../SendComponents/ReadySignSection";
import RecipientListSection from "../../SendComponents/RecipientListSection";
import SelectSignerSection from "../../SendComponents/SelectSignerSection";
import SignersListSection from "../../SendComponents/SignersListSection";
import UserManagementSection from "../../SendComponents/UserManagementSection";
import CannotSendOrSignInfoPanel from "../components/CannotSendOrSignInfoPanel";

/**
 * @typedef {object} SendTabProps
 * @property {boolean} open
 * @property {(param?: boolean) => void} handleCloseGroup
 * @property {(param?: boolean) => void} handleCloseDialog
 * @property {(tabIndex: number) => void} changeTab
 * @property {*} agreement
 * @property {boolean} canSend
 */

/**
 * @param {SendTabProps} props
 * @returns {React.JSX.Element}
 */
export default function SendTab({
  open,
  handleCloseGroup,
  agreement,
  changeTab,
  ...props
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();

  const [userRole] = useState(state.user.role?.name);
  const [activeStep, setActiveStep] = useState(0);
  const [partyManagement, setPartyManagement] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [editUser, setEditUser] = useState(/** @type {* | null} */ (null));
  const [closing, setClosing] = useState(false);

  const {
    loading,
    errorMsg,
    setErrorMsg,
    allUsers,
    collaborators,
    addCollaborators,
    signers,
    addSigner,
    changeSigners,
    signingOrder,
    oidsForWhichYouCanAddUser,
    handleSigningOrderChange,
    selectedOrganizationID,
    setSelectedOrganizationID,
    userCreationType,
    closeUserSections,
    setSignerCreation,
    setCollaboratorCreation,
    handleSubmitUserForm,
    handleEditUserForm,
    canSign,
    message,
    setMessage,
    readyToSign,
    setReadyToSign,
    updateAgreement,
    sendUserInvitations,
    sendAgreement,
    reminders,
    setReminders,
    handleRevokeUser,
    handlePartyChange,
    agreementUpdate,
    roles,
    setRoles,
    setAgreementUpdate,
    attachWordDocumentToEmail,
    setAttachWordDocumentToEmail,
    attachWordDocumentToEmailType,
    setAttachWordDocumentToEmailType,
    setAttachPdfFileToEmail,
    attachPdfFileToEmail,
    attachPdfFileToEmailType,
    setAttachPdfFileToEmailType,
    wordEditAuthorization,
    setWordEditAuthorization,
    comparisonVersion,
    setComparisonVersion,
    // getAgreementSfdt,
  } = useAgreementData(agreement, open);

  useEffect(
    () => {
      dispatch({ type: "RESET_SEND_FLOW_STATE" });
    },
    // Runs only once, on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initiateClosing = () => {
    if (closing || !agreementUpdate) return closeDialog();

    setClosing(true);
  };

  const closeDialog = () => {
    handleCloseGroup();
  };

  const handleConfirmationClose = () => {
    sendUserInvitations();
    updateAgreement();
    if (errorMsg) return;

    closeDialog();
  };

  const handleBack = () => {
    if (closing) return setClosing(false);
    if (!!userCreationType || !!selectedOrganizationID) {
      return closeUserSections();
    }
    if (editUser) return setEditUser(null);
    if (state.sendFlow?.addParty) {
      return dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: false });
    }
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: null });
    }
    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_REMOVE_USER", payload: null });
    }
    if (partyManagement) return setPartyManagement(false);
    if (userManagement) return setUserManagement(false);
    if (activeStep > 0) return setActiveStep((previous) => previous - 1);

    initiateClosing();
  };

  const handleNext = () => {
    setErrorMsg("");
    setActiveStep((previous) => previous + 1);
  };

  const getSelectedUsers = () => {
    return allUsers.filter((au) => signers.some((s) => s._id === au._id));
  };

  const getDialogTitle = () => {
    if (partyManagement) return "Manage Parties";
    if (userManagement) return "Manage Collaborators";

    if (userCreationType) return `Create ${userCreationType}`;

    if (activeStep === 0) return "Collaborators";
    if (activeStep === 1) return "Signers";
    if (activeStep === 2) return "Message";
  };

  /**
   * Review and Sign flow.
   */
  const handleSend = async () => {
    sendAgreement({ attachWordDocumentToEmail, attachPdfFileToEmail });

    // I have no idea why this code was here.
    // const files = {};
    // if (attachWordDocumentToEmail) {
    //   files.wordFile = await getAgreementSfdt();
    // }

    // if (attachPdfFileToEmail) {
    //   files.pdfFile = await getAgreementSfdt();
    // }

    // // @ts-ignore
    // sendAgreement(files);

    if (errorMsg) return;

    handleCloseGroup(true);
    navigate("/dashboard/" + (userRole === "Counterparty" ? "0" : "1"));
  };

  const handleConfirm = () => {
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_PARTY" });
    }

    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_USER" });
    }
  };

  const handleUserFormSubmission = async (
    /** @type {{ firstName: string; lastName: string; }} */ formData
  ) => {
    if (userCreationType) return handleSubmitUserForm(formData);
    if (editUser) {
      const result = await handleEditUserForm({
        ...editUser,
        ...formData,
        displayName: `${formData.firstName} ${formData.lastName}`,
      });
      if (result) setEditUser(null);
    }
  };

  if (!props.canSend) {
    return <CannotSendOrSignInfoPanel variant="send" changeTab={changeTab} />;
  }

  return (
    <Box mt={1}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 12,
          }}
        >
          <CanveoCircularProgress />
        </Box>
      )}

      {!loading && (
        <Box>
          <Grid
            container
            direction="column"
            justifyContent={"center"}
            alignItems="center"
            sx={{ minHeight: !closing ? "360px" : "unset" }}
          >
            <Box mt={4}>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={1} />

                <Grid item>
                  <Typography variant="h4">Send Agreement</Typography>
                </Grid>

                <Grid item xs={1} />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Grid item xs={1} />

                <Grid item>
                  <Typography>
                    Share the agreement with your counterparties
                  </Typography>
                </Grid>

                <Grid item xs={1} />
              </Grid>
            </Box>

            {!closing && (
              <>
                <Grid item sx={{ mt: 0, mb: 1 }}>
                  <Grid container justifyContent="center" justifyItems="center">
                    <Grid item mt={3}>
                      <MobileStepper
                        variant="dots"
                        steps={3}
                        sx={{ maxWidth: "50px", maxHeight: "25px" }}
                        position="static"
                        activeStep={activeStep}
                        nextButton={null}
                        backButton={null}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sx={{
                    textAlign: "center",
                    mb: !!state.parties && state.parties.length > 2 ? 3 : 4,
                  }}
                >
                  <Typography variant="h6">{getDialogTitle()}</Typography>

                  {errorMsg && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Typography align="center" color="error">
                        {errorMsg}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </>
            )}
            <Grid item sx={{ mb: 0, px: 1, width: "100%" }}>
              {closing ? (
                <Box>
                  <Typography sx={{ fontWeight: "600", mb: 2 }} align="center">
                    Do you want to persist the changes made?
                  </Typography>
                  <Typography sx={{ fontWeight: "600" }} align="center">
                    If you added new collaborators, they will receive email
                    notifications once you send the agreement.
                  </Typography>
                </Box>
              ) : userCreationType || editUser ? (
                <CreateUserSection
                  handleSubmit={handleUserFormSubmission}
                  user={editUser}
                />
              ) : selectedOrganizationID ? (
                <SelectSignerSection
                  // @ts-ignore
                  allowedOrganizations={oidsForWhichYouCanAddUser}
                  orgID={selectedOrganizationID}
                  users={allUsers.filter(
                    (au) => au.orgID === selectedOrganizationID
                  )}
                  owner={agreement.owner}
                  hiddenUsers={getSelectedUsers()}
                  handleCreateUser={setSignerCreation}
                  handleSelection={(/** @type {*} */ signer) => {
                    // @ts-ignore
                    addSigner(signer);
                    closeUserSections();
                  }}
                />
              ) : partyManagement ? (
                <PartyManagementSection
                  roles={roles}
                  setRoles={setRoles}
                  owner={agreement.owner}
                  handlePartyChange={handlePartyChange}
                  setAgreementUpdate={setAgreementUpdate}
                />
              ) : userManagement ? (
                <UserManagementSection
                  collaborators={collaborators}
                  signers={signers}
                  owner={agreement.owner}
                  handleEditUserForm={handleEditUserForm}
                  handleRevokeUser={handleRevokeUser}
                />
              ) : activeStep === 0 ? (
                <RecipientListSection
                  collaborators={collaborators}
                  users={allUsers}
                  owner={agreement.owner}
                  handleChangeCollaborators={addCollaborators}
                  handleCreateUser={(/** @type {string} */ orgID) => {
                    setCollaboratorCreation();
                    setSelectedOrganizationID(orgID);
                  }}
                />
              ) : activeStep === 1 ? (
                <SignersListSection
                  signConfig={agreement.sigConfig}
                  signers={signers}
                  signingOrder={signingOrder}
                  isOwner={agreement.owner === state.org._id}
                  owner={agreement.owner}
                  handleSignersChange={changeSigners}
                  handleOrganizationSelection={(/** @type {*} */ orgID) =>
                    setSelectedOrganizationID(orgID)
                  }
                  handleSigningOrderChange={handleSigningOrderChange}
                  handleEditUser={(/** @type {*} */ user) => setEditUser(user)}
                />
              ) : (
                activeStep === 2 && (
                  <ReadySignSection
                    message={message}
                    readyToSign={readyToSign}
                    reminders={reminders}
                    isOwner={agreement.owner === state.org._id}
                    handleChangeReady={(/** @type {*} */ isReady) =>
                      setReadyToSign(isReady)
                    }
                    handleChangeMessage={(/** @type {*} */ message) =>
                      setMessage(message)
                    }
                    handleChangeReminder={(/** @type {*} */ reminder) =>
                      setReminders(reminder)
                    }
                    {...{
                      attachWordDocumentToEmail,
                      setAttachWordDocumentToEmail,
                      attachWordDocumentToEmailType,
                      setAttachWordDocumentToEmailType,
                      setAttachPdfFileToEmail,
                      attachPdfFileToEmail,
                      attachPdfFileToEmailType,
                      setAttachPdfFileToEmailType,
                      wordEditAuthorization,
                      setWordEditAuthorization,
                      comparisonVersion,
                      setComparisonVersion,
                    }}
                  />
                )
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      <DialogActions
        sx={{
          marginTop:
            activeStep === 0
              ? 14
              : activeStep === 1
              ? 2
              : activeStep === 2
              ? 4
              : undefined,
        }}
      >
        {!closing && (
          <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Back
          </Button>
        )}
        {activeStep !== 2 &&
          !userCreationType &&
          !userManagement &&
          !partyManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setUserManagement(true)}>
              <FontAwesomeIcon icon={faUser} />
              &nbsp;&nbsp;Manage Collaborators
            </Button>
          )}
        {activeStep !== 2 &&
          !userCreationType &&
          !partyManagement &&
          !userManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setPartyManagement(true)}>
              <FontAwesomeIcon icon={faBuildings} />
              &nbsp;&nbsp;Manage Parties
            </Button>
          )}

        {(activeStep === 0 || activeStep === 1) &&
          !state.sendFlow?.addParty &&
          !state.sendFlow?.removeParty &&
          !state.sendFlow?.removeUser &&
          !closing && (
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              disabled={
                loading ||
                !!userCreationType ||
                !!selectedOrganizationID ||
                partyManagement ||
                userManagement ||
                state.parties.length <= 1 ||
                !!editUser
              }
              onClick={handleNext}
            >
              Next&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        {state.sendFlow?.addParty && !closing && (
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: "12px" }}
            disabled={
              !(
                state.sendFlow?.addParty.organization &&
                state.sendFlow?.addParty.entity &&
                state.sendFlow?.addParty.role
              )
            }
            onClick={() => dispatch({ type: "SEND_FLOW_SUBMIT_ADD_PARTY" })}
          >
            Add&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        )}
        {(state.sendFlow?.removeParty || state.sendFlow?.removeUser) && (
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: "12px" }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        )}
        {activeStep === 2 &&
          reminders === null &&
          agreement.owner === state.org._id &&
          !closing &&
          getCanveoTier(state?.user?.email) === "experimental" && (
            <Button
              disableElevation
              color="primary"
              variant="text"
              onClick={() => setReminders("7")}
            >
              <FontAwesomeIcon icon={faClock} />
              &nbsp;&nbsp;Reminders
            </Button>
          )}
        {activeStep === 2 && !closing && (
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: "12px" }}
            disabled={loading || !!userCreationType}
            onClick={handleSend}
          >
            {canSign && readyToSign ? (
              <>
                Initiate Signing&nbsp;&nbsp;
                <FontAwesomeIcon icon={faSignature} />
              </>
            ) : (
              <>
                Send to counterparty&nbsp;&nbsp;
                <FontAwesomeIcon icon={faPaperPlane} />
              </>
            )}
          </Button>
        )}

        {closing && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirmationClose}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Box>
  );
}
