import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, IconButton, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import theme from "../theme/theme";
import CanveoCircularProgress from "./CanveoCircularProgress";

/**
 * @typedef {object} UploadStatusPopUpProps
 * @property {boolean} uploading
 * @property {{ version: string; agreementID: string; agrName: string; }} agrVersionCreated
 * @property {() => void} handleClose
 */

/**
 * @param {UploadStatusPopUpProps} props
 * @returns {React.JSX.Element}
 */
export default function UploadStatusPopUp({
  uploading,
  agrVersionCreated,
  handleClose,
}) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      p={3}
      sx={{
        // TODO: Change position to have the same rules as the fab icon (responsiveness).
        position: "fixed",
        right: "20px",
        bottom: "20px",
        width: "230px",
        height: "250px",
        boxShadow: "0px 0px 12px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "25px",
        gap: "20px",
        backgroundColor: "white",
        zIndex: 99999,
      }}
      justifyContent="center"
    >
      <Grid item sx={{ height: "10px" }}>
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={handleClose}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>
      </Grid>

      {uploading && (
        <>
          <Grid item>
            <Typography variant="body1" align="center">
              Uploading document...
            </Typography>
          </Grid>

          <Grid item>
            <CanveoCircularProgress />
          </Grid>
        </>
      )}

      {agrVersionCreated && (
        <>
          <Grid item>
            <Typography variant="body1" align="center">
              A new version {agrVersionCreated.version} has been uploaded to:
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1" align="center">
              <Link
                component="button"
                // @ts-ignore
                color={theme.palette.link.main}
                sx={{ textAlign: "unset" }}
                onClick={() =>
                  navigate(`/agreement/${agrVersionCreated.agreementID}`)
                }
              >
                {agrVersionCreated.agrName}
              </Link>
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
}
