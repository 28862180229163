import { $createClauseNode } from "../../nodes/ClauseNode";
import { $createTocElementNode } from "../../nodes/TableOfContentsElementNode";
import { checkBlockType } from "./blocks";
import { createHeadingNode } from "./headings";
import { generateListNode } from "./lists";
import { createParagraphNode } from "./paragraphs";
import { createCaptionNode, createTableNode } from "./tables";

/**
 * @param {import("../../types/sfdt").Block[]} blocks
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @param {Set<String>} ongoingCommentsSet
 * @param {any} user
 * @param {any} agreement
 * @param {Record<string, string>} metadata
 * @param {import("lexical").RootNode | import("../../nodes/SectionNode").SectionNode} root
 * @param {import("../../types/sfdt").Section} section
 * @param {{listInfo: listInfo, addedLists: listInfo[] }} listsData
 * @param {*[]} organizationUsers
 */
export function parseBlocksAndPush(
  blocks,
  sfdt,
  sfdtCommentsMap,
  ongoingCommentsSet,
  user,
  agreement,
  metadata,
  root,
  section,
  listsData,
  organizationUsers
) {
  // This might need to be defined outside of this function due to lists possibly traversing sections.
  const { addedLists, listInfo } = listsData;

  if (!blocks) return ongoingCommentsSet;

  // Iterate section blocks.
  for (let index = 0; index < blocks.length; index++) {
    const previousBlock = blocks.at(index - 1);
    const block = blocks[index];

    const themeStyle = sfdt.styles.find(
      (s) => s.name === block.paragraphFormat?.styleName
    );

    let currentListId =
      block.paragraphFormat?.listFormat?.listId ??
      themeStyle?.paragraphFormat?.listFormat?.listId;
    if (
      (block.paragraphFormat?.listFormat ||
        themeStyle?.paragraphFormat?.listFormat) &&
      (currentListId === null || currentListId === undefined)
    ) {
      let basedOn = sfdt.styles.find((st) => st.name === themeStyle?.basedOn);
      /**
       * Find styles with `basedOn` property.
       * @param {import("../../types/sfdt").styles} arg
       * @returns {import("../../types/sfdt").styles | undefined}
       */
      const findFn = (arg) =>
        sfdt.styles.find((st) => st.name === arg?.basedOn);
      while (basedOn?.basedOn && !basedOn.paragraphFormat?.listFormat?.listId) {
        const newBasedOn = findFn(basedOn);
        if (newBasedOn) {
          basedOn = newBasedOn;
        }
      }
      currentListId = basedOn?.paragraphFormat?.listFormat?.listId;
    }

    /** @type {import("../../types/sfdt").Inline | undefined} */
    let clauseIdBookmark;

    // If the block is a table we need to fetch the secret bookmark from the first cell of the table since the
    // table block does not support inlines.
    if (block.rows && block.tableFormat) {
      clauseIdBookmark = block?.rows[0]?.cells[0]?.blocks[0]?.inlines?.find(
        (x) => x.bookmarkType === 0 && x.name?.startsWith("_c_")
      );
    } else {
      clauseIdBookmark = block.inlines?.find(
        (x) => x.bookmarkType === 0 && x.name?.startsWith("_c_")
      );
    }

    let /** @type {string | undefined} */ clauseId = undefined;

    if (clauseIdBookmark) {
      clauseId = clauseIdBookmark.name?.split("_c_")[1].replaceAll("_", "-");
    }

    const clauseNode = $createClauseNode({
      clauseTypes: [],
      workflows: [],
      libIDs: [],
      filter: "none",
      lock: "none",
      id: clauseId,
      key: undefined,
      variants: [],
    });

    // Check what type of block we are dealing with.
    let blockType = checkBlockType(block);
    // Only check the special conditions if the block does not belong to a table of contents.
    if (blockType !== "TOC") {
      // Handle TOC last paragraph.
      if (
        previousBlock &&
        checkBlockType(previousBlock) === "TOC" &&
        block.inlines?.at(0)?.fieldType
      ) {
        blockType = "TOC";
      }
      // There are situations where paragraphs do not have an explicit defined type, so we need to check
      // against the listId.
      else {
        const isList = (currentListId ?? -2) >= -1;
        if (isList) {
          blockType = "List Paragraph";
        }
      }
    }

    switch (blockType) {
      case "TOC": {
        const tableOfContentsElementNode = $createTocElementNode({
          block,
        });
        root.append(clauseNode.append(tableOfContentsElementNode));
        break;
      }
      case "Caption": {
        const caption = createCaptionNode(
          sfdt,
          section,
          block,
          sfdtCommentsMap,
          ongoingCommentsSet,
          user,
          agreement?.collabs,
          metadata,
          organizationUsers
        );
        ongoingCommentsSet = caption.ongoingCommentsSet;
        root.append(clauseNode.append(caption.node));
        break;
      }
      case "Table": {
        const table = createTableNode(
          sfdt,
          section,
          block,
          sfdtCommentsMap,
          ongoingCommentsSet,
          user,
          agreement?.collabs,
          metadata,
          organizationUsers
        );
        ongoingCommentsSet = table.ongoingCommentsSet;
        root.append(clauseNode.append(table.node));
        break;
      }
      case "Heading": {
        const heading = createHeadingNode(
          sfdt,
          section,
          block,
          sfdtCommentsMap,
          ongoingCommentsSet,
          user,
          agreement?.collabs,
          metadata,
          block.paragraphFormat?.styleName ?? "",
          organizationUsers,
          !!currentListId
        );
        ongoingCommentsSet = heading.ongoingCommentsSet;
        const headingType = block.paragraphFormat?.styleName;
        //if it's part of a list
        /** @type {import("../../types/sfdt").styles | undefined} */
        const themeStyle = sfdt.styles.find((s) => s.name === headingType);
        // ...listFormat.listId === -1 (list without markers)

        if (headingType && themeStyle && currentListId && currentListId > 0) {
          const isBulleted = !!sfdt.abstractLists.find(
            (li) =>
              li.abstractListId === currentListId &&
              li.levels[0].listLevelPattern === "Bullet"
          ); //if it's undefined means it's not bulleted, otherwise it is
          /** @type {import("./../utils/text").ComplementaryData} */
          const complementaryData = {
            ongoingCommentsSet,
            sfdtCommentsMap,
            user,
            collabs: agreement?.collabs,
            metadata,
          };
          const newList = generateListNode(
            currentListId,
            sfdt,
            clauseNode,
            block,
            complementaryData,
            section,
            themeStyle,
            headingType,
            addedLists,
            listInfo,
            heading.node,
            currentListId === -1,
            isBulleted,
            organizationUsers
          );
          if (!newList) {
            throw new Error("Failed to create a new list.");
          } //something went wrong
          ongoingCommentsSet = complementaryData.ongoingCommentsSet;
          root.append(clauseNode.append(newList));
        } else {
          //it's not part of list
          root.append(clauseNode.append(heading.node));
        }
        // elementNodesMap.set(blockKey, heading.node);
        break;
      }
      case "List Paragraph": {
        const isBulleted = !!sfdt.abstractLists.find(
          (li) =>
            li.abstractListId === currentListId &&
            li.levels[0].listLevelPattern === "Bullet"
        );
        /** @type {import("./../utils/text").ComplementaryData} */
        const complementaryData = {
          ongoingCommentsSet,
          sfdtCommentsMap,
          user,
          collabs: agreement?.collabs,
          metadata,
        };
        const newList = generateListNode(
          currentListId,
          sfdt,
          clauseNode,
          block,
          complementaryData,
          section,
          themeStyle,
          block.paragraphFormat?.styleName ?? "",
          addedLists,
          listInfo,
          undefined,
          currentListId === -1,
          isBulleted,
          organizationUsers
        );
        if (!newList) {
          throw new Error("Failed to create a new list.");
        } else {
          ongoingCommentsSet = complementaryData.ongoingCommentsSet;

          // Undoes weird logic where we mix the style paragraph format with the
          // block paragraph format. This enforces that the paragraph format on
          // the list pertains only to the block paragraph format.
          newList.setParagraphFormat(block.paragraphFormat);
          // Undoes weird logic where we mix the style character format with the
          // block character format. This enforces that the character format on
          // the list pertains only to the block character format.
          newList.setCharacterFormat(block.characterFormat || undefined);
          root.append(clauseNode.append(newList));
        }

        break;
      }
      case "Subtitle":
      case "Title":
      case "Paragraph":
      default: {
        const paragraph = createParagraphNode(
          sfdt,
          section,
          block,
          blockType,
          sfdtCommentsMap,
          ongoingCommentsSet,
          user,
          agreement?.collabs,
          metadata,
          organizationUsers
        );

        ongoingCommentsSet = paragraph.ongoingCommentsSet;
        // // Undoes weird logic where we mix the style paragraph format with the
        // // block paragraph format. This enforces that the paragraph format on
        // // the paragraph pertains only to the block character format.
        // paragraph.node.setParagraphFormat(block.paragraphFormat);
        // // Undoes weird logic where we mix the style character format with the
        // // block character format. This enforces that the character format on
        // // the paragraph pertains only to the block character format.
        // paragraph.node.setCharacterFormat(block.characterFormat || undefined);
        root.append(clauseNode.append(paragraph.node));
        break;
      }
    }
  }

  return ongoingCommentsSet;
}
