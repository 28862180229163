import { faPen, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { DialogEntity, MenuPopEntities } from ".";
import defaultLogo from "../assets/img/defaultlogo.png";
import defaultUser from "../assets/img/defaultuser.png";
import countries from "../assets/static/countries";
import theme from "../theme/theme";
import { getAddressForEntity, getColorForPartyID, trunc } from "../utils";

/**
 * @typedef {object} CardCptyProps
 * @property {string} partyID
 * @property {(param: string, param2: any) => void} handleCardCptyRequest
 * @property {*} cpty
 * @property {*} [defaultLogo]
 * @property {*[]} switch
 * @property {boolean} remove
 * @property {boolean} canCreateEntity
 * @property {boolean} edit
 * @property {string} type
 *
 */

/**
 * @param {CardCptyProps} props
 * @returns {React.JSX.Element}
 */
export default function CardCpty(props) {
  const styles = useMemo(
    () => ({
      cardCpty: {
        width: "260px",
        borderRadius: "20px 20px 0px 0px",
        borderBottom: "2px solid " + getColorForPartyID(props.partyID),
        position: "relative",
        minHeight: "182px",
        overflow: "unset",
        height: "auto",
      },
      cardContent: {
        margin: "10px 0px 5px 0px",
        "&:firstChild": {
          paddingTop: "15px",
        },
        "&:lastChild": {
          paddingBottom: "15px",
        },
        textAlign: "center",
        paddingTop: 0,
        paddingBottom: 0,
      },
      avLogo: {
        position: "absolute",
        top: "-24px",
        left: "110px",
        height: "40px",
        width: "40px",
        backgroundColor: theme.palette.primary.contrastText,
      },
      removeIcon: {
        fontSize: "14px",
        color: getColorForPartyID(props.partyID),
        "&:hover": {
          color: getColorForPartyID(props.partyID),
          cursor: "pointer",
        },
      },
    }),
    [props.partyID]
  );

  const billToAddress = useMemo(
    () => getAddressForEntity(props.cpty, "billto"),
    [props.cpty]
  );

  const [dialogEntityOpen, setDialogEntityOpen] = useState(
    /** @type {* | null} */ (null)
  );

  /**
   * @param {*} action
   * @returns {void}
   */
  function closeEntityDialog(action) {
    if (action !== undefined && action !== null && action.ent !== undefined) {
      props.handleCardCptyRequest("updateEntity", action.ent);
    }
    setDialogEntityOpen(null);
  }

  /**
   * @param {*} id
   * @returns {void}
   */
  function selectEntity(id) {
    if (id.toCreate) {
      return props.handleCardCptyRequest("changeownent", {
        ...id,
        orgID: props.cpty.orgID,
      });
    }

    props.handleCardCptyRequest("changeownent", id);
  }

  return (
    <>
      <Card
        variant="outlined"
        // @ts-ignore
        style={styles.cardCpty}
      >
        <Avatar
          src={
            props.cpty && props.cpty.logoURL
              ? props.cpty.logoURL
              : props.defaultLogo
              ? props.defaultLogo
              : props.cpty && ["Person"].includes(props.cpty.entityCategory)
              ? defaultUser
              : defaultLogo
          }
          // @ts-ignore
          style={styles.avLogo}
        />

        <CardContent
          // @ts-ignore
          style={styles.cardContent}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            {
              // Show switch or edit icon.
              props.switch?.length > 0 && (
                <span
                  style={{
                    top: "5px",
                    right: props.remove ? "30px" : "5px",
                    padding: "4px 3px 4px 3px",
                    cursor: "pointer",
                  }}
                >
                  {/* @ts-ignore */}
                  <MenuPopEntities
                    variant="smallicon"
                    partyID={props.partyID}
                    entities={props.switch}
                    canCreateEntity={props.canCreateEntity}
                    selectEntity={selectEntity}
                  />
                </span>
              )
            }

            {props.edit && props.cpty.entityType !== "Subsidiary" && (
              <span
                style={{
                  top: "5px",
                  right: props.remove ? "30px" : "5px",
                  padding: "4px 3px 4px 3px",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon
                  icon={faPen}
                  style={styles.removeIcon}
                  onClick={() =>
                    setDialogEntityOpen({ type: "updcp", ent: props.cpty })
                  }
                />
              </span>
            )}

            {
              // Show delete party icon.
              props.remove && !!props.cpty?._id && (
                <span
                  style={{
                    top: "5px",
                    right: "5px",
                    padding: "4px 3px 4px 3px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={styles.removeIcon}
                    onClick={() =>
                      props.handleCardCptyRequest("removeParty", props.cpty._id)
                    }
                  />
                </span>
              )
            }
          </div>

          {!props?.cpty ? (
            <Box sx={{ margin: "10px 5px 2px 5px" }}>
              {/* @ts-ignore */}
              <MenuPopEntities
                variant="bigbutton"
                type={"primary"}
                entities={props.switch}
                selectEntity={selectEntity}
              />
            </Box>
          ) : (
            <Box sx={{ pt: 1 }}>
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ fontWeight: "700" }}
              >
                {props.cpty.shortName ? (
                  trunc(props.cpty.shortName, 25)
                ) : (
                  <span>&nbsp;</span>
                )}
              </Typography>

              <Typography variant="body2">
                {props.cpty.legalName ? (
                  trunc(props.cpty.legalName, 32)
                ) : (
                  <span>&nbsp;</span>
                )}
              </Typography>

              <Typography variant="body2">
                {billToAddress.streetAndNo
                  ? trunc(billToAddress.streetAndNo, 32)
                  : ""}
              </Typography>

              <Typography variant="body2">
                {billToAddress.address2
                  ? trunc(billToAddress.address2, 32)
                  : ""}
              </Typography>

              <Typography variant="body2">
                {billToAddress.city || billToAddress.zip || billToAddress.state
                  ? trunc(
                      (billToAddress.city ? billToAddress.city + " " : "") +
                        (billToAddress.zip ? billToAddress.zip + " " : "") +
                        (billToAddress.state ? billToAddress.state : ""),
                      32
                    )
                  : ""}
              </Typography>

              <Typography variant="body2">
                {billToAddress.country
                  ? countries.find((c) => c.code === billToAddress.country)
                      ?.label
                  : ""}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>

      <DialogEntity
        open={Boolean(dialogEntityOpen)}
        closeDialog={closeEntityDialog}
        type={
          dialogEntityOpen !== null && dialogEntityOpen.type !== undefined
            ? dialogEntityOpen.type
            : ""
        }
        ent={
          dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined
            ? dialogEntityOpen.ent
            : ""
        }
      />
    </>
  );
}
