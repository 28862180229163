import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import theme from "../../../theme/theme";
import { getDisplayUsersCellForTable } from "../../../views/Tasks/getAssigneesChip";
import { getStatusChip } from "../../../views/Tasks/getStatusChip";
import { getReviewListAssignees } from "../functions/getReviewListAssignees";

/**
 * @param {{ reviewTickets: ReviewTicket[], setSelectedReviewTicketIndex: (value: React.SetStateAction<number>) => void}} param0
 * @returns {JSX.Element}
 */
export default function ReviewsTable({
  reviewTickets,
  setSelectedReviewTicketIndex,
}) {
  return (
    <Grid container mt={4} mb={4}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>

              <TableCell>Name</TableCell>

              <TableCell>Requestor</TableCell>

              <TableCell>Assignee(s)</TableCell>

              <TableCell>Created</TableCell>

              <TableCell>Updated</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {reviewTickets.map((reviewTicket, index) => (
              <TableRow
                key={reviewTicket._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                onClick={() => setSelectedReviewTicketIndex(index)}
              >
                <TableCell component="th" scope="row">
                  {getStatusChip(reviewTicket.status)}
                </TableCell>

                <TableCell>{reviewTicket.name}</TableCell>

                <TableCell>
                  {getDisplayUsersCellForTable([
                    reviewTicket.isTemplateConfiguration ||
                    (reviewTicket.isDefaultRequest &&
                      reviewTicket.status === "Pending Assignment")
                      ? { displayName: "Requesting User" }
                      : {
                          displayName: reviewTicket.requestor.displayName,
                          title: reviewTicket.requestor.title,
                          photoURL: reviewTicket.requestor.photoURL,
                        },
                  ])}
                </TableCell>

                <TableCell>
                  {getDisplayUsersCellForTable(
                    getReviewListAssignees(reviewTicket.reviewChain.reviewList)
                  )}
                </TableCell>

                <TableCell>
                  {DateTime.fromJSDate(
                    new Date(reviewTicket.createdAt)
                  ).toFormat("dd/MM/yyyy")}
                </TableCell>

                <TableCell>
                  {DateTime.fromJSDate(
                    new Date(reviewTicket.updatedAt)
                  ).toFormat("dd/MM/yyyy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
