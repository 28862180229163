import { $isAutoLinkNode } from "@lexical/link";
import { $dfs, $getNearestNodeOfType } from "@lexical/utils";
import {
  $getNodeByKey,
  $isElementNode,
  $isLineBreakNode,
  $isTextNode,
} from "lexical";
import { v4 as uuid } from "uuid";
import { HYPERLINK_REGEX, REF_REGEX } from "../../../utils/constants";
import { CustomListItemNode } from "../nodes";
import { $isCaptionNode } from "../nodes/CaptionNode";
import { $isCrossRefNode } from "../nodes/CrossRefNode";
import { $isCustomHeadingNode } from "../nodes/CustomHeadingNode";
import { $isCustomListNode, CustomListNode } from "../nodes/CustomListNode";
import { $isCustomParagraphNode } from "../nodes/CustomParagraphNode";
import { $isCustomTable } from "../nodes/CustomTableNode";
import { $isCustomTableRowNode } from "../nodes/CustomTableRowNode";
import { $isImageNode } from "../nodes/ImageNode";
import { $isMarkNode } from "../nodes/MarkNode";
import { $isRedlineNode } from "../nodes/RedlineNode";
import { $isTabNode } from "../nodes/TabNode";
import { $isTocElementNode } from "../nodes/TableOfContentsElementNode";
import { getListItemsFromDfs } from "../plugins/ListExtendedPlugin/getListItemsFromDfs";
import { $populateListHandler } from "../plugins/ListExtendedPlugin/populateListHandler";
import { ALLOWED_CHARACTER_FORMAT_KEYS } from "../utils/constants";
import { generateCrossReferenceFieldText } from "./utils/cross_references";
import {
  convertCSStoBlockIndent,
  generateInline,
  parseStyles,
} from "./utils/text";

/**
 * @typedef {object} CanveoComment
 * @property {string} _id
 * @property { string | undefined } customId
 * @property {string} wfType
 * @property {string} orgID
 * @property {string} lid
 * @property {string} docID
 * @property {object[]} comments
 * @property {string} comments.id
 * @property { string | undefined } comments.customId
 * @property {object} comments.creator
 * @property {string} comments.creator.uid
 * @property {string} comments.creator.displayName
 * @property {string} comments.creator.email
 * @property {string} comments.creator.photoURL
 * @property {string} comments.content
 * @property {string} comments.date
 * @property {boolean} comments.isEdited
 * @property {string} comments._id
 * @property {string} quote
 * @property {string} note
 * @property {string[]} subscribers
 * @property {object} creator
 * @property {string} creator.uid
 * @property {string} creator.displayName
 * @property {string} creator.email
 * @property {string} creator.photoURL
 * @property {null} assignee
 * @property {string} wfStatus
 * @property {string} creationDate
 * @property {number} __v
 */

/**
 * Imports SFDT (JSON) and converts to Lexical.
 *
 * @param {import("lexical").RootNode} root
 * @param {import("../types/sfdt").Sfdt} originalAgreementSfdt
 * @param {{agreementId?: string; orgId?: string; entityId?: string; partyId?: string;}} metadata
 * @param {CanveoComment[]} comments
 * @param {import("../types/sfdt").AbstractList[]} [listsStructure]
 * @returns {import("../types/sfdt").Sfdt}
 */
export default function $convertLexicalToSfdt(
  root,
  originalAgreementSfdt,
  metadata = {},
  comments = [],
  listsStructure = []
) {
  let hasAddedMetadataBookmark = false;

  // Clone abstract lists so that we can restore them at the end.
  const originalAbstractLists = originalAgreementSfdt.abstractLists
    ? JSON.parse(JSON.stringify(originalAgreementSfdt.abstractLists))
    : undefined;

  // We clone the original agreement SFDT so that we do not change the original one
  // sent by parameter.
  const /** @type {import("../types/sfdt").Sfdt} */ newAgreementSfdt =
      JSON.parse(JSON.stringify(originalAgreementSfdt));
  newAgreementSfdt.revisions = [];

  const /** @type {import("../types/sfdt").Comment[]} */ sfdtComments = [];

  if (comments?.length) {
    for (let index = 0; index < comments.length; index++) {
      const comment = comments[index];
      comment.customId = generateRevisionID();

      // Uncomment when uploading files for tests.
      // comment.customId = "410787ef-25b2-4715-8e58-46f0c911a014";
      // comment.lid = "cp_410787ef-25b2-4715-8e58-46f0c911a014";

      // We remove the first comment from the comments because it is a copy of the main comment
      // (which is also in the root object), leaving us with just the replies.
      comment.comments.shift();

      const commentReplies = comment.comments;
      const displayName = comment?.creator?.displayName || "";

      const /** @type {import("../types/sfdt").Comment} */ sfdtComment = {
          author: comment.creator.email,
          date: comment.creationDate,
          done: comment.wfStatus === "resolved",
          initial: displayName
            .split(" ")
            .map((x) => x[0])
            .join(""),
          blocks: [{ inlines: [{ text: comment.note }] }],
          commentId: comment.customId, // comment._id,
          replyComments: commentReplies.map((reply) => {
            reply.customId = uuid();
            return {
              author: reply.creator.email,
              date: reply.date,
              done: false,
              initial: reply.creator.displayName
                .split(" ")
                .map((x) => x[0])
                .join(""),
              blocks: [{ inlines: [{ text: reply.content }] }],
              commentId: reply.customId, //reply._id,
              replyComments: [],
            };
          }),
        };

      sfdtComments.push(sfdtComment);
    }

    newAgreementSfdt.comments = sfdtComments;
  }

  const listItems = getListItemsFromDfs($dfs());
  const populateListHandlerResult = $populateListHandler(
    listItems,
    listsStructure
  );
  const listHandler = {
    ...populateListHandlerResult,
    currentSfdt: originalAgreementSfdt,
  };

  newAgreementSfdt.sections = [];
  if (listsStructure) newAgreementSfdt.abstractLists = listsStructure;

  /**
   * @type {import("../nodes/SectionNode").SectionNode[]}
   */
  const sections = root.getChildren();

  // Primary loop that handles the conversion logic.
  for (const section of sections) {
    /**
     * @type {import("../types/sfdt").Block[]}
     */
    const blocks = [];
    /**
     * @type {import("../nodes/ClauseNode").ClauseNode[]}
     */
    const clauses = section.getChildren();
    for (const clause of clauses) {
      const children = clause.getChildren();
      for (const child of children) {
        // A block is equivalent to a paragraph.
        let /** @type {import("../types/sfdt").Block} */ block = {
            inlines: [],
          };

        let numberOfTabs = 0;

        if ($isTocElementNode(child)) {
          block = child.getBlock();
          // When a block belongs to a Table of Contents (TOC) we do not do any further processing.
          blocks.push(block);
          continue;
        } else if ($isCustomTable(child)) {
          // It is a table.
          block = {
            tableFormat: child.getTableFormat(),
            rows: [],
            title: child.getTitle(),
            description: child.getDescription(),
          };
        } else if ($isCaptionNode(child)) {
          block = {
            paragraphFormat: {
              //@ts-ignore
              styleName: "Caption",
            },
            inlines: [],
          };
        } else if ($isCustomHeadingNode(child)) {
          const tag = child.__tag;
          const styleName = `Heading ${
            ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(tag) + 1
          }`;
          block = {
            paragraphFormat: {
              //@ts-ignore
              styleName: styleName,
            },
            inlines: [],
          };

          if (child.characterFormat) {
            block.characterFormat = child.characterFormat;
          }
        } else if ($isCustomListNode(child)) {
          const firstTextNodeChild = child?.getAllTextNodes()?.at(0);
          if (!firstTextNodeChild) continue;

          const listItemNode = $getNearestNodeOfType(
            firstTextNodeChild,
            CustomListItemNode
          );

          // If there is no ListItemNode or its List ID is -1 we skip to the next one.
          if (!listItemNode || listItemNode.getListId() === -1) {
            continue;
          }

          const currentItem = listHandler.root.find(
            (x) => x.id === listItemNode.getKey()
          );
          if (!currentItem) throw new Error("Could not find current item.");

          const /** @type {import("../nodes").CustomListItemNode | null} */ node =
              $getNodeByKey(currentItem.id);
          const absList = listsStructure?.find(
            (abslist) => abslist.abstractListId === currentItem.listId
          );
          const level = absList ? absList.levels[currentItem.level] : undefined;
          if (node && level) {
            // Check if paragraph format object is empty.
            if (
              child?.__paragraphFormat &&
              Object.keys(child.__paragraphFormat).length === 0 &&
              child.__paragraphFormat.constructor === Object
            ) {
              block.paragraphFormat = {
                textAlignment: "Left",
                listFormat: {
                  listId: currentItem.listId,
                  listLevelNumber: currentItem.level,
                },
              };
            } else {
              block.paragraphFormat = {
                ...child.__paragraphFormat,
                listFormat: {
                  ...child.__paragraphFormat.listFormat,
                  listId: currentItem.listId,
                  listLevelNumber: currentItem.level,
                },
              };
            }

            const styleName =
              node.styleName && node.styleName !== ""
                ? node.styleName
                : "List Paragraph";

            const themeStyle = listHandler.currentSfdt.styles.find((el) =>
              findFn(el, currentItem, styleName)
            );

            if (
              listHandler.currentSfdt.styles.find((el) => el.name === styleName)
            ) {
              block.paragraphFormat.styleName = styleName;
            }

            if (node.getBlockStyles() && node.getBlockStyles() !== "") {
              const parsedStyles = parseStyles(node.getBlockStyles());
              if (parsedStyles) {
                parsedStyles.forEach(({ key, value }) => {
                  if (ALLOWED_CHARACTER_FORMAT_KEYS.includes(key)) {
                    const splittedKey = key.split("");
                    const index = splittedKey.indexOf("-");
                    if (index >= 0) {
                      splittedKey.splice(index, 1);
                      splittedKey[index] = splittedKey[index].toUpperCase();
                    }
                    let formattedKey,
                      /** @type {string | number | boolean | null} */
                      formattedValue = value;
                    if (key === "color") {
                      formattedKey = "fontColor";
                    } else if (["font-weight", "font-style"].includes(key)) {
                      formattedKey = value;
                      formattedValue = true;
                    } else if (["font-size"].includes(key)) {
                      formattedKey = splittedKey.join("");
                      formattedValue = parseInt(value);
                    } else if (key === "text-decoration") {
                      formattedKey = value;
                      switch (value) {
                        case "underline": {
                          formattedValue = "Single";
                          break;
                        }
                        case "line-through": {
                          formattedKey = "strikethrough";
                          formattedValue = "SingleStrike";
                          break;
                        }
                        default: {
                          formattedKey = formattedValue = null;
                          break;
                        }
                      }
                    } else {
                      formattedKey = splittedKey.join("");
                    }

                    if (formattedKey && formattedValue) {
                      if (!block.characterFormat) {
                        block.characterFormat = {
                          [formattedKey]: formattedValue,
                        };
                      } else {
                        //@ts-ignore
                        block.characterFormat[formattedKey] = formattedValue;
                      }
                    }
                  }
                });
              }
            }
            if (level.blockPaddingStyles && themeStyle) {
              convertCSStoBlockIndent(level.blockPaddingStyles, block);
            }
            // We have list item info.
            block.inlines = [];
            for (const child of node.getChildren()) {
              if ($isRedlineNode(child)) {
                createInlineTrackedChange(child, block, newAgreementSfdt);
              } else if ($isTextNode(child)) {
                const styles = parseStyles(child.getStyle());
                const newInline = generateInline(child, block, styles);
                block.inlines?.push(newInline);
              } else if (
                $isMarkNode(child) &&
                child.getMarkType() === "mergeField"
              ) {
                generateMergeField(child, block, newAgreementSfdt, comments);
              } else if ($isMarkNode(child)) {
                for (const id of child.__ids) {
                  const comment = comments.find(
                    (comment) => comment.lid === id
                  );

                  if (!comment) {
                    throw new Error("Missing comment required for export.");
                  }

                  block.inlines?.push({
                    commentId: comment?.customId,
                    commentCharacterType: 0,
                  });

                  // Add the replies.
                  if (comment.comments.length) {
                    for (const reply of comment.comments) {
                      block.inlines?.push({
                        commentId: reply?.customId,
                        commentCharacterType: 0,
                      });
                    }
                  }
                }

                for (const gc of child.getChildren()) {
                  if ($isRedlineNode(gc)) {
                    createInlineTrackedChange(gc, block, newAgreementSfdt);
                  } else if ($isTextNode(gc)) {
                    const styles = parseStyles(gc.getStyle());
                    const newInline = generateInline(gc, block, styles);
                    block.inlines?.push(newInline);
                  }
                }

                for (const id of child.__ids) {
                  const comment = comments.find(
                    (comment) => comment.lid === id
                  );

                  if (!comment) {
                    throw new Error("Missing comment required for export.");
                  }

                  block.inlines?.push({
                    commentId: comment?.customId,
                    commentCharacterType: 1,
                  });

                  if (comment.comments.length) {
                    for (const reply of comment.comments) {
                      block.inlines?.push({
                        commentId: reply?.customId,
                        commentCharacterType: 1,
                      });
                    }
                  }
                }
              } else if ($isCrossRefNode(child)) {
                /** LIFO Stack
                 * @type {import("../types/sfdt").Inline[]} */
                const stackEndInlines = [];
                extractCrossReferenceInlines(
                  child,
                  block,
                  stackEndInlines,
                  newAgreementSfdt,
                  {
                    comments: comments,
                  }
                );
              }
            }
            blocks.push(block);
          }
          // listIndex++;

          // TODO: Add clause bookmark in just one place.
          if (!clause.__id) throw new Error("Clause does not have an ID.");

          // Word bookmarks do not like dashes, so we replace them with underscores.
          const wordCompatibleClauseId = clause.__id.replaceAll("-", "_");
          const name = `_c_${wordCompatibleClauseId}`;

          if (!block.inlines) {
            block = {
              inlines: [],
            };
          }

          // Add bookmark to list.
          block?.inlines?.unshift(
            {
              name,
              bookmarkType: 0,
            },
            {
              name,
              bookmarkType: 1,
            }
          );

          const parent = $getNearestNodeOfType(listItemNode, CustomListNode);
          if ($isCustomListNode(parent)) {
            block.characterFormat = parent.getCharacterFormat();
          }

          // If the file only contains lists, the metadata of the document will not be added
          // because here we jump to the next iteration of the loop and skip adding it.
          continue;
        } else {
          block = {
            inlines: [],
          };
        }

        // @ts-ignore
        if (child.__lineSpacing || child.__paragraphSpacing) {
          if (!block.paragraphFormat) block.paragraphFormat = {};
          // @ts-ignore
          if (child.__lineSpacing) {
            // @ts-ignore
            const { lineSpacing, lineSpacingType } = child.__lineSpacing;
            block.paragraphFormat.lineSpacing = lineSpacing;
            block.paragraphFormat.lineSpacingType = lineSpacingType;
          }
          // @ts-ignore
          if (child.__paragraphSpacing) {
            const { contextualSpacing, afterSpacing, beforeSpacing } =
              // @ts-ignore
              child.__paragraphSpacing;
            if (contextualSpacing) {
              block.paragraphFormat.contextualSpacing = contextualSpacing;
            }
            if (afterSpacing) block.paragraphFormat.afterSpacing = afterSpacing;
            if (beforeSpacing) {
              block.paragraphFormat.beforeSpacing = beforeSpacing;
            }
          }
        }

        // Handle indentation.
        if (child.__indentation) {
          if (child.__indentation.firstLineIndent) {
            block.paragraphFormat = {
              ...block.paragraphFormat,
              firstLineIndent: child.__indentation.firstLineIndent,
            };
          }

          if (child.__indentation.leftIndent) {
            block.paragraphFormat = {
              ...block.paragraphFormat,
              leftIndent: child.__indentation.leftIndent,
            };
          }
        }

        const grandchildren = child.getChildren();
        for (const grandchild of grandchildren) {
          if ($isAutoLinkNode(grandchild)) {
            const [textNode] = grandchild.getChildren();
            if (textNode && $isTextNode(textNode)) {
              createTextInline(textNode, block, newAgreementSfdt);
            }
          } else if ($isLineBreakNode(grandchild)) {
            block.inlines?.push({
              // TODO: Not sure if we need this.
              characterFormat: {
                localeId: 1033,
              },
              // Represents a line break on Microsoft Word (represented by the ↵ symbol).
              // Do not confuse with a paragraph break (represented by the ¶ symbol).
              text: "\u000b",
            });
          } else if ($isImageNode(grandchild)) {
            const inline = convertImageNodeToInline(grandchild);
            block.inlines?.push(inline);
          } else if ($isTabNode(grandchild)) {
            numberOfTabs++;
          } else if ($isCustomTable(grandchild)) {
            block = {
              tableFormat: grandchild.getTableFormat(),
              rows: [],
              title: grandchild.getTitle(),
              description: grandchild.getDescription(),
            };
            for (const row of grandchild.getChildren()) {
              if ($isCustomTableRowNode(row)) {
                // @ts-ignore
                generateTableRow(row, newAgreementSfdt, comments, block);
              }
            }
          } else if ($isCustomTableRowNode(grandchild)) {
            generateTableRow(grandchild, newAgreementSfdt, comments, block);
          } else if ($isRedlineNode(grandchild)) {
            //revision exists
            createInlineTrackedChange(grandchild, block, newAgreementSfdt);
          } else if ($isTextNode(grandchild)) {
            createTextInline(grandchild, block, newAgreementSfdt);
          } else if (
            $isMarkNode(grandchild) &&
            grandchild.getMarkType() === "publicComment"
          ) {
            generateComment(grandchild, comments, block, newAgreementSfdt);
          } else if (
            $isMarkNode(grandchild) &&
            grandchild.getMarkType() === "mergeField"
          ) {
            generateMergeField(grandchild, block, newAgreementSfdt, comments);
          } else if (
            $isMarkNode(grandchild) &&
            grandchild.getMarkType() === "both"
          ) {
            const commentIds = grandchild
              .getIDs()
              .filter((id) => id.startsWith("cp_"));
            for (const id of commentIds) {
              const comment = comments.find((comment) => comment.lid === id);

              if (!comment) {
                throw new Error("Missing comment required for export.");
              }

              block.inlines?.push({
                commentId: comment?.customId,
                commentCharacterType: 0,
              });

              // Add the replies.
              if (comment.comments.length) {
                for (const reply of comment.comments) {
                  block.inlines?.push({
                    commentId: reply?.customId,
                    commentCharacterType: 0,
                  });
                }
              }
            }

            generateMergeField(grandchild, block, newAgreementSfdt, comments);
            for (const id of commentIds) {
              const comment = comments.find((comment) => comment.lid === id);

              if (!comment) {
                throw new Error("Missing comment required for export.");
              }

              block.inlines?.push({
                commentId: comment?.customId,
                commentCharacterType: 1,
              });

              if (comment.comments.length) {
                for (const reply of comment.comments) {
                  block.inlines?.push({
                    commentId: reply?.customId,
                    commentCharacterType: 1,
                  });
                }
              }
            }
          } else if ($isCrossRefNode(grandchild)) {
            /** LIFO Stack
             * @type {import("../types/sfdt").Inline[]} */
            const stackEndInlines = [];
            extractCrossReferenceInlines(
              grandchild,
              block,
              stackEndInlines,
              newAgreementSfdt,
              {
                comments,
              }
            );
          }

          if (numberOfTabs > 0) {
            if (!block.paragraphFormat) {
              block.paragraphFormat = {
                firstLineIndent: Math.trunc(
                  originalAgreementSfdt.defaultTabWidth
                ),
              };
            } else {
              block.paragraphFormat.firstLineIndent = Math.trunc(
                originalAgreementSfdt.defaultTabWidth
              );
            }
            if (numberOfTabs > 1) {
              block.paragraphFormat.leftIndent = Math.trunc(
                (numberOfTabs - 1) * originalAgreementSfdt.defaultTabWidth
              );
            }
          }
        }

        if (!block.inlines?.length) {
          block.inlines = [];
        }

        if ($isElementNode(child)) {
          const formatType = child.getFormatType();
          if (formatType) {
            const format =
              formatType.charAt(0).toUpperCase() + formatType.slice(1);

            if (block.paragraphFormat) {
              block.paragraphFormat.textAlignment = format;
            } else {
              block.paragraphFormat = {
                textAlignment: format,
              };
            }
          }
        }

        if (!clause.__id) throw new Error("Clause does not have an ID.");

        // Word bookmarks do not like dashes, so we replace them with underscores.
        const wordCompatibleClauseId = clause.__id.replaceAll("-", "_");

        const name = `_c_${wordCompatibleClauseId}`;

        // If the block is a table we need to add the secret bookmark to the first cell
        // of the table since the table block does not support inlines.
        if (block.rows && block.tableFormat) {
          block?.rows[0]?.cells[0]?.blocks[0]?.inlines?.unshift(
            {
              name,
              bookmarkType: 0,
            },
            {
              name,
              bookmarkType: 1,
            }
          );
        } else {
          block.inlines.unshift(
            {
              name,
              bookmarkType: 0,
            },
            {
              name,
              bookmarkType: 1,
            }
          );
        }

        if (!hasAddedMetadataBookmark && metadata && metadata.agreementId) {
          hasAddedMetadataBookmark = true;

          const agreementId = `_a_${metadata.agreementId}`;
          const orgId = `_o_${metadata.orgId}`;
          const entityId = `_e_${metadata.entityId}`;
          const partyId = `_p_${metadata.partyId}`;

          block.inlines.unshift(
            {
              name: partyId,
              bookmarkType: 0,
            },
            {
              name: partyId,
              bookmarkType: 1,
            }
          );

          block.inlines.unshift(
            {
              name: entityId,
              bookmarkType: 0,
            },
            {
              name: entityId,
              bookmarkType: 1,
            }
          );

          block.inlines.unshift(
            {
              name: orgId,
              bookmarkType: 0,
            },
            {
              name: orgId,
              bookmarkType: 1,
            }
          );

          block.inlines.unshift(
            {
              name: agreementId,
              bookmarkType: 0,
            },
            {
              name: agreementId,
              bookmarkType: 1,
            }
          );
        }

        blocks.push(block);
      }
    }

    const sectionMetadata = section.getMetadata();
    newAgreementSfdt.sections.push({
      blocks,
      // Restore headersFooters and sectionFormat from metadata stored in the Section node.
      headersFooters: sectionMetadata.headersFooters,
      sectionFormat: sectionMetadata.sectionFormat,
    });
  }

  // Iterate generated SFDT to remove repeated starting SFDT comments and replies due to overlapping and
  // cross-block comments.
  const newSfdtSections = newAgreementSfdt.sections;

  const /** @type {Set<string>} */ startingInlineCommentsSet = new Set();

  for (let i = 0; i < newSfdtSections.length; i++) {
    const section = newSfdtSections[i];
    const blocks = section.blocks;

    for (let ii = 0; ii < blocks.length; ii++) {
      const block = blocks[ii];
      const inlines = block.inlines;
      const rows = block.rows;

      // If we encounter a table.
      if (rows && rows.length) {
        delete block.inlines;
        for (let iii = 0; iii < rows.length; iii++) {
          const row = rows[iii];
          const cells = row.cells;

          if (cells && cells.length) {
            for (let iv = 0; iv < cells.length; iv++) {
              const cell = cells[iv];
              const cellBlocks = cell.blocks;

              for (let v = 0; v < cellBlocks.length; v++) {
                const cellBlock = cellBlocks[v];
                const cellBlockInlines = cellBlock.inlines;

                if (cellBlockInlines && cellBlockInlines.length) {
                  for (let vi = 0; vi < cellBlockInlines.length; vi++) {
                    const cellBlockInline = cellBlockInlines[vi];

                    if (cellBlockInline.commentCharacterType === 0) {
                      if (!cellBlockInline.commentId) {
                        throw new Error(
                          "Missing comment ID for inline comment."
                        );
                      }

                      if (
                        startingInlineCommentsSet.has(cellBlockInline.commentId)
                      ) {
                        // Remove element and decrease iterator to account for the removal.
                        cellBlockInlines.splice(vi, 1);
                        vi--;
                      } else {
                        startingInlineCommentsSet.add(
                          cellBlockInline.commentId
                        );
                        const comment = comments.find(
                          (c) => c.customId === cellBlockInline.commentId
                        );
                        if (comment) {
                          cellBlockInlines?.splice(vi, 0, {
                            name: `_cid_${comment.lid}`,
                            bookmarkType: 0,
                          });
                          vi++;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // If regular text.
      else if (inlines && inlines.length) {
        for (let iii = 0; iii < inlines.length; iii++) {
          const inline = inlines[iii];

          if (inline.commentCharacterType === 0) {
            if (!inline.commentId) {
              throw new Error("Missing comment ID for inline comment.");
            }
            if (startingInlineCommentsSet.has(inline.commentId)) {
              // Remove element and decrease iterator to account for the removal.
              inlines.splice(iii, 1);
              iii--;
            } else {
              startingInlineCommentsSet.add(inline.commentId);
              const comment = comments.find(
                (c) => c.customId === inline.commentId
              );
              if (comment) {
                inlines?.splice(iii, 0, {
                  name: `_cid_${comment.lid}`,
                  bookmarkType: 0,
                });
                iii++;
              }
            }
          }
        }
      }
    }
  }

  const /** @type {Set<string>} */ endingInlineCommentsSet = new Set();

  // Iterate generated SFDT backwards to remove repeated SFDT ending comments and replies due to overlapping
  // and cross-block comments.
  for (let i = newSfdtSections.length - 1; i >= 0; i--) {
    const section = newSfdtSections[i];
    const blocks = section.blocks;

    for (let ii = blocks.length - 1; ii >= 0; ii--) {
      const block = blocks[ii];
      const inlines = block.inlines;
      const rows = block.rows;

      // If we encounter a table.
      if (rows && rows.length) {
        for (let iii = rows.length - 1; iii >= 0; iii--) {
          const row = rows[iii];
          const cells = row.cells;

          if (cells && cells.length) {
            for (let iv = cells.length - 1; iv >= 0; iv--) {
              const cell = cells[iv];
              const cellBlocks = cell.blocks;

              for (let v = cellBlocks.length - 1; v >= 0; v--) {
                const cellBlock = cellBlocks[v];
                const cellBlockInlines = cellBlock.inlines;

                if (cellBlockInlines && cellBlockInlines.length) {
                  for (let vi = cellBlockInlines.length - 1; vi >= 0; vi--) {
                    const cellBlockInline = cellBlockInlines[vi];

                    if (cellBlockInline.commentCharacterType === 1) {
                      if (!cellBlockInline.commentId) {
                        throw new Error(
                          "Missing comment ID for inline comment."
                        );
                      }

                      if (
                        endingInlineCommentsSet.has(cellBlockInline.commentId)
                      ) {
                        cellBlockInlines.splice(vi, 1);
                      } else {
                        endingInlineCommentsSet.add(cellBlockInline.commentId);
                        const comment = comments.find(
                          (c) => c.customId === cellBlockInline.commentId
                        );
                        if (comment) {
                          cellBlockInlines?.splice(vi + 1, 0, {
                            name: `_cid_${comment.lid}`,
                            bookmarkType: 1,
                          });
                          vi--;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // If regular text.
      else if (inlines) {
        for (let iii = inlines.length - 1; iii >= 0; iii--) {
          const inline = inlines[iii];

          if (inline.commentCharacterType === 1) {
            if (!inline.commentId) {
              throw new Error("Missing comment ID for inline comment.");
            }

            if (endingInlineCommentsSet.has(inline.commentId)) {
              inlines.splice(iii, 1);
            } else {
              endingInlineCommentsSet.add(inline.commentId);
              const comment = comments.find(
                (c) => c.customId === inline.commentId
              );
              if (comment) {
                inlines?.splice(iii + 1, 0, {
                  name: `_cid_${comment.lid}`,
                  bookmarkType: 1,
                });
                iii--;
              }
            }
          }
        }
      }
    }
  }

  // // We do this to clean the list levels from the changes done
  // // on import and edit avoiding problems on DocIO.
  // if (Array.isArray(newAgreementSfdt.abstractLists)) {
  //   newAgreementSfdt.abstractLists.forEach((abs) => {
  //     abs.levels.forEach((level) => {
  //       delete level.blockPaddingStyles;
  //       delete level.blockStyles;
  //       delete level.paddingStyles;
  //     });
  //   });
  // } else {
  //   newAgreementSfdt.abstractLists = [];
  // }

  // Not sure what the logic above (which removes some styles from the
  // abstract lists) accomplished. Commenting it for now. Uncoment if
  // exported lists start having issues.
  if (!Array.isArray(newAgreementSfdt.abstractLists)) {
    newAgreementSfdt.abstractLists = [];
  }

  // Restore original abstract lists.
  newAgreementSfdt.abstractLists = originalAbstractLists;

  console.info("%cGenerated SFDT:", "color: gold");
  console.info(JSON.parse(JSON.stringify(newAgreementSfdt)));

  return newAgreementSfdt;
}

/**
 *
 * @param {import("../nodes/CrossRefNode").CrossRefNode} node
 * @param {import("../types/sfdt").Block} block
 * @param {import("../types/sfdt").Inline[]} stackEndInlines
 * @param {import("../types/sfdt").Sfdt} newAgreementSfdt
 * @param {{comments: CanveoComment[]}} complementaryData
 */
export const extractCrossReferenceInlines = (
  node,
  block,
  stackEndInlines,
  newAgreementSfdt,
  complementaryData
) => {
  if (!block.inlines) {
    block.inlines = [];
  }

  const isTarget = node.isTarget;
  const target = node.getTarget();
  let index;
  if (isTarget) {
    const firstInline = {
      name: target,
      bookmarkType: 0,
    };
    block.inlines.push(firstInline);
    index = block.inlines.indexOf(firstInline);
    stackEndInlines.push({
      name: target,
      bookmarkType: 1,
    });
  } else {
    const firstInlines = [
      {
        hasFieldEnd: true,
        fieldType: 0,
      },
      {
        text: generateCrossReferenceFieldText(node),
      },
      {
        fieldType: 2,
      },
    ];
    block.inlines.push(...firstInlines);
    index = block.inlines.indexOf(firstInlines[0]);
    stackEndInlines.push({
      fieldType: 1,
    });
  }

  for (const child of node.getChildren()) {
    if ($isImageNode(child)) {
      const inline = convertImageNodeToInline(child);
      block.inlines?.push(inline);
    } else if ($isRedlineNode(child)) {
      createInlineTrackedChange(child, block, newAgreementSfdt);
    } else if ($isTextNode(child)) {
      createTextInline(child, block, newAgreementSfdt);
    } else if ($isMarkNode(child)) {
      for (const id of child.__ids) {
        const comment = complementaryData.comments.find(
          (comment) => comment.lid === id
        );

        if (!comment) {
          throw new Error("Missing comment required for export.");
        }

        block.inlines?.push({
          commentId: comment?.customId,
          commentCharacterType: 0,
        });

        // Add the replies.
        if (comment.comments.length) {
          for (const reply of comment.comments) {
            block.inlines?.push({
              commentId: reply?.customId,
              commentCharacterType: 0,
            });
          }
        }
      }

      for (const grandgrandchild of child.getChildren()) {
        if ($isImageNode(grandgrandchild)) {
          const inline = convertImageNodeToInline(grandgrandchild);
          block.inlines?.push(inline);
        } else if ($isRedlineNode(grandgrandchild)) {
          createInlineTrackedChange(grandgrandchild, block, newAgreementSfdt);
        } else if ($isTextNode(grandgrandchild)) {
          createTextInline(grandgrandchild, block, newAgreementSfdt);
        } else if ($isCrossRefNode(grandgrandchild)) {
          /** LIFO Stack
           * @type {import("../types/sfdt").Inline[]} */
          const stackEndInlines = [];
          extractCrossReferenceInlines(
            grandgrandchild,
            block,
            stackEndInlines,
            newAgreementSfdt,
            { comments: complementaryData.comments }
          );
        }
      }

      for (const id of child.__ids) {
        const comment = complementaryData.comments.find(
          (comment) => comment.lid === id
        );

        if (!comment) {
          throw new Error("Missing comment required for export.");
        }

        block.inlines?.push({
          commentId: comment?.customId,
          commentCharacterType: 1,
        });

        if (comment.comments.length) {
          for (const reply of comment.comments) {
            block.inlines?.push({
              commentId: reply?.customId,
              commentCharacterType: 1,
            });
          }
        }
      }
    } else if ($isCrossRefNode(child)) {
      extractCrossReferenceInlines(
        child,
        block,
        stackEndInlines,
        newAgreementSfdt,
        { comments: complementaryData.comments }
      );
    }
    // This probably happens when a link is created by pressing space after typing a URL on Word.
    else if (child.__type === "autolink") {
      // @ts-ignore
      const childNodes = child.getAllTextNodes();
      if (childNodes.length) {
        const textNode = childNodes[0];
        createTextInline(textNode, block, newAgreementSfdt);
      }
    }
  }

  const poppedElement = stackEndInlines.pop();
  if (poppedElement) {
    block.inlines.push(poppedElement);
  } else {
    // Could not pop end element or stack was not properly cleared and there are missing inlines.
    throw new Error(
      "Something went wrong closing a cross reference node on export."
    );
  }

  const { isLink } = node.getMetadata();
  if (isLink && !node.isTarget) {
    // Add hyperlink stylename if needed.
    for (let i = index; i < block.inlines.length; i++) {
      const el = block.inlines[i];
      if (
        !el.fieldType &&
        !el.bookmarkType &&
        !el.imageString &&
        el.text &&
        !(el.text?.match(REF_REGEX) ?? el.text?.match(HYPERLINK_REGEX))
      ) {
        if (el.characterFormat) {
          el.characterFormat.styleName = "Hyperlink";
        } else {
          el.characterFormat = {
            styleName: "Hyperlink",
          };
        }
      }
    }
  }
};

/**
 * @param {import("../nodes/CustomTableRowNode").CustomTableRowNode} rowNode
 * @param {import("../types/sfdt").Sfdt} newAgreementSfdt
 * @param {CanveoComment[]} comments
 * @param {import("../types/sfdt").Block} block
 */
function generateTableRow(rowNode, newAgreementSfdt, comments, block) {
  const /** @type {import("../types/sfdt").Row} */ row = {
      rowFormat: rowNode.getRowFormat(),
      cells: [],
    };

  const /** @type {import("../nodes/CustomTableCellNode").CustomTableCellNode[]} */ cellNodes =
      rowNode.getChildren();
  if (cellNodes.every((cellNode) => cellNode.hasHeaderState(0))) {
    row.rowFormat.isHeader = true;
  }

  for (const cellNode of cellNodes) {
    const /** @type {import("../types/sfdt").Cell} */ cell = {
        cellFormat: cellNode.getCellFormat(),
        blocks: [],
      };

    let /** @type {import("../types/sfdt").Block} */ cellBlock = {
        inlines: [],
      };

    for (const node of cellNode.getChildren()) {
      if ($isCustomParagraphNode(node)) {
        cellBlock = {
          characterFormat: {
            bold: true,
            boldBidi: true,
            localeId: 3082,
          },
          // @ts-ignore
          paragraphFormat: node.__paragraphFormat,
          inlines: [],
        };

        const paragraphChildren = node.getChildren();
        for (const node of paragraphChildren) {
          if ($isRedlineNode(node)) {
            createInlineTrackedChange(node, cellBlock, newAgreementSfdt);
          } else if ($isTextNode(node)) {
            const styles = parseStyles(node.getStyle());
            const newInline = generateInline(node, cellBlock, styles);
            cellBlock.inlines?.push(newInline);
          } else if (
            $isMarkNode(node) &&
            node.getMarkType() === "publicComment"
          ) {
            generateComment(node, comments, cellBlock, newAgreementSfdt);
          } else if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
            generateMergeField(node, cellBlock, newAgreementSfdt, comments);
          } else if ($isMarkNode(node) && node.getMarkType() === "both") {
            // TODO: If overlapping comments and merge fields are not working check
            // if we should pass cellBlock instead of block in the lines below.
            const commentIds = node
              .getIDs()
              .filter((id) => id.startsWith("cp_"));
            for (const id of commentIds) {
              const comment = comments.find((comment) => comment.lid === id);

              if (!comment) {
                throw new Error("Missing comment required for export.");
              }

              block.inlines?.push({
                commentId: comment?.customId,
                commentCharacterType: 0,
              });

              // Add the replies.
              if (comment.comments.length) {
                for (const reply of comment.comments) {
                  block.inlines?.push({
                    commentId: reply?.customId,
                    commentCharacterType: 0,
                  });
                }
              }
            }

            for (const grandgrandchild of node.getChildren()) {
              if ($isImageNode(grandgrandchild)) {
                const inline = convertImageNodeToInline(grandgrandchild);
                block.inlines?.push(inline);
              } else if ($isRedlineNode(grandgrandchild)) {
                createInlineTrackedChange(
                  grandgrandchild,
                  block,
                  newAgreementSfdt
                );
              } else if ($isTextNode(grandgrandchild)) {
                createTextInline(grandgrandchild, block, newAgreementSfdt);
              } else if ($isCrossRefNode(grandgrandchild)) {
                // LIFO stack.
                const /** @type {import("../types/sfdt").Inline[]} */ stackEndInlines =
                    [];
                extractCrossReferenceInlines(
                  grandgrandchild,
                  block,
                  stackEndInlines,
                  newAgreementSfdt,
                  { comments }
                );
              }
            }

            generateMergeField(node, block, newAgreementSfdt, comments);

            for (const id of commentIds) {
              const comment = comments.find((comment) => comment.lid === id);

              if (!comment) {
                throw new Error("Missing comment required for export.");
              }

              block.inlines?.push({
                commentId: comment?.customId,
                commentCharacterType: 1,
              });

              if (comment.comments.length) {
                for (const reply of comment.comments) {
                  block.inlines?.push({
                    commentId: reply?.customId,
                    commentCharacterType: 1,
                  });
                }
              }
            }
          } else if ($isImageNode(node)) {
            const inline = convertImageNodeToInline(node);
            cellBlock.inlines?.push(inline);
          } else if ($isCrossRefNode(node)) {
            // LIFO stack.
            const /** @type {import("../types/sfdt").Inline[]} */ stackEndInlines =
                [];
            extractCrossReferenceInlines(
              node,
              cellBlock,
              stackEndInlines,
              newAgreementSfdt,
              {
                comments: comments,
              }
            );
          }
        }
      }

      // if ($isCustomParagraphNode(node)) {
      //   cell.blocks.push({
      //     characterFormat: {
      //       bold: true,
      //       boldBidi: true,
      //       localeId: 3082,
      //     },
      //     paragraphFormat: {
      //       lineSpacing: 1.5,
      //       lineSpacingType: "Multiple",
      //       textAlignment: "Justify",
      //     },
      //     inlines: [],
      //   });
      // } else
      if ($isRedlineNode(node)) {
        createInlineTrackedChange(node, cellBlock, newAgreementSfdt);
      } else if ($isTextNode(node)) {
        const styles = parseStyles(node.getStyle());
        const newInline = generateInline(node, cellBlock, styles);
        cellBlock.inlines?.push(newInline);
      } else if ($isMarkNode(node) && node.getMarkType() === "publicComment") {
        generateComment(node, comments, cellBlock, newAgreementSfdt);
      } else if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
        generateMergeField(node, cellBlock, newAgreementSfdt, comments);
      } else if ($isMarkNode(node) && node.getMarkType() === "both") {
        // TODO: If overlapping comments and merge fields are not working check
        // if we should pass cellBlock instead of block in the lines below.
        const commentIds = node.getIDs().filter((id) => id.startsWith("cp_"));
        for (const id of commentIds) {
          const comment = comments.find((comment) => comment.lid === id);

          if (!comment) {
            throw new Error("Missing comment required for export.");
          }

          block.inlines?.push({
            commentId: comment?.customId,
            commentCharacterType: 0,
          });

          // Add the replies.
          if (comment.comments.length) {
            for (const reply of comment.comments) {
              block.inlines?.push({
                commentId: reply?.customId,
                commentCharacterType: 0,
              });
            }
          }
        }

        for (const grandgrandchild of node.getChildren()) {
          if ($isImageNode(grandgrandchild)) {
            const inline = convertImageNodeToInline(grandgrandchild);
            block.inlines?.push(inline);
          } else if ($isRedlineNode(grandgrandchild)) {
            createInlineTrackedChange(grandgrandchild, block, newAgreementSfdt);
          } else if ($isTextNode(grandgrandchild)) {
            createTextInline(grandgrandchild, block, newAgreementSfdt);
          } else if ($isCrossRefNode(grandgrandchild)) {
            // LIFO stack.
            const /** @type {import("../types/sfdt").Inline[]} */ stackEndInlines =
                [];
            extractCrossReferenceInlines(
              grandgrandchild,
              block,
              stackEndInlines,
              newAgreementSfdt,
              { comments }
            );
          }
        }

        generateMergeField(node, block, newAgreementSfdt, comments);

        for (const id of commentIds) {
          const comment = comments.find((comment) => comment.lid === id);

          if (!comment) {
            throw new Error("Missing comment required for export.");
          }

          block.inlines?.push({
            commentId: comment?.customId,
            commentCharacterType: 1,
          });

          if (comment.comments.length) {
            for (const reply of comment.comments) {
              block.inlines?.push({
                commentId: reply?.customId,
                commentCharacterType: 1,
              });
            }
          }
        }
      } else if ($isCrossRefNode(node)) {
        // LIFO stack.
        const /** @type {import("../types/sfdt").Inline[]} */ stackEndInlines =
            [];
        extractCrossReferenceInlines(
          node,
          cellBlock,
          stackEndInlines,
          newAgreementSfdt,
          {
            comments: comments,
          }
        );
      }

      // TODO: When merging we need to put this back into the previous
      // to account for linebreaks.
      cell.blocks?.push(cellBlock);
    }

    row.cells.push(cell);
  }

  block.rows?.push(row);
}

/**
 * @param {import("../nodes/MarkNode").MarkNode} node
 * @param {CanveoComment[]} comments
 * @param {import("../types/sfdt").Block} block
 * @param {import("../types/sfdt").Sfdt} sfdt
 */
function generateComment(node, comments, block, sfdt) {
  const ids = node.getIDs();
  for (const id of ids) {
    const comment = comments.find((comment) => comment.lid === id);

    if (!comment) {
      throw new Error("Missing comment required for export. Id: " + id);
    }

    block.inlines?.push({
      commentId: comment?.customId,
      commentCharacterType: 0,
    });

    // Add the replies.
    if (comment.comments.length) {
      for (const reply of comment.comments) {
        block.inlines?.push({
          commentId: reply?.customId,
          commentCharacterType: 0,
        });
      }
    }
  }

  for (const grandgrandchild of node.getChildren()) {
    if ($isRedlineNode(grandgrandchild)) {
      createInlineTrackedChange(grandgrandchild, block, sfdt);
    } else if ($isTextNode(grandgrandchild)) {
      createTextInline(grandgrandchild, block, sfdt);
    } else if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
      generateMergeField(node, block, sfdt, comments);
    }
    //no need to check for comments within comments because
  }

  for (const id of ids) {
    const comment = comments.find((comment) => comment.lid === id);

    if (!comment) {
      throw new Error("Missing comment required for export.");
    }

    block.inlines?.push({
      commentId: comment?.customId,
      commentCharacterType: 1,
    });

    if (comment.comments.length) {
      for (const reply of comment.comments) {
        block.inlines?.push({
          commentId: reply?.customId,
          commentCharacterType: 1,
        });
      }
    }
  }
}

/**
 *
 * @param {import("lexical").TextNode} grandchild
 * @param {import("../types/sfdt").Block} block
 * @param {import("../types/sfdt").Sfdt} newAgreementSfdt
 */
function createInlineTrackedChange(grandchild, block, newAgreementSfdt) {
  const styles = parseStyles(grandchild.getStyle());
  const newInline = generateInline(grandchild, block, styles);
  // @ts-ignore
  const { revisionId, creatorEmail, creationDate } = grandchild.getMetadata();
  if (revisionId) {
    newInline.revisionIds = [];
    const revision = newAgreementSfdt.revisions?.find(
      (rev) => rev.revisionId === revisionId
    );
    // Uncomment when uploading files for tests.
    // const revision = newAgreementSfdt.revisions?.find(
    //   (rev) => rev.revisionId === "410787ef-25b2-4715-8e58-46f0c911a014"
    // );
    if (!revision) {
      //revision is new, does not exists
      // update revisions of sfdt
      /** @type {import("../types/sfdt").Revision} */
      const newRevision = {
        author: creatorEmail,
        date: creationDate,
        revisionId: generateRevisionID(),
        // @ts-ignore
        revisionType: ["add", "xdel"].includes(grandchild.getRedlineType())
          ? "Insertion"
          : "Deletion",
      };

      newAgreementSfdt.revisions?.push(newRevision);

      // add revisionIds id to this inline
      if (newInline.revisionIds) {
        newInline.revisionIds.push(newRevision.revisionId);
      } else newInline.revisionIds = [newRevision.revisionId];
      //Uncomment when uploading files for tests.
      // newInline.revisionIds = newInline.revisionIds.map(
      //   (e) => "410787ef-25b2-4715-8e58-46f0c911a014"
      // );
    } else {
      if (revisionId) newInline.revisionIds = [revisionId];
      // Uncomment when uploading files for tests.
      // if (revisionId)
      //   newInline.revisionIds = ["410787ef-25b2-4715-8e58-46f0c911a014"];
    }
  }
  block.inlines?.push(newInline);
}

/**
 *
 * @param {import("lexical").TextNode} grandchild
 * @param {import("../types/sfdt").Block} block
 * @param {import("../types/sfdt").Sfdt} sfdt
 */
function createTextInline(grandchild, block, sfdt) {
  const styles = parseStyles(grandchild.getStyle());

  const styleName = styles.find((st) => st.key === "styleName") ?? {
    key: "Paragraph",
    value: "",
  };

  if (styleName.key !== "Paragraph" && !block.paragraphFormat?.styleName) {
    const parentParagraphFormat = grandchild?.getParent()?.__paragraphFormat;
    if (parentParagraphFormat?.styleName === styleName.value) {
      block.paragraphFormat = parentParagraphFormat;
    } else {
      block.paragraphFormat = {
        styleName: styleName.value,
      };
    }
  }

  if (grandchild.getTextContent()) {
    const newInline = generateInline(grandchild, block, styles);
    const styleDoesNotExist = !sfdt?.styles.find(
      (st) => st.name === styleName.value
    );
    if (styleDoesNotExist && styleName.value) {
      sfdt.styles.push({
        type: "Paragraph",
        name: styleName.value,
        basedOn: "Normal",
      });
    }
    block.inlines?.push(newInline);
  }
}

function generateRevisionID() {
  // return TESTING ? "410787ef-25b2-4715-8e58-46f0c911a014" : uuid();
  return uuid();
}

/**
 *
 * @param {import("../nodes/MarkNode").MarkNode} node
 * @param {import("../types/sfdt").Block} block
 * @param {import("../types/sfdt").Sfdt} sfdt
 * @param {CanveoComment[]} comments
 */
const generateMergeField = (node, block, sfdt, comments) => {
  const markType = node.getMarkType();
  if (
    !$isMarkNode(node) ||
    ($isMarkNode(node) &&
      markType &&
      !["mergeField", "both"].includes(markType))
  ) {
    throw new Error("Node argument is not a merge field.");
  }
  //MERGE FIELDS
  const ids = node.getIDs();
  const editorMarkNodeId = ids.find((id) => !id.startsWith("cp_"));
  const secretMergeFieldBookmark = `_mf_${editorMarkNodeId}`;
  if (!block.inlines) {
    block.inlines = [];
  }
  //secret bookmark for import
  block.inlines.push({
    name: secretMergeFieldBookmark,
    bookmarkType: 0,
  });
  for (const child of node.getChildren()) {
    if ($isRedlineNode(child)) {
      createInlineTrackedChange(child, block, sfdt);
    } else if ($isTextNode(child)) {
      createTextInline(child, block, sfdt);
    } else if ($isMarkNode(node) && node.getMarkType() === "publicComment") {
      generateComment(node, comments, block, sfdt);
    } else if ($isMarkNode(node) && node.getMarkType() === "mergeField") {
      throw new Error("Overlapping of merge fields is not allowed.");
    }
  }
  block.inlines.push({
    name: secretMergeFieldBookmark,
    bookmarkType: 1,
  });
};

/** Find suitable theme styles
 *
 * @param {import("../types/sfdt").styles} el
 * @param {PseudoListItem} current
 * @param {string} styleName
 * @returns
 */
function findFn(el, current, styleName) {
  const listFormat = el.paragraphFormat?.listFormat;
  if (listFormat) {
    //if has listFormat
    if (listFormat.listId === current.listId) {
      // if it's same list id
      return (
        el.name === styleName
        // && (listFormat.listLevelNumber
        //   ? listFormat.listLevelNumber === current.level
        //   : current.level === 0)
      ); //if has same level of indentation (when it's 0, listLevelNumber is undefined)
    }
    //else theme style doesn't have style for list
  }
  return false;
}

/**
 * Converts a `Lexical` `ImageNode` to a Syncfusion inline.
 *
 * @param {import("../nodes/ImageNode").ImageNode} imageNode
 * @returns {import("../types/sfdt").Inline}
 */
function convertImageNodeToInline(imageNode) {
  // The image inline is stored on the image node as metadata when converting the SFDT to Lexical.
  const imageNodeMetadata = imageNode.getMetadata();

  // If there is no metadata (the Syncfusion inline object) we revert to the previous approach.
  if (JSON.stringify(imageNodeMetadata) === "{}") {
    const { __altText, __height, __width, __src, __isInline } = imageNode;
    const inline = {
      alternativeText: __altText,
      width: __width,
      height: __height,
      imageString: __src,
      isInlineImage: __isInline,
    };
    return inline;
  }

  // Otherwise we just return the inline.
  return imageNodeMetadata;
}
