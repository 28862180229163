import { faCopy, faFile, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import { reviewsAndApprovalsParentDialogStyles } from "../../reviewsAndApprovals/constants/reviewsAndApprovalsParentDialogStyles";
import {
  getFilledCircleStyles,
  getOutlinedCircleStyles,
} from "../DialogOwnerSendOptions/constants";
import TabPanel from "./components/TabPanel";
import SetDefaultsTab from "./tabs/SetDefaultsTab";
import TemplateApprovalsTab from "./tabs/TemplateApprovalsTab";
import TemplateReviewsTab from "./tabs/TemplateReviewsTab";

/**
 * @typedef {object} DialogConfigureTemplateProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {DialogConfigureTemplateTemplate} template
 * @property {"Review" | "Approval" | string & {} | null} taskType
 * @property {string | null} taskId
 */

/**
 * @param {DialogConfigureTemplateProps} props
 * @returns {JSX.Element}
 */
export default function DialogConfigureTemplate({
  open,
  close,
  template,
  taskType,
  taskId,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(false);
  const [configureTemplateMetadata, setConfigureTemplateMetadata] = useState(
    /** @returns {ConfigureTemplateMetadata | null} */
    () => null
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedButton, setSelectedButton] = useState(() => {
    return /** @type {"approvals" | "reviews"} */ ("approvals");
  });

  useEffect(
    () => {
      if (!configureTemplateMetadata) {
        loadConfigureTemplateMetadata(template._id);
      }
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * @param {string} templateId
   * @returns {Promise<void>}
   */
  async function loadConfigureTemplateMetadata(templateId) {
    setIsLoading(true);

    const getConfigureTemplateMetadataResponse = await axios
      .get(
        `${state.settings.api}template/${templateId}/configureTemplateMetadata`
      )
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to retrieve configure template metadata, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    if (!getConfigureTemplateMetadataResponse) return;

    /** @type {ConfigureTemplateMetadata} */
    const configureTemplateMetadata =
      getConfigureTemplateMetadataResponse.data.data;

    setConfigureTemplateMetadata(configureTemplateMetadata);
  }

  /**
   * @param {React.SyntheticEvent} _event
   * @param {number} newValue
   * @returns {void}
   */
  function handleChange(_event, newValue) {
    setSelectedTab(newValue);
  }

  /**
   * @param {ReviewTicket[]} reviewTickets
   * @returns {void}
   */
  function handleUpdateReviewTickets(reviewTickets) {
    setConfigureTemplateMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, reviewTasks: reviewTickets };
    });
  }

  /**
   * @param {ApprovalTicket[]} approvalTickets
   * @returns {void}
   */
  function handleUpdateApprovalTickets(approvalTickets) {
    setConfigureTemplateMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, approvalTasks: approvalTickets };
    });
  }

  /**
   * @param {ReviewTicket[]} reviewTickets
   * @returns {void}
   */
  function handleUpdateDefaultReviewTickets(reviewTickets) {
    setConfigureTemplateMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, defaultReviewTasks: reviewTickets };
    });
  }

  /**
   * @param {ApprovalTicket[]} approvalTickets
   * @returns {void}
   */
  function handleUpdateDefaultApprovalTickets(approvalTickets) {
    setConfigureTemplateMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, defaultApprovalTasks: approvalTickets };
    });
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={false}
      sx={reviewsAndApprovalsParentDialogStyles}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        <Grid container direction="row" alignItems="center" gap={2}>
          <Grid item>Approvals and Reviews</Grid>

          {/* <Grid item>
            <Chip
              color="secondary"
              label={template.active ? "Active" : "Inactive"}
            />
          </Grid> */}
        </Grid>
      </DialogTitle>

      <DialogContent>
        {isLoading && (
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <CanveoCircularProgress />
            </Grid>
          </Grid>
        )}

        {!isLoading && configureTemplateMetadata && (
          <Box mt={2} ml={4} mr={4}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tooltip
                title="Create default approvals for all agreements based on this template"
                placement="top"
              >
                <Tab
                  label={
                    <Box mb={2}>For Agreements based on this Template</Box>
                  }
                  sx={{ fontWeight: "bold" }}
                  icon={
                    <Box>
                      <Box
                        mb={2}
                        sx={
                          selectedTab === 0
                            ? getFilledCircleStyles()
                            : getOutlinedCircleStyles()
                        }
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Box>
                    </Box>
                  }
                />
              </Tooltip>

              <Tooltip
                title="Get this template approved by others"
                placement="top"
              >
                <Tab
                  label={<Box mb={2}>For this Template</Box>}
                  sx={{ fontWeight: "bold" }}
                  icon={
                    <Box>
                      <Box
                        mb={2}
                        sx={
                          selectedTab === 1
                            ? getFilledCircleStyles()
                            : getOutlinedCircleStyles()
                        }
                      >
                        <FontAwesomeIcon icon={faFile} />
                      </Box>
                    </Box>
                  }
                />
              </Tooltip>
            </Tabs>

            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            />

            <Box>
              <TabPanel value={selectedTab} index={0}>
                <SetDefaultsTab
                  template={template}
                  reviewTickets={configureTemplateMetadata.defaultReviewTasks}
                  updateReviewTickets={handleUpdateDefaultReviewTickets}
                  approvalTickets={
                    configureTemplateMetadata.defaultApprovalTasks
                  }
                  updateApprovalTickets={handleUpdateDefaultApprovalTickets}
                />
              </TabPanel>

              <TabPanel value={selectedTab} index={1}>
                <Grid container direction="row" gap={1} mt={4}>
                  <Grid item>
                    <Button
                      variant={
                        selectedButton === "approvals"
                          ? "contained"
                          : "outlined"
                      }
                      disableElevation
                      onClick={() => setSelectedButton("approvals")}
                    >
                      Approvals
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant={
                        selectedButton === "reviews" ? "contained" : "outlined"
                      }
                      disableElevation
                      onClick={() => setSelectedButton("reviews")}
                    >
                      Reviews
                    </Button>
                  </Grid>
                </Grid>

                {selectedButton === "approvals" && (
                  <TemplateApprovalsTab
                    template={template}
                    approvalTickets={configureTemplateMetadata.approvalTasks}
                    updateApprovalTickets={handleUpdateApprovalTickets}
                    taskId={taskType === "Approval" ? taskId : null}
                  />
                )}

                {selectedButton === "reviews" && (
                  <TemplateReviewsTab
                    template={template}
                    reviewTickets={configureTemplateMetadata.reviewTasks}
                    updateReviewTickets={handleUpdateReviewTickets}
                    taskId={taskType === "Review" ? taskId : null}
                  />
                )}
              </TabPanel>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
