import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../state/store";
import { NewReviewTicketButtonPanel } from "../../../reviewsAndApprovals/components/NewReviewTicketButtonPanel";
import ReviewsTable from "../../../reviewsAndApprovals/components/ReviewsTable";
import DialogCreateReviewTicket from "../../DialogCreateReviewTicket";
import DialogReviewTicketDetails from "../../DialogReviewTicketDetails";

/**
 * @typedef {object} ReviewTabProps
 * @property {DialogOwnerSendOptionsAgreement} agreement
 * @property {ReviewTicket[]} reviewTickets
 * @property {(reviewTickets: ReviewTicket[]) => void} updateReviewTickets
 * @property {string | null} taskId
 */

/**
 * @param {ReviewTabProps} props
 * @returns {JSX.Element}
 */
export default function ReviewTab({
  agreement,
  reviewTickets,
  updateReviewTickets,
  taskId,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [openDialogCreateReviewTicket, setOpenDialogCreateReviewTicket] =
    useState(false);
  const [selectedReviewTicketIndex, setSelectedReviewTicketIndex] = useState(
    () => {
      if (taskId) {
        const ticketIndex = reviewTickets.findIndex(
          (reviewTicket) => reviewTicket._id === taskId
        );
        if (ticketIndex >= 0) {
          return ticketIndex;
        }
      }

      return -1;
    }
  );

  const openDialogReviewTicketDetails = selectedReviewTicketIndex >= 0;

  /**
   * @param {string} agreementId
   * @returns {Promise<void>}
   */
  async function loadAgreementReviewTickets(agreementId) {
    try {
      const result = await axios.get(
        `${state.settings.api}task?agreementId=${agreementId}&type=Review`
      );

      /** @type {ReviewTicket[]} */
      const reviewTickets = result.data.data;
      updateReviewTickets(reviewTickets);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to retrieve review requests, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    }
  }

  /**
   * @param {ReviewTicket} reviewTicket
   * @returns {void}
   */
  function handleUpdateReviewTicket(reviewTicket) {
    const updatedReviewTickets = [...reviewTickets];

    const index = updatedReviewTickets.findIndex(
      (rt) => rt._id === reviewTicket._id
    );
    updatedReviewTickets[index] = reviewTicket;

    updateReviewTickets(updatedReviewTickets);
  }

  /**
   * @param {string} reviewTicketId
   * @returns {void}
   */
  function handleDeleteReviewTicket(reviewTicketId) {
    const updatedReviewTickets = [...reviewTickets];

    const index = updatedReviewTickets.findIndex(
      (rt) => rt._id === reviewTicketId
    );
    updatedReviewTickets.splice(index, 1);

    updateReviewTickets(updatedReviewTickets);
  }

  /**
   * @returns {ReviewList}
   */
  function getReviewListTemplate() {
    const oldestReviewTemplate = reviewTickets
      .sort(
        (previous, next) =>
          new Date(previous.createdAt).getTime() -
          new Date(next.createdAt).getTime()
      )
      .find((reviewTicket) => reviewTicket.isDefaultRequest);
    if (!oldestReviewTemplate) return [];

    return oldestReviewTemplate.reviewChain.reviewList;
  }

  return (
    <Box mt={4}>
      <Grid container>
        {reviewTickets.length === 0 && (
          <NewReviewTicketButtonPanel
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />
        )}
      </Grid>

      {reviewTickets.length === 1 && (
        <>
          <NewReviewTicketButtonPanel
            buttonPosition="left"
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />

          <DialogReviewTicketDetails
            open={openDialogReviewTicketDetails}
            close={() => setSelectedReviewTicketIndex(-1)}
            agreement={agreement}
            reviewTicket={reviewTickets[0]}
            updateReviewTicket={handleUpdateReviewTicket}
            deleteReviewTicket={handleDeleteReviewTicket}
            displayOnlyDialogContent
          />
        </>
      )}

      {reviewTickets.length > 1 && (
        <>
          <NewReviewTicketButtonPanel
            buttonPosition="left"
            setOpenDialogCreateReviewTicket={setOpenDialogCreateReviewTicket}
          />

          <ReviewsTable
            reviewTickets={reviewTickets}
            setSelectedReviewTicketIndex={setSelectedReviewTicketIndex}
          />

          {openDialogReviewTicketDetails && (
            <DialogReviewTicketDetails
              open={openDialogReviewTicketDetails}
              close={() => setSelectedReviewTicketIndex(-1)}
              agreement={agreement}
              reviewTicket={reviewTickets[selectedReviewTicketIndex]}
              updateReviewTicket={handleUpdateReviewTicket}
              deleteReviewTicket={handleDeleteReviewTicket}
            />
          )}
        </>
      )}

      {openDialogCreateReviewTicket && (
        <DialogCreateReviewTicket
          open={openDialogCreateReviewTicket}
          isTemplate={false}
          close={(refreshReviewTickets) => {
            setOpenDialogCreateReviewTicket(false);
            if (refreshReviewTickets) {
              loadAgreementReviewTickets(agreement._id);
            }
          }}
          createReviewTicket={(reviewList, comment) => {
            /** @type {CreateReviewTicket} */
            const reviewTicket = {
              type: "Review",
              status: "Assigned",
              agreement: agreement._id,
              organization: state.user.orgID,
              requestor: state.user._id,
              reviewChain: {
                reviewList,
                messages: comment
                  ? [
                      {
                        text: comment,
                        senderId: state.user._id,
                        senderDisplayName: state.user.displayName,
                        senderPhotoUrl: state.user.photoURL,
                        sentTime: new Date().toISOString(),
                      },
                    ]
                  : [],
                subscribers: [
                  {
                    _id: state.user._id,
                    email: state.user.email,
                    orgId: state.user.orgID,
                  },
                ],
              },
            };
            return reviewTicket;
          }}
          reviewListTemplate={getReviewListTemplate()}
        />
      )}
    </Box>
  );
}
