import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import EditApprovalList from "../../../reviewsAndApprovals/components/EditApprovalList";
import { canSubmitApprovalList } from "../../../reviewsAndApprovals/functions/canSubmitApprovalList";

/**
 * @typedef {object} ApprovalListTabProps
 * @property {ApprovalList} approvalList
 * @property {(approvalListItemIndex: number) => void} handleAddNewApprover
 * @property {(approvalListItemIndex: number) => void} handleRemoveApprover
 * @property {() => void} handleCancel
 * @property {(comment: string) => void} handleSubmit
 * @property {string} [comment]
 * @property {string} [commentLabel]
 * @property {React.Dispatch<React.SetStateAction<string>>} [setComment]
 * @property {boolean} [isDefault]
 */

/**
 * @param {ApprovalListTabProps} props
 * @returns {JSX.Element}
 */
export default function ApprovalListTab({
  approvalList,
  handleAddNewApprover,
  handleRemoveApprover,
  handleCancel,
  handleSubmit,
  comment = "",
  commentLabel = "Insert Comment ...",
  setComment,
  isDefault = false,
}) {
  return (
    <>
      <DialogContent sx={{ paddingX: 5 }}>
        <EditApprovalList
          approvalList={approvalList}
          handleAddNewApprover={handleAddNewApprover}
          handleRemoveApprover={handleRemoveApprover}
          isDefault={isDefault}
        />

        {setComment && (
          <Grid container direction="row" mt={4}>
            <TextField
              value={comment}
              fullWidth
              multiline
              rows={8}
              label={commentLabel}
              onChange={(event) => setComment(event.target.value)}
            />
          </Grid>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={handleCancel}>Cancel</Button>

        <Button
          variant="contained"
          disableElevation
          color="primary"
          disabled={!canSubmitApprovalList(approvalList) || !comment}
          onClick={() => {
            if (canSubmitApprovalList(approvalList)) handleSubmit(comment);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
}
