import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../../state/store";
import { getAvailableOrganizationUsers } from "../../../../reviewsAndApprovals/functions/getAvailableOrganizationUsers";
import AddReviewerTab from "../../../DialogCreateReviewTicket/tabs/AddReviewerTab";
import ReviewListTab from "../../../DialogCreateReviewTicket/tabs/ReviewListTab";

/**
 * @typedef {object} DialogCreateReviewTicketProps
 * @property {() => void} close
 * @property {ReviewTicket} reviewTicket
 * @property {(reviewTicket: ReviewTicket) => void} updateReviewTicket
 */

/**
 * @param {DialogCreateReviewTicketProps} props
 * @returns {JSX.Element}
 */
export default function DialogCreateReviewTicket({
  close,
  reviewTicket,
  updateReviewTicket,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /** @type {StateUser[]} */
  const organizationUsers = state.users;

  const [reviewList, setReviewList] = useState(
    reviewTicket.reviewChain.reviewList
  );

  const reviewListUsers = /** @type {ReviewListUserItem[]} */ (
    reviewList.filter((reviewListItem) => reviewListItem.type === "user")
  );

  const [tab, setTab] = useState(
    /** @type {"reviewList" | "addReviewer"} */ ("reviewList")
  );
  const [reviewListItemIndex, setReviewListItemIndex] = useState(
    /** @type {number | null} */ (null)
  );
  const originalComment = reviewTicket.reviewChain.messages.at(0)?.text ?? "";
  const [comment, setComment] = useState(originalComment);

  const availableOrganizationUsers = getAvailableOrganizationUsers(
    state?.user?.role?.name,
    organizationUsers,
    reviewListUsers
  );

  /**
   * @param {StateUser} newReviewer
   * @returns {void}
   */
  function addNewReviewer(newReviewer) {
    if (!reviewListItemIndex) return;

    setReviewList((previous) => {
      const copy = [...previous];

      /** @type {ReviewList} */
      const newItems = [
        { type: "addReviewer" },
        {
          type: "user",
          _id: newReviewer._id,
          photoUrl: newReviewer.photoURL,
          role: "reviewer",
          displayName: newReviewer.displayName,
          email: newReviewer.email,
          title: newReviewer.title,
          reviewStatus: "Pending",
        },
        { type: "addReviewer" },
      ];

      copy.splice(reviewListItemIndex, 1, ...newItems);

      return copy.filter((x) => x.type !== "caret");
    });

    setTab("reviewList");
  }

  /**
   * @returns {Promise<void>}
   */
  async function submitReviewTicket() {
    try {
      /** @type {ReviewTicket["reviewChain"]} */
      const updatedChain = {
        ...reviewTicket.reviewChain,
        reviewList,
      };

      if (comment && comment !== originalComment) {
        updatedChain.messages[0].text = comment;
        updatedChain.messages[0].sentTime = new Date().toISOString();
      }

      const putUpdateReviewChainResponse = await axios.put(
        state.settings.api + `task/${reviewTicket._id}/chain`,
        { chain: updatedChain, status: "initial" }
      );
      /** @type {ReviewTicket} */
      const updatedReviewTicket = putUpdateReviewChainResponse.data.data;

      updateReviewTicket(updatedReviewTicket);
      close();
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to update review request chain, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    }
  }

  return (
    <>
      {tab === "reviewList" && (
        <ReviewListTab
          reviewList={reviewList}
          handleAddNewReviewer={(reviewListItemIndex) => {
            setReviewListItemIndex(reviewListItemIndex);
            setTab("addReviewer");
          }}
          handleRemoveReviewer={(reviewListItemIndex) => {
            setReviewList((previous) => {
              const copy = [...previous];
              copy.splice(reviewListItemIndex, 2);
              return copy;
            });
          }}
          handleCancel={() => close()}
          handleSubmit={submitReviewTicket}
          comment={comment}
          setComment={
            reviewTicket.isTemplateConfiguration ||
            reviewTicket.isDefaultRequest
              ? setComment
              : undefined
          }
        />
      )}

      {tab === "addReviewer" && (
        <AddReviewerTab
          organizationUsers={availableOrganizationUsers}
          handleBack={() => setTab("reviewList")}
          handleNext={addNewReviewer}
        />
      )}
    </>
  );
}
