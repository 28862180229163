import { faCaretDown, faPen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useContext, useMemo, useState } from "react";
import { globalStore } from "../state/store";
import { getCanveoTier } from "../utils/getCanveoTier";
import CardCpty from "./CardCpty";
import { DialogEntity } from "./index";

/**
 * @typedef {object} PartyListProps
 * @property {string} owner
 * @property {*[]} parties
 * @property {{ label: string; }[]} roles
 * @property {(operation: "create" | "update" | "delete", role: string, originalRole?: string | undefined) => void} handleRoleChange
 * @property {boolean} [readOnly]
 * @property {(party: *) => void} handleAddParty
 * @property {(party: *) => void} handleRemoveParty
 * @property {(entity: *) => void} handleEntityChange
 * @property {(entity: *) => void} handleEntityUpdate
 * @property {(party: *) => void} handlePartyChange
 * @property {*} [sx]
 * @property {boolean} [disableRoleCreation]
 * @property {boolean} [displayAddParty]
 */

/**
 * @param {PartyListProps} props
 * @returns {React.JSX.Element}
 */
export default function PartyList({
  owner: ownerOrganizationId,
  parties,
  roles = [],
  handleRoleChange,
  readOnly,
  handleAddParty,
  handleRemoveParty,
  handleEntityChange,
  handleEntityUpdate,
  handlePartyChange,
  disableRoleCreation = true,
  displayAddParty = false,
  ...props
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [createEntity, setCreateEntity] = useState(
    /** @type {* | null} */ (null)
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openRoleDialog, setOpenRoleDialog] = useState("closed");
  const [partyRoleName, setPartyRoleName] = useState("");
  const [originalPartyRoleName, setOriginalPartyRoleName] = useState("");
  const [selectedParty, setSelectedParty] = useState(
    /** @type {* | null} */ (null)
  );
  const [textFieldError, setTextFieldError] = useState(false);

  /**
   * @param {*} event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedParty(null);
  };

  const groupedParties = useMemo(() => {
    if (!parties) return {};
    return parties?.reduce((group, party) => {
      const { role } = party;
      group[role] = group[role] ?? [];
      group[role].push(party);
      return group;
    }, {});
  }, [parties]);

  /**
   * @param {*} party
   */
  const canEditParty = (party) => {
    if (readOnly) return false;
    if (ownerOrganizationId === state.org._id) {
      return party.orgID !== state.org._id;
    }

    return party.orgID === state.org._id;
  };

  /**
   * @param {*} party
   */
  const getOrgEntities = (party) => {
    if (party.entityCategory === "Person" || readOnly) return [];

    let entities;
    if (party.orgID === state.org._id) entities = [...state.subs];
    else {
      entities = state.cpents.filter(
        (/** @type {{ orgID: string; }} */ c) => c.orgID === party.orgID
      );
    }

    return (state.org._id === ownerOrganizationId && entities.length) ||
      state.org._id !== ownerOrganizationId
      ? entities
      : [];
  };

  /**
   * Method to handle CardCpty components event without refactoring it.
   *
   * @param {*} action
   * @param {*} value
   */
  const handlePartyCardRequest = (action, value) => {
    if (action === "changeownent") {
      if (value.toCreate) {
        const party = parties.find((p) => p.orgID === value.orgID);
        setCreateEntity({
          // @ts-ignore
          type: "newcp",
          entity: {
            orgID: party.orgID,
            ownerOrgID: state.org._id,
            shortName: party.shortName,
            logoURL: party.logoURL,
          },
        });
        return;
      }

      let entityData = state.subs.find(
        (/** @type {{ _id: string; }} */ s) => s._id === value
      );
      if (!entityData) {
        entityData = state.cpents.find(
          (/** @type {{ _id: string; }} */ s) => s._id === value
        );
      }

      handleEntityChange(entityData);
    } else if (action === "removeParty") {
      // Request to remove an additional party.
      const party = parties.find((party) => party._id === value);
      handleRemoveParty(party);
    } else if (action === "updateEntity") {
      handleEntityUpdate(value);
    }
  };

  /**
   * @param {*} _
   */
  const handleCreateEntity = ({ ent }) => {
    setCreateEntity(null);
    if (ent?.orgID) handleEntityChange(ent);
  };

  function canDisplayAddParty() {
    if (!displayAddParty) return false;
    if (state.user.role.name === "Counterparty") return false;
    if (state.org._id !== ownerOrganizationId) return false;

    if (getCanveoTier(state?.user?.email) === "stable") {
      if (parties.length >= 2) return false;
    }

    return true;
  }

  // if (!Object.keys(groupedParties).length && !readOnly) {
  //   return (
  //     <Button
  //       variant="text"
  //       color="primary"
  //       size="small"
  //       onClick={() => handleAddParty("")}
  //       sx={{
  //         fontWeight: "600",
  //         mt: 2,
  //       }}
  //     >
  //       Add Party ...
  //     </Button>
  //   );
  // }

  return (
    <Box {...props}>
      <Box
        component={"div"}
        style={{
          overflow: "auto",
          maxHeight: !readOnly ? undefined : "738px",
        }}
      >
        {Object.keys(groupedParties)?.map((role, index) => (
          <Grid
            key={index}
            container
            alignItems="center"
            style={{ gap: "10px" }}
            pb={3}
          >
            {groupedParties[role]?.map((/** @type {*} */ party) => {
              return (
                <Grid item key={party._id}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item>
                      {disableRoleCreation ? (
                        <b>
                          {party.role
                            ? party.myClient
                              ? `${role} (Client)`
                              : role
                            : ""}
                        </b>
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "transparent",
                            paddingLeft: 0,
                          }}
                          disableRipple
                          variant="text"
                          disableElevation
                          onClick={(event) => {
                            setSelectedParty(party);
                            handleClick(event);
                          }}
                          endIcon={
                            <FontAwesomeIcon
                              icon={faCaretDown}
                              style={{
                                color: "black",
                                fontSize: "14px",
                              }}
                            />
                          }
                        >
                          <b style={{ color: "black" }}>
                            {role !== "null" ? role : "Select Role"}
                          </b>
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {!disableRoleCreation && state.org.iAmALawFirm && (
                    <Grid container direction="row">
                      <Grid item>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={party.myClient}
                                onChange={() => {
                                  const changedParty = {
                                    ...party,
                                    myClient: !party.myClient,
                                  };
                                  handlePartyChange(changedParty);
                                }}
                              />
                            }
                            label="This is our client"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  )}

                  <Box mt={4}>
                    <CardCpty
                      cpty={party}
                      type="primary"
                      partyID={party.partyID}
                      remove={
                        !readOnly && state.org._id === ownerOrganizationId
                      }
                      switch={getOrgEntities(party)}
                      edit={canEditParty(party)}
                      handleCardCptyRequest={handlePartyCardRequest}
                      canCreateEntity={
                        !readOnly &&
                        state.org._id === ownerOrganizationId &&
                        party.orgID !== ownerOrganizationId &&
                        party.entityCategory !== "Person"
                      }
                    />
                  </Box>
                </Grid>
              );
            })}

            {!disableRoleCreation && (
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setOpenRoleDialog("create");
                    setAnchorEl(null);
                  }}
                >
                  <b style={{ color: "#5B5B5B" }}>Create New Role ...</b>
                </MenuItem>

                {roles?.map((role, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      const changedParty = {
                        ...selectedParty,
                        role: role.label,
                      };
                      handlePartyChange(changedParty);
                      handleClose();
                    }}
                  >
                    <Grid
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item>{role.label}</Grid>
                      <Grid item>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setPartyRoleName(role.label);
                            setOriginalPartyRoleName(role.label);
                            setOpenRoleDialog("edit");
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            style={{
                              color: "#5B5B5B",
                              fontSize: "14px",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </Menu>
            )}
          </Grid>
        ))}
      </Box>

      {canDisplayAddParty() && (
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={handleAddParty}
          sx={{
            fontWeight: "600",
            mt: 2,
          }}
        >
          Add Party ...
        </Button>
      )}

      {createEntity && (
        <DialogEntity
          open={!!createEntity}
          closeDialog={handleCreateEntity}
          type={createEntity?.type}
          ent={createEntity?.entity}
        />
      )}

      {openRoleDialog && (
        <Dialog
          open={["create", "edit"].includes(openRoleDialog)}
          fullWidth
          maxWidth={"xs"}
          onClose={() => {
            setPartyRoleName("");
            setTextFieldError(false);
            setOriginalPartyRoleName("");
            setOpenRoleDialog("closed");
          }}
        >
          <DialogTitle>Create New Role</DialogTitle>

          <DialogContent>
            <br />
            <TextField
              error={textFieldError}
              helperText={
                textFieldError
                  ? `Role ${partyRoleName} already exists.`
                  : undefined
              }
              fullWidth
              autoFocus={true}
              value={partyRoleName}
              label="Party Role Name"
              variant="outlined"
              placeholder="Party Role Name"
              onChange={(event) => {
                setPartyRoleName(event.target.value);
                const alreadyExists = roles.find(
                  (role) => role.label === event.target.value
                );
                setTextFieldError(Boolean(alreadyExists));
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                setPartyRoleName("");
                setTextFieldError(false);
                setOriginalPartyRoleName("");
                setOpenRoleDialog("closed");
                setSelectedParty(null);
              }}
            >
              Cancel
            </Button>

            <div style={{ flex: "1 0 0" }} />

            {openRoleDialog === "create" && (
              <Button
                disabled={textFieldError || !partyRoleName}
                onClick={() => {
                  handleRoleChange("create", partyRoleName);
                  const changedParty = {
                    ...selectedParty,
                    role: partyRoleName,
                  };
                  handlePartyChange(changedParty);
                  setPartyRoleName("");
                  setTextFieldError(false);
                  setOriginalPartyRoleName("");
                  setOpenRoleDialog("closed");
                  setSelectedParty(null);
                }}
              >
                Create
              </Button>
            )}

            {openRoleDialog === "edit" && (
              <>
                <Button
                  color={"error"}
                  onClick={() => {
                    handleRoleChange(
                      "delete",
                      partyRoleName,
                      originalPartyRoleName
                    );
                    setPartyRoleName("");
                    setTextFieldError(false);
                    setOriginalPartyRoleName("");
                    setOpenRoleDialog("closed");
                    setSelectedParty(null);
                  }}
                >
                  Delete
                </Button>

                <Button
                  disabled={textFieldError || !partyRoleName}
                  onClick={() => {
                    handleRoleChange(
                      "update",
                      partyRoleName,
                      originalPartyRoleName
                    );
                    setPartyRoleName("");
                    setTextFieldError(false);
                    setOriginalPartyRoleName("");
                    setOpenRoleDialog("closed");
                    setSelectedParty(null);
                  }}
                >
                  Update
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}
