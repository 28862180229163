import { Grid, Typography } from "@mui/material";
import React from "react";

/**
 * @typedef {object} ChainItemProps
 * @property {JSX.Element} children
 * @property {string} [primaryText]
 * @property {string} [secondaryText]
 */

/**
 * @param {ChainItemProps} props
 * @return {JSX.Element}
 */
export default function ChainItem({ children, primaryText, secondaryText }) {
  return (
    <Grid item container direction="column" alignItems="center">
      <Grid item>{children}</Grid>

      <Grid item visibility={!primaryText ? "hidden" : "visible"}>
        <Typography variant="caption" fontWeight="bold" color="#646464">
          {!primaryText ? "placeholder" : primaryText}
        </Typography>
      </Grid>

      <Grid item mt={-1} visibility={!secondaryText ? "hidden" : "visible"}>
        <Typography variant="caption" color="#646464">
          ({!secondaryText ? "placeholder" : secondaryText})
        </Typography>
      </Grid>
    </Grid>
  );
}
