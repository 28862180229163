import countries from "../assets/static/countries";

/**
 * @typedef {object} PartyAddresss
 * @property {string} streetAndNo
 * @property {string} city
 * @property {string} country
 * @property {string} state
 * @property {string} zip
 * @property {string} address2
 */

/**
 * @typedef {object} AgreementParty
 * @property {string} [role]
 * @property {PartyAddresss[]} address
 * @property {string} legalName
 * @property {string} orgID
 */

/**
 * Calculates the value of a party information merge field at the time of agreement creation.
 *
 * @param {import("../components/editor/nodes/MarkNode").MergeField} mergeField
 * @param {AgreementParty[]} agreementParties
 * @param {{ _id: string; shortName: string }[]} organizations
 * @returns {string}
 */
export function calculatePartyInformationMergeFieldValue(
  mergeField,
  agreementParties,
  organizations
) {
  let /** @type {string | undefined} */ value;

  // First we try to find the party by the role.
  let party = agreementParties.find(
    (agreementParty) =>
      agreementParty?.role?.toLowerCase() ===
      mergeField?.partyRole?.toLowerCase()
  );

  // If no party was found, then we try finding it by its legal name. This happens when
  // creating a party information merge field on an agreement for a party that does not
  // have an assigned role.
  if (!party) {
    party = agreementParties.find(
      (agreementParty) =>
        agreementParty?.legalName?.toLowerCase() ===
        mergeField?.partyRole?.toLowerCase()
    );
    if (!party) {
      return "";
    }
  }

  // If a party exists, then the party legal name becomes the role.
  mergeField.partyRole = party.legalName;

  switch (mergeField.name) {
    case "Party Address (L1)": {
      value = party?.address?.at(0)?.streetAndNo;
      break;
    }
    case "Party Address (L2)": {
      value = party?.address?.at(0)?.address2;
      break;
    }
    // TODO: Do we fetch this from the first location?
    case "Party City": {
      value = party?.address?.at(0)?.city;
      break;
    }
    case "Party Country": {
      const country = countries.find(
        (c) => c.code === party?.address?.at(0)?.country
      )?.label;
      if (country) {
        value = country;
      }
      break;
    }
    case "Party Entity Name": {
      value = party?.legalName;
      break;
    }
    case "Party Org Name": {
      value = organizations?.find((x) => x?._id === party?.orgID)?.shortName;
      break;
    }
    case "Party State/Province": {
      value = party?.address?.at(0)?.state;
      break;
    }
    case "Party ZIP Code": {
      value = party?.address?.at(0)?.zip;
      break;
    }

    default: {
      throw new Error(`Invalid Party Information field: ${mergeField.name}`);
    }
  }

  // If we are not able to calculate a value then we return an empty string. This can happen
  // when the entity does not have the property that the party information field is looking
  // for. For example, if a "Party ZIP Code" merge field is created but the entity does not
  // have a zip code, then it will not be possible to calculate its value and we return an
  // empty string.
  return value || "";
}
