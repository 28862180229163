/**
 * @param {StateUser["role"]["name"]} currentUserRole
 * @param {StateUser[]} organizationUsers
 * @param {{ _id: string, role: "requestor" | string & {} }[]} alreadySelectedUsers
 * @returns {StateUser[]}
 */
export function getAvailableOrganizationUsers(
  currentUserRole,
  organizationUsers,
  alreadySelectedUsers
) {
  if (currentUserRole === "Admin" || currentUserRole === "Legal") {
    const availableOrganizationUsers = organizationUsers.filter(
      (organizationUser) =>
        !alreadySelectedUsers.some(
          (x) => x._id === organizationUser._id && x.role !== "requestor"
        )
    );
    return availableOrganizationUsers;
  }

  const availableOrganizationUsers = organizationUsers.filter(
    (organizationUser) =>
      !alreadySelectedUsers.some((x) => x._id === organizationUser._id)
  );
  return availableOrganizationUsers;
}
