import { Box } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalStore } from "../../state/store";
import { CanveoCircularProgress } from "../index";
import LargeDrawer from "./LargeDrawer";
import MiniDrawer from "./MiniDrawer";

const drawerMiniWidth = 85;
const drawerWidth = 330;

const openedMixin = (/** @type {import("@mui/material").Theme} */ theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (/** @type {import("@mui/material").Theme} */ theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: drawerMiniWidth,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})((/** @type {*} */ { theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/**
 * @param {*} props
 */
export default function CoreDrawer({
  drawerItems,
  hasLargeDrawer,
  isTemplate,
  ...props
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [open, setOpen] = useState("");
  const [loading] = useState(false);

  const handleDrawerOpen = (/** @type {string} */ type) => {
    if (type === "Export" || type === "Approvals") return props.onEvent(type);

    setOpen(type.toLowerCase());
  };

  const handleDrawerClose = () => {
    setOpen("");
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const openedDrawer = searchParams.get("openedDrawer");

  if (openedDrawer) {
    if (openedDrawer === "versions") {
      setTimeout(() => {
        setSearchParams({});
        document.getElementById("editor-drawer-versions")?.click();
      }, 100);
    } else if (openedDrawer === "exhibits") {
      // setTimeout(() => {
      //   const element = document.getElementById("editor-drawer-exhibits");
      //   if (element) {
      //     element.click();
      //     setSearchParams({});
      //   }
      // }, 100);
    }
  } else {
    const openNewVersionDialog = searchParams.get("openNewVersionDialog");
    if (Boolean(openNewVersionDialog)) {
      setTimeout(() => {
        document.getElementById("editor-drawer-versions")?.click();
      }, 100);
    }

    const openExportDialog = searchParams.get("openExportDialog");
    if (Boolean(openExportDialog)) {
      setTimeout(() => {
        setSearchParams({});
        document.getElementById("editor-drawer-export")?.click();
      }, 100);
    }

    const openDatesAndRenewalDialog = searchParams.get(
      "openDatesAndRenewalDialog"
    );
    if (Boolean(openDatesAndRenewalDialog)) {
      setTimeout(() => {
        document.getElementById("editor-drawer-summary")?.click();
      }, 100);
    }
  }

  const [alreadyOpenedExhibitsDrawer, setAlreadyOpenedExhibitsDrawer] =
    useState(false);

  // If agreement has exhibits we open the exhibits drawer automatically.
  useEffect(() => {
    if (!alreadyOpenedExhibitsDrawer) {
      let exhibits;

      if (isTemplate) {
        exhibits = state.template.filter((/** @type {*} */ t) => t.parentID);
      } else {
        exhibits = state.agrs.filter((/** @type {*} */ a) => a.parentID);
      }

      if (exhibits?.length) {
        const element = document.getElementById("editor-drawer-exhibits");
        if (element) {
          element.click();
          setAlreadyOpenedExhibitsDrawer(true);
        }
      }
    }
  }, [alreadyOpenedExhibitsDrawer, isTemplate, state.agrs, state.template]);

  return (
    <Drawer variant={"permanent"} open={!!open} sx={{ zIndex: "0" }}>
      {loading && (
        <Box
          sx={{
            position: "fixed",
            zIndex: "2000",
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CanveoCircularProgress color="inherit" />
        </Box>
      )}

      {hasLargeDrawer && !!open ? (
        <LargeDrawer
          title={open}
          content={props[open]}
          handleClose={handleDrawerClose}
          hasMenu={open === "versions"}
        />
      ) : // MiniDrawer - buttons will depend on page
      !!drawerItems ? (
        <MiniDrawer items={drawerItems} handleDrawerOpen={handleDrawerOpen} />
      ) : (
        ""
      )}
    </Drawer>
  );
}
