import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../../state/store";
import { getAvailableOrganizationUsers } from "../../../../reviewsAndApprovals/functions/getAvailableOrganizationUsers";
import AddApproverTab from "../../../DialogCreateApprovalTicket/tabs/AddApproverTab";
import ApprovalListTab from "../../../DialogCreateApprovalTicket/tabs/ApprovalListTab";

/**
 * @typedef {object} EditApproverTabProps
 * @property {() => void} close
 * @property {ApprovalTicket} approvalTicket
 * @property {(approvalTicket: ApprovalTicket) => void} updateApprovalTicket
 */

/**
 * @param {EditApproverTabProps} props
 * @returns {JSX.Element}
 */
export default function EditApproverTab({
  close,
  approvalTicket,
  updateApprovalTicket,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /** @type {StateUser[]} */
  const organizationUsers = state.users;

  const [approvalList, setApprovalList] = useState(
    approvalTicket.approvalChain.approvalList
  );
  const [tab, setTab] = useState(
    /** @type {"approvalList" | "addApprover"} */ ("approvalList")
  );
  const [approvalListItemIndex, setReviewListItemIndex] = useState(
    /** @type {number | null} */ (null)
  );
  const originalComment =
    approvalTicket.approvalChain.messages.at(0)?.text ?? "";
  const [comment, setComment] = useState(originalComment);

  const approvalListUsers = /** @type {ApprovalListUserItem[]} */ (
    approvalList.filter((reviewListItem) => reviewListItem.type === "user")
  );
  const availableOrganizationUsers = getAvailableOrganizationUsers(
    state?.user?.role?.name,
    organizationUsers,
    approvalListUsers
  );

  /**
   * @param {StateUser} newApprover
   * @returns {void}
   */
  function addNewApprover(newApprover) {
    if (!approvalListItemIndex) return;

    setApprovalList((previous) => {
      const copy = [...previous];

      /** @type {ApprovalList} */
      const newItems = [
        { type: "addApprover" },
        {
          type: "user",
          _id: newApprover._id,
          photoUrl: newApprover.photoURL,
          role: "approver",
          displayName: newApprover.displayName,
          email: newApprover.email,
          title: newApprover.title,
          approvalStatus: "Pending",
        },
        { type: "addApprover" },
      ];

      copy.splice(approvalListItemIndex, 1, ...newItems);

      return copy.filter((x) => x.type !== "caret");
    });

    setTab("approvalList");
  }

  /**
   * @returns {Promise<void>}
   */
  async function submitApprovalTicket() {
    try {
      /** @type {ApprovalTicket["approvalChain"]} */
      const updatedChain = {
        ...approvalTicket.approvalChain,
        approvalList,
      };

      if (comment && comment !== originalComment) {
        updatedChain.messages[0].text = comment;
        updatedChain.messages[0].sentTime = new Date().toISOString();
      }

      const putUpdateApprovalChainResponse = await axios.put(
        state.settings.api + `task/${approvalTicket._id}/chain`,
        { chain: updatedChain, status: "initial" }
      );
      /** @type {ApprovalTicket} */
      const updatedApprovalTicket = putUpdateApprovalChainResponse.data.data;

      updateApprovalTicket(updatedApprovalTicket);
      close();
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to update approval request chain, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    }
  }

  return (
    <>
      {tab === "approvalList" && (
        <ApprovalListTab
          approvalList={approvalList}
          handleAddNewApprover={(approvalListItemIndex) => {
            setReviewListItemIndex(approvalListItemIndex);
            setTab("addApprover");
          }}
          handleRemoveApprover={(approvalListItemIndex) => {
            setApprovalList((previous) => {
              const copy = [...previous];
              copy.splice(approvalListItemIndex, 2);
              return copy;
            });
          }}
          handleCancel={() => close()}
          handleSubmit={submitApprovalTicket}
          comment={comment}
          setComment={
            approvalTicket.isTemplateConfiguration ||
            approvalTicket.isDefaultRequest
              ? setComment
              : undefined
          }
        />
      )}

      {tab === "addApprover" && (
        <AddApproverTab
          organizationUsers={availableOrganizationUsers}
          handleBack={() => setTab("approvalList")}
          handleNext={addNewApprover}
        />
      )}
    </>
  );
}
