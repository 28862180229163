import { $isTextNode } from "lexical";
import { $createCaptionNode } from "../../nodes/CaptionNode";
import { $createCustomParagraphNode } from "../../nodes/CustomParagraphNode";
import { $createCustomTableCellNode } from "../../nodes/CustomTableCellNode";
import { $createCustomTableNode } from "../../nodes/CustomTableNode";
import { $createCustomTableRowNode } from "../../nodes/CustomTableRowNode";
import { $isImageNode } from "../../nodes/ImageNode";
import { $isMarkNode } from "../../nodes/MarkNode";
import { elementNodeBlockFormatting, parseInlines } from "./text";

/**
 * Check if inlines contain clause secret bookmarks.
 *
 * @param {*[]} inlines
 * @returns {boolean}
 */
function inlinesContainClauseSecretBookmarks(inlines = []) {
  const inlinesContainClauseSecretBookmarks =
    inlines &&
    Array.isArray(inlines) &&
    inlines.length &&
    inlines.length === 2 &&
    inlines[0].name?.startsWith("_c_") &&
    inlines[0].bookmarkType === 0 &&
    inlines[1].name?.startsWith("_c_") &&
    inlines[1].bookmarkType === 1;
  return inlinesContainClauseSecretBookmarks;
}

/**
 * Creates and returns a table node
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Section} section
 * @param {import("../../types/sfdt").Block} block
 * @param {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @param {Set<string>} ongoingCommentsSet
 * @param {any} user
 * @param {any} collabs
 * @param {Record<string, any>} metadata,
 * @param {*[]} organizationUsers
 */
export const createTableNode = (
  sfdt,
  section,
  block,
  sfdtCommentsMap,
  ongoingCommentsSet,
  user,
  collabs = [],
  metadata,
  organizationUsers
) => {
  if (!block.rows || !block.tableFormat) {
    throw new Error("Malformed table block.");
  }

  const { rows, tableFormat, title, description } = block;

  const borders = tableFormat.borders;

  const {
    left,
    right,
    bottom,
    top,
    horizontal,
    vertical,
    diagonalDown,
    diagonalUp,
  } = borders;
  const table = $createCustomTableNode(undefined, undefined, undefined, {
    borderLeft: left,
    borderRight: right,
    borderTop: top,
    borderBottom: bottom,
    borderHorizontal: horizontal,
    borderVertical: vertical,
    borderDiagonalUp: diagonalUp,
    borderDiagonalDown: diagonalDown,
  });
  elementNodeBlockFormatting(sfdt, block, table);
  table.setTableFormat(tableFormat);
  table.setDirection(!tableFormat.bidi ? "ltr" : "rtl");

  /** accessibility */
  if (title) {
    table.setTitle(title);
  }

  if (description) {
    table.setDescription(description);
  }

  for (const row of rows) {
    const customRowNode = $createCustomTableRowNode(row.rowFormat);
    const { cells } = row;
    for (const cell of cells) {
      const /** @type {import("lexical").LexicalNode[]} */ cellChildNodes = [];

      const { blocks } = cell;
      for (let i = 0; i < blocks.length; i++) {
        const cellBlock = blocks[i];

        // This may have to do with the secret bookmarks.
        // if (i > 0) {
        //   const customParagraphNode = $createCustomParagraphNode();
        //   customParagraphNode.setParagraphFormat(cellBlock.paragraphFormat);
        //   cellChildNodes.push($createCustomParagraphNode());
        // }

        // SFDT inlines are equivalent to fragments of Lexical paragraphs.
        const { inlines } = cellBlock;
        if (inlines?.length && !inlinesContainClauseSecretBookmarks(inlines)) {
          const /** @type {import("lexical").LexicalNode[]} */ generatedNodes =
              [];
          // This function pushes the result to the cellChildNodes array.
          parseInlines(
            cellBlock,
            inlines,
            generatedNodes,
            sfdt,
            {
              ongoingCommentsSet,
              sfdtCommentsMap,
              user,
              collabs,
              metadata,
            },
            section,
            organizationUsers,
            block.paragraphFormat?.styleName ?? ""
          );

          const customParagraphNode = $createCustomParagraphNode();
          customParagraphNode.setParagraphFormat(cellBlock.paragraphFormat);

          for (const node of generatedNodes) {
            // Not sure why this was added but in order to not break anything any time we need to support a new
            // node type we need to add a new check.
            if ($isTextNode(node) || $isImageNode(node) || $isMarkNode(node)) {
              customParagraphNode.append(node);
            }
          }

          if (customParagraphNode.getChildrenSize() > 0) {
            cellChildNodes.push(customParagraphNode);
          }
        }
        // If inlines are empty or contain clause secret bookmarks we create an empty paragraph.
        else {
          const customParagraphNode = $createCustomParagraphNode();
          customParagraphNode.setParagraphFormat(cellBlock.paragraphFormat);
          cellChildNodes.push(customParagraphNode);
        }
      }

      const cellBorders = cell.cellFormat.borders;
      const customCellNode = $createCustomTableCellNode(
        undefined,
        cell.cellFormat.columnSpan, //if undefined then 1
        undefined,
        cell.cellFormat.rowSpan,
        {
          borderLeft: cellBorders.left,
          borderRight: cellBorders.right,
          borderTop: cellBorders.top,
          borderBottom: cellBorders.bottom,
          borderHorizontal: cellBorders.horizontal,
          borderVertical: cellBorders.vertical,
          borderDiagonalUp: cellBorders.diagonalUp,
          borderDiagonalDown: cellBorders.diagonalDown,
        },
        cell.cellFormat
      );

      customCellNode.append(...cellChildNodes);
      customRowNode.append(customCellNode);
    }
    table.append(customRowNode);
  }

  if (block.paragraphFormat?.textAlignment) {
    const textAlignment = block.paragraphFormat.textAlignment.toLowerCase();
    // @ts-ignore
    table.setFormat(textAlignment);
  }
  return { node: table, ongoingCommentsSet };
};

/**
 * Creates and returns a caption node
 *
 * @param {import("../../types/sfdt").Sfdt} sfdt
 * @param {import("../../types/sfdt").Section} section
 * @param {import("../../types/sfdt").Block} block
 * @param {Map<string, import("../../types/sfdt").Comment>} sfdtCommentsMap
 * @param {Set<string>} ongoingCommentsSet
 * @param {any} user
 * @param {any} collabs
 * @param {Record<string, any> } metadata
 * @param {*[]} organizationUsers
 */
export const createCaptionNode = (
  sfdt,
  section,
  block,
  sfdtCommentsMap,
  ongoingCommentsSet,
  user,
  collabs = [],
  metadata,
  organizationUsers
) => {
  // SFDT inlines are equivalent to fragments of Lexical paragraphs.
  const { inlines } = block;
  const captionNode = $createCaptionNode();
  /**@type {import("lexical").LexicalNode[]} */
  const captionNodes = [];

  if (inlines && inlines?.length) {
    parseInlines(
      block,
      inlines,
      captionNodes,
      sfdt,
      {
        ongoingCommentsSet,
        sfdtCommentsMap,
        user,
        collabs,
        metadata,
      },
      section,
      organizationUsers,
      block.paragraphFormat?.styleName || ""
    );
  }

  if (block.paragraphFormat?.textAlignment) {
    const textAlignment = block.paragraphFormat.textAlignment.toLowerCase();
    // @ts-ignore
    captionNode.setFormat(textAlignment);
  }
  captionNode.append(...captionNodes);

  return { node: captionNode, ongoingCommentsSet };
};

/**
 * @param {string} caption
 */
export const parseCaptionText = (caption) => {
  // TODO: For whatever reason the caption text shows with \n characters.
  // We haven't been able to find out where they're coming from, so for now
  // we just remove them.
  const normalizedCapation = caption.replaceAll("\n", "");
  //Tabela  SEQ Tabela \* ARABIC 1 It's a caption
  const field1 = normalizedCapation.split("SEQ")[0];
  let indexOfNumberType = -1,
    type = "";
  if (normalizedCapation.indexOf("ARABIC") > 0) {
    type = "ARABIC";
    indexOfNumberType = normalizedCapation.indexOf("ARABIC");
  } else if (normalizedCapation.indexOf("LOWROMAN") > 0) {
    type = "LOWROMAN";
    indexOfNumberType = normalizedCapation.indexOf("LOWROMAN");
  } else if (normalizedCapation.indexOf("UPROMAN") > 0) {
    type = "UPROMAN";
    indexOfNumberType = normalizedCapation.indexOf("UPROMAN");
  } else if (normalizedCapation.indexOf("LOWLETTER") > 0) {
    type = "LOWLETTER";
    indexOfNumberType = normalizedCapation.indexOf("LOWLETTER");
  } else if (normalizedCapation.indexOf("UPLETTER") > 0) {
    type = "UPLETTER";
    indexOfNumberType = normalizedCapation.indexOf("UPLETTER");
  }
  const field2 = normalizedCapation.slice(
    field1.length,
    indexOfNumberType + type.length
  );
  let restOfString = normalizedCapation
    .substring(indexOfNumberType + type.length)
    .split(" ");

  const field3 = restOfString[1];
  const field4 = ` ${restOfString.slice(2).toString().replace(/,/g, " ")}`;
  return { field1, field2, field3, field4 };
};
