import React, { createContext, useReducer } from "react";
import SweetScroll from "sweet-scroll";
import { countries } from "../assets/static";
import {
  agrExecReducer,
  agrTypesReducer,
  agrsReducer,
  appBarReducer,
  avsReducer,
  clauseLibItemsReducer,
  clauseOptionsReducer,
  clauseTypeGuidesReducer,
  clauseTypesReducer,
  cpentsReducer,
  fileUploadReducer,
  labelsReducer,
  orgReducer,
  paramsReducer,
  partiesReducer,
  sendFlowReducer,
  snackbarReducer,
  subsReducer,
  templateReducer,
  templatesReducer,
  userReducer,
  userSessionReducer,
  usersReducer,
  versionsReducer,
  workflowsReducer,
} from "./reducers";
import editorReducer from "./reducers/editor";
import playbookEventsReducer from "./reducers/playbookEvents";
import selectedClauseTopicReducer from "./reducers/selectedClauseTopic";
import selectedMergeFieldReducer from "./reducers/selectedMergeField";
import selectedOpenIssueReducer from "./reducers/selectedOpenIssue";

if (process.env.NODE_ENV === "development") {
  console.log("REACT_APP_CANVEO_TIER:", process.env.REACT_APP_CANVEO_TIER);
}

//for scrolling inside changes on editor
const scroller = new SweetScroll({
  duration: 800,
  offset: -280,
  easing: "easeInOutQuart",
});

const settings = {
  api:
    process.env.NODE_ENV === "production"
      ? "https://api.canveo.net/api/v2/"
      : "/api/v2/",
  // Uncomment when running production build locally i.e., "npm run build && serve -s build -l 8080".
  // api: "http://localhost:3001/api/v2/",
};

const initialState = {
  agrExec: {},
  agrs: [],
  agrTypes: [],
  avs: [],
  clauseLibItems: [],
  clauseTypeGuides: [],
  clauseTypes: [],
  cpents: [],
  countries: countries,
  labels: [],
  org: {},
  params: { lib: [], doc: [] },
  parties: [],
  subs: [],
  scroller: scroller,
  settings: settings,
  snackbar: null,
  templates: [],
  user: {},
  users: [],
  userSession: {
    filter: null,
  },
  workflows: [],
  /** @type {{ id: string | null; type: "text" | "navigation" | null, status: "ongoing" | "completed" | null} | null} **/
  selectedOpenIssue: { id: null, type: null, status: null },
  drawerVersions: { versions: [], active: null },
  sendFlow: {
    addParty: null,
    removeParty: null,
    removeCollaborator: null,
  },
  fileUpload: null,
  template: [],
  selectedMergeField: null,
  playbookEvents: null,
  selectedClauseTopic: null,
};

const globalStore = createContext(initialState);
const { Provider } = globalStore;

/**
 * @param {*} reducers
 * @returns {(state: any, action: any) => any }
 */
const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

/**
 * @param {*} _
 * @returns
 */
const StateProvider = ({ children }) => {
  const reducers = combineReducers({
    reset: (
      /** @type {*} */ state,
      /** @type {{ type: string; }} */ action
    ) => {
      switch (action.type) {
        case "RESET":
          return initialState;
        default:
          return state;
      }
    },
    appBar: appBarReducer,
    agrExec: agrExecReducer,
    agrs: agrsReducer,
    agrTypes: agrTypesReducer,
    avs: avsReducer,
    clauseLibItems: clauseLibItemsReducer,
    clauseTypeGuides: clauseTypeGuidesReducer,
    clauseTypes: clauseTypesReducer,
    cpents: cpentsReducer,
    labels: labelsReducer,
    org: orgReducer,
    params: paramsReducer,
    parties: partiesReducer,
    snackbar: snackbarReducer,
    subs: subsReducer,
    templates: templatesReducer,
    user: userReducer,
    users: usersReducer,
    userSession: userSessionReducer,
    workflows: workflowsReducer,
    selectedOpenIssue: selectedOpenIssueReducer,
    drawerVersions: versionsReducer,
    sendFlow: sendFlowReducer,
    fileUpload: fileUploadReducer,
    template: templateReducer,
    selectedMergeField: selectedMergeFieldReducer,
    editor: editorReducer,
    clauseOptions: clauseOptionsReducer,
    playbookEvents: playbookEventsReducer,
    selectedClauseTopic: selectedClauseTopicReducer,
  });

  const [state, dispatch] = useReducer(reducers, initialState);
  const store = React.useMemo(() => [state, dispatch], [state]); //this is not a good solution should be 2 separate contexts

  // @ts-ignore
  return <Provider value={store}>{children}</Provider>;
};

export { StateProvider, globalStore };
