import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DateTime } from "luxon";
import React from "react";
import theme from "../../../theme/theme";
import { getDisplayUsersCellForTable } from "../../../views/Tasks/getAssigneesChip";
import { getStatusChip } from "../../../views/Tasks/getStatusChip";
import { getApprovalsAssignees } from "../functions/getApprovalsAssignees";

/**
 * @typedef {object} ApprovalsTableProps
 * @property {ApprovalTicket[]} approvalTickets
 * @property {(value: React.SetStateAction<number>) => void} setSelectedApprovalTicketIndex
 */

/**
 * @param {ApprovalsTableProps} props
 * @returns {JSX.Element}
 */
export default function ApprovalsTable({
  approvalTickets,
  setSelectedApprovalTicketIndex,
}) {
  return (
    <Grid container mt={4} mb={4}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>

              <TableCell>Name</TableCell>

              <TableCell>Requestor</TableCell>

              <TableCell>Assignee(s)</TableCell>

              <TableCell>Created</TableCell>

              <TableCell>Updated</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {approvalTickets.map((approvalTicket, index) => (
              <TableRow
                key={approvalTicket._id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  "&:hover": {
                    cursor: "pointer",
                    backgroundColor: theme.palette.grey[100],
                  },
                }}
                onClick={() => setSelectedApprovalTicketIndex(index)}
              >
                <TableCell component="th" scope="row">
                  {getStatusChip(approvalTicket.status)}
                </TableCell>

                <TableCell>{approvalTicket.name}</TableCell>

                <TableCell>
                  {getDisplayUsersCellForTable([
                    approvalTicket.isTemplateConfiguration ||
                    (approvalTicket.isDefaultRequest &&
                      approvalTicket.status === "Pending Assignment")
                      ? {
                          displayName: "Requesting User",
                        }
                      : {
                          displayName: approvalTicket.requestor.displayName,
                          title: approvalTicket.requestor.title,
                          photoURL: approvalTicket.requestor.photoURL,
                        },
                  ])}
                </TableCell>

                <TableCell>
                  {getDisplayUsersCellForTable(
                    getApprovalsAssignees(
                      approvalTicket.approvalChain.approvalList
                    )
                  )}
                </TableCell>

                <TableCell>
                  {DateTime.fromJSDate(
                    new Date(approvalTicket.createdAt)
                  ).toFormat("dd/MM/yyyy")}
                </TableCell>
                <TableCell>
                  {DateTime.fromJSDate(
                    new Date(approvalTicket.updatedAt)
                  ).toFormat("dd/MM/yyyy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
