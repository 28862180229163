/**
 * @param {ReviewList} reviewList
 * @returns {boolean}
 */
export function canSubmitReviewList(reviewList) {
  const canSubmit = reviewList.some(
    (reviewListItem) =>
      reviewListItem.type === "user" && reviewListItem.role === "reviewer"
  );
  return canSubmit;
}
