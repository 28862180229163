import { supportedHeadingsMap } from "./headings";

/**
 * @typedef {"TOC" | "Heading" | "Table" | "List Paragraph" | "Caption" | "Subtitle" | "Title" | "Paragraph"} BlockType
 */

/**
 * Receives an SFDT Block and returns its type.
 *
 * @param {import("../../types/sfdt").Block} block
 * @returns {BlockType}
 */
export function checkBlockType(block) {
  // The default value is "Paragraph".
  const blockType =
    /** @type {BlockType | null | undefined} */ (
      block.paragraphFormat?.styleName
    ) || "Paragraph";

  if (blockType.startsWith("TOC")) {
    return "TOC";
  }

  if (supportedHeadingsMap.get(blockType)) {
    return "Heading";
  }

  if (!block.inlines && block.rows) {
    return "Table";
  }

  // If the `paragraphFormat.styleName` is "List Paragraph" but there is no `paragraphFormat.listFormat`, that
  // means we are in the situation where a user has pressed enter on an OL/UL (which creates a new list item)
  // and then pressed backspace. This demotes it from being a list but still keeps the "List Paragraph" style.
  if (
    blockType === "List Paragraph" &&
    !block.paragraphFormat?.listFormat?.listId
  ) {
    return "Paragraph";
  }

  return blockType;
}
