import {
  faEnvelope,
  faPhone,
  faTag,
  faUserCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { ChipUserType } from "../";
import { globalStore } from "../../state/store";
import StyledFormHelperText from "../styledComponents/StyledFormHelperText";

/**
 * @typedef {object} UserRole
 * @property {string} _id
 * @property {string} name
 * @property {string} description
 * @property {string} orgID
 * @property {boolean} active
 * @property {boolean} hasReadOnly
 * @property {string} description2
 * @property {string[]} labels
 * @property {number} priority
 */

/**
 * @typedef {object} UserFormData
 * @property {string} title
 * @property {string} email
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} phone
 * @property {UserRole | "Counterparty"} role
 * @property {boolean} readOnly
 */

/**
 * @typedef {object} UserFormProps
 * @property {(params: *) => void} handleSubmit
 * @property {string[]} hideFields
 * @property {string[]} disableFields
 * @property {UserFormData} initialValues
 * @property {boolean} [selectRole]
 */

/**
 * @param {UserFormProps} props
 * @returns {JSX.Element}
 */
export default function UserForm(props) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const theme = useTheme();

  const [roles, setRoles] = useState([]);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [userRole, setUserRole] = useState(
    /** @type {UserRole | null} */ (null)
  );
  // Initial value is "role to update" or "role object".
  const [readOnly] = useState(props.initialValues?.readOnly);
  const [roleDescription, setRoleDescription] = useState("");

  const valSchema = Yup.object().shape({
    title: Yup.string()
      .max(50, "The title too long")
      .required("The title is required"),
    firstName: Yup.string()
      .max(40, "First name is too long")
      .required("First name is required"),
    lastName: Yup.string()
      .max(40, "Last name is too long")
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    phone: Yup.string().max(16, "The phone number is too long"),
  });

  const formik = useFormik({
    initialValues: {
      title: props.initialValues.title,
      email: props.initialValues.email.toLowerCase(),
      firstName: props.initialValues.firstName,
      lastName: props.initialValues.lastName,
      phone:
        "+" +
        (props.initialValues.phone !== undefined &&
        props.initialValues.phone !== null
          ? String(props.initialValues.phone).replace(/[^\d]/g, "")
          : ""),
    },
    validationSchema: valSchema,
    onSubmit: (values) => {
      // @ts-ignore
      values.role = userRole;
      // @ts-ignore
      values.readOnly = !userRole?.hasReadOnly ? false : readOnly;
      values.phone = values.phone.replace(/[^\d]/g, "");
      props.handleSubmit(values);
      values.phone = "+" + values.phone; // Update after sending it to the DB (so the "+" is just cosmetic only).
    },
  });

  // Get roles.
  useEffect(
    () => {
      axios
        .get(`${state.settings.api}role/${state.user.orgID}`)
        .then(({ data }) => {
          // Get active roles.
          setRoles(
            data.data.filter(
              (/** @type {{ active: boolean; }} */ role) => role.active
            )
          );
          // If it is a new user, populate first role on select.
          // @ts-ignore
          if (props.initialValues?.role === "Counterparty") {
            axios
              .get(`${state.settings.api}role/cpty/get`)
              .then(({ data }) => {
                setUserRole(data.data);
              })
              .catch(() => {});
          } else if (props.initialValues?.role === null) {
            setUserRole(
              data.data.find(
                (/** @type {{ name: string; }} */ e) => e.name === "Admin"
              )
            );
          } else {
            setUserRole(props.initialValues?.role);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Runs only once, on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (!!userRole && Object.keys(userRole).length > 0) {
        // Update the "ReadOnly" to no if you select any other user than a Business User when read only changes
        // (checkbox).
        setRoleDescription(
          readOnly ? userRole.description2 : userRole.description
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole, readOnly]
  );

  const emailField = (
    <Grid item container xs={12}>
      <TextField
        required
        fullWidth
        disabled={props.disableFields.includes("email")}
        variant="outlined"
        id="email"
        label="Email Address"
        placeholder="Email address"
        autoComplete="email"
        autoFocus
        {...formik.getFieldProps("email")}
        name="email"
        error={formik.touched.email && formik.errors.email ? true : false}
        helperText={
          formik.touched.email && formik.errors.email ? formik.errors.email : ""
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faEnvelope} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          {!props.hideFields.includes("userRole") ? (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              {!props.selectRole ? (
                <Box sx={{ mb: 2 }}>
                  {userRole !== null && (
                    <ChipUserType
                      role={userRole !== null ? userRole.name : "Unknown"}
                      type="standard"
                    />
                  )}
                </Box>
              ) : (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      display: "flex",
                      height: 130,
                      alignItems: "center",
                    }}
                  >
                    <FormControl
                      sx={{
                        mt: 2,
                        width: matches ? "100%" : "calc(100% - 16px)",
                      }}
                    >
                      {roles.length > 0 && userRole !== null && (
                        <Autocomplete
                          id="role"
                          options={roles}
                          getOptionLabel={(option) => option.name}
                          value={userRole}
                          autoSelect
                          onChange={(_e, val) => {
                            setUserRole(val);
                          }}
                          disableClearable
                          renderInput={(params) => (
                            <TextField {...params} label="Role" />
                          )}
                          isOptionEqualToValue={(opt, val) =>
                            opt._id === val._id
                          }
                          renderOption={(params, r) => (
                            <MenuItem {...params} key={r._id} value={r._id}>
                              {r.name}
                            </MenuItem>
                          )}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{ display: "flex", height: 130, alignItems: "center" }}
                  >
                    <StyledFormHelperText>
                      {roleDescription}
                    </StyledFormHelperText>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            ""
          )}

          {!["Admin", "Legal"].includes(
            // @ts-ignore
            userRole || ""
          ) &&
            // @ts-ignore
            props.context !== "account" && (
              <Grid
                item
                container
                xs={12}
                direction="row"
                mb={2}
                ml={1}
                mt={-4}
              >
                {
                  // @ts-ignore
                  userRole?.restrictEditing && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // @ts-ignore
                            checked={userRole?.restrictEditing}
                            disabled
                          />
                        }
                        label={
                          <Typography fontWeight="400">
                            Restrict Editing
                          </Typography>
                        }
                      />
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[800]}
                        ml={4}
                      >
                        User is prevented from making changes to the text of an
                        agreement (except for Merge Fields).
                      </Typography>
                    </FormGroup>
                  )
                }

                {
                  // @ts-ignore
                  userRole?.restrictSending && (
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // @ts-ignore
                            checked={userRole?.restrictSending}
                            disabled
                          />
                        }
                        label={
                          <Typography fontWeight="400">
                            Restrict Sending
                          </Typography>
                        }
                      />
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[800]}
                        ml={4}
                      >
                        User is prevented from sending agreements to
                        counterparties (whether for review or signature), unless
                        the latest version of the agreement has been approved.
                      </Typography>
                    </FormGroup>
                  )
                }
              </Grid>
            )}

          {/* {userRole !== null && !props.hideFields.includes("readOnly") && (
            <Grid container sx={{ mb: 2 }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 42,
                }}
              >
                {userRole?.hasReadOnly && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={readOnly}
                        onChange={() => setReadOnly(!readOnly)}
                        name="readOnly"
                      />
                    }
                    // @ts-ignore
                    disabled={["Admin", "Legal"].includes(userRole)}
                    label={"Read-only"}
                  />
                )}
              </Grid>
            </Grid>
          )} */}
          {!props.hideFields.includes("email") ? emailField : ""}

          {!props.hideFields.includes("firstName") ? (
            <Grid item container xs={12} direction="row">
              <Grid
                item
                container
                xs={6}
                sx={{
                  [theme.breakpoints.down("xs")]: { paddingRight: "10px" },
                  [theme.breakpoints.up("sm")]: { paddingRight: "20px" },
                }}
              >
                <TextField
                  required
                  disabled={props.disableFields.includes("firstName")}
                  fullWidth
                  variant="outlined"
                  id="firstName"
                  label="First Name(s)"
                  placeholder="First name(s)"
                  autoComplete="fname"
                  {...formik.getFieldProps("firstName")}
                  name="firstName"
                  error={
                    formik.touched.firstName && formik.errors.firstName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faUserCircle} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item container xs={6}>
                <TextField
                  required
                  disabled={props.disableFields.includes("firstName")}
                  fullWidth
                  variant="outlined"
                  id="lastName"
                  label="Last Name"
                  placeholder="Last name"
                  autoComplete="lname"
                  {...formik.getFieldProps("lastName")}
                  name="lastName"
                  error={
                    formik.touched.lastName && formik.errors.lastName
                      ? true
                      : false
                  }
                  helperText={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : ""
                  }
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {!props.hideFields.includes("title") ? (
            <Grid item container xs={12}>
              <TextField
                required
                fullWidth
                disabled={props.disableFields.includes("title")}
                // style={props.type === 'disabled' ? {backgroundColor: theme.palette.grey[100]} : {backgroundColor: theme.palette.primary.contrastText}}
                variant="outlined"
                id="title"
                label="Corporate Title"
                placeholder="Title"
                autoComplete="title"
                {...formik.getFieldProps("title")}
                name="title"
                error={
                  formik.touched.title && formik.errors.title ? true : false
                }
                helperText={
                  formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faTag} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : (
            ""
          )}
          {!props.hideFields.includes("phone") ? (
            <Grid item container xs={12}>
              <TextField
                required
                fullWidth
                disabled={props.disableFields.includes("phone")}
                variant="outlined"
                id="phone"
                label="Phone Number"
                placeholder="Your Phone Number"
                type={"text"}
                {...formik.getFieldProps("phone")}
                name="phone"
                error={
                  formik.touched.phone && formik.errors.phone ? true : false
                }
                helperText={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faPhone} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          ) : (
            ""
          )}

          <Grid item container xs={12}>
            <Button
              fullWidth
              disabled={
                formik.values.firstName === "" ||
                formik.values.lastName === "" ||
                formik.values.email === "" ||
                formik.values.title === ""
              }
              disableElevation
              type="submit"
              variant="contained"
              color="primary"
              style={{
                fontSize: "17px",
                fontWeight: "600",
                padding: "6px 15px",
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      <Grid container justifyContent="center" mt={4}>
        <Grid item>
          <Typography variant="body2">
            Current Version: v{process.env.REACT_APP_VERSION}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
