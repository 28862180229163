import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { Avatar, Box, Typography } from "@mui/material";
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
} from "lexical";
import * as React from "react";
import {
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import useSummaryService from "../../../hooks/useSummaryService";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import { $createMentionNode, MentionNode } from "../nodes/MentionNode";
import { useLayoutEffect } from "../utils";

const PUNCTUATION =
  "\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%'\"~=<>_:;";
const NAME = "\\b[A-Z][^\\s" + PUNCTUATION + "]";
const DocumentMentionsRegex = { NAME, PUNCTUATION };

const CapitalizedNameMentionsRegex = new RegExp(
  "(^|[^#])((?:" + DocumentMentionsRegex.NAME + "{" + 1 + ",})$)"
);
const PUNC = DocumentMentionsRegex.PUNCTUATION;
const TRIGGERS = ["@", "\\uff20"].join("");
// Chars we expect to see in a mention (non-space, non-punctuation).
const VALID_CHARS = "[^" + TRIGGERS + PUNC + "\\s]";

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  "(?:" +
  "\\.[ |$]|" + // E.g., "r. " in "Mr. Smith".
  " |" + // E.g., " " in "Josh Duck".
  "[" +
  PUNC +
  "]|" + // E.g., "-' in "Salier-Hellendag".
  ")";

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    VALID_JOINS +
    "){0," +
    LENGTH_LIMIT +
    "})" +
    ")$"
);

// 50 is the longest alias length limit.
const ALIAS_LENGTH_LIMIT = 50;

// Regex used to match alias.
const AtSignMentionsRegexAliasRegex = new RegExp(
  "(^|\\s|\\()(" +
    "[" +
    TRIGGERS +
    "]" +
    "((?:" +
    VALID_CHARS +
    "){0," +
    ALIAS_LENGTH_LIMIT +
    "})" +
    ")$"
);

/**
 * @param {*} props
 * @returns {React.ReactPortal | null}
 */
export default function MentionsPlugin(props) {
  const [editor] = useLexicalComposerContext();
  return useMentions(editor, props);
}

/**
 * @param {string} mentionString
 * @param {boolean} isTemplate
 * @param {string} docID
 * @param {*} allowedOrgs
 * @param {*} mentionUsers
 */
function useMentionLookupService(
  mentionString,
  isTemplate,
  docID,
  allowedOrgs,
  mentionUsers
) {
  const [results, setResults] = useState(null);
  // @ts-ignore
  const [state] = useContext(globalStore);

  const { signers, collaborators } = useSummaryService(docID, isTemplate, true);

  useEffect(() => {
    if (mentionUsers?.length) {
      setResults(mentionUsers);
      return;
    }

    const stringToCompare = mentionString.toLowerCase();

    const filteredUsers = state.users.filter(
      (/** @type {*} */ u) =>
        u.active &&
        u._id !== state.user._id &&
        (!isTemplate || ["Admin", "Legal"].includes(u.role.name)) &&
        (u.displayName.toLowerCase().includes(stringToCompare) ||
          u.email.toLowerCase().includes(stringToCompare))
    );

    const /** @type {*[]} */ filteredCollabs = collaborators.filter(
        (/** @type {*} */ c) => c.orgID !== state.org._id
      );

    const /** @type {*[]} */ filteredSigners = signers.filter(
        (/** @type {*} */ s) =>
          s.orgID !== state.org._id &&
          !filteredCollabs.find((c) => c._id === s._id)
      );

    let users = [...filteredUsers, ...filteredCollabs, ...filteredSigners];

    if (allowedOrgs?.length) {
      users = users.filter((u) => allowedOrgs.includes(u.orgID));
    }

    const results = users.map((user) => {
      const party = state.parties.find(
        (/** @type {*} */ p) => p.orgID === user.orgID
      );
      return { ...user, party };
    });

    // @ts-ignore
    setResults(results);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionString, isTemplate, signers, collaborators, state.parties]);

  return results;
}

/**
 * @param {*} props
 */
function MentionsTypeaheadItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  result,
}) {
  const liRef = useRef(null);

  let className = "item";
  if (isSelected) {
    className += " selected";
  }

  return (
    <li
      key={result._id}
      tabIndex={-1}
      className={className}
      ref={liRef}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={{
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          borderRadius: "5px",
        }}
      >
        <Avatar
          src={result.photoURL}
          sx={{ height: "25px", width: "25px" }}
        ></Avatar>
        <Typography variant="subtitle2">{result.displayName}</Typography>
        {result.party && (
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.grey[700],
            }}
          >
            ({result.party.shortName})
          </Typography>
        )}
      </Box>
    </li>
  );
}

/**
 * @param {*} props
 */
function MentionsTypeahead({
  close,
  editor,
  resolution,
  isTemplate,
  docID,
  allowedOrgs,
  users,
}) {
  const divRef = useRef(null);
  const match = resolution.match;
  const results = useMentionLookupService(
    match.matchingString,
    isTemplate,
    docID,
    allowedOrgs,
    users
  );

  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    const /** @type {*} */ div = divRef.current;
    const rootElement = editor.getRootElement();
    if (results !== null && div !== null && rootElement !== null) {
      const range = resolution.range;
      const { left, top, height } = range.getBoundingClientRect();

      div.style.height = "fit-content";
      div.style.width = "fit-content";
      div.style.left = `${left}px`;
      div.style.top = `${top + height}px`;

      div.style.display = "block";
      div.style.position = "fixed";
      rootElement.setAttribute("aria-controls", "mentions-typeahead");

      return () => {
        div.style.display = "none";
        rootElement.removeAttribute("aria-controls");
      };
    }
  }, [editor, resolution, results]);

  const applyCurrentSelected = useCallback(() => {
    if (results === null || selectedIndex === null) {
      return;
    }
    const selectedEntry = results[selectedIndex];

    close();

    createMentionNodeFromSearchResult(editor, selectedEntry, match);
  }, [close, match, editor, results, selectedIndex]);

  const updateSelectedIndex = useCallback(
    (/** @type {*} */ index) => {
      const rootElem = editor.getRootElement();
      if (rootElem !== null) {
        rootElem.setAttribute(
          "aria-activedescendant",
          "typeahead-item-" + index
        );
        setSelectedIndex(index);
      }
    },
    [editor]
  );

  useEffect(() => {
    return () => {
      const rootElem = editor.getRootElement();
      if (rootElem !== null) {
        rootElem.removeAttribute("aria-activedescendant");
      }
    };
  }, [editor]);

  useLayoutEffect(() => {
    if (results === null) {
      setSelectedIndex(null);
    } else if (selectedIndex === null) {
      updateSelectedIndex(0);
    }
  }, [results, selectedIndex, updateSelectedIndex]);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_ARROW_DOWN_COMMAND,
        (/** @type {*} */ payload) => {
          const event = payload;
          if (results !== null && selectedIndex !== null) {
            // @ts-ignore
            if (selectedIndex !== results.length - 1) {
              updateSelectedIndex(selectedIndex + 1);
            }
            event.preventDefault();
            event.stopImmediatePropagation();
          }
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ARROW_UP_COMMAND,
        (/** @type {*} */ payload) => {
          const event = payload;
          if (results !== null && selectedIndex !== null) {
            if (selectedIndex !== 0) {
              updateSelectedIndex(selectedIndex - 1);
            }
            event.preventDefault();
            event.stopImmediatePropagation();
          }
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ESCAPE_COMMAND,
        (/** @type {*} */ payload) => {
          const event = payload;
          if (results === null || selectedIndex === null) {
            return false;
          }
          event.preventDefault();
          event.stopImmediatePropagation();
          close();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_TAB_COMMAND,
        (/** @type {*} */ payload) => {
          const event = payload;
          if (results === null || selectedIndex === null) {
            return false;
          }
          event.preventDefault();
          event.stopImmediatePropagation();
          applyCurrentSelected();
          return true;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (/** @type {*} */ event) => {
          if (results === null || selectedIndex === null) {
            return false;
          }
          if (event !== null) {
            event.preventDefault();
            event.stopImmediatePropagation();
          }
          applyCurrentSelected();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [
    applyCurrentSelected,
    close,
    editor,
    results,
    selectedIndex,
    updateSelectedIndex,
  ]);

  if (results === null) {
    return null;
  }

  return (
    <div
      aria-label="Suggested mentions"
      id="mentions-typeahead"
      ref={divRef}
      role="listbox"
    >
      <ul
        style={{
          borderRadius: "5px",
        }}
      >
        {results
          // @ts-ignore
          .map((/** @type {*} */ result, /** @type {*} */ i) => (
            <MentionsTypeaheadItem
              index={i}
              isSelected={i === selectedIndex}
              onClick={() => {
                setSelectedIndex(i);
                applyCurrentSelected();
              }}
              onMouseEnter={() => {
                setSelectedIndex(i);
              }}
              key={result._id}
              result={result}
            />
          ))}
      </ul>
    </div>
  );
}

/**
 * @param {*} text
 * @param {*} minMatchLength
 */
function checkForCapitalizedNameMentions(text, minMatchLength) {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
}

/**
 * @param {*} text
 * @param {*} minMatchLength
 */
function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }
  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

/**
 * @param {*} text
 */
function getPossibleMentionMatch(text) {
  const match = checkForAtSignMentions(text, 1);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

/**
 * @param {*} selection
 */
function getTextUpToAnchor(selection) {
  const anchor = selection.anchor;
  if (anchor.type !== "text") {
    return null;
  }
  const anchorNode = anchor.getNode();
  // We should not be attempting to extract mentions out of nodes
  // that are already being used for other core things. This is
  // especially true for token nodes, which can't be mutated at all.
  if (!anchorNode.isSimpleText()) {
    return null;
  }
  const anchorOffset = anchor.offset;
  return anchorNode.getTextContent().slice(0, anchorOffset);
}

/**
 * @param {*} match
 * @param {*} range
 */
function tryToPositionRange(match, range) {
  const domSelection = window.getSelection();
  if (domSelection === null || !domSelection.isCollapsed) {
    return false;
  }
  const anchorNode = domSelection.anchorNode;
  const startOffset = match.leadOffset;
  const endOffset = domSelection.anchorOffset;
  try {
    range.setStart(anchorNode, startOffset);
    range.setEnd(anchorNode, endOffset);
  } catch (error) {
    return false;
  }

  return true;
}

/**
 * @param {*} editor
 */
function getMentionsTextToSearch(editor) {
  let text = null;
  editor.getEditorState().read(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection)) {
      return;
    }
    text = getTextUpToAnchor(selection);
  });
  return text;
}

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 *
 * For example: User types "Hello Sarah Smit" and we match "Smit" to "Sarah Smith".
 * Replacing just the match would give us "Hello Sarah Sarah Smith".
 * Instead we find the string "Sarah Smit" and replace all of it.
 *
 * @param {*} documentText
 * @param {*} entryText
 * @param {*} offset
 */
function getMentionOffset(documentText, entryText, offset) {
  let triggerOffset = offset;
  for (let ii = triggerOffset; ii <= entryText.length; ii++) {
    if (documentText.substr(-ii) === entryText.substr(0, ii)) {
      triggerOffset = ii;
    }
  }

  return triggerOffset;
}

/**

 */

/**
 * From a Typeahead Search Result, replace plain text from search offset and
 * render a newly created MentionNode.
 *
 * @param {*} editor
 * @param {*} user
 * @param {*} match
 */
function createMentionNodeFromSearchResult(editor, user, match) {
  editor.update(() => {
    const selection = $getSelection();
    if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
      return;
    }
    const anchor = selection.anchor;
    if (anchor.type !== "text") {
      return;
    }
    const anchorNode = anchor.getNode();
    // We should not be attempting to extract mentions out of nodes
    // that are already being used for other core things. This is
    // especially true for token nodes, which can't be mutated at all.
    if (!anchorNode.isSimpleText()) {
      return;
    }
    const selectionOffset = anchor.offset;
    const textContent = anchorNode.getTextContent().slice(0, selectionOffset);
    const characterOffset = match.replaceableString.length;

    // Given a known offset for the mention match, look backward in the
    // text to see if there's a longer match to replace.
    const mentionOffset = getMentionOffset(
      textContent,
      user.displayName,
      characterOffset
    );
    const startOffset = selectionOffset - mentionOffset;
    if (startOffset < 0) {
      return;
    }

    let nodeToReplace;
    if (startOffset === 0) {
      [nodeToReplace] = anchorNode.splitText(selectionOffset);
    } else {
      [, nodeToReplace] = anchorNode.splitText(startOffset, selectionOffset);
    }

    const { displayName, email, _id, partyID, orgID } = user;
    const mentionNode = $createMentionNode("@" + displayName, null, {
      email,
      _id,
      partyID,
      orgID,
    });
    nodeToReplace.replace(mentionNode);
    mentionNode.select();
  });
}

/**
 * @param {*} editor
 * @param {*} offset
 */
function isSelectionOnEntityBoundary(editor, offset) {
  if (offset !== 0) {
    return false;
  }
  return editor.getEditorState().read(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchor = selection.anchor;
      const anchorNode = anchor.getNode();
      const prevSibling = anchorNode.getPreviousSibling();
      return $isTextNode(prevSibling) && prevSibling.isTextEntity();
    }
    return false;
  });
}

/**
 * @param {*} editor
 * @param {*} options
 */
function useMentions(editor, { isTemplate, docID, allowedOrgs, users }) {
  const [resolution, setResolution] = useState(null);

  useEffect(() => {
    if (!editor.hasNodes([MentionNode])) {
      throw new Error("MentionsPlugin: MentionNode not registered on editor");
    }
  }, [editor]);

  useEffect(() => {
    let activeRange = document.createRange();
    let /** @type {string | null} */ previousText = null;

    const updateListener = () => {
      const range = activeRange;
      const text = getMentionsTextToSearch(editor);

      if (text === previousText || range === null) {
        return;
      }
      previousText = text;

      if (text === null) {
        return;
      }
      const match = getPossibleMentionMatch(text);
      if (
        match !== null &&
        !isSelectionOnEntityBoundary(editor, match.leadOffset)
      ) {
        const isRangePositioned = tryToPositionRange(match, range);
        if (isRangePositioned !== null) {
          startTransition(() =>
            setResolution({
              // @ts-ignore
              match,
              range,
            })
          );
          return;
        }
      }
      startTransition(() => setResolution(null));
    };

    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      // @ts-ignore
      activeRange = null;
      removeUpdateListener();
    };
  }, [editor]);

  const closeTypeahead = useCallback(() => {
    setResolution(null);
  }, []);

  return resolution === null || editor === null
    ? null
    : createPortal(
        <MentionsTypeahead
          close={closeTypeahead}
          resolution={resolution}
          editor={editor}
          isTemplate={isTemplate}
          docID={docID}
          allowedOrgs={allowedOrgs}
          users={users}
        />,
        document.body
      );
}
