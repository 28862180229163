import { $dfs, $getNearestNodeOfType } from "@lexical/utils";
import {
  $getSelection,
  $insertNodes,
  $isElementNode,
  $isRangeSelection,
  $isTextNode,
  KEY_ENTER_COMMAND,
} from "lexical";
import { ClauseNode, CustomTableCellNode } from "../../../../../nodes";
import {
  $createClauseNode,
  $isClauseNode,
} from "../../../../../nodes/ClauseNode";
import { $isCustomHeadingNode } from "../../../../../nodes/CustomHeadingNode";
import { $isCustomListItemNode } from "../../../../../nodes/CustomListItemNode";
import { $isCustomListNode } from "../../../../../nodes/CustomListNode";
import {
  $createCustomParagraphNode,
  $isCustomParagraphNode,
  CustomParagraphNode,
} from "../../../../../nodes/CustomParagraphNode";
import {
  $createRedlineNode,
  $isRedlineNode,
} from "../../../../../nodes/RedlineNode";
import { handleSelectionDelete } from "../../../../../utils/handleSelectionDelete";
import { defaultClauseNodeSettings } from "../../../../CanveoPlugin/constants/defaultClauseNodeSettings";
import { selectionIsAtBeginningOfInline } from "../../../../CanveoPlugin/utils/selectionIsAtBeginningOfInline";
import { selectionIsAtEndOfInline } from "../../../../CanveoPlugin/utils/selectionIsAtEndOfInline";
import { isClipboardEvent } from "../../../utils";
import { preventEventPropagation } from "../../utils/preventEventPropagation";
import { getCopiedNodesFromClipboard } from "./utils/getCopiedNodesFromClipboard";

/**
 * @param {import("lexical").PasteCommandType} event
 * @param {import("lexical").LexicalEditor} editor
 * @param {RedlineData} defaultRedlineData
 * @returns {boolean}
 */
export function pasteCommandHandler(event, editor, defaultRedlineData) {
  if (!isClipboardEvent(event)) return preventEventPropagation(event);

  const initialSelection = $getSelection();
  if (!$isRangeSelection(initialSelection)) {
    return preventEventPropagation(event);
  }
  // If we are pasting after selecting text, the selected text needs to be handled for deletion.
  handleSelectionDelete(initialSelection, defaultRedlineData);

  if (!event.clipboardData) return preventEventPropagation(event);

  const updatedSelection = $getSelection();
  if (!$isRangeSelection(updatedSelection) || !updatedSelection.isCollapsed()) {
    return preventEventPropagation(event);
  }

  const updatedSelectionNode = (
    updatedSelection.isBackward()
      ? updatedSelection.focus
      : updatedSelection.anchor
  ).getNode();
  const selectionInsideTableCell = !!$getNearestNodeOfType(
    updatedSelectionNode,
    CustomTableCellNode
  );

  const copiedNodes = getCopiedNodesFromClipboard(
    event.clipboardData,
    updatedSelection,
    defaultRedlineData,
    // If the selection is inside a table cell we do not want to generate clauses.
    !selectionInsideTableCell
  );

  const copyingClauseNodes = copiedNodes.every($isClauseNode);
  const copyingTextNodes = copiedNodes.every($isTextNode);
  const copyingHeadingNodes = copiedNodes.every($isCustomHeadingNode);
  const copyingListNodes = copiedNodes.every($isCustomListNode);
  const copyingParagraphNodes = copiedNodes.every($isCustomParagraphNode);

  if (copyingClauseNodes) {
    // Selection of type element occurs when there are no text nodes in an element node e.g., a paragraph without
    // text or an empty paragraph that has just been created from an enter key press.
    if (
      updatedSelection.anchor.type === "element" &&
      updatedSelection.focus.type === "element"
    ) {
      if (copyingTextNodes) {
        $insertNodes(copiedNodes);
      } else {
        const selectionNode = updatedSelection.anchor.getNode();
        const selectionParagraphNode = $getNearestNodeOfType(
          selectionNode,
          CustomParagraphNode
        );

        const selectionClauseNode = $getNearestNodeOfType(
          selectionNode,
          ClauseNode
        );
        if (!selectionClauseNode) return preventEventPropagation(event);

        const newClauseNode = $createClauseNode(defaultClauseNodeSettings);
        const paragraphNode = selectionParagraphNode
          ? CustomParagraphNode.copy(selectionParagraphNode)
          : $createCustomParagraphNode();

        newClauseNode.append(paragraphNode);

        /** @type {ClauseNode} */
        let currentNode = selectionClauseNode;
        for (const node of copiedNodes) {
          if ($isElementNode(node)) {
            const textNodes = node.getAllTextNodes();
            for (const textNode of textNodes) {
              if (!$isRedlineNode(textNode)) {
                const redlineNode = $createRedlineNode({
                  ...defaultRedlineData,
                  text: textNode.getTextContent(),
                  redlineType: "add",
                });
                redlineNode.setFormat(textNode.getFormat());
                redlineNode.setStyle(textNode.getStyle());

                textNode.replace(redlineNode);
              }
            }
          }

          currentNode.insertAfter(node);
          currentNode = node;
        }
        selectionClauseNode.remove();
        currentNode.selectEnd();
      }
    } else {
      if (selectionIsAtBeginningOfInline(updatedSelection)) {
        const node = updatedSelection.anchor.getNode();

        const selectionClauseNode = $getNearestNodeOfType(node, ClauseNode);
        if (!selectionClauseNode) return preventEventPropagation(event);

        /**
         * @type {ClauseNode}
         */
        let currentNode = selectionClauseNode;
        for (let index = copiedNodes.length - 1; index >= 0; index--) {
          const node = copiedNodes[index];
          if ($isElementNode(node)) {
            const textNodes = node.getAllTextNodes();
            for (const textNode of textNodes) {
              const redlineNode = $createRedlineNode({
                ...defaultRedlineData,
                text: textNode.getTextContent(),
                redlineType: "add",
              });
              redlineNode.setFormat(textNode.getFormat());
              redlineNode.setStyle(textNode.getStyle());

              textNode.replace(redlineNode);
            }
          }

          currentNode.insertBefore(node);
          currentNode = node;
        }

        currentNode.selectStart();
      } else if (selectionIsAtEndOfInline(updatedSelection)) {
        const node = updatedSelection.anchor.getNode();

        const selectionClauseNode = $getNearestNodeOfType(node, ClauseNode);
        if (!selectionClauseNode) return preventEventPropagation(event);

        /**
         * @type {ClauseNode}
         */
        let currentNode = selectionClauseNode;
        for (const node of copiedNodes) {
          if ($isElementNode(node)) {
            const textNodes = node.getAllTextNodes();
            for (const textNode of textNodes) {
              const redlineNode = $createRedlineNode({
                ...defaultRedlineData,
                text: textNode.getTextContent(),
                redlineType: "add",
              });
              redlineNode.setFormat(textNode.getFormat());
              redlineNode.setStyle(textNode.getStyle());

              textNode.replace(redlineNode);
            }
          }

          currentNode.insertAfter(node);
          currentNode = node;
        }

        currentNode.selectEnd();
      }
      // Selection is in the middle of an inline.
      else {
        // Dispatch a key enter command to break the current text where the selection is.
        editor.dispatchCommand(KEY_ENTER_COMMAND, null);

        const selectionNode = updatedSelection.anchor.getNode();

        const selectionClauseNode = $getNearestNodeOfType(
          selectionNode,
          ClauseNode
        );
        if (!selectionClauseNode) return preventEventPropagation(event);

        /**
         * @type {ClauseNode}
         */
        let currentNode = selectionClauseNode;
        for (let index = copiedNodes.length - 1; index >= 0; index--) {
          const node = copiedNodes[index];
          if ($isElementNode(node)) {
            const textNodes = node.getAllTextNodes();
            for (const textNode of textNodes) {
              const redlineNode = $createRedlineNode({
                ...defaultRedlineData,
                text: textNode.getTextContent(),
                redlineType: "add",
              });
              redlineNode.setFormat(textNode.getFormat());
              redlineNode.setStyle(textNode.getStyle());

              textNode.replace(redlineNode);
            }
          }

          currentNode.insertBefore(node);
          currentNode = node;
        }

        currentNode.selectStart();
      }
    }
  } else if (copyingTextNodes) {
    const selectionFocusNode = updatedSelection.focus.getNode();

    const nodesToInsert = [];
    for (const copiedNode of copiedNodes) {
      if ($isRedlineNode(copiedNode)) {
        if ($isTextNode(selectionFocusNode)) {
          copiedNode.setFormat(selectionFocusNode.getFormat());
          copiedNode.setStyle(selectionFocusNode.getStyle());
        }

        nodesToInsert.push(copiedNode);
      } else {
        const redlineNode = $createRedlineNode({
          ...defaultRedlineData,
          redlineType: "add",
          text: copiedNode.getTextContent(),
        });

        const selectionNodes = updatedSelection.getNodes();
        if (selectionNodes.length === 1) {
          const [selectionNode] = selectionNodes;

          // We want to keep the source formatting/styling if the destination is empty i.e., an empty paragraph.
          if (
            $isElementNode(selectionNode) &&
            selectionNode.getTextContentSize() === 0 &&
            // If it is an empty list item we want the formatting of the list to take place.
            !$isCustomListItemNode(selectionNode) &&
            $isTextNode(copiedNode)
          ) {
            redlineNode.setFormat(copiedNode.getFormat());
            redlineNode.setStyle(copiedNode.getStyle());
          }
          // Otherwise, we keep the destination formatting/styling.
          else if ($isTextNode(selectionNode)) {
            redlineNode.setFormat(selectionNode.getFormat());
            redlineNode.setStyle(selectionNode.getStyle());
          }
        }

        nodesToInsert.push(redlineNode);
      }
    }

    if (updatedSelection.focus.type === "element") {
      const node = updatedSelection.focus.getNode();
      if (updatedSelection.isBackward()) {
        node.selectStart();
      } else {
        node.selectEnd();
      }
      updatedSelection.insertNodes(nodesToInsert);
    } else {
      updatedSelection.insertNodes(nodesToInsert);
    }
  } else if (copyingHeadingNodes) {
    const selectionFocusNode = updatedSelection.focus.getNode();

    /** @type {RedlineNode[]} */
    const redlineNodes = [];
    for (const copiedNode of copiedNodes) {
      const textNodes = copiedNode.getAllTextNodes();
      for (const textNode of textNodes) {
        if ($isRedlineNode(textNode)) {
          if ($isTextNode(selectionFocusNode)) {
            textNode.setFormat(selectionFocusNode.getFormat());
            textNode.setStyle(selectionFocusNode.getStyle());
          }
          redlineNodes.push(textNode);
        } else {
          const redlineNode = $createRedlineNode({
            ...defaultRedlineData,
            text: textNode.getTextContent(),
            redlineType: "add",
          });

          // We want to keep the source formatting/styling if the destination is empty i.e., an empty paragraph.
          if (
            $isElementNode(selectionFocusNode) &&
            selectionFocusNode.getTextContentSize() === 0 &&
            // If it is an empty list item we want the formatting of the list to take place.
            !$isCustomListItemNode(selectionFocusNode) &&
            $isTextNode(copiedNode)
          ) {
            redlineNode.setFormat(copiedNode.getFormat());
            redlineNode.setStyle(copiedNode.getStyle());
          }
          // Otherwise, we keep the destination formatting/styling.
          else if ($isTextNode(selectionFocusNode)) {
            redlineNode.setFormat(selectionFocusNode.getFormat());
            redlineNode.setStyle(selectionFocusNode.getStyle());
          }

          redlineNodes.push(redlineNode);
        }
      }
    }

    if (updatedSelection.focus.type === "element") {
      const node = updatedSelection.focus.getNode();

      if ($isClauseNode(node)) {
        const firstDescendant = node.getFirstDescendant();
        if ($isCustomParagraphNode(firstDescendant)) {
          firstDescendant.remove();
          node.append(...copiedNodes);
          copiedNodes.at(-1)?.selectEnd();
        }
      } else if ($isCustomParagraphNode(node)) {
        const clause = node.getParent();
        if ($isClauseNode(clause)) {
          node.remove();
          clause.append(...copiedNodes);
          copiedNodes.at(-1)?.selectEnd();
        }
      }
    } else {
      updatedSelection.insertNodes(redlineNodes);
    }
  } else if (copyingListNodes) {
    const selectionFocusNode = updatedSelection.focus.getNode();

    /** @type {RedlineNode[]} */
    const redlineNodes = [];
    for (const copiedNode of copiedNodes) {
      const textNodes = copiedNode.getAllTextNodes();
      for (const textNode of textNodes) {
        if ($isRedlineNode(textNode)) {
          if ($isTextNode(selectionFocusNode)) {
            textNode.setFormat(selectionFocusNode.getFormat());
            textNode.setStyle(selectionFocusNode.getStyle());
          }
          redlineNodes.push(textNode);
        } else {
          const redlineNode = $createRedlineNode({
            ...defaultRedlineData,
            text: textNode.getTextContent(),
            redlineType: "add",
          });

          // We want to keep the source formatting/styling if the destination is empty i.e., an empty paragraph.
          if (
            $isElementNode(selectionFocusNode) &&
            selectionFocusNode.getTextContentSize() === 0 &&
            // If it is an empty list item we want the formatting of the list to take place.
            !$isCustomListItemNode(selectionFocusNode) &&
            $isTextNode(copiedNode)
          ) {
            redlineNode.setFormat(copiedNode.getFormat());
            redlineNode.setStyle(copiedNode.getStyle());
          }
          // Otherwise, we keep the destination formatting/styling.
          else if ($isTextNode(selectionFocusNode)) {
            redlineNode.setFormat(selectionFocusNode.getFormat());
            redlineNode.setStyle(selectionFocusNode.getStyle());
          }

          textNode.replace(redlineNode);
          redlineNodes.push(redlineNode);
        }
      }
    }

    if (updatedSelection.focus.type === "element") {
      const node = updatedSelection.focus.getNode();

      if ($isClauseNode(node)) {
        const firstDescendant = node.getFirstDescendant();
        if ($isCustomParagraphNode(firstDescendant)) {
          firstDescendant.remove();
          node.append(...copiedNodes);
          copiedNodes.at(-1)?.selectEnd();
        }
      } else if ($isCustomParagraphNode(node)) {
        const clause = node.getParent();
        if ($isClauseNode(clause)) {
          node.remove();
          clause.append(...copiedNodes);
          copiedNodes.at(-1)?.selectEnd();
        }
      } else if ($isCustomListItemNode(node)) {
        node.append(...redlineNodes);
        node.selectEnd();
      }
    } else {
      updatedSelection.insertNodes(redlineNodes);
    }

    // Force update of all list nodes so that the markers are updated.
    setTimeout(() => {
      editor.update(() => {
        console.info("List reconciliation after pasting.");
        const dfs = $dfs();
        for (const { node } of dfs) {
          if (!$isCustomListItemNode(node)) continue;

          // We set the value to its own value to retrigger the createDOM.
          node.setValue(node.getValue());
        }
      });
    }, 0);
  } else if (copyingParagraphNodes) {
    updatedSelection.insertNodes(copiedNodes);
  }

  return preventEventPropagation(event);
}
