import {
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircleX,
  faHourglassStart,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Box, Grid } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";
import { getStatusTaskStyles } from "../../../views/Tasks/getStatusTaskStyles";
import { avatarSize } from "../constants/avatarSize";
import { avatarStyles } from "../constants/avatarStyles";
import { badgeStyles } from "../constants/badgeStyles";
import ChainItem from "./ChainItem";

/**
 * @typedef {object} DisplayReviewListProps
 * @property {ReviewTicket} reviewTicket
 */

/**
 * @param {DisplayReviewListProps} props
 * @returns {JSX.Element}
 */
export default function DisplayReviewList({ reviewTicket }) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      mt={2}
    >
      {reviewTicket.reviewChain.reviewList.map((reviewListItem, index) => {
        switch (reviewListItem.type) {
          case "user": {
            switch (reviewListItem.reviewStatus) {
              case "Pending": {
                return (
                  <Grid item key={index}>
                    <ChainItem
                      primaryText={reviewListItem.displayName}
                      secondaryText={reviewListItem.title}
                    >
                      <Avatar
                        src={reviewListItem.photoUrl}
                        sx={{ ...avatarStyles }}
                      />
                    </ChainItem>
                  </Grid>
                );
              }

              case "In Progress": {
                return (
                  <Badge
                    key={index}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Box marginLeft="10px">
                        <Box
                          sx={{
                            ...badgeStyles,
                            // Background color of the circle.
                            backgroundColor: theme.palette.primary.main,
                            // Color of the icon.
                            color: "white",
                          }}
                        >
                          <FontAwesomeIcon icon={faHourglassStart} />
                        </Box>
                      </Box>
                    }
                  >
                    <ChainItem
                      primaryText={reviewListItem.displayName}
                      secondaryText={reviewListItem.title}
                    >
                      <Avatar
                        src={reviewListItem.photoUrl}
                        sx={{ ...avatarStyles }}
                      />
                    </ChainItem>
                  </Badge>
                );
              }

              case "Completed": {
                return (
                  <Badge
                    key={index}
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <Box marginLeft="10px">
                        <Box
                          sx={{
                            ...badgeStyles,
                            // Background color of the circle.
                            backgroundColor: "green",
                            // Color of the icon.
                            color: "white",
                          }}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Box>
                      </Box>
                    }
                  >
                    <ChainItem
                      primaryText={reviewListItem.displayName}
                      secondaryText={reviewListItem.title}
                    >
                      <Avatar
                        src={reviewListItem.photoUrl}
                        sx={{ ...avatarStyles }}
                      />
                    </ChainItem>
                  </Badge>
                );
              }

              default: {
                return (
                  <Grid item key={index}>
                    <ChainItem
                      primaryText={reviewListItem.displayName}
                      secondaryText={reviewListItem.title}
                    >
                      <Avatar
                        src={reviewListItem.photoUrl}
                        sx={{ ...avatarStyles }}
                      />
                    </ChainItem>
                  </Grid>
                );
              }
            }
          }

          case "addReviewer": {
            return (
              <Grid item key={index} mb={4}>
                <FontAwesomeIcon icon={faCaretRight} color="#ABA9B8" />
              </Grid>
            );
          }

          case "check": {
            const styles = getStatusTaskStyles(reviewTicket.status);

            switch (reviewTicket.status) {
              case "Cancelled": {
                return (
                  <Grid item key={index}>
                    <ChainItem primaryText={reviewTicket.status}>
                      <FontAwesomeIcon
                        icon={faCircleX}
                        color="#ABA9B8"
                        fontSize={avatarSize}
                      />
                    </ChainItem>
                  </Grid>
                );
              }

              case "Completed": {
                return (
                  <Grid item key={index}>
                    <ChainItem primaryText={reviewTicket.status}>
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        color={"green"}
                        fontSize={avatarSize}
                      />
                    </ChainItem>
                  </Grid>
                );
              }

              default: {
                return (
                  <Grid item key={index}>
                    <ChainItem primaryText={reviewTicket.status}>
                      <Box
                        sx={{
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          // Makes the container a circle.
                          borderRadius: "50%",
                          ...avatarStyles,
                          // Background color of the circle.
                          backgroundColor: "#ABA9B8",
                          // Color of the icon.
                          color: "white",
                          ...styles,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faHourglassStart}
                          fontSize={avatarSize - 30}
                        />
                      </Box>
                    </ChainItem>
                  </Grid>
                );
              }
            }
          }

          default: {
            throw new Error("Invalid type.");
          }
        }
      })}
    </Grid>
  );
}
