import {
  faArrowRightArrowLeft,
  faCommentsQuestionCheck,
  faSignature,
  faThumbsUp,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Tab,
  Tabs,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import { reviewsAndApprovalsParentDialogStyles } from "../../reviewsAndApprovals/constants/reviewsAndApprovalsParentDialogStyles";
import TabPanel from "./components/TabPanel";
import { getFilledCircleStyles, getOutlinedCircleStyles } from "./constants";
import ApprovalTab from "./tabs/ApprovalTab";
import ReviewTab from "./tabs/ReviewTab";
import SendTab from "./tabs/SendTab";
import SignTab from "./tabs/SignTab";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 4,
    top: 12,
    fontWeight: "bold",
  },
}));

/**
 * @typedef {object} DialogOwnerSendOptionsProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {DialogOwnerSendOptionsAgreement} agreement
 * @property {"Review" | "Approval" | string & {} | null} taskType
 * @property {string | null} taskId
 * @property {string} [dialogTitle]
 * @property {string} [approvalsTabTitle]
 * @property {string} [reviewsTabTitle]
 * @property {boolean} [hideSendAndSigningTabs]
 * @property {number} [defaultSelectedTab]
 */

/**
 * @param {DialogOwnerSendOptionsProps} props
 * @returns {JSX.Element}
 */
export default function DialogOwnerSendOptions({
  open,
  close,
  agreement,
  taskType,
  taskId,
  dialogTitle = "Complete this Agreement",
  approvalsTabTitle = "Get approvals",
  reviewsTabTitle = "Get help from Legal",
  hideSendAndSigningTabs = false,
  defaultSelectedTab = -1,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [isLoading, setIsLoading] = useState(false);
  const [sendFlowMetadata, setSendFlowMetadata] = useState(
    /** @type {SendFlowMetadata | null} */ (null)
  );
  const [selectedTab, setSelectedTab] = useState(
    taskType === "Approval" ? 1 : 0
  );

  useEffect(
    () => {
      if (sendFlowMetadata) return;

      loadAgreementSendFlowMetadata(agreement._id).then((sendFlowMetadata) => {
        if (defaultSelectedTab >= 0) return;
        if (!sendFlowMetadata) return;
        // If user comes from email link, we do not want the default tab behaviour.
        if (taskType && taskId) return;

        // Set default selected tabs according to rules.
        // if (sendFlowMetadata.inSigningState) {
        //   setSelectedTab(3);
        //   return;
        // }

        const approvalTasksInStatusOtherThanApproved =
          sendFlowMetadata.approvalTasks.some(
            (approvalTask) => approvalTask.status === "In Progress"
          );
        if (approvalTasksInStatusOtherThanApproved) {
          setSelectedTab(1);
          return;
        }

        const reviewTasksInStatusOtherThanCompleted =
          sendFlowMetadata.reviewTasks.some(
            (reviewTask) =>
              reviewTask.status === "In Progress" ||
              reviewTask.status === "Assigned"
          );
        if (reviewTasksInStatusOtherThanCompleted) {
          setSelectedTab(0);
          return;
        }

        setSelectedTab(2);
        return;
      });
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * @param {string} agreementId
   * @returns {Promise<SendFlowMetadata | undefined>}
   */
  async function loadAgreementSendFlowMetadata(agreementId) {
    try {
      setIsLoading(true);

      const getSendFlowMetadataResponse = await axios.get(
        `${state.settings.api}agr/${agreementId}/sendFlowMetadata`
      );

      /** @type {SendFlowMetadata} */
      const sendFlowMetadata = getSendFlowMetadataResponse.data.data;
      setSendFlowMetadata(sendFlowMetadata);
      return sendFlowMetadata;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to retrieve send flow metadata, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * @param {React.SyntheticEvent} _event
   * @param {number} newValue
   * @returns {void}
   */
  function handleChange(_event, newValue) {
    setSelectedTab(newValue);
  }

  /**
   * @param {ReviewTicket[]} reviewTickets
   * @returns {void}
   */
  function handleUpdateReviewTickets(reviewTickets) {
    setSendFlowMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, reviewTasks: reviewTickets };
    });
  }

  /**
   * @param {ApprovalTicket[]} approvalTickets
   * @returns {void}
   */
  function handleUpdateApprovalTickets(approvalTickets) {
    setSendFlowMetadata((previous) => {
      if (!previous) return previous;

      return { ...previous, approvalTasks: approvalTickets };
    });
  }

  const canSend = useMemo(() => {
    if (!state.user.role.restrictSending) return true;

    if (!sendFlowMetadata) return false;
    const currentVersion = state.drawerVersions.versions[0];
    if (!sendFlowMetadata || !currentVersion) return false;

    const currentVersionApproved =
      currentVersion.basedOnApproved || currentVersion.basedOnReviewed;
    const ongoingApprovalTasks = sendFlowMetadata.approvalTasks.some(
      (approvalTask) =>
        approvalTask.status !== "Approved" &&
        approvalTask.status !== "Cancelled"
    );
    const ongoingReviewTasks = sendFlowMetadata.reviewTasks.some(
      (reviewTask) =>
        reviewTask.status !== "Completed" && reviewTask.status !== "Cancelled"
    );

    const canSend =
      currentVersionApproved && !ongoingApprovalTasks && !ongoingReviewTasks;

    return canSend;
  }, [sendFlowMetadata, state.drawerVersions, state.user.role.restrictSending]);

  // For the time being, the logic to enable sending as well as signing is the exact same.
  const canSign = canSend;

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={false}
      sx={reviewsAndApprovalsParentDialogStyles}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        <Grid container direction="row" alignItems="center" gap={2}>
          <Grid item>{dialogTitle}</Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        {isLoading && (
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <CanveoCircularProgress />
            </Grid>
          </Grid>
        )}

        {!isLoading && sendFlowMetadata && (
          <Box mt={2} ml={4} mr={4}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab
                label={<Box mb={2}>{reviewsTabTitle}</Box>}
                sx={{ fontWeight: "bold" }}
                icon={
                  <Box>
                    <StyledBadge
                      invisible={(() =>
                        !sendFlowMetadata.reviewTasks.some((reviewTask) =>
                          ["Assigned", "In Progress"].includes(
                            reviewTask.status
                          )
                        ))()}
                      badgeContent="!"
                      color="secondary"
                    >
                      <Box
                        mb={2}
                        sx={
                          selectedTab === 0
                            ? getFilledCircleStyles()
                            : getOutlinedCircleStyles()
                        }
                      >
                        <FontAwesomeIcon icon={faCommentsQuestionCheck} />
                      </Box>
                    </StyledBadge>
                  </Box>
                }
              />

              <Tab
                label={<Box mb={2}>{approvalsTabTitle}</Box>}
                sx={{ fontWeight: "bold" }}
                icon={
                  <Box>
                    <StyledBadge
                      invisible={(() =>
                        !sendFlowMetadata.approvalTasks.some((approvalTask) =>
                          ["In Progress"].includes(approvalTask.status)
                        ))()}
                      badgeContent="!"
                      color="secondary"
                    >
                      <Box
                        mb={2}
                        sx={
                          selectedTab === 1
                            ? getFilledCircleStyles()
                            : getOutlinedCircleStyles()
                        }
                      >
                        <FontAwesomeIcon icon={faThumbsUp} />
                      </Box>
                    </StyledBadge>
                  </Box>
                }
              />

              <Tab
                label={<Box mb={2}>Send</Box>}
                sx={{
                  fontWeight: "bold",
                  pointerEvents: "auto",
                  display: hideSendAndSigningTabs ? "none" : undefined,
                }}
                icon={
                  <Box>
                    <Box
                      mb={2}
                      sx={
                        selectedTab === 2
                          ? getFilledCircleStyles()
                          : getOutlinedCircleStyles()
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                    </Box>
                  </Box>
                }
              />

              <Tab
                label={<Box mb={2}>Sign</Box>}
                sx={{
                  fontWeight: "bold",
                  pointerEvents: "auto",
                  display: hideSendAndSigningTabs ? "none" : undefined,
                }}
                icon={
                  <Box>
                    <Box
                      mb={2}
                      sx={
                        selectedTab === 3
                          ? getFilledCircleStyles()
                          : getOutlinedCircleStyles()
                      }
                    >
                      <FontAwesomeIcon icon={faSignature} />
                    </Box>
                  </Box>
                }
              />
            </Tabs>

            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            />

            <Box>
              <TabPanel value={selectedTab} index={0}>
                <ReviewTab
                  agreement={agreement}
                  reviewTickets={sendFlowMetadata.reviewTasks}
                  updateReviewTickets={handleUpdateReviewTickets}
                  taskId={taskType === "Review" ? taskId : null}
                />
              </TabPanel>

              <TabPanel value={selectedTab} index={1}>
                <ApprovalTab
                  agreement={agreement}
                  approvalTickets={sendFlowMetadata.approvalTasks}
                  updateApprovalTickets={handleUpdateApprovalTickets}
                  taskId={taskType === "Approval" ? taskId : null}
                />
              </TabPanel>

              <TabPanel value={selectedTab} index={2}>
                <SendTab
                  open={true}
                  handleCloseDialog={() => {}}
                  handleCloseGroup={() => {}}
                  agreement={agreement}
                  changeTab={(tabIndex) => {
                    setSelectedTab(tabIndex);
                  }}
                  canSend={canSend}
                />
              </TabPanel>

              <TabPanel value={selectedTab} index={3}>
                <SignTab
                  open={true}
                  handleCloseDialog={() => {}}
                  handleCloseGroup={close}
                  agreement={agreement}
                  changeTab={(tabIndex) => {
                    setSelectedTab(tabIndex);
                  }}
                  canSign={canSign}
                />
              </TabPanel>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
