import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faH1,
  faHighlighter,
  faImage,
  faItalic,
  faListOl,
  faListUl,
  faRedo,
  faUnderline,
  faUndo,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import { IconButton } from "@mui/material";
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  ElementNode,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { globalStore } from "../../../../state/store";
import { getCanveoTier } from "../../../../utils/getCanveoTier";
import { supportedStylesMap } from "../../utils/constants";
import getDOMRangeRect from "../../utils/getDOMRangeRect";
import getSelectedNode from "../../utils/getSelectedNode";
import { PREVENT_EVENT_PROPAGATION } from "../TrackChangesPlugin/utils";
import ToolbarFontsPlugin from "./ToolbarFontsPlugin";
import FontColorControls from "./ToolbarFontsPlugin/FontColorControls";
import ToolbarTablePlugin from "./ToolbarTablePlugin";
import ToolbarStylesComponent from "./components/ToolbarHeadingsComponent";

/**
 * Creates the Box for the Link editor.
 *
 * @param {*} editor
 * @param {*} rect
 * @returns {void}
 */
function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

/**
 * Handles the link editor.
 *
 * @param {{ editor: LexicalEditor }} props
 * @returns {JSX.Element}
 */
function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      nativeSelection?.anchorNode &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rangeRect);
      }
      // @ts-ignore
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(
        /**
         * @param {*} param
         */
        ({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }
      ),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkUrl}
            </a>
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

/**
 * @param {*} props
 * @returns {JSX.Element}
 */
export default function ToolbarPlugin({ sfdt }) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  // eslint-disable-next-line no-unused-vars
  const [selectedElementKey, setSelectedElementKey] = useState(
    /** @type {string | null} */ (null)
  );
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikeThrough] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [activeTextAlign, setActiveTextAlign] = useState(
    /** @type {number | string} */ (0)
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $getNearestNodeOfType(anchorNode, ElementNode);
      if (!element) return;
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          let type;
          if ($isHeadingNode(element)) {
            type = element.getTag();
          } else {
            const descendant = element.getLastDescendant();
            if (descendant) {
              type = descendant.getType();
            } else {
              type = element.getType();
            }
          }

          setBlockType(type);
        }
        setActiveTextAlign(
          [4].includes(element.getFormat())
            ? "justify"
            : [3].includes(element.getFormat())
            ? "right"
            : [2].includes(element.getFormat())
            ? "center"
            : "left"
        );
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikeThrough(selection.hasFormat("strikethrough"));
      setIsHighlight(selection.hasFormat("highlight"));
      setIsSubscript(selection.hasFormat("subscript"));
      setIsSuperscript(selection.hasFormat("superscript"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return PREVENT_EVENT_PROPAGATION;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return PREVENT_EVENT_PROPAGATION;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return PREVENT_EVENT_PROPAGATION;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, updateToolbar]);

  // eslint-disable-next-line no-unused-vars
  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const formatBulletList = () => {
    if (blockType !== "ul") {
      // @ts-ignore
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      // @ts-ignore
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      // @ts-ignore
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  /**
   * @type {ToolbarOption[]}
   */
  const options = [
    {
      name: "Undo",
      icon: faUndo,
      isActive: false,
      // @ts-ignore
      click: (e) => editor.dispatchCommand(UNDO_COMMAND),
      disabled: !canUndo,
    },
    {
      name: "Redo",
      icon: faRedo,
      isActive: false,
      // @ts-ignore
      click: (e) => editor.dispatchCommand(REDO_COMMAND),
      disabled: !canRedo,
      hasDivider: true,
    },
    {
      name: "H1",
      icon: faH1,
      isActive: Array.from(supportedStylesMap).includes(
        // @ts-ignore
        (el) => el[1] === blockType
      ),
      component: (
        <ToolbarStylesComponent
          sfdt={sfdt}
          item={{
            name: "Styles",
            isActive: supportedStylesMap.get(blockType),
          }}
        />
      ),
    },
    {
      component: (
        <ToolbarFontsPlugin
          sfdt={sfdt}
          item={{
            name: "Fonts",
            isActive: false,
          }}
        />
      ),
    },
    // {
    //   name: "Paragraph",
    //   icon: faFont,
    //   isActive: blockType === "paragraph",
    //   click: (e) => formatBlock("paragraph"),
    // },
    // {
    //   name: "Link",
    //   icon: faLink,
    //   isActive: isLink,
    //   click: insertLink,
    //   hasDivider: true,
    // },
    // {
    //   name: "Sticky Note",
    //   icon: faNoteSticky,
    //   isActive: false,
    //   click: (e) => null,
    // },
  ];

  if (getCanveoTier(state?.user?.email) === "experimental") {
    options.push();
  }

  options.push(
    {
      name: "Bold",
      icon: faBold,
      isActive: isBold,
      click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold"),
      hasDividerWithPrevious: true,
    },
    {
      name: "Italic",
      icon: faItalic,
      isActive: isItalic,
      click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic"),
    },
    {
      name: "Underline",
      icon: faUnderline,
      isActive: isUnderline,
      click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline"),
    },
    {
      component: <FontColorControls />,
    },
    {
      name: "Highlight",
      icon: faHighlighter,
      isActive: isHighlight,
      click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "highlight"),
      hasDivider: true,
    }
  );

  if (getCanveoTier(state?.user?.email) === "experimental") {
    options.push(
      {
        name: "OL",
        icon: faListOl,
        isActive: blockType === "ol",
        click: formatNumberedList,
      },
      {
        name: "UL",
        icon: faListUl,
        isActive: blockType === "ul",
        click: formatBulletList,
      }
      // {
      //   name: "Strikethrough",
      //   icon: faStrikethrough,
      //   isActive: isStrikethrough,
      //   click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough"),
      // },
      // {
      //   name: "Subscript",
      //   icon: faSubscript,
      //   isActive: isSubscript,
      //   click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript"),
      // },
      // {
      //   name: "Superscript",
      //   icon: faSuperscript,
      //   isActive: isSuperscript,
      //   click: () => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript"),
      // })
    );
  }

  options.push(
    {
      name: "Left Align",
      icon: faAlignLeft,
      isActive: activeTextAlign === "left",
      // @ts-ignore
      click: (e) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left"),
      hasDividerWithPrevious: true,
    },
    {
      name: "Center Align",
      icon: faAlignCenter,
      isActive: activeTextAlign === "center",
      // @ts-ignore
      click: (e) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center"),
    },
    {
      name: "Right Align",
      icon: faAlignRight,
      isActive: activeTextAlign === "right",
      // @ts-ignore
      click: (e) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right"),
    },
    {
      name: "Justify Align",
      icon: faAlignJustify,
      isActive: activeTextAlign === "justify",
      // @ts-ignore
      click: (e) => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify"),
      hasDivider: true,
    }
  );

  if (getCanveoTier(state?.user?.email) === "experimental") {
    options.push(
      {
        component: (
          <ToolbarTablePlugin
            sfdt={sfdt}
            item={{
              name: "Table",
              isActive: true,
            }}
          />
        ),
      },
      // @ts-ignore
      { name: "Image", icon: faImage, isActive: true, click: (e) => null }
    );
  }

  const toolbarOptions = useMemo(
    () => options,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      canUndo,
      canRedo,
      editor,
      blockType,
      activeTextAlign,
      isBold,
      isItalic,
      isUnderline,
      isStrikethrough,
      isSubscript,
      isSuperscript,
      isHighlight,
      sfdt,
    ]
  );

  return (
    <div className="toolbar" ref={toolbarRef}>
      {toolbarOptions.map((b, i) => (
        <Fragment key={`${i}-${b.name}`}>
          {!b.component ? (
            <>
              {/* @ts-ignore */}
              <IconButton
                disabled={b.disabled}
                onClick={b.click}
                aria-label={b.name}
                //className={"toolbar-item spaced " + (b.isActive ? "active" : "")}
                sx={(theme) => {
                  return {
                    fontSize: "15px",
                    padding: "10px",
                    marginRight: b.hasDivider ? "20px" : 0,
                    marginLeft: b.hasDividerWithPrevious ? "20px" : 0,
                    border: "1px solid" + theme.palette.grey[300],
                    color: b.disabled
                      ? theme.palette.grey[500]
                      : theme.palette.grey[800],
                    backgroundColor: b.isActive
                      ? theme.palette.grey[200]
                      : theme.palette.primary.contrastText,
                    borderRadius: "5px",
                  };
                }}
              >
                {/* @ts-ignore */}
                <FontAwesomeIcon icon={b.icon} />
              </IconButton>
            </>
          ) : (
            b.component
          )}
        </Fragment>
      ))}

      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </div>
  );
}
