import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import React from "react";

/**
 * @typedef {object} NewApprovalTicketButtonProps
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenDialogCreateApprovalTicket
 */

/**
 * @param {NewApprovalTicketButtonProps} props
 * @returns {JSX.Element}
 */
export default function NewApprovalTicketButton({
  setOpenDialogCreateApprovalTicket,
}) {
  return (
    <Button
      variant="contained"
      disableElevation
      startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
      onClick={() => setOpenDialogCreateApprovalTicket(true)}
    >
      New
    </Button>
  );
}
