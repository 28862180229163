import { faCirclePlus, faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DialogUser, FabStandard } from "../../components";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import { trunc } from "../../utils";

export default function Users() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [users, setUsers] = useState(/** @type {*[]} */ ([]));
  const [usersNeedUpdate, setUsersNeedUpdate] = useState(true);
  const [dialogUserOpen, setDialogUserOpen] = useState(
    /** @type {* | null} */ (null)
  );

  const [, setLoading] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const navigate = useNavigate();
  const pageSize = 10;

  useEffect(() => {
    if (usersNeedUpdate) {
      setLoading(true);
      axios
        .get(`${state.settings.api}user/org/${state.user.orgID}`)
        .then(({ data }) => {
          const newUsers = data.data.filter(
            (/** @type {{ active: any; }} */ r) => r.active
          );
          setUsers(newUsers);
          setLoading(false);
          setUsersNeedUpdate(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersNeedUpdate]);

  const closeUserDialog = (
    /** @type {string} */ snack,
    /** @type {any} */ newUser
  ) => {
    if (
      ["snackUserCreated", "snackUserUpdated", "snackUserDeleted"].includes(
        snack
      )
    ) {
      dispatch({
        type: ["snackUserCreated"].includes(snack)
          ? "ADD_USERS"
          : "UPDATED_USERS",
        payload: newUser,
      });
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: ["snackUserCreated"].includes(snack)
            ? "New user has been created, welcome e-mail has been sent"
            : ["snackUserUpdated"].includes(snack)
            ? "User has been updated"
            : ["snackUserDeleted"].includes(snack)
            ? "User has been deleted"
            : "",
          severity: "success",
        },
      });
      setUsersNeedUpdate(true);
    }
    setDialogUserOpen(null);
  };

  return (
    <>
      <Box>
        <FabStandard
          click={() => setDialogUserOpen({ type: "newusr" })}
          text="New"
          icon={faCirclePlus}
          sx={{
            left: "100px",
            top: "80px",
            right: "unset",
          }}
        />
        <Grid container direction="column" alignItems="center" sx={{ my: 5 }}>
          <Grid item>
            <Typography variant="h4">Manage Users</Typography>
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <Typography variant="body1" display="block" align="center">
              Add or manage your users, and assign them roles
            </Typography>
          </Grid>
          <Grid item sx={{ mt: 5 }}>
            <List sx={{ width: "400px" }}>
              {users
                .filter((u) => u.active)
                .slice((curPage - 1) * pageSize, curPage * pageSize)
                .map((u) => (
                  <ListItemButton
                    key={u._id}
                    onClick={(e) =>
                      u._id === state.user._id
                        ? navigate("/account")
                        : setDialogUserOpen({ type: "updusr", user: u })
                    }
                  >
                    <ListItemAvatar>
                      <Avatar src={u.photoURL} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <span>
                          <b>{u._id === state.user._id ? "(You) " : ""}</b>
                          {u.displayName}
                        </span>
                      }
                      secondary={
                        <>
                          <span
                            style={{
                              fontWeight: "700",
                              color: ["Admin"].includes(u.role)
                                ? theme.palette.secondary.main
                                : ["Legal"].includes(u.role)
                                ? theme.palette.primary.main
                                : ["Business"].includes(u.role)
                                ? // @ts-ignore
                                  theme.palette.tertiary.main
                                : theme.palette.grey[700],
                            }}
                          >
                            {u.role?.name}
                            {u.readOnly ? (
                              <>
                                &nbsp;&nbsp;
                                <FontAwesomeIcon icon={faEye} />
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                          <span style={{ color: theme.palette.grey[600] }}>
                            {trunc(" - " + u.title, 20)}
                          </span>
                        </>
                      }
                    />
                  </ListItemButton>
                ))}
            </List>
          </Grid>
          <Grid item sx={{ mt: 5 }}>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(
                  state.users.filter(
                    (/** @type {{ active: any; }} */ u) => u.active
                  ).length / pageSize
                )}
                defaultPage={1}
                page={curPage}
                onChange={(e, newVal) => setCurPage(newVal)}
                siblingCount={0}
                boundaryCount={2}
                renderItem={(item) => <PaginationItem {...item} />}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <DialogUser
        open={Boolean(dialogUserOpen)}
        closeDialog={closeUserDialog}
        type={
          dialogUserOpen !== null && dialogUserOpen.type !== undefined
            ? dialogUserOpen.type
            : ""
        }
        userToUpdate={
          dialogUserOpen !== null && dialogUserOpen.user !== undefined
            ? dialogUserOpen.user
            : null
        }
      />
    </>
  );
}
