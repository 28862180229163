import { Box } from "@mui/material";
import React from "react";

/**
 * @typedef {object} TabPanelProps
 * @property {React.ReactNode} [children]
 * @property {number} index
 * @property {number} value
 */

/**
 * @param {TabPanelProps} props
 * @returns {React.JSX.Element}
 */
export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {<Box>{children}</Box>}
    </div>
  );
}
