import {
  faArrowLeft,
  faArrowRight,
  faBuildings,
  faSignature,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { DateTime } from "luxon";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAgreementData from "../../../../hooks/useAgreementData";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import CanveoCircularProgress from "../../../CanveoCircularProgress";
import CreateUserSection from "../../SendComponents/CreateUserSection";
import PartyManagementSection from "../../SendComponents/PartyManagementSection";
import SelectSignerSection from "../../SendComponents/SelectSignerSection";
import SignersListSection from "../../SendComponents/SignersListSection";
import UserManagementSection from "../../SendComponents/UserManagementSection";
import CannotSendOrSignInfoPanel from "../components/CannotSendOrSignInfoPanel";

/**
 * @typedef {object} SignTabProps
 * @property {boolean} open
 * @property {(param?: string) => void} handleCloseGroup
 * @property {(param?: boolean) => void} handleCloseDialog
 * @property {(tabIndex: number) => void} changeTab
 * @property {*} agreement
 * @property {boolean} canSign
 */

/**
 * @param {SignTabProps} props
 * @returns {JSX.Element}
 */
export default function SignTab({
  open,
  handleCloseGroup,
  agreement,
  changeTab,
  ...props
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const currentAgreementHasExhibits = state?.agrs?.length > 1;

  const [userRole] = useState(state.user.role?.name);
  const navigate = useNavigate();
  const [partyManagement, setPartyManagement] = useState(false);
  const [userManagement, setUserManagement] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [closing, setClosing] = useState(false);
  const [processingComparisonPdf, setProcessingComparisonPdf] = useState(false);

  const {
    loading,
    errorMsg,
    allUsers,
    signers,
    addSigner,
    changeSigners,
    oidsForWhichYouCanAddUser,
    signingOrder,
    handleSigningOrderChange,
    selectedOrganizationID,
    setSelectedOrganizationID,
    userCreationType,
    closeUserSections,
    setSignerCreation,
    handleSubmitUserForm,
    canSign,
    isOwner,
    updateAgreement,
    sendUserInvitations,
    sendAgreement,
    reminders,
    setReminders,
    collaborators,
    handleRevokeUser,
    handlePartyChange,
    handleEditUserForm,
    agreementUpdate,
    attachPdfFileToEmailType,
    setAttachPdfFileToEmailType,
    comparisonVersion,
    setComparisonVersion,
    mainAgreementVersion,
    getSfdtFromVersionId,
    roles,
    setRoles,
    setAgreementUpdate,
  } = useAgreementData(agreement, open, true);

  useEffect(() => {
    dispatch({ type: "RESET_SEND_FLOW_STATE" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateClosing = () => {
    if (closing || !agreementUpdate) return closeDialog();

    setClosing(true);
  };

  const closeDialog = () => {
    handleCloseGroup();
  };

  const handleConfirmationClose = () => {
    sendUserInvitations();
    updateAgreement();
    if (errorMsg) return;

    closeDialog();
  };

  const handleBack = () => {
    if (closing) return setClosing(false);
    if (!!userCreationType || !!selectedOrganizationID) {
      return closeUserSections();
    }
    if (editUser) return setEditUser(null);
    if (state.sendFlow?.addParty) {
      return dispatch({ type: "SEND_FLOW_ADD_PARTY", payload: false });
    }
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_REMOVE_PARTY", payload: null });
    }
    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_REMOVE_USER", payload: null });
    }
    if (partyManagement) return setPartyManagement(false);
    if (userManagement) return setUserManagement(false);

    initiateClosing();
  };

  const getSelectedUsers = () => {
    return allUsers.filter((au) => signers.some((s) => s._id === au._id));
  };

  /**
   * Sign directly flow.
   */
  const handleSend = () => {
    // @ts-ignore
    sendAgreement({}, true);
    if (errorMsg) return;

    if (agreement.sigConfig?.provider === "docusign") {
      handleCloseGroup("preparingYourDocumentForSignatureSetup");
    } else {
      navigate("/dashboard/" + (userRole === "Counterparty" ? "0" : "1"));
    }
  };

  // const getDialogTitle = () => {
  //   if (partyManagement) return "Manage Parties";
  //   if (userManagement) return "Manage Collaborators";

  //   if (userCreationType) return `Create Signer`;

  //   return "Add Signers";
  // };

  const handleConfirm = () => {
    if (state.sendFlow?.removeParty) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_PARTY" });
    }

    if (state.sendFlow?.removeUser) {
      return dispatch({ type: "SEND_FLOW_SUBMIT_REMOVE_USER" });
    }
  };

  /**
   * @param {*} formData
   * @returns
   */
  const handleUserFormSubmission = async (formData) => {
    if (userCreationType) return handleSubmitUserForm(formData);
    if (editUser) {
      const result = await handleEditUserForm({
        // @ts-ignore
        ...editUser,
        ...formData,
        displayName: `${formData.firstName} ${formData.lastName}`,
      });
      if (result) setEditUser(null);
    }
  };

  // const [attachPdfFileToEmail, setAttachPdfFileToEmail] = useState(false);
  // const [attachPdfFileToEmailType, setAttachPdfFileToEmailType] =
  //   useState("comparison");
  // const [comparisonVersion, setComparisonVersion] = useState(undefined);
  // Ignore the first version because that's the one we are currently seeing.
  const [, ...versionsOptions] = state.drawerVersions.versions.map(
    (/** @type {*} */ version) => ({
      label: `Version ${version.version}`,
      author: version.creationBy.displayName,
      description: version.description,
      lastUpdated: `${DateTime.fromISO(version.lastUpdateDate).toFormat(
        "dd LLL yyyy"
      )} by ${version.lastUpdateBy.displayName}`,
      version,
    })
  );

  if (!props.canSign) {
    return <CannotSendOrSignInfoPanel variant="sign" changeTab={changeTab} />;
  }

  return (
    <Box mt={4}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 12,
          }}
        >
          <CanveoCircularProgress />
        </Box>
      )}
      {!loading && (
        <Box>
          <Grid
            container
            direction="column"
            justifyContent={"center"}
            alignItems="center"
            sx={{ minHeight: !closing ? "360px" : "unset" }}
          >
            <Box>
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={1} />

                <Grid item>
                  <Typography variant="h4">Sign Agreement</Typography>
                </Grid>

                <Grid item xs={1} />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
              >
                <Grid item xs={1} />

                <Grid item>
                  <Typography>Send the agreement for e-signature</Typography>
                </Grid>

                <Grid item xs={1} />
              </Grid>
            </Box>

            {!closing && (
              <Grid
                item
                mt={2}
                sx={{
                  textAlign: "center",
                  mb: !!state.parties && state.parties.length > 2 ? 3 : 4,
                }}
              >
                {/* <Typography variant="h4">{getDialogTitle()}</Typography> */}

                {errorMsg && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <Typography align="center" color="error">
                      {errorMsg}
                    </Typography>
                  </Box>
                )}
              </Grid>
            )}

            <Grid item sx={{ mb: 0, px: 1, width: "100%" }}>
              {closing ? (
                <Box>
                  <Typography sx={{ fontWeight: "600", mb: 2 }} align="center">
                    Do you want to persist the changes made?
                  </Typography>
                  <Typography sx={{ fontWeight: "600" }} align="center">
                    If you added new collaborators, they will receive email
                    notifications once you send the agreement.
                  </Typography>
                </Box>
              ) : userCreationType || editUser ? (
                <CreateUserSection
                  handleSubmit={handleUserFormSubmission}
                  // @ts-ignore
                  user={editUser}
                />
              ) : selectedOrganizationID ? (
                <SelectSignerSection
                  allowedOrganizations={oidsForWhichYouCanAddUser}
                  orgID={selectedOrganizationID}
                  users={allUsers.filter(
                    (au) => au.orgID === selectedOrganizationID
                  )}
                  owner={agreement.owner}
                  hiddenUsers={getSelectedUsers()}
                  handleCreateUser={setSignerCreation}
                  handleSelection={(/** @type {*} */ signer) => {
                    // @ts-ignore
                    addSigner(signer);
                    closeUserSections();
                  }}
                />
              ) : partyManagement ? (
                <PartyManagementSection
                  owner={agreement.owner}
                  roles={roles}
                  setRoles={setRoles}
                  handlePartyChange={handlePartyChange}
                  setAgreementUpdate={setAgreementUpdate}
                />
              ) : userManagement ? (
                <UserManagementSection
                  collaborators={collaborators}
                  signers={signers}
                  owner={agreement.owner}
                  handleEditUserForm={handleEditUserForm}
                  handleRevokeUser={handleRevokeUser}
                />
              ) : (
                <>
                  <SignersListSection
                    signConfig={agreement.sigConfig}
                    signers={signers}
                    signingOrder={signingOrder}
                    reminders={reminders}
                    isOwner={agreement.owner === state.org._id}
                    owner={agreement.owner}
                    handleSignersChange={changeSigners}
                    handleOrganizationSelection={(/** @type {*} */ orgID) =>
                      setSelectedOrganizationID(orgID)
                    }
                    handleChangeReminder={(/** @type {*} */ reminder) =>
                      setReminders(reminder)
                    }
                    handleSigningOrderChange={handleSigningOrderChange}
                    handleEditUser={(/** @type {*} */ user) =>
                      setEditUser(user)
                    }
                  />

                  {!currentAgreementHasExhibits && (
                    <>
                      <Grid container mt={2} mb={4}>
                        <Grid item>
                          <Button
                            variant="text"
                            onClick={(_event) =>
                              setAttachPdfFileToEmailType(
                                attachPdfFileToEmailType === "clean"
                                  ? "comparison"
                                  : "clean"
                              )
                            }
                          >
                            Sign a version highlighting changes ...
                          </Button>
                        </Grid>
                      </Grid>

                      {attachPdfFileToEmailType === "comparison" && (
                        <Grid container mt={1}>
                          <Grid item>
                            <Grid pl={1}>
                              <RadioGroup
                                value={attachPdfFileToEmailType}
                                onChange={(event) =>
                                  setAttachPdfFileToEmailType(
                                    event.target.value
                                  )
                                }
                              >
                                {/* <FormControlLabel
                                value="clean"
                                control={<Radio />}
                                label="Sign clean PDF"
                              />

                              <FormControlLabel
                                value="comparison"
                                control={<Radio />}
                                label="Sign comparison vs. earlier version ..."
                              /> */}

                                {/* {attachPdfFileToEmailType === "comparison" && ( */}
                                {/* <> */}
                                <Grid mt={1}>
                                  <Autocomplete
                                    value={comparisonVersion}
                                    onChange={(_event, value) =>
                                      setComparisonVersion(value)
                                    }
                                    options={versionsOptions}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Compare against version ..."
                                        placeholder="Select version"
                                      />
                                    )}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": {
                                            mr: 2,
                                            flexShrink: 0,
                                          },
                                        }}
                                        {...props}
                                      >
                                        <Grid container direction="column">
                                          <Grid item>
                                            <Typography
                                              variant="body1"
                                              fontWeight="bolder"
                                              color={theme.palette.grey[800]}
                                            >
                                              {option.label}
                                            </Typography>
                                          </Grid>

                                          <Grid item>
                                            <Typography
                                              variant="subtitle2"
                                              color={theme.palette.grey[500]}
                                            >
                                              Author: {option.author}
                                            </Typography>
                                          </Grid>

                                          <Grid item>
                                            <Typography
                                              variant="subtitle2"
                                              color={theme.palette.grey[500]}
                                            >
                                              Description: {option.description}
                                            </Typography>
                                          </Grid>

                                          <Grid item>
                                            <Typography
                                              variant="subtitle2"
                                              color={theme.palette.grey[500]}
                                            >
                                              Last updated: {option.lastUpdated}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    )}
                                  />
                                </Grid>
                                {/* </> */}
                                {/* )} */}
                              </RadioGroup>
                            </Grid>

                            <Grid mt={1}>
                              <Button
                                disabled={
                                  processingComparisonPdf || !comparisonVersion
                                }
                                sx={{ fontSize: 12 }}
                                onClick={async () => {
                                  try {
                                    setProcessingComparisonPdf(true);

                                    const versionsToCompare = {
                                      leftSfdt:
                                        /** @type {import("../../../editor/types/sfdt").Sfdt | null} */ (
                                          null
                                        ),
                                      rightSfdt:
                                        /** @type {import("../../../editor/types/sfdt").Sfdt | null} */ (
                                          null
                                        ),
                                    };

                                    if (
                                      attachPdfFileToEmailType === "comparison"
                                    ) {
                                      if (!mainAgreementVersion?._id) {
                                        throw new Error(
                                          "Main agreement ID is required for PDF comparison."
                                        );
                                      }

                                      if (!comparisonVersion?.version?._id) {
                                        throw new Error(
                                          "Comparison verion ID is required for PDF comparison."
                                        );
                                      }

                                      versionsToCompare.leftSfdt =
                                        await getSfdtFromVersionId(
                                          comparisonVersion.version._id
                                        );
                                      versionsToCompare.rightSfdt =
                                        await getSfdtFromVersionId(
                                          mainAgreementVersion._id
                                        );

                                      const result = await axios.post(
                                        state.settings.api +
                                          "document/comparePdfs",
                                        {
                                          versionsToCompare,
                                        }
                                      );

                                      const url =
                                        result.data.data.comparisonResultPdfUrl;
                                      const fileResponse = await axios.get(
                                        url,
                                        {
                                          responseType: "blob",
                                        }
                                      );
                                      const downloadFileAnchor =
                                        document.createElement("a");
                                      downloadFileAnchor.href =
                                        window.URL.createObjectURL(
                                          fileResponse.data
                                        );
                                      downloadFileAnchor.download = `comparison.pdf`;
                                      downloadFileAnchor.click();
                                    }
                                  } catch (error) {
                                    console.error(error);
                                    dispatch({
                                      type: "NEW_SNACKBAR",
                                      payload: {
                                        severity: "error",
                                        message:
                                          "An error occurred while generating the comparison PDF.",
                                      },
                                    });
                                  } finally {
                                    setProcessingComparisonPdf(false);
                                  }
                                }}
                              >
                                Preview ...
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}

      <DialogActions>
        {!closing && (
          <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Back
          </Button>
        )}
        {!userCreationType &&
          !userManagement &&
          !partyManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setUserManagement(true)}>
              <FontAwesomeIcon icon={faUser} />
              &nbsp;&nbsp;Manage Collaborators
            </Button>
          )}
        {!userCreationType &&
          !partyManagement &&
          !userManagement &&
          !editUser &&
          !closing && (
            <Button disabled={loading} onClick={() => setPartyManagement(true)}>
              <FontAwesomeIcon icon={faBuildings} />
              &nbsp;&nbsp;Manage Parties
            </Button>
          )}
        {state.sendFlow?.addParty && !closing && (
          <Button
            variant="contained"
            disableElevation
            style={{ marginLeft: "12px" }}
            disabled={
              !(
                state.sendFlow?.addParty.organization &&
                state.sendFlow?.addParty.entity &&
                state.sendFlow?.addParty.role
              )
            }
            onClick={() => dispatch({ type: "SEND_FLOW_SUBMIT_ADD_PARTY" })}
          >
            Add&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        )}

        {(state.sendFlow?.removeParty || state.sendFlow?.removeUser) &&
          !closing && (
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          )}
        {!state.sendFlow?.addParty &&
          !state.sendFlow?.removeParty &&
          !state.sendFlow?.removeUser &&
          !closing && (
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              // @ts-ignore
              disabled={
                loading ||
                userCreationType ||
                !(canSign || isOwner) ||
                partyManagement ||
                userManagement ||
                !!editUser ||
                !signers.length
              }
              onClick={handleSend}
            >
              Initiate Signing&nbsp;&nbsp;
              <FontAwesomeIcon icon={faSignature} />
            </Button>
          )}

        {closing && (
          <>
            <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disableElevation
              style={{ marginLeft: "12px" }}
              onClick={handleConfirmationClose}
            >
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Box>
  );
}
