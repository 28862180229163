/**
 * @param {ApprovalList} approvalList
 * @returns {boolean}
 */
export function canSubmitApprovalList(approvalList) {
  const canSubmit = approvalList.some(
    (approvalListItem) =>
      approvalListItem.type === "user" && approvalListItem.role === "approver"
  );
  return canSubmit;
}
