import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ApprovalsTab from "./tabs/TemplateDefaultApprovalsTab";
import ReviewsTab from "./tabs/TemplateDefaultReviewsTab";

/**
 * @typedef {object} SetDefaultsTabProps
 * @property {DialogConfigureTemplateTemplate} template
 * @property {ReviewTicket[]} reviewTickets
 * @property {(reviewTickets: ReviewTicket[]) => void} updateReviewTickets
 * @property {ApprovalTicket[]} approvalTickets
 * @property {(approvalTickets: ApprovalTicket[]) => void} updateApprovalTickets
 */

/**
 * @param {SetDefaultsTabProps} props
 * @returns {JSX.Element}
 */
export default function SetDefaultsTab({
  template,
  reviewTickets,
  updateReviewTickets,
  approvalTickets,
  updateApprovalTickets,
}) {
  const [selectedButton, setSelectedButton] = useState(() => {
    return /** @type {"approvals" | "reviews"} */ ("approvals");
  });

  return (
    <>
      <Grid container direction="row" gap={1} mt={4}>
        <Grid item>
          <Button
            variant={selectedButton === "approvals" ? "contained" : "outlined"}
            disableElevation
            onClick={() => setSelectedButton("approvals")}
          >
            Approvals
          </Button>
        </Grid>

        <Grid item>
          <Button
            variant={selectedButton === "reviews" ? "contained" : "outlined"}
            disableElevation
            onClick={() => setSelectedButton("reviews")}
          >
            Reviews
          </Button>
        </Grid>
      </Grid>

      {selectedButton === "approvals" && (
        <ApprovalsTab
          template={template}
          approvalTickets={approvalTickets}
          updateApprovalTickets={updateApprovalTickets}
        />
      )}

      {selectedButton === "reviews" && (
        <ReviewsTab
          template={template}
          reviewTickets={reviewTickets}
          updateReviewTickets={updateReviewTickets}
        />
      )}
    </>
  );
}
