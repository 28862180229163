import { Box, Button, Grid } from "@mui/material";
import React, { useContext, useState } from "react";
import { DialogEntity, Header } from "../components";
import DialogLogo from "../components/dialogs/DialogLogo";
import { globalStore } from "../state/store";

/**
 * @returns {React.JSX.Element}
 */
export default function SuperAdmin() {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [dialogEntityOpen, setDialogEntityOpen] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [dialogLogoOpen, setDialogLogoOpen] = React.useState(false);
  const [newOrganizationId, setNewOrganizationId] = useState(
    /** @type {string | null} */ (null)
  );
  const [newLogoUrl, setNewLogoUrl] = useState(
    /** @type {string | null} */ (null)
  );

  const closeEntityDialog = (/** @type {{ orgID: string; }} */ newOrg) => {
    if (newOrg.orgID) {
      setNewOrganizationId(newOrg.orgID);
      setNewLogoUrl(null);
    }
    setDialogEntityOpen(null);
  };

  const closeLogoDialog = (/** @type {string} */ newLogoURL) => {
    setDialogLogoOpen(false);
    if (newLogoURL) {
      setNewLogoUrl(newLogoURL);
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          margin: "0px",
          padding: "0px",
        }}
      >
        {["dbuser@getcanveo.com", "owner1@getcanveo.com"].includes(
          state.user.email
        ) ? (
          <>
            <Header page={"SuperAdmin"} />

            <Box sx={{ my: 20, mx: 3, width: "100%" }}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Button
                    onClick={() => setDialogEntityOpen({ type: "signup" })}
                  >
                    Sign up new org
                  </Button>
                </Grid>

                {!!newOrganizationId && (
                  <Grid item>
                    <Button onClick={() => setDialogLogoOpen(true)}>
                      Update logo for created Org
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </>
        ) : (
          "no access"
        )}

        <DialogEntity
          open={Boolean(dialogEntityOpen)}
          closeDialog={closeEntityDialog}
          type={
            dialogEntityOpen !== null && dialogEntityOpen.type !== undefined
              ? dialogEntityOpen.type
              : ""
          }
          ent={
            dialogEntityOpen !== null && dialogEntityOpen.ent !== undefined
              ? dialogEntityOpen.ent
              : ""
          }
        />

        <DialogLogo
          isOpen={dialogLogoOpen}
          onCloseDialog={closeLogoDialog}
          orgID={newOrganizationId}
          currentLogo={newLogoUrl}
        />
      </Box>
    </div>
  );
}
