export const badgeStyles = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "18px",
  height: "18px",
  // Makes the container a circle.
  borderRadius: "50%",
  // Adjust the size of the icon.
  fontSize: "10px",
  marginRight: 3,
  marginBottom: 1.5,
};
