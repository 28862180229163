import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalStore } from "../../state/store";
import { getPartyID } from "../../utils/getPartyID";
import PartyList from "../PartyList";
import DialogAddParty from "../dialogs/DialogAddParty";
import DialogRemoveParty from "../dialogs/DialogRemoveParty";

/**
 * @typedef {object} PartySelectorProps
 * @property {*} agreement
 * @property {(change: string, value: *) => void} handleAgreementChange
 */

/**
 * @param {PartySelectorProps} props
 */
export default function PartySelector({ agreement, handleAgreementChange }) {
  // @ts-ignore
  const [state] = useContext(globalStore);

  const [searchParams] = useSearchParams();
  const hubSpotCompanyId = searchParams.get("hcid");

  const [addingParty, setAddingParty] = useState(false);
  const [removingParty, setRemovingParty] = useState(
    /** @type {* | null} */ (null)
  );

  const handleAddRole = (/** @type {*} */ value) => {
    handleAgreementChange("roles", [...agreement.roles, { label: value }]);
  };

  const handleAddOrUpdateParty = (/** @type {*} */ party) => {
    // const hubSpotParty = agreement.parties.find(
    //   (/** @type {{ hubSpotCompanyId: string; }} */ party) =>
    //     party.hubSpotCompanyId === hubSpotCompanyId
    // );
    // If HubSpot party has already been added we update only its role.
    // if (Boolean(hubSpotParty)) {
    //   hubSpotParty.role = party.role;
    //   handleAgreementChange("parties", [...agreement.parties]);
    // } else {
    const partyID = getPartyID(
      agreement.parties,
      party.entity.orgID === state.org._id,
      true
    );

    handleAgreementChange("parties", [
      ...agreement.parties,
      {
        ...party.entity,
        role: party.role,
        partyID: partyID,
        myClient: false,
      },
    ]);
    // }

    setAddingParty(false);
  };

  useEffect(() => {
    if (hubSpotCompanyId) {
      axios
        .post(state.settings.api + "hubspot/importCounterparty", {
          hubSpotCompanyId,
        })
        .then((res) => {
          const result = res.data.data;
          const party = {
            organization: result.organization,
            entity: result.entity,
            role: null,
          };

          handleAddOrUpdateParty(party);
        })
        .catch(() => {
          throw new Error("Error importing HubSpot counterparty.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [templateId, setTemplateId] = useState("");

  useEffect(() => {
    if (agreement?.template?._id && templateId !== agreement?.template?._id) {
      setTemplateId(templateId);
      const url = `${state.settings.api}document/${agreement.template._id}/mergeFields`;
      axios
        .get(url)
        .then((response) => {
          if (!response) throw new Error("Error getting response.");

          const /** @type {import("../editor/nodes/MarkNode").MergeField[]} */ documentMergeFields =
              response.data.data;
          if (!documentMergeFields) {
            throw new Error("Error getting Merge Fields");
          }

          // If we are creating the agreement from within HubSpot there are specific rules.
          if (hubSpotCompanyId) {
            const partyInformationMergeFieldsRoles = documentMergeFields
              .filter((mergeField) => mergeField.type === "partyInformation")
              .map((mergeField) => mergeField.partyRole);

            const uniquePartyInformationMergeFieldsRoles = [
              ...new Set(partyInformationMergeFieldsRoles),
            ];

            const hubSpotParty = agreement.parties.find(
              (/** @type {{ hubSpotCompanyId: string; }} */ party) =>
                party.hubSpotCompanyId === hubSpotCompanyId
            );
            if (!hubSpotParty) {
              throw new Error("HubSpot party was not imported correctly.");
            }

            if (uniquePartyInformationMergeFieldsRoles.length) {
              // If the party information merge fields are for just one role, then we apply this role to the
              // party pulled from HubSpot.
              if (uniquePartyInformationMergeFieldsRoles.length === 1) {
                hubSpotParty.role = uniquePartyInformationMergeFieldsRoles[0];
                handleAddOrUpdateParty(hubSpotParty);
              }
              // If the party info merge fields are for no more than two roles, of which one has been set with
              // the option setOrganizationAsRole, apply the role of the setOrganizationAsRole merge field to
              // the owner (done later in the other component) and apply the other role to the party pulled
              // from HubSpot.
              else if (
                uniquePartyInformationMergeFieldsRoles.length === 2 &&
                documentMergeFields.filter(
                  (x) => x.setOrganizationAsRole === true
                ).length === 1
              ) {
                hubSpotParty.role = documentMergeFields.find(
                  (x) => x.setOrganizationAsRole !== true
                )?.partyRole;
                handleAddOrUpdateParty(hubSpotParty);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement?.template?._id]);

  const initiateRemoveParty = (/** @type {*} */ party) => {
    setRemovingParty(party);
  };

  const handleRemoveParty = () => {
    handleAgreementChange(
      "parties",

      agreement.parties.filter(
        (/** @type {{ _id: string; }} */ party) =>
          party._id !== removingParty?._id
      )
    );
    setRemovingParty(null);
  };

  const handleEntityChange = (/** @type {*} */ newEntity) => {
    const newParties = agreement.parties.map((/** @type {*} */ party) => {
      if (newEntity.orgID === party.orgID) {
        return { ...party, ...newEntity, role: party.role };
      }
      return party;
    });

    handleAgreementChange("parties", newParties);
  };

  /**
   * Handle role change and propagate the change to all other parties.
   *
   * @param {"create" | "update" | "delete"} operation
   * @param {string} newOrUpdatedRole
   * @param {string} [originalRole]
   */
  const handleRoleChange = (
    operation,
    newOrUpdatedRole,
    originalRole = undefined
  ) => {
    switch (operation) {
      case "create": {
        const newRoles = [...agreement.roles, { label: newOrUpdatedRole }];
        handleAgreementChange("roles", newRoles);
        break;
      }

      case "update": {
        if (!originalRole) {
          throw new Error("`originalRole` is required during an update.");
        }

        const newParties = agreement.parties.map((/** @type {*} */ party) => {
          if (party.role === originalRole) {
            return { ...party, role: newOrUpdatedRole };
          }
          return party;
        });
        handleAgreementChange("parties", newParties);

        const newRoles = agreement.roles.map(
          (/** @type {{ label: string; }} */ role) => {
            if (role.label === originalRole) {
              return { label: newOrUpdatedRole };
            }
            return role;
          }
        );
        handleAgreementChange("roles", newRoles);

        break;
      }

      case "delete": {
        if (!originalRole) {
          throw new Error("`originalRole` is required during an update.");
        }

        const newParties = agreement.parties.map((/** @type {*} */ party) => {
          if (party.role === originalRole) {
            return { ...party, role: "null" };
          }
          return party;
        });

        handleAgreementChange("parties", newParties);

        const newRoles = agreement.roles.filter(
          (/** @type {{ label: string; }} */ role) =>
            role.label !== originalRole
        );
        handleAgreementChange("roles", newRoles);
        break;
      }

      default: {
        throw new Error(`${operation} is not a valid operation.`);
      }
    }
  };

  return (
    <>
      <PartyList
        disableRoleCreation={false}
        sx={{
          px: 2,
          mt: 4,
        }}
        parties={agreement.parties}
        roles={agreement.roles}
        handleRoleChange={handleRoleChange}
        owner={state.org._id}
        handleAddParty={() => setAddingParty(true)}
        handleRemoveParty={initiateRemoveParty}
        handleEntityChange={handleEntityChange}
        handleEntityUpdate={handleEntityChange}
        handlePartyChange={(changedParty) => {
          const newParties = agreement.parties.map((/** @type {*} */ party) => {
            if (changedParty._id === party._id) {
              return { ...changedParty };
            }
            return party;
          });

          handleAgreementChange("parties", newParties);
        }}
        displayAddParty={true}
      />

      <DialogAddParty
        open={addingParty}
        parties={agreement.parties}
        roles={agreement.roles}
        handleAddRole={handleAddRole}
        handleAddParty={handleAddOrUpdateParty}
        handleClose={() => setAddingParty(false)}
      />

      <DialogRemoveParty
        open={removingParty}
        party={removingParty}
        handleRemove={handleRemoveParty}
        handleClose={() => setRemovingParty(null)}
      />
    </>
  );
}
