import { Box } from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { globalStore } from "../../../../state/store";
import ApprovalsTable from "../../../reviewsAndApprovals/components/ApprovalsTable";
import { NewApprovalTicketButtonPanel } from "../../../reviewsAndApprovals/components/NewApprovalTicketButtonPanel";
import DialogApprovalTicketDetails from "../../DialogApprovalTicketDetails";
import DialogCreateApprovalTicket from "../../DialogCreateApprovalTicket";

/**
 * @typedef {object} TemplateApprovalsTabProps
 * @property {DialogConfigureTemplateTemplate} template
 * @property {ApprovalTicket[]} approvalTickets
 * @property {(approvalTickets: ApprovalTicket[]) => void} updateApprovalTickets
 * @property {string | null} taskId
 */

/**
 * @param {TemplateApprovalsTabProps} props
 * @returns {JSX.Element}
 */
export default function TemplateApprovalsTab({
  template,
  approvalTickets,
  updateApprovalTickets,
  taskId,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [openDialogCreateApprovalTicket, setOpenDialogCreateApprovalTicket] =
    useState(false);
  const [selectedApprovalTicketIndex, setSelectedApprovalTicketIndex] =
    useState(() => {
      if (taskId) {
        const ticketIndex = approvalTickets.findIndex(
          (approvalTicket) => approvalTicket._id === taskId
        );
        if (ticketIndex >= 0) {
          return ticketIndex;
        }
      }

      return -1;
    });

  const openDialogApprovalTicketDetails = selectedApprovalTicketIndex >= 0;

  /**
   * @param {string} templateId
   * @returns {Promise<void>}
   */
  async function loadTemplateApprovalTickets(templateId) {
    const result = await axios
      .get(`${state.settings.api}task?templateId=${templateId}&type=Approval`)
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to retrieve approval requests, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!result) return;

    const approvalTickets = /** @type {ApprovalTicket[]} */ (result.data.data);
    updateApprovalTickets(approvalTickets);
  }

  /**
   * @param {ApprovalTicket} approvalTicket
   * @returns {void}
   */
  function handleUpdateApprovalTicket(approvalTicket) {
    const updatedApprovalTickets = [...approvalTickets];

    const index = updatedApprovalTickets.findIndex(
      (at) => at._id === approvalTicket._id
    );
    updatedApprovalTickets[index] = approvalTicket;

    updateApprovalTickets(updatedApprovalTickets);
  }

  /**
   * @param {string} approvalTicketId
   * @returns {void}
   */
  function handleDeleteApprovalTicket(approvalTicketId) {
    const updatedApprovalTickets = [...approvalTickets];

    const index = updatedApprovalTickets.findIndex(
      (rt) => rt._id === approvalTicketId
    );
    updatedApprovalTickets.splice(index, 1);

    updateApprovalTickets(updatedApprovalTickets);
  }

  return (
    <Box mt={4}>
      {approvalTickets.length === 0 && (
        <NewApprovalTicketButtonPanel
          setOpenDialogCreateApprovalTicket={setOpenDialogCreateApprovalTicket}
        />
      )}

      {approvalTickets.length === 1 && (
        <>
          <NewApprovalTicketButtonPanel
            buttonPosition="left"
            setOpenDialogCreateApprovalTicket={
              setOpenDialogCreateApprovalTicket
            }
          />

          <DialogApprovalTicketDetails
            open={openDialogApprovalTicketDetails}
            close={() => setSelectedApprovalTicketIndex(-1)}
            agreement={{
              ents: [],
              agrTitle: template.agrTitle,
              _id: template._id,
            }}
            approvalTicket={approvalTickets[0]}
            updateApprovalTicket={handleUpdateApprovalTicket}
            deleteApprovalTicket={handleDeleteApprovalTicket}
            displayOnlyDialogContent
            isTemplate
          />
        </>
      )}

      {approvalTickets.length > 1 && (
        <>
          <NewApprovalTicketButtonPanel
            buttonPosition="left"
            setOpenDialogCreateApprovalTicket={
              setOpenDialogCreateApprovalTicket
            }
          />

          <ApprovalsTable
            approvalTickets={approvalTickets}
            setSelectedApprovalTicketIndex={setSelectedApprovalTicketIndex}
          />

          {openDialogApprovalTicketDetails && (
            <DialogApprovalTicketDetails
              open={openDialogApprovalTicketDetails}
              close={() => setSelectedApprovalTicketIndex(-1)}
              agreement={{
                ents: [],
                agrTitle: template.agrTitle,
                _id: template._id,
              }}
              approvalTicket={approvalTickets[selectedApprovalTicketIndex]}
              updateApprovalTicket={handleUpdateApprovalTicket}
              deleteApprovalTicket={handleDeleteApprovalTicket}
              isTemplate
            />
          )}
        </>
      )}

      {openDialogCreateApprovalTicket && (
        <DialogCreateApprovalTicket
          isTemplate={false}
          open={openDialogCreateApprovalTicket}
          close={(refreshApprovalTickets) => {
            setOpenDialogCreateApprovalTicket(false);
            if (refreshApprovalTickets) {
              loadTemplateApprovalTickets(template._id);
            }
          }}
          createApprovalTicket={(approvalList, comment) => {
            /** @type {CreateApprovalTicket} */
            const approvalTicket = {
              type: "Approval",
              status: "In Progress",
              template: template._id,
              organization: state.user.orgID,
              requestor: state.user._id,
              approvalChain: {
                approvalList,
                messages: comment
                  ? [
                      {
                        text: comment,
                        senderId: state.user._id,
                        senderDisplayName: state.user.displayName,
                        senderPhotoUrl: state.user.photoURL,
                        sentTime: new Date().toISOString(),
                      },
                    ]
                  : [],
                subscribers: [
                  {
                    _id: state.user._id,
                    email: state.user.email,
                    orgId: state.user.orgID,
                  },
                ],
              },
            };
            return approvalTicket;
          }}
        />
      )}
    </Box>
  );
}
