import {
  faEllipsis,
  faHourglassHalf,
  faSignature,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useContext } from "react";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import { trunc } from "../utils";
import { getCanveoTier } from "../utils/getCanveoTier";
import CanveoCircularProgress from "./CanveoCircularProgress";

/**
 * @typedef {*} AgrExecBoxProps
 */

/**
 * @param {AgrExecBoxProps} props
 * @returns {React.JSX.Element}
 */
export default function AgrExecBox(props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const [anchorElCancel, setAnchorElCancel] = React.useState(
    /** @type {*} */ (null)
  );
  const [loading, setLoading] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(/** @type {*} */ (null));

  const userRole = state.user?.role?.name;
  const signURL =
    "https://my.skribble.com/view/" +
    (state.agrExec ? state.agrExec.signRequestID : "err");

  const handleCancelSignRequest = () => {
    setLoading(true);
    setErrMsg(null);
    setAnchorElCancel(null);

    axios
      .post(state.settings.api + "signing/cancel", {
        mainAid: state.agrExec.agrID,
        signRequestID: state.agrExec.signRequestID,
      })
      .then((resAgDetail) => {
        const signers = state.agrExec.signers;
        const collabs = props.doc.collabs;
        const recipients = collabs
          .filter(
            (/** @type {{ email: string; }} */ c) =>
              !signers.find(
                (
                  /** @type {{ signer_identity_data: { email_address: string; }; }} */ s
                ) => s.signer_identity_data.email_address === c.email
              )
          )
          .map((/** @type {{ uid: string; }} */ c) => {
            const { password, role } = state.users.filter(
              (/** @type {{ _id: string; }} */ u) => u._id === c.uid
            )[0];
            return { ...c, role, password };
          }); //missing role

        const currentParty = state.parties.find(
          (/** @type {{ orgID: string; }} */ p) => p.orgID === state.user.orgID
        );
        const v = resAgDetail.data.data.avs.find(
          (/** @type {{ _id: string; }} */ v) =>
            v._id === state.drawerVersions.active._id
        );
        const agr = resAgDetail.data.data.ags.find(
          (/** @type {{ _id: string; }} */ a) => a._id === v.agrID
        );
        if (currentParty && recipients.length && agr) {
          const legalNames = state.parties.map(
            (/** @type {{ legalName: string; }} */ p) => p.legalName
          );
          const partyFullString =
            state.parties.length === 2
              ? legalNames.join(" and ")
              : legalNames.join(", ");
          // TODO => Inform all collabs that signing was canceled
          let mailConfig = {
            type: "signRequestCancel",
            whiteLabel: agr.whiteLabel,
            agr: agr,
            senderLegalName: currentParty.legalName,
            toEntString: "",
            partyFullString: partyFullString,
            readyToSign: false,
            requestComment: null,
          };
          recipients.forEach((/** @type {*} */ recipient) => {
            // @ts-ignore
            mailConfig.recipient = recipient;
            axios.post(state.settings.api + "mail/informagr", mailConfig);
          });
        }
        dispatch({ type: "INIT_AGRS", payload: resAgDetail.data.data.ags });
        dispatch({ type: "INIT_AVS", payload: resAgDetail.data.data.avs });
        dispatch({
          type: "INIT_PARTIES",
          payload: resAgDetail.data.data.parties,
        });
        dispatch({
          type: "UPDATE_AGREXEC",
          payload: resAgDetail.data.data.agrExec,
        });
        setLoading(false);
      })
      .catch((err) => {
        setErrMsg("An error occurred while canceling the sign request");
        setLoading(false);
      });
  };

  function getSortedSigners() {
    if (
      Array.isArray(state?.agrExec?.signers) &&
      state?.agrExec?.signers?.length
    ) {
      // Need to clone array otherwise sort will mutate original in the store.
      const signers = [...state?.agrExec?.signers];
      const sortedSigners = signers.sort(
        (
          /** @type {{ order: number; }} */ a,
          /** @type {{ order: number; }} */ b
        ) => (a.order > b.order ? 1 : -1)
      );

      return sortedSigners;
    }

    return [];
  }

  return (
    <Box
      sx={{
        mt: 1,
        borderRadius: "20px 20px 0px 0px",
        border: "1px solid" + theme.palette.grey[300],
        borderBottom: "3px solid" + theme.palette.primary.main,
        padding: "20px",
        width: isMdUp ? "600px" : isSmUp ? "440px" : "320px",
      }}
    >
      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Grid item sx={{ width: "30px" }}>
            &nbsp;
          </Grid>
          <Grid item>
            <Box mb={2}>
              <FontAwesomeIcon
                icon={faSignature}
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "38px",
                  padding: "1px",
                }}
              />
            </Box>
          </Grid>
          <Grid item sx={{ width: "30px" }}>
            {userRole !== "Counterparty" &&
            Boolean(state.agrExec) &&
            Boolean(state.agrExec.signRequestID) ? (
              <>
                {getCanveoTier(state?.user?.email) === "experimental" && (
                  <Tooltip title="Cancel sign request" placement="left">
                    <IconButton
                      onClick={(e) => setAnchorElCancel(e.currentTarget)}
                      disabled={loading}
                    >
                      <FontAwesomeIcon
                        icon={faEllipsis}
                        style={{
                          fontSize: "14px",
                          padding: "0px 1px",
                          marginTop: "2px",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}

                <Menu
                  anchorEl={anchorElCancel}
                  keepMounted
                  open={Boolean(anchorElCancel)}
                  onClose={(e) => setAnchorElCancel(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      padding: "10px 30px",
                    }}
                    onClick={handleCancelSignRequest}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={{
                        color: theme.palette.error.main,
                        padding: "5px",
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;Cancel sign request
                  </MenuItem>
                </Menu>
              </>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        {loading ? (
          <Grid container justifyContent="center" sx={{ my: 3 }}>
            <CanveoCircularProgress />
          </Grid>
        ) : errMsg ? (
          <Grid container justifyContent="center" sx={{ my: 3 }}>
            <Typography color="error" align="center">
              {errMsg}
              <br />
              Refresh the page and try again or contact Canveo Support if the
              issue persists.
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Box mb={1}>
                <Typography variant="subtitle1" align="center">
                  This agreement requires signatures from:
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              justifyContent="center"
              sx={{ width: "100%", px: isMdUp ? 3 : isSmUp ? 2 : 0 }}
            >
              {!state.agrExec ? (
                <Box sx={{ my: 3 }}>
                  <CanveoCircularProgress />
                </Box>
              ) : (
                <List sx={{ width: "100%" }}>
                  {getSortedSigners().map((signer, index) => {
                    const photoUrl = signer.signer_identity_data.photo_url;
                    const agreement = state.agrs.filter(
                      (/** @type {{ _id: string; }} */ agreement) =>
                        agreement._id === state.agrExec.agrID
                    )[0];

                    const signingOrder = state.agrExec.signers.some(
                      (
                        /** @type {{ order: number | null | undefined; }} */ signer
                      ) =>
                        signer.order !== undefined &&
                        signer.order !== null &&
                        signer.order > -1
                    );

                    const signingOrderAssignee =
                      signingOrder &&
                      Boolean(
                        state.agrExec.signers
                          .filter(
                            (/** @type {{ status_code: string; }} */ s) =>
                              !["SIGNED"].includes(s.status_code)
                          )
                          .sort(
                            (
                              /** @type {{ order: number; }} */ a,
                              /** @type {{ order: number; }} */ b
                            ) => (a.order > b.order ? 1 : -1)
                          )[0]
                      )
                        ? state.agrExec.signers
                            .filter(
                              (/** @type {{ status_code: string; }} */ s) =>
                                !["SIGNED"].includes(s.status_code)
                            )
                            .sort(
                              (
                                /** @type {{ order: number; }} */ a,
                                /** @type {{ order: number; }} */ b
                              ) => (a.order > b.order ? 1 : -1)
                            )[0].order
                        : null;

                    const signerEmail =
                      Boolean(signer.signer_identity_data) &&
                      Boolean(signer.signer_identity_data.email_address)
                        ? signer.signer_identity_data.email_address
                        : signer.signer_email_address;

                    const signerFirstName =
                      Boolean(signer.signer_identity_data) &&
                      Boolean(signer.signer_identity_data.first_name)
                        ? signer.signer_identity_data.first_name
                        : "";

                    const signerLastName =
                      Boolean(signer.signer_identity_data) &&
                      Boolean(signer.signer_identity_data.last_name)
                        ? signer.signer_identity_data.last_name
                        : "";

                    const /** @type {string} */ personalSignURL = Boolean(
                        signer.signing_url
                      )
                        ? signer.signing_url
                        : signURL;

                    const currentSigner = agreement
                      ? agreement.signers.filter(
                          (/** @type {{ email: string; }} */ s) =>
                            s.email === signerEmail
                        )[0]
                      : null;

                    const entityName = currentSigner
                      ? currentSigner.entityName
                      : "";

                    const status = ["DECLINED"].includes(signer.status_code) ? (
                      <>
                        <span
                          className="bold"
                          style={{
                            color: theme.palette.error.main,
                            fontSize: "14px",
                          }}
                        >
                          Signed&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                      </>
                    ) : state.agrExec.signers.some(
                        (/** @type {{ status_code: string; }} */ s) =>
                          s.status_code === "DECLINED"
                      ) ? (
                      <>
                        <span
                          className="bold"
                          style={{
                            color: theme.palette.grey[500],
                            fontSize: "14px",
                          }}
                        >
                          Cancelled&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faTimesCircle} />
                        </span>
                      </>
                    ) : ["SIGNED"].includes(signer.status_code) ? (
                      <>
                        <Button
                          fullWidth
                          variant="contained"
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          sx={{
                            padding: "5px 21px",
                            width: "110px",
                            cursor: "auto",
                            "&:hover": {
                              background: theme.palette.primary.main,
                            },
                          }}
                        >
                          Signed&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faSignature} />
                        </Button>
                      </>
                    ) : signerEmail === state.user.email &&
                      (["OPEN"].includes(signer.status_code) ||
                        !signer.status_code) &&
                      (!signingOrder ||
                        signingOrderAssignee === signer.order) ? (
                      <>
                        <Button
                          variant="contained"
                          disableElevation
                          fullWidth
                          sx={{ padding: "5px 21px", width: "110px" }}
                          onClick={() => {
                            if (personalSignURL.includes("docusign")) {
                              axios
                                .post(
                                  state.settings.api +
                                    "signing/generateSigningUrl",
                                  {
                                    agreementId: state.agrExec.agrID,
                                  }
                                )
                                .then((response) => {
                                  window.location =
                                    response.data.data.signingUrl;
                                });
                            } else {
                              window.location.assign(
                                personalSignURL +
                                  "?exitURL=" +
                                  encodeURIComponent(
                                    window.location.origin +
                                      "/dashboard/" +
                                      (userRole === "Counterparty" ? "2" : "3")
                                  ) +
                                  "&redirectTimeout=10&hidedownload=true"
                              );
                            }
                          }}
                        >
                          Sign&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faSignature} />
                        </Button>
                      </>
                    ) : signingOrder &&
                      signer.order > signingOrderAssignee &&
                      (["OPEN"].includes(signer.status_code) ||
                        !signer.status_code) ? (
                      <>
                        <span
                          className="bold"
                          style={{
                            color: theme.palette.grey[500],
                            fontSize: "14px",
                          }}
                        >
                          Awaiting&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faHourglassHalf} />
                        </span>
                      </>
                    ) : ["OPEN"].includes(signer.status_code) ||
                      !signer.status_code ? (
                      <>
                        <Button
                          fullWidth
                          variant="outlined"
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          sx={{
                            padding: "5px 21px",
                            width: "110px",

                            cursor: "auto",
                            "&:hover": {
                              background: "none",
                              border: "1px solid rgba(114, 67, 221, 0.5)",
                            },
                          }}
                        >
                          Pending&nbsp;&nbsp;
                          <FontAwesomeIcon icon={faSignature} />
                        </Button>
                      </>
                    ) : (
                      ""
                    );

                    return (
                      <ListItem key={index} secondaryAction={status}>
                        <ListItemAvatar>
                          <Avatar src={photoUrl}>
                            {signerFirstName[0] + signerLastName[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={trunc(
                            (signingOrder ? signer.order + 1 + ". " : "") +
                              signerFirstName +
                              " " +
                              signerLastName,
                            24
                          )}
                          secondary={trunc(entityName, 30)}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}
