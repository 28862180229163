import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import useVersionService from "../../../hooks/useVersionService";
import { globalStore } from "../../../state/store";
import theme from "../../../theme/theme";
import VersionsDrawer from "../../drawer/VersionsDrawer";
import { defaultLexical } from "../converters/defaultSfdt";
import { populateGlobalListHandler } from "./ListExtendedPlugin/populateGlobalListHandler";

/**
 * @typedef {*} VersionsPluginProps
 */

/**
 * @param {VersionsPluginProps} props
 * @returns {React.JSX.Element}
 */
export default function VersionsPlugin({
  isTemplate,
  docID,
  isActive,
  isInEffect,
  handleExport,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const { loading, fetchVersion } = useVersionService();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  // When deleting versions.
  useEffect(() => {
    const { versions, active } = state.drawerVersions;
    const activeVersion = versions.find(
      (/** @type {{ _id: string; }} */ v) => v._id === active._id
    );

    if (activeVersion) return;

    const { _id, owner } = versions[0];
    let editMode = owner?.find(
      (/** @type {{ orgID: string; }} */ o) => o.orgID === state.org._id
    )?.editMode;

    if (isTemplate) editMode = isActive ? "read" : "full";
    else editMode = isInEffect ? editMode : "read";

    dispatch({
      type: "CHANGE_ACTIVE",
      payload: { _id, editMode, forceReload: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.drawerVersions, dispatch, state.org._id]);

  /**
   * @param {string} versionID
   */
  const changeVersionContent = async (versionID) => {
    const version = await fetchVersion(docID, versionID, isTemplate);

    const documentRoute = isTemplate
      ? "template/exportDetails/"
      : "agr/agrdetails/";

    const documentDetailsResponse = await axios.get(
      state.settings.api + documentRoute + docID
    );
    if (!documentDetailsResponse.data.success) {
      throw new Error("Error fetching workflows.");
    }

    if (documentDetailsResponse.data.data.workflows) {
      dispatch({
        type: "INIT_WORKFLOWS",
        payload: documentDetailsResponse.data.data.workflows,
      });
    }

    // Populate the global list handler with the updated SFDT before setting the editor state, so that the list
    // calculations work as expected.
    populateGlobalListHandler(editor, version.sfdt);

    if (version.content) {
      editor.setEditorState(editor.parseEditorState(version.content), {
        tag: "changeVersionContent",
      });
    } else {
      const lexical = await defaultLexical();
      const defaultLexicalContent = lexical.content;
      editor.setEditorState(editor.parseEditorState(defaultLexicalContent));
    }

    let editMode;

    // We do not allow editing if the size is equal or smaller to a tablet.
    if (!isMdUp) {
      editMode = "read";
    }
    // TODO: Check how to calculate the edit mode of a template.
    else if (isTemplate) {
      editMode = isActive ? "read" : "full";
    } else {
      const versionEditMode = version.owner?.find(
        (/** @type {*} */ o) => (o.orgID = state.org._id)
      )?.editMode;

      editMode = !isInEffect ? versionEditMode : "read";
    }

    editor.setEditable(editMode !== "read");

    dispatch({
      type: "CHANGE_ACTIVE",
      payload: {
        ...state.drawerVersions.active,
        sfdt: version.sfdt,
        forceReload: false,
      },
    });

    dispatch({
      type: "NEW_OPEN_ISSUE_SELECTION",
      payload: { id: null, type: null, status: null },
    });
  };

  useEffect(() => {
    if (!state.drawerVersions.active.forceReload) return;
    changeVersionContent(state.drawerVersions.active._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.drawerVersions.active._id,
    state.drawerVersions.active.forceReload,
  ]);

  return (
    <VersionsDrawer
      isTemplate={isTemplate}
      loading={loading}
      docID={docID}
      handleExport={handleExport}
      isInEffect={isInEffect}
    />
  );
}
