import { Box, Grid, Typography } from "@mui/material";
import { DataGrid, GridToolbar, gridClasses } from "@mui/x-data-grid";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CanveoCircularProgress, Header } from "../../components";
import DialogDatesAndRenewal from "../../components/dialogs/DialogDatesAndRenewal";
import { DialogTicketTaskDetails } from "../../components/dialogs/DialogTicketTaskDetails";
import { globalStore } from "../../state/store";
import { getColumns } from "./constants";

/**
 * @typedef {object} UserDetails
 * @property {string} _id
 * @property {string} photoURL
 * @property {string} displayName
 * @property {string} title
 */

/**
 * @typedef {object} TaskRow
 * @property {string} id
 * @property {string} name
 * @property {string} logo
 * @property {Date} created
 * @property {Date} updated
 * @property {UserDetails} requestor
 * @property {UserDetails[]} assignee
 * @property {string} type
 * @property {string} agreement
 * @property {string} area
 * @property {string} counterparty
 * @property {Date | string} dueDate
 * @property {string} status
 */

export default function Tasks() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [columns] = useState(getColumns(navigate));
  const [tasks, setTasks] = useState(/** @type {TaskRow[]} */ ([]));
  const [selectedAgreementId, setSelectedAgreementId] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [openDialogDatesAndRenewal, setOpenDialogDatesAndRenewal] =
    useState(false);
  const [openDialogTicketTaskDetails, setOpenDialogTicketTaskDetails] =
    useState(false);

  async function loadTasks() {
    const getTasksResponse = await axios.get(`${state.settings.api}task`);
    const tasks = getTasksResponse.data.data;

    const rows = tasks.map(
      (/** @type {{ type: "Renewal" | "Ticket"} & *} */ task) => {
        let counterparty = state.cpents.find(
          (/** @type {{ _id: string; }} */ entity) =>
            entity._id ===
            task?.agreement?.ents?.find(
              (/** @type {{ partyID: string; }} */ e) => e.partyID === "party1"
            )?.entID
        )?.legalName;

        if (
          task?.agreement?.ents?.some(
            (/** @type {{ partyID: string; }} */ e) => e.partyID === "party2"
          )
        ) {
          counterparty += " et. al.";
        }

        const counterpartyObject = state?.cpents?.find(
          (/** @type {{ _id: string; }} */ x) =>
            x?._id ===
            task?.agreement?.ents?.find(
              (/** @type {{ partyID: string; }} */ e) => e?.partyID === "party1"
            )?.entID
        );

        const counterpartyLogo = counterpartyObject?.logoURL;

        /** @type {UserDetails[]} */
        const assignees = [];

        if (task.type === "Review" || task.type === "Approval") {
          const list = task?.approvalChain?.approvalList?.length
            ? task.approvalChain.approvalList
            : task.reviewChain.reviewList;
          const chainAssignees = list.filter(
            (/** @type {{ role: string; }} */ item) =>
              item.role === "approver" || item.role === "reviewer"
          );

          for (const chainAssignee of chainAssignees) {
            assignees.push({
              _id: chainAssignee._id,
              displayName: chainAssignee.displayName,
              photoURL: chainAssignee.photoUrl,
              title: chainAssignee.title,
            });
          }
        } else {
          assignees.push(task.assignee);
        }

        /** @type {TaskRow} */
        const row = {
          id: task._id,
          logo: counterpartyLogo,
          name: task.name,
          created: new Date(task.createdAt),
          updated: new Date(task.updatedAt),
          requestor: {
            _id: task.requestor._id,
            displayName: task.requestor.displayName,
            photoURL: task.requestor.photoURL,
            title: task.requestor.title,
          },
          assignee: assignees,
          type: task.type,
          agreement: task.agreement || task.template,
          area:
            (task?.areaLabel?.name ??
              state.labels.find(
                (/** @type {{ _id: string; }} */ label) =>
                  label._id === task?.agreement?.areaLabels[0]?.labelID
              )?.name) ||
            "n/a",
          counterparty: counterparty || "n/a",
          dueDate: task.dueDate ? new Date(task.dueDate) : "",
          status: task.status,
        };

        return row;
      }
    );

    setTasks(rows);
    setIsLoading(false);
  }

  useEffect(
    () => {
      loadTasks();
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (selectedAgreementId) {
        setIsLoading(true);
        axios
          .get(state.settings.api + "agr/agrdetails/" + selectedAgreementId)
          .then((resAgDetail) => {
            if (!resAgDetail.data.success) {
              throw new Error("Error fetching agreement details.");
            }

            dispatch({ type: "INIT_AGRS", payload: resAgDetail.data.data.ags });
            dispatch({ type: "INIT_AVS", payload: resAgDetail.data.data.avs });
            dispatch({
              type: "INIT_PARTIES",
              payload: resAgDetail.data.data.parties,
            });
            dispatch({
              type: "UPDATE_AGREXEC",
              payload: resAgDetail.data.data.agrExec,
            });
            dispatch({
              type: "INIT_WORKFLOWS",
              payload: resAgDetail.data.data.workflows,
            });
            dispatch({
              type: "INIT_PARAMS_DOC",
              payload: resAgDetail.data.data.params,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
    // Runs only when `selectedAgreementId` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAgreementId]
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <Header page={"Templates"} />

        <Grid container justifyContent="center" sx={{ mt: 50 }}>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Header page={"Templates"} />

      <Box sx={{ mt: 16 }}>
        <Grid container justifyContent="center">
          <Grid item display={"flex"} justifyContent={"center"} xs={8}>
            <Typography variant="h4">Manage Tasks</Typography>
          </Grid>

          <Grid
            item
            display={"flex"}
            justifyContent={"center"}
            xs={8}
            sx={{ m: 2 }}
          >
            <Typography variant="body1" textAlign={"center"}>
              Stay updated on your tasks and filter them intelligently
            </Typography>
          </Grid>
        </Grid>

        {/* <br /> */}

        {/* <Grid container direction="row" spacing={4}>
          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faHourglass}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">
                        Pending tasks assigned to me
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPersonWalkingArrowRight}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">
                        Pending tasks requested by me
                      </Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card sx={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                      backgroundColor: "#ECE3FF",
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAlarmExclamation}
                      size={"xl"}
                      color={theme.palette.primary.main}
                    />
                  </Grid>

                  <Grid item sx={{ paddingLeft: "10px" }}>
                    <Grid container>
                      <Typography variant="body2">Overdue tasks</Typography>
                    </Grid>

                    <Grid container>
                      <Typography variant="body1">7</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}

        {/* <Grid container direction="column" alignItems="center"> */}
        {/* Commented for now. */}
        {/* <FabStandard
            click={() => {}}
            text="New"
            icon={faCirclePlus}
            sx={{
              left: "100px",
              top: "80px",
              right: "unset",
            }}
          /> */}
        {/* </Grid> */}

        {/* <Grid container justifyContent={"center"} mt={4}>
          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Assigned to me"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faPersonWalkingArrowLoopLeft}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Requested by me"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faPersonWalkingArrowRight}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Unassigned"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faLocationQuestion}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Overdue"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faAlarmExclamation}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Pending"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faHourglass}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />

          <Chip
            style={{ border: "1px solid", fontWeight: "bolder" }}
            color="primary"
            label="Done"
            variant="outlined"
            sx={{ mr: 2 }}
            icon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                style={{ marginLeft: "12px" }}
              />
            }
            onClick={() => {}}
          />
        </Grid> */}

        {/* <Grid
          container
          sx={{ mt: 4 }}
          justifyContent="right"
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: theme.palette.primary.main }}
          />

          <Divider orientation="vertical" flexItem sx={{ mx: 2, my: 2 }} />

          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
            }}
          />
        </Grid> */}

        {/* <Grid container direction="row" mt={4} spacing={1}>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              startIcon={
                <FontAwesomeIcon
                  icon={faBarsFilter}
                  style={{ fontSize: "14px" }}
                />
              }
              endIcon={
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ fontSize: "14px" }}
                />
              }
            >
              Filters
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              size="small"
              startIcon={
                <FontAwesomeIcon icon={faSave} style={{ fontSize: "14px" }} />
              }
              endIcon={
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{ fontSize: "14px" }}
                />
              }
            >
              Saved Searches
            </Button>
          </Grid>
        </Grid> */}

        <Grid container mt={4} justifyContent="center">
          <Box sx={{ height: 400, width: 1020 }}>
            <DataGrid
              density="comfortable"
              getRowHeight={() => "auto"}
              // onRowClick={(params) => {
              //   switch (params.row.type) {
              //     case "Renewal": {
              //       const agreementId = params?.row?.agreement?._id;
              //       if (!agreementId) return;
              //       setIsLoading(true);
              //       setSelectedAgreementId(agreementId);
              //       setOpenDialogDatesAndRenewal(true);
              //       break;
              //     }
              //     case "Ticket": {
              //       setSelectedTaskId(params.row.id);
              //       setOpenDialogTicketTaskDetails(true);
              //       break;
              //     }
              //     default: {
              //       throw new Error("Invalid row type.");
              //     }
              //   }
              // }}
              columns={columns}
              rows={tasks}
              disableRowSelectionOnClick
              filterMode="client"
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 2,
                },
                [`& .${gridClasses.columnHeaderTitle}`]: {
                  fontWeight: 400,
                },
                [`& .${gridClasses.detailPanel}`]: {
                  background: "transparent",
                },
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
                  {
                    outline: "none",
                  },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                  {
                    outline: "none",
                  },
                border: 0,
              }}
              hideFooter
              autoHeight
              disableColumnFilter
            />

            {openDialogDatesAndRenewal && (
              // @ts-ignore
              <DialogDatesAndRenewal
                agreementId={selectedAgreementId}
                open={openDialogDatesAndRenewal}
                close={() => {
                  setSelectedAgreementId("");
                  setOpenDialogDatesAndRenewal(false);
                }}
              />
            )}

            {openDialogTicketTaskDetails && (
              <DialogTicketTaskDetails
                taskId={selectedTaskId}
                open={openDialogTicketTaskDetails}
                close={() => {
                  setSelectedTaskId("");
                  setOpenDialogTicketTaskDetails(false);
                }}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
