import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

/**
 * @typedef {object} AddApproverTabProps
 * @property {StateUser[]} organizationUsers
 * @property {() => void} handleBack
 * @property {(newApprover: StateUser) => void} handleNext
 */

/**
 * @param {AddApproverTabProps} props
 * @returns {JSX.Element}
 */
export default function AddApproverTab({
  organizationUsers,
  handleBack,
  handleNext,
}) {
  const [approverToAdd, setApproverToAdd] = useState(
    /** @type {StateUser | null} */ (null)
  );

  return (
    <>
      <DialogContent sx={{ paddingX: 10 }}>
        <Grid container direction="row" mt={1}>
          <Autocomplete
            options={organizationUsers}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => option.displayName}
            renderOption={(props, option) => {
              const { ...optionProps } = props;
              return (
                <Box key={option._id} component="li" {...optionProps}>
                  <Avatar
                    alt={option.displayName}
                    src={
                      option.photoURL !== undefined && option.photoURL !== ""
                        ? option.photoURL
                        : ""
                    }
                    sx={{ mr: 2 }}
                  />
                  {option.displayName +
                    (option.title !== undefined &&
                    option.title !== null &&
                    option.title !== ""
                      ? ", " + option.title
                      : "")}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Approver"
                inputProps={{
                  ...params.inputProps,
                }}
                // Disables autocomplete and browser autofill.
                autoComplete="off"
              />
            )}
            onChange={(_event, value) => {
              setApproverToAdd(value);
            }}
          />
        </Grid>

        <Grid container direction="row" mt={2}>
          <Button
            variant="contained"
            fullWidth
            disableElevation
            disabled={!approverToAdd}
            onClick={() => {
              if (!approverToAdd) return;
              handleNext(approverToAdd);
            }}
            sx={{ marginRight: "auto" }}
          >
            Next&nbsp;&nbsp;
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button sx={{ marginRight: "auto" }} onClick={handleBack}>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Back
        </Button>
      </DialogActions>
    </>
  );
}
