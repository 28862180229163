import {
  faArrowLeft,
  faCheck,
  faImage,
  faStoreSlash,
  faTimes,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UploadImg } from ".";
import defaultCompanyLogo from "../assets/img/defaultlogo.png";
import defaultPersonLogo from "../assets/img/defaultuser.png";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import {
  randomString as generateRandomString,
  getAddressForEntity,
} from "../utils";
import { EntForm } from "./forms";

/**
 * - type: "newsub" => Setting up a new Subsidiary for an Org (Admin Module)
 * - type: "updsub" => Updating an existing Subsidiary for an Org (Admin Module)
 * - type: "newcp" => Setting up a new Counterparty, inside the New Flow
 * - type: "updcp" => Updating an existing Counterparty
 * - type: "signup" => Signing up (SuperAdmin)
 *
 * @typedef {*} DialogEntityProps
 * @property {boolean} open
 * @property {"newsub" | "updsub" | "newcp" | "updcp" | "signup"} type
 * @property {() => void} closDialog
 * @property {*} ent
 */

/**
 * @param {DialogEntityProps} props
 * @returns {React.JSX.Element}
 */
export default function DialogEntity(props) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [loading, setLoading] = React.useState(false);
  const [confirmDeactivate, setConfirmDeactivate] = React.useState(false);
  const [stagedEnt, setStagedEnt] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [stagedPerson, setStagedPerson] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [logoUpdating, setLogoUpdating] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(/** @type {* | null} */ (null));
  const [logoCanvas, setLogoCanvas] = React.useState(false);
  const [isUsed, setIsUsed] = useState(false);

  const isCreatingEntityForCpty =
    ["newcp"].includes(props.type) &&
    props.ent !== undefined &&
    props.ent !== null &&
    props.ent.orgID !== undefined &&
    props.ent.orgID !== null;

  const uid = state.user._id;
  const orgID = state.org._id;
  const { logoURL } = state.org;
  const { settings } = state;

  useEffect(() => {
    const checkIsUsed = async () => {
      const isUsed = await axios.get(
        `${state.settings.api}entity/check/${props.ent?._id}`
      );
      setIsUsed(isUsed.data.data);
    };
    if (!props.ent?._id) return;

    checkIsUsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ent]);

  useEffect(() => {
    setLoading(true);

    if (
      ["newcp", "updcp"].includes(props.type) &&
      props.ent.shortName !== undefined
    ) {
      if (
        ["updcp"].includes(props.type) &&
        ["Person"].includes(props.ent.entityCategory)
      ) {
        axios
          .get(state.settings.api + "user/org/" + props.ent.orgID) // Pull user for entity type "Person"
          .then((resUsers) => {
            if (resUsers.data.success && resUsers.data.data[0] !== undefined) {
              let user = resUsers.data.data.sort(
                (
                  /** @type {{ creationDate: number; }} */ a,
                  /** @type {{ creationDate: number; }} */ b
                ) => (a.creationDate < b.creationDate ? 1 : -1)
              )[0];
              setStagedPerson(user);
              setStagedEnt(props.ent);
              setLoading(false);
            } else {
              setErrMsg(
                "Unable to retrieve user for counterparty - please try again"
              );
              setLoading(false);
            }
          })
          .catch(() => {
            setErrMsg("Unable to retrieve user for counterparty");
            setLoading(false);
          });
      } else {
        setStagedEnt(props.ent);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  const reInitialize = () => {
    setLoading(false);
    setConfirmDeactivate(false);
    setLogoUpdating(false);
    setStagedEnt(null);
    setErrMsg(null);
  };

  /**
   * @param {*} snack
   */
  const closeDialog = (snack) => {
    props.closeDialog(snack);
    reInitialize();
  };

  /**
   * @param {*} child
   */
  const handleSubmitImage = (child) => {
    if (child !== null && child !== undefined) {
      setErrMsg(null);
      setLoading(true);

      let newent = { ...stagedEnt, logoURL: child };
      let /** @type {*} */ newpers = null;

      setStagedEnt(newent);

      if (["Person"].includes(stagedEnt.entityCategory)) {
        // If entity is a person, also update photo for User
        newpers = { ...stagedPerson, photoURL: child };
        setStagedPerson(newpers);
      }

      if (["updsub", "updcp"].includes(props.type)) {
        axios
          .put(state.settings.api + "org/orglogo/" + stagedEnt.orgID, {
            newLogo: child,
          })
          .then(() => {
            dispatch({ type: "UPDATE_CPENT", payload: newent });
            closeDialog({ ent: newent, person: newpers });
            setLoading(false);
          })
          .catch(() => {
            setErrMsg("Unable to update the logo");
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  };

  /**
   * @param {*} child
   */
  const handleSubmit = (child) => {
    setLoading(true);
    let creationDate = new Date().toISOString();

    // TODO: for the entity creatd/updated
    // CHECK if already exists in DB - return
    // Potential:
    // - Org exists as CP, Ent not => ent to be added for ownerOrg
    // - Org exists as CP, Ent also => ent to be duplicated for ownerOrg
    // - Org exists as CUST => select their org/ent, otherwise contact them
    // - User exists (email)

    const {
      shortName,
      legalName,
      streetAndNo,
      address2,
      city,
      zip,
      state,
      country,
      entityCategory,
      email,
      firstName,
      lastName,
      displayName,
      title,
      size,
      industries,
      domain,
      defaultLegalEntity,
    } = child;

    let address = [
      {
        addressType: "billto",
        streetAndNo: streetAndNo,
        address2: address2,
        zip: zip,
        city: city,
        state: state,
        country: country,
      },
    ];

    if (["newsub"].includes(props.type)) {
      // CREATE NEW SUB

      const newEnt = {
        orgID: orgID,
        ownerOrgID: null,
        entityCategory: entityCategory,
        shortName: shortName,
        legalName: legalName,
        address: address,
        logoURL: logoURL,
        active: true,
        sourceData: null,
        defaultSigners: [],
        defaultLegalEntity,
        creationBy: uid,
        creationDate: creationDate,
      };

      axios
        .post(settings.api + "entity", { entity: newEnt })
        .then((resEnt) => {
          if (resEnt.data.success) {
            // Add newly created entity to the reducer
            dispatch({ type: "ADD_SUB", payload: resEnt.data.data });

            axios
              .get(`${settings.api}entity`)
              .then((entitiesResponse) => {
                if (!entitiesResponse.data.data) {
                  setErrMsg("An error occured while fetching the entities");
                  return;
                }
                const entities = entitiesResponse.data.data;
                dispatch({ type: "INIT_SUBS", payload: entities });
                setLoading(false);
                closeDialog("snackEntCreated");
              })
              .catch((error) => {
                setErrMsg("An error occured while fetching the entities");
                throw error;
              });
          } else {
            setErrMsg(
              "An error occured while creating the entity - refresh your browser"
            );
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error("err", err);
          setErrMsg("An error occured while creating the entity");
          setLoading(false);
        });
    } else if (["updsub"].includes(props.type)) {
      // UPDATE EXISTING SUB

      let newent = props.ent;
      newent.lastUpdateBy = uid;
      newent.lastUpdateDate = creationDate;

      if (child === "deactivate") {
        // You are deactivating
        newent.active = false;
      } else if (shortName !== undefined) {
        // There actually was a child => You're updating
        newent.entityCategory = entityCategory;
        newent.shortName = shortName;
        newent.legalName = legalName;
        newent.address = address;
      }

      newent.defaultLegalEntity = defaultLegalEntity;

      pushEntity(newent, null);
    } else if (["newcp"].includes(props.type)) {
      // CREATE NEW COUNTERPARTY

      if (child === "finalize" || isCreatingEntityForCpty) {
        // You're completing the creation for a new ORG / ENT

        let person =
          ["Person"].includes(stagedEnt.entityCategory) &&
          stagedPerson !== undefined &&
          stagedPerson !== null &&
          stagedPerson.displayName !== undefined
            ? stagedPerson
            : null;

        if (stagedEnt.orgID === null || stagedEnt.orgID === undefined) {
          // Need to first create an ORG

          axios
            .post(settings.api + "org", {
              org: {
                shortName: stagedEnt.shortName,
                logoURL: stagedEnt.logoURL,
                orgType: "cpty",
                creationDate: creationDate,
                size: stagedEnt.size,
                industries: stagedEnt.industries,
                domain: stagedEnt.domain,
              },
            })
            .then((resOrg) => {
              if (resOrg.data.success) {
                let e = stagedEnt;
                e.orgID = resOrg.data.data._id;
                pushEntity(e, person);
              } else {
                setErrMsg(
                  "An error occured while creating the org - refresh your browser"
                );
              }
            })
            .catch((err) => {
              console.log("err", err);
              setErrMsg("An error occured while creating the org");
            });
        } else if (isCreatingEntityForCpty) {
          // ORG already exists, create a child entity

          let newEntForCp = stagedEnt;
          newEntForCp.entityCategory = entityCategory;
          newEntForCp.shortName = shortName;
          newEntForCp.legalName = legalName;
          newEntForCp.address = address;
          newEntForCp.active = true;
          newEntForCp.sourceData = null;
          newEntForCp.defaultSigners = [];
          newEntForCp.creationBy = uid;
          newEntForCp.creationDate = creationDate;
          pushEntity(newEntForCp, person);
        }
      } else {
        // You're staging and moving on to the LogoUpdating

        const defaultLogo =
          entityCategory === "Person" ? defaultPersonLogo : defaultCompanyLogo;

        setStagedEnt({
          ...stagedEnt,
          //_id: generateRandomString(20),
          //orgID: generateRandomString(20),
          ownerOrgID: orgID,
          entityCategory: entityCategory,
          shortName: shortName,
          legalName: legalName,
          address: address,
          logoURL: defaultLogo,
          active: true,
          sourceData: null,
          defaultSigners: [],
          creationBy: uid,
          creationDate: creationDate,
          size,
          industries,
          domain,
        });
        if (["Person"].includes(entityCategory)) {
          // If you're creating a person, stage the User Record
          setStagedPerson({
            email: email,
            password: generateRandomString(20),
            firstName: firstName,
            lastName: lastName,
            displayName: displayName,
            title: title,
            phone: "",
            role: "Counterparty",
            readOnly: false,
            creationBy: uid,
            creationDate: creationDate,
            active: true,
          });
        }
        setLogoUpdating(true);
        setLoading(false);
      }
    } else if (["updcp"].includes(props.type)) {
      // UPDATE EXISTING COUNTERPARTY

      let newcp = stagedEnt;
      newcp.shortName = shortName;
      newcp.legalName = legalName;
      newcp.address = address;

      let newpers = null; // todo - update based on updates
      if (
        ["Person"].includes(newcp.entityCategory) &&
        stagedPerson !== null &&
        stagedPerson._id !== undefined
      ) {
        newpers = stagedPerson;
        newpers.email = email;
        newpers.firstName = firstName;
        newpers.lastName = lastName;
        newpers.displayName = displayName;
        newpers.title = title;
      }
      pushEntity(newcp, newpers);
    } else if (["signup"].includes(props.type)) {
      // SIGNUP A NEW ORG / USER

      if (
        child.shortName !== "" &&
        child.legalName !== "" &&
        child.email !== "" &&
        child.firstName !== "" &&
        child.lastName !== "" &&
        child.title !== ""
      ) {
        let creationDate = new Date().toISOString();
        axios
          .post(settings.api + "auth/signup", {
            org: {
              shortName: child.shortName,
              logoURL: null,
              orgType: "cust",
              creationDate: creationDate,
              stripecusid: null,
              orgSettings: {
                headerInPdf: true,
                headerInWord: true,
                signMethod: "hellosign",
              },
              integrations: null,
              size,
              industries,
              domain,
            },
            user: {
              email: child.email,
              password: "Password1",
              firstName: child.firstName,
              lastName: child.lastName,
              displayName: child.displayName,
              title: child.title,
              phone: "",
              photoURL: null,
              role: "Admin",
              readOnly: false,
              creationBy: uid,
              creationDate: creationDate,
              active: true,
            },
          })
          .then(function (res) {
            // TODO FROM BACKEND - SEND EMAILS / CREATE NOTIFICATION
            console.log("signup completed!", res);
            closeDialog({ orgID: res.data.data.orgID });
          })
          .catch(() => {
            setErrMsg("Unable to complete the signup");
          });
      } else {
        setLoading(false);
      }
    }
  };

  const pushEntity = async (/** @type {*} */ ent, /** @type {*} */ pers) => {
    try {
      if (!["updsub", "updcp", "newcp"].includes(props.type)) return;

      const resEnt = await axios({
        method: ["updsub", "updcp"].includes(props.type) ? "put" : "post",
        url:
          settings.api +
          "entity" +
          (["updsub", "updcp"].includes(props.type) ? "/" + ent._id : ""),
        data: { entity: ent },
      }).catch((error) => {
        setErrMsg("An error occured while updating the entity");
        throw error;
      });
      if (!resEnt.data.success) {
        setErrMsg(
          "An error occured while updating the entity - refresh your browser"
        );
        return;
      }

      dispatch({
        type: ["updsub"].includes(props.type)
          ? "UPDATE_SUB"
          : ["updcp"].includes(props.type)
          ? "UPDATE_CPENT"
          : "ADD_CPENT",
        payload: resEnt.data.data,
      });

      if (["Person"].includes(ent.entityCategory) && pers !== null) {
        // You have created/updated an entity type "person".
        // Update to a newly generated orgID-
        pers.orgID = ent.orgID;

        const resUser = await axios({
          method: ["updsub", "updcp"].includes(props.type) ? "put" : "post",
          url:
            settings.api +
            "user" +
            (["updsub", "updcp"].includes(props.type) ? "/" + pers._id : ""),
          data: { user: pers },
        }).catch((error) => {
          setErrMsg("An error occured while updating the user");
          throw error;
        });
        if (!resUser.data.success) {
          setErrMsg(
            "An error occured while updating the user - refresh your browser"
          );
          return;
        }

        // Completed the Person update.
        setLoading(false);
        closeDialog(
          ["updsub"].includes(props.type)
            ? "snackEntUpdated" // Update: close with Snack
            : { ent: resEnt.data.data, person: resUser.data.data }
        ); // Create : Close with Entity and User
      } else {
        // You've created / updated an entity type "company"
        closeDialog(
          ["updsub"].includes(props.type)
            ? "snackEntUpdated" // Update: close with Snack
            : { ent: resEnt.data.data, person: null }
        ); // Create: close with Entity
      }

      const entitiesResponse = await axios
        .get(`${settings.api}entity`)
        .catch((error) => {
          setErrMsg("An error occured while fetching the entities");
          throw error;
        });
      if (!entitiesResponse.data.data) {
        setErrMsg("An error occured while fetching the entities");
        return;
      }

      const entities = entitiesResponse.data.data;

      dispatch({ type: "INIT_SUBS", payload: entities });
    } catch (error) {
      setErrMsg("An error occured while updating the entity");
    } finally {
      setLoading(false);
    }
  };

  const getHiddenFields = () => {
    if (
      ["newsub", "updsub", "updcp"].includes(props.type) ||
      isCreatingEntityForCpty
    ) {
      return ["entityCategory", "size", "industries", "domain", "shortName"];
    }

    //return ["newcp", "signup"].includes(props.type) ? ["entityCategory"] : [];

    return props.type === "signup" ? ["entityCategory"] : [];
  };

  const getDisabledFields = () => {
    if (isCreatingEntityForCpty || ["newsub", "updsub"].includes(props.type)) {
      return ["shortName", "email"];
    }
    // todo => ability to update shortname for CP, would need to change across ents/org and test it's unique
    // todo => ability to update email for CP, would need to change across agrs (collab/signer) and test it's unique
    if (props.type === "updcp") {
      return isUsed ? ["shortName", "email"] : ["shortName", "email"];
    }
    return [];
  };

  const getInitialValues = () => {
    const billToAddress = ["updsub"].includes(props.type)
      ? getAddressForEntity(props.ent, "billto")
      : getAddressForEntity(stagedEnt, "billto");

    return {
      _id: props.ent._id,
      shortName: stagedEnt?.shortName
        ? stagedEnt.shortName // Any previously provided value
        : ["newsub"].includes(props.type)
        ? state.org.shortName // Creating a new sub => org shortname
        : ["updsub"].includes(props.type) && props.ent.shortName !== undefined
        ? props.ent.shortName // As provided by the parent component
        : "",
      legalName: stagedEnt?.legalName
        ? stagedEnt.legalName
        : ["updsub"].includes(props.type) && props.ent.legalName !== undefined
        ? props.ent.legalName
        : "",
      streetAndNo: billToAddress.streetAndNo ? billToAddress.streetAndNo : "",
      address2: billToAddress.address2 ? billToAddress.address2 : "",
      city: billToAddress.city ? billToAddress.city : "",
      zip: billToAddress.zip ? billToAddress.zip : "",
      state: billToAddress.state ? billToAddress.state : "",
      country: billToAddress.country ? billToAddress.country : "",
      entityCategory: stagedEnt?.entityCategory
        ? stagedEnt.entityCategory
        : "Company",
      firstName: stagedPerson?.firstName ? stagedPerson.firstName : "",
      lastName: stagedPerson?.lastName ? stagedPerson.lastName : "",
      email: stagedPerson?.email ? stagedPerson.email : "",
      title: stagedPerson?.title ? stagedPerson.title : "",
      size: stagedEnt?.size ? stagedEnt.size : null,
      industries: stagedEnt?.industries ? stagedEnt.industries[0] : null,
      domain: stagedEnt?.domain ? stagedEnt.domain : "",
      defaultLegalEntity: props?.ent?.defaultLegalEntity || false,
    };
  };

  return (
    <div>
      <Dialog open={props.open} onClose={closeDialog} fullWidth maxWidth="sm">
        <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
          <IconButton onClick={closeDialog}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ padding: "4px 7px", fontSize: "20px" }}
            />
          </IconButton>
        </Box>

        <DialogTitle>
          {loading
            ? "Loading ..."
            : ["newsub"].includes(props.type)
            ? "Create New Entity"
            : ["updsub"].includes(props.type)
            ? "Update Entity"
            : ["newcp"].includes(props.type)
            ? "Create New Party"
            : ["updcp"].includes(props.type)
            ? "Update Party"
            : ["signup"].includes(props.type)
            ? "Sign up"
            : ""}
        </DialogTitle>

        <DialogContent>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 20,
              }}
            >
              <CircularProgress size={24} />
            </Box>
          ) : // Updating the Logo
          logoUpdating ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 8,
                mb: logoCanvas ? 2 : 6,
              }}
            >
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  {!stagedEnt.logoURL ? (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                    >
                      Click to upload a{" "}
                      {["Person"].includes(stagedEnt.entityCategory)
                        ? "photo"
                        : "logo"}
                    </Typography>
                  ) : (
                    <Typography variant="subtitle1" align="center">
                      {stagedEnt.shortName}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <UploadImg
                    type={
                      ["Person"].includes(stagedEnt.entityCategory)
                        ? "avatar"
                        : "logo"
                    }
                    handleSubmitImage={handleSubmitImage}
                    setLogoCanvas={setLogoCanvas}
                    initialImg={stagedEnt.logoURL}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : // Ask whether they really want to deactivate
          confirmDeactivate ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 12,
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    style={{
                      fontSize: "40px",
                      color: theme.palette.error.main,
                    }}
                  />
                </Grid>
                <Grid item sx={{ mt: 2, pb: 2 }}>
                  <Typography align="center">
                    Are you sure you want to deactivate this entity?
                  </Typography>
                  <Typography align="center">
                    This action cannot be undone.
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="error"
                    variant="contained"
                    disableElevation
                    sx={{ mr: 1 }}
                    onClick={() => handleSubmit("deactivate")}
                  >
                    Confirm
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => setConfirmDeactivate(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : // Default: Show the FORM
          ["newsub", "updsub", "newcp", "updcp", "signup"].includes(
              props.type
            ) ? (
            <Box sx={{ mt: 2 }}>
              {errMsg !== null ? ( // An error exists - show it
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 4,
                  }}
                >
                  <Typography align="center" color="error">
                    {errMsg}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <EntForm
                orgId={props?.ent?.orgID || state.org._id}
                handleSubmit={handleSubmit}
                buttonName={
                  ["newcp"].includes(props.type) && !isCreatingEntityForCpty
                    ? "Next"
                    : "Submit"
                }
                optionalAddress={["newcp", "updcp", "signup"].includes(
                  props.type
                )}
                isSignup={["signup"].includes(props.type)}
                hideFields={getHiddenFields()}
                disableFields={getDisabledFields()}
                initialValues={getInitialValues()}
                showAddressFields={
                  props.type === "updcp" || props.type === "newcp"
                }
              />
            </Box>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          {logoUpdating ? (
            <Button
              sx={{ marginRight: "auto" }}
              onClick={() => setLogoUpdating(false)}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              &nbsp;&nbsp;Back
            </Button>
          ) : (
            <Button sx={{ marginRight: "auto" }} onClick={closeDialog}>
              Cancel
            </Button>
          )}

          {
            // DEACTIVATE BUTTON
            ["updsub"].includes(props.type) && // You are Updating the entity
            !confirmDeactivate ? ( // AND you're not yet deactivating
              <Button color="error" onClick={() => setConfirmDeactivate(true)}>
                Deactivate&nbsp;&nbsp;
                <FontAwesomeIcon icon={faStoreSlash} />
              </Button>
            ) : // FINALIZE BUTTON
            ["newcp"].includes(props.type) && // Creating a new counterparty
              logoUpdating && // You're in step 2: Logo Updating
              stagedEnt !== null &&
              stagedEnt.legalName !== undefined ? ( // Staged values are defined
              <Button
                variant="contained"
                disableElevation
                onClick={() => handleSubmit("finalize")}
                disabled={logoCanvas}
              >
                Finalize&nbsp;&nbsp;
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            ) : // UPDATE LOGO BUTTON
            ["updcp"].includes(props.type) &&
              stagedEnt !== null &&
              ["Company", "Person"].includes(stagedEnt.entityCategory) &&
              !logoUpdating ? (
              <Button
                variant="text"
                disableElevation
                onClick={() => setLogoUpdating(true)}
              >
                Update{" "}
                {["Person"].includes(stagedEnt.entityCategory)
                  ? "Photo"
                  : "Logo"}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={faImage} />
              </Button>
            ) : (
              ""
            )
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
