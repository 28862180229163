import {
  faCaretRight,
  faCheckCircle,
  faPlusCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Grid, IconButton, Tooltip } from "@mui/material";
import React from "react";
import theme from "../../../theme/theme";
import { avatarSize } from "../constants/avatarSize";
import { avatarStyles } from "../constants/avatarStyles";
import ChainItem from "./ChainItem";

/**
 * @typedef {object} EditApprovalListProps
 * @property {ApprovalList} approvalList
 * @property {(approvalListItemIndex: number) => void} handleAddNewApprover
 * @property {(approvalListItemIndex: number) => void} handleRemoveApprover
 * @property {boolean} [isDefault]
 */

/**
 * @param {EditApprovalListProps} props
 * @returns {JSX.Element}
 */
export default function EditApprovalList({
  approvalList,
  handleAddNewApprover,
  handleRemoveApprover,
  isDefault,
}) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      mt={2}
    >
      {approvalList.map((approvalListItem, index) => {
        switch (approvalListItem.type) {
          case "user": {
            return (
              <Grid item key={index}>
                {approvalListItem.role === "requestor" && (
                  <ChainItem
                    primaryText={approvalListItem.displayName}
                    secondaryText={approvalListItem.title}
                  >
                    <Avatar
                      src={approvalListItem.photoUrl}
                      sx={{ ...avatarStyles }}
                    />
                  </ChainItem>
                )}

                {approvalListItem.role === "approver" && (
                  <ChainItem
                    primaryText={approvalListItem.displayName}
                    secondaryText={approvalListItem.title}
                  >
                    <Tooltip title="Click to remove approver" arrow>
                      <Avatar
                        src={approvalListItem.photoUrl}
                        sx={{
                          ...avatarStyles,
                          "&:hover": {
                            cursor: "pointer",
                            border: "2px solid",
                            borderColor: theme.palette.secondary.main,
                          },
                        }}
                        onClick={(_event) => handleRemoveApprover(index)}
                      />
                    </Tooltip>
                  </ChainItem>
                )}
              </Grid>
            );
          }

          case "caret": {
            return (
              <Grid item key={index} mb={4}>
                <FontAwesomeIcon icon={faCaretRight} color="#ABA9B8" />
              </Grid>
            );
          }

          case "addApprover": {
            return (
              <Grid item key={index} mb={4}>
                <IconButton
                  size="large"
                  onClick={(_event) => handleAddNewApprover(index)}
                >
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    color={theme.palette.grey[600]}
                    fontSize={20}
                  />
                </IconButton>
              </Grid>
            );
          }

          case "check": {
            return (
              <Grid item key={index}>
                <ChainItem>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={theme.palette.grey[600]}
                    fontSize={avatarSize}
                  />
                </ChainItem>
              </Grid>
            );
          }

          default: {
            throw new Error("Invalid type.");
          }
        }
      })}
    </Grid>
  );
}
