import { $isTextNode } from "lexical";
import { $createMarkNode } from "../../nodes/MarkNode";

/**
 * @param {*} ongoingCommentsSet
 * @param {*} sfdtCommentsMap
 * @param {*} metadata
 * @param {*} collabs
 * @param {*} user
 * @param {*} node
 * @param {*} organizationUsers
 */
export function createComment(
  ongoingCommentsSet,
  sfdtCommentsMap,
  metadata,
  collabs,
  user,
  node,
  organizationUsers
) {
  const commentIds = Array.from(ongoingCommentsSet);
  // TODO: Consider using the `createUID()` function instead of the comment ID that comes
  // from Word. If we use the ID from Word we will create duplicates every time we import
  // the file.
  const publicCommentIds = commentIds.map((cId) => ["cp", "_", cId].join(""));
  // If comments overlap, for now we only store the metadata regarding the first one.
  const comment = Array.from(sfdtCommentsMap.values()).find(
    (v) =>
      [v.commentId, v.commentId.substring(3)].includes(commentIds[0]) ||
      v.realId === commentIds[0]
  );
  if (!comment) throw new Error("Could not find comment.");
  const { author, date } = comment;

  if ($isTextNode(node)) {
    comment.quote = node.getTextContent();
  } else {
    comment.quote = "(n/a)";
  }

  // Checks if document has metadata.
  const hasMetadata = JSON.stringify(metadata) !== "{}";

  // Checks what is the party ID of the organization users.
  const { partyID: organizationUsersPartyID } = collabs.find(
    (/** @type {{ email: string; }} */ collab) =>
      organizationUsers.some(
        (/** @type {{ email: string; }} */ organizationUser) =>
          collab.email === organizationUser.email
      )
  ) || { partyID: "party0" };

  // Joins the agreement collaborators with the organization users.
  const collaboratorsAndOrganizationUsers = [
    ...collabs,
    ...organizationUsers.map((/** @type {*} */ organizationUser) => ({
      ...organizationUser,
      partyID: organizationUsersPartyID,
    })),
  ];

  const collab = hasMetadata
    ? collaboratorsAndOrganizationUsers.find(
        (/** @type {PartialUser}*/ x) => x.email === author
      )
    : undefined;

  const uploader = collaboratorsAndOrganizationUsers.find(
    (x) => x.partyID === metadata?.partyId
  ) ||
    collaboratorsAndOrganizationUsers.find((x) => x.email === user?.email) || {
      ...user,
      partyID: metadata?.partyId ?? "party0",
    };

  const useDisplayNameFromWord = collaboratorsAndOrganizationUsers.every(
    (x) => x.email !== author
  );

  const markNode = $createMarkNode(publicCommentIds, {
    partyId: collab ? collab.partyID : uploader.partyID,
    creatorDisplayName: useDisplayNameFromWord
      ? author
      : collab
      ? collab.displayName
      : uploader.displayName,
    creationDate: date,
    creatorEmail: collab ? collab.email : uploader.email,
    creatorId: collab ? collab._id : uploader._id,
    creatorPhotoUrl: collab ? collab.photoURL : user?.photoURL,
  });

  if (comment.done === true) {
    markNode.setResolvedIds([markNode.getIDs()[0]]);
  }

  // @ts-ignore
  markNode.append(node);
  return markNode;
}
