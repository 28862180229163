import { faXmark } from "@fortawesome/pro-light-svg-icons";
import {
  faCirclePlus,
  faCircleXmark,
  faInputText,
  faPen,
  faSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faFileImport,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  createFilterOptions,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { v4 as uuid } from "uuid";
import useMergeFieldService from "../../hooks/useMergeFieldService";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";
import {
  calculateCondition,
  calculateMergeFieldValue,
} from "../../utils/calculateRegularMergeFieldValue";
import { getCanveoTier } from "../../utils/getCanveoTier";
import CanveoCircularProgress from "./../CanveoCircularProgress";
import {
  currencyCodes,
  defaultDocumentMergeFields,
  defaultListOptions,
  defaultMergeFieldValue,
  defaultPartyRoles,
  defaultStep,
  durationUnits,
  getDefaultMergeFields,
  mergeFieldValueTypes,
} from "./constants";
import ListOptionsDialog from "./dialogs/ListOptionsDialog";
import NewConditionDialog from "./dialogs/NewConditionDialog";
import {
  extractIsoStringDateFromText,
  extractNumberFromText,
  getConditionSummary,
  getMergeFieldDefaultValue,
  getMergeFieldDisplayValue,
  getMergeFieldValue,
  mergeFieldValueIsValid,
} from "./utils";

const filter = createFilterOptions();
const devMode = false;
const zIndex = 1260;

/**
 * @typedef {object} MergeFieldMenuProps
 * @property {boolean} open
 * @property {string} organizationId
 * @property {string} organizationName
 * @property {boolean} documentIsTemplate
 * @property {boolean} isQuestionnaire
 * @property {string} mergeFieldText
 * @property {string} partyId
 * @property {string} docId
 * @property {(newLid: string, mergeField: MergeField) => void} handleSubmit
 * @property {(editorMarkNodeId: string, mergeField: MergeField) => void} handleUpdate
 * @property {() => void} handleClose
 * @property {MergeField} [existingMergeField]
 */

/**
 * @param {MergeFieldMenuProps} props
 * @returns {JSX.Element}
 */
export default function MergeFieldMenu({
  open,
  organizationName,
  mergeFieldText,
  partyId,
  docId,
  handleSubmit,
  handleUpdate,
  handleClose,
  documentIsTemplate,
  isQuestionnaire,
  existingMergeField,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const canveoTier = getCanveoTier(state?.user?.email);

  const [agreement] = documentIsTemplate ? state.template : state.agrs;

  const [step, setStep] = useState(
    existingMergeField && existingMergeField?.type === "regular"
      ? "new"
      : defaultStep
  );
  const [isLoading, setIsLoading] = useState(false);
  const [valueBasedOnCondition, setValueBasedOnCondition] = useState(false);
  const [openNewConditionDialog, setOpenNewConditionDialog] = useState(false);

  const includeCreateAction =
    canveoTier === "experimental" || documentIsTemplate;
  const defaultMergeFields = getDefaultMergeFields({
    includeCreateAction,
    createActionLabel: isQuestionnaire
      ? "Create New Property"
      : "Create New Merge Field",
  });

  const [mergeFields, setMergeFields] = useState(
    isQuestionnaire ? [defaultMergeFields[0]] : defaultMergeFields
  );
  const [mergeFieldName, setMergeFieldName] = useState("");
  const [partyRole, setPartyRole] = useState("");
  const [mergeFieldValueType, setMergeFieldValueType] = useState(
    /** @type {import("./../editor/nodes/MarkNode").MergeFieldValueType | ""} */ (
      ""
    )
  );
  const [mergeFieldValue, setMergeFieldValue] = useState(
    defaultMergeFieldValue
  );
  const [mergeFieldDisplayValue, setMergeFieldDisplayValue] =
    useState(mergeFieldText);

  const [dontSetValueNow, setDontSetValueNow] = useState(
    documentIsTemplate ? (isQuestionnaire ? false : true) : false
  );
  const [counterpartyName, setCounterpartyName] = useState("");
  const [visibilityForCounterparties, setVisibilityForCounterparties] =
    useState("notVisible");
  const [wizardQuestion, setWizardQuestion] = useState("");
  const [wizardGuidance, setWizardGuidance] = useState("");
  const [saveMergeField, setSaveMergeField] = useState(false);
  const [containsSensitiveInformation, setContainsSensitiveInformation] =
    useState(false);
  const [
    askCounterPartyToCompleteOrConfirmMergeField,
    setAskCounterPartyToCompleteOrConfirmMergeField,
  ] = useState(false);
  const [mergeFieldType, setMergeFieldType] = useState("");
  const [mergeFieldOriginId, setMergeFieldOriginId] = useState("");
  const [openListOptionsDialog, setOpenListOptionsDialog] = useState(false);
  const [listOptions, setListOptions] = useState(defaultListOptions);
  const [isList, setIsList] = useState(false);
  const [defaultStepContinueButton, setDefaultStepContinueButton] =
    useState("Next");
  const [documentMergeFields, setDocumentMergeFields] = useState(
    defaultDocumentMergeFields
  );
  const isEditing = !!existingMergeField;
  const [displayMergeFieldNameError, setDisplayMergeFieldNameError] =
    useState(false);
  const [displayPartyRoleError, setDisplayPartyRoleError] = useState(false);
  // const [pullValueFromConnectedSystem, setPullValueFromConnectedSystem] =
  //   useState(false);

  const /** @type {string[]} */ defaultSelectedListOptionIds = [];
  const [selectedListOptionsIds, setSelectedListOptionsIds] = useState(
    defaultSelectedListOptionIds
  );
  const [
    allowSelectingMultipleListOptions,
    setAllowSelectingMultipleListOptions,
  ] = useState(false);
  const [conditions, setConditions] = useState(
    /** @type {import("./dialogs/NewConditionDialog/condition").Condition[]} */ ([])
  );
  const [conditionToEdit, setConditionToEdit] = useState(
    /** @type {import("./dialogs/NewConditionDialog/condition").Condition | null} */ (
      null
    )
  );
  const [documentPartyRoles, setDocumentPartyRoles] = useState(
    /** @type {{label: string;}[]} */ ([])
  );
  const [setOrganizationAsRole, setSetOrganizationAsRole] = useState(false);
  const [setTask, setSetTask] = useState(false);
  const [assignees] = useState(
    /** @type {import("../dialogs/DialogDatesAndRenewal/constants").AssigneeOption[]} */ (
      state.users.map(
        (
          /** @type {{ _id: string; displayName: string; email: string; }} */ user
        ) => ({
          _id: user._id,
          name: user.displayName,
          email: user.email,
        })
      )
    )
  );
  const [assignee, setAssignee] = useState(
    /** @type {import("../dialogs/DialogDatesAndRenewal/constants").AssigneeOption | null} */ ({
      _id: state.user._id,
      name: state.user.displayName,
      email: state.user.email,
    })
  );
  const [reminderDate, setReminderDate] = useState("");

  const loadDocumentMergeFields = useCallback(
    async () => {
      let url = `${state.settings.api}document/${docId}/mergeFields`;
      if (isQuestionnaire) {
        url += "?isQuestionnaire=true";
      }
      const response = await axios.get(url).catch((error) => {
        console.error(error);
      });

      setIsLoading(false);

      if (!response) throw new Error("Error getting response.");

      /** @type {import("./../editor/nodes/MarkNode").MergeField[]} */
      const documentMergeFields = response.data.data;
      if (!documentMergeFields) throw new Error("Error getting Merge Fields.");

      setDocumentMergeFields(documentMergeFields);

      if (documentMergeFields.length) {
        /** @type {(import("./constants").DefaultMergeField & { mergeField: import("./../editor/nodes/MarkNode").MergeField}) [] } */
        let displayableMergeFields = documentMergeFields.map((mergeField) => {
          const type = mergeFieldValueTypes.find(
            (mft) => mft.value === mergeField.valueType
          );

          return {
            label:
              mergeField.type === "partyInformation"
                ? mergeField.name + ` (${mergeField.partyRole})`
                : mergeField.name,
            value: mergeField.name,
            type: mergeField.type,
            icon: type ? type.icon : faInputText,
            isTemplate: mergeField.isTemplate,
            id: mergeField._id,
            mergeField: mergeField,
          };
        });

        /** @type {import("./constants").DefaultMergeField | undefined} */
        let createAction = undefined;
        /** @type {import("./constants").DefaultMergeField[]} */
        let rest = [];

        if (includeCreateAction) {
          [createAction, ...rest] = defaultMergeFields;
        } else {
          rest = defaultMergeFields;
        }

        if (isQuestionnaire) {
          rest = [];

          displayableMergeFields = displayableMergeFields.filter(
            (x) =>
              x.mergeField.scope === "questionnaire" && x.isTemplate === true
          );
        }

        const alphabeticallySortedDocumentMergeFields = [
          ...rest,
          ...displayableMergeFields,
        ].sort((previous, next) =>
          previous.label.toLowerCase().localeCompare(next.label.toLowerCase())
        );

        if (includeCreateAction && createAction) {
          setMergeFields([
            createAction,
            ...alphabeticallySortedDocumentMergeFields,
          ]);
        } else {
          setMergeFields([...alphabeticallySortedDocumentMergeFields]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.settings.api, docId, isQuestionnaire]
  );

  const loadDocumentPartyRoles = useCallback(async () => {
    if (documentIsTemplate) {
      const response = await axios
        .get(`${state.settings.api}document/${docId}/roles`)
        .catch((error) => {
          console.error(error);
        });
      if (!response) throw new Error("Error getting response.");
      const documentRoles = response.data.data;
      if (!documentRoles) throw new Error("Error getting Merge Fields");
      setDocumentPartyRoles(documentRoles);
    } else {
      const parties =
        state.parties?.map(
          (/** @type {{ legalName: string; role: string; }} */ party) => ({
            label:
              party.role && party.role !== "null"
                ? `${party.legalName} (${party.role})`
                : party.legalName,
          })
        ) || [];
      setDocumentPartyRoles(parties);
    }
  }, [documentIsTemplate, state.settings.api, docId, state.parties]);

  useEffect(() => {
    if (!existingMergeField) {
      setMergeFieldValue({ type: "freeText", value: mergeFieldText });
    }
  }, [mergeFieldText, existingMergeField]);

  // We only want to load the Merge Fields if we are creating a new one, not when editing.
  useEffect(() => {
    if (!existingMergeField) {
      setIsLoading(true);
      loadDocumentMergeFields();
      loadDocumentPartyRoles();
    }
  }, [loadDocumentMergeFields, loadDocumentPartyRoles, existingMergeField]);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // This use effect is used to compute the value of the Merge Field display value.
  useEffect(() => {
    const displayValue = getMergeFieldDisplayValue(mergeFieldValue);
    setMergeFieldDisplayValue(displayValue);
  }, [mergeFieldValue, mergeFieldValueType]);

  // const partyRoleAutoCompleteInputField = useRef(null);
  const mergeFieldTypeAutoCompleteInputField = useRef(null);

  const { createMergeField, updateMergeField } = useMergeFieldService();

  const resetForm = () => {
    setStep("default");
    setMergeFieldName("");
    setMergeFieldValueType("");
    setValueBasedOnCondition(false);
    setDontSetValueNow(documentIsTemplate ? true : false);
    setPartyRole("");
    setCounterpartyName("");
    setVisibilityForCounterparties("notVisible");
    setWizardQuestion("");
    setWizardGuidance("");
    setSaveMergeField(false);
    setMergeFieldOriginId("");
    setMergeFieldType("");
    setAskCounterPartyToCompleteOrConfirmMergeField(false);
    setIsList(false);
    setDefaultStepContinueButton("Next");
    setListOptions([]);
    setMergeFieldValue(defaultMergeFieldValue);
    setSelectedListOptionsIds([]);
    setAllowSelectingMultipleListOptions(false);
    setConditions([]);
  };

  const close = () => {
    handleClose();
    resetForm();
  };

  const setMergeField = (
    /** @type {import("./../editor/nodes/MarkNode").MergeField} **/ mergeField
  ) => {
    setMergeFieldName(mergeField.name);
    setMergeFieldType(mergeField.type);
    setMergeFieldValue(mergeField.value);
    setMergeFieldValueType(mergeField.valueType);
    setValueBasedOnCondition(mergeField.valueBasedOnCondition);
    setSaveMergeField(mergeField.isTemplate);
    setPartyRole(mergeField.partyRole);
    setVisibilityForCounterparties(mergeField.visibilityForCounterparties);
    setWizardQuestion(mergeField.wizardQuestion);
    setWizardGuidance(mergeField.wizardGuidance);
    setMergeFieldOriginId(mergeField.originId);
    setAskCounterPartyToCompleteOrConfirmMergeField(
      mergeField.askCounterpartyToConfirm
    );
    setListOptions(mergeField.listOptions);
    setSelectedListOptionsIds(mergeField.selectedListOptionsIds);
    setDontSetValueNow(mergeField.setValueLater);
    if (
      mergeField.visibilityForCounterparties === "visibleToSpecificCounterparty"
    ) {
      setCounterpartyName(mergeField.counterPartyThatCanView);
    }
    setMergeFieldDisplayValue(mergeField.displayValue);
    setIsList(mergeField.isList);
    setAllowSelectingMultipleListOptions(
      mergeField.allowSelectingMultipleListOptions
    );
    setValueBasedOnCondition(mergeField.valueBasedOnCondition);
    setConditions(mergeField.conditions);
    setContainsSensitiveInformation(mergeField.containsSensitiveInformation);
    setSetOrganizationAsRole(mergeField.setOrganizationAsRole);
  };

  const loadMergeField = (/** @type {string}*/ mergeFieldId) => {
    const mergeField = documentMergeFields.find(
      (dmf) => dmf._id === mergeFieldId
    );

    if (!mergeField) {
      throw new Error("Merge field not found.");
    }

    setMergeField(mergeField);
  };

  const submit = async () => {
    setIsLoading(true);

    /** @type {string | undefined} */
    let calculatedDisplayValue;

    // If the base value of the conditions of the merge field doesn't itself have any conditions and is not a
    // party information merge field and is not set to be calculated later, then we can calculate it immediately,
    // otherwise the calculation is deffered to when we create the agreement.
    if (conditions.length) {
      for (const condition of conditions) {
        if (
          condition.baseValue.type !== "partyInformation" &&
          !condition.baseValue.setValueLater &&
          !condition.baseValue.conditions.length
        ) {
          const result = calculateCondition(condition);
          if (!result) continue;

          calculatedDisplayValue = getMergeFieldDisplayValue(result);
          break;
        }
      }
    }

    let role = "";

    if (documentIsTemplate) {
      role = partyRole;
    } else {
      // In the case of an agreement, the user picks a party instead of a role. Because the string contains the
      // legal name of the entity plus the role inside parenthesis e.g., Company Ltd (Counterparty), we split
      // via "(" to get the legal name. The calculation of the merge field already takes this into account. It
      // searches for a match first by role and then by the party legal name.
      const splitted = partyRole?.split("(");
      if (splitted.length >= 1) {
        const [first] = splitted;
        // Remove leading and trailing white space from role.
        role = first.trim();
      }
    }

    /** @type {Omit<import("./../editor/nodes/MarkNode").MergeField, "_id" | "editorMarkNodeId" | "documentId" | "position" | "createdAt" | "updatedAt" | "organizationId">} */
    const mergeField = {
      name: mergeFieldName,
      type: mergeFieldType,
      value: mergeFieldValue,
      valueType: mergeFieldValueType || "freeText",
      valueBasedOnCondition,
      conditions,
      isTemplate: saveMergeField,
      partyRole: role,
      visibilityForCounterparties,
      wizardQuestion,
      wizardGuidance,
      organizationName,
      originId: mergeFieldOriginId,
      askCounterpartyToConfirm: askCounterPartyToCompleteOrConfirmMergeField,
      listOptions,
      selectedListOptionsIds,
      setValueLater: !!calculatedDisplayValue ? false : dontSetValueNow,
      counterPartyThatCanView: counterpartyName || "party0",
      selectedText: mergeFieldText,
      displayValue: calculatedDisplayValue || mergeFieldDisplayValue,
      scope: isQuestionnaire ? "questionnaire" : "document",
      isList,
      allowSelectingMultipleListOptions,
      containsSensitiveInformation,
      setOrganizationAsRole,
    };

    if (setTask) {
      // @ts-ignore
      mergeField.reminder = {
        assignee,
        reminderDate,
      };
    }

    if (existingMergeField) {
      const mergeFieldToUpdate = {
        ...existingMergeField,
        ...mergeField,
        // The selected text will never ever change in the context of a merge field update.
        selectedText: existingMergeField.selectedText,
      };

      // We only want to update if we have made changes to the merge field.
      if (
        JSON.stringify(mergeFieldToUpdate) ===
        JSON.stringify(existingMergeField)
      ) {
        close();
        return;
      }

      const updatedMergeField = await updateMergeField(
        {
          ...existingMergeField,
          ...mergeField,
        },
        isQuestionnaire ? "Agreement Property" : "Merge Field"
      ).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

      if (!isQuestionnaire && !mergeField.setValueLater) {
        handleUpdate(updatedMergeField.editorMarkNodeId, updatedMergeField);
      }
    } else {
      let createdMergeField = await createMergeField(
        mergeField,
        partyId,
        docId,
        isQuestionnaire ? "Agreement Property" : "Merge Field"
      ).catch((error) => {
        console.error(error);
        setIsLoading(false);
      });

      if (!createdMergeField) throw new Error("Error creating Merge field.");

      // If we are in an agreement and the merge field is of type party information, we can calculate it
      // assuming we have parties.
      if (
        !documentIsTemplate &&
        createdMergeField.type === "partyInformation"
      ) {
        const agreement = state.agrs.find((/** @type {*} */ a) => !a.parentID);
        if (agreement && agreement?.ents?.length && state?.parties?.length) {
          // Add the role to the party.
          const parties = agreement.ents.map((/** @type {*} */ x) => {
            const party = state.parties.find(
              (/** @type {*} */ p) => p._id === x.entID
            );

            return {
              ...party,
              role: x.role,
            };
          });

          const organizationsWithData =
            /** @type {{ _id: string; shortName: string }[]} */ ([]);
          for (const { orgID: organizationId } of parties) {
            const getOrganizationWithDataResponse = await axios.get(
              `${state.settings.api}org/${organizationId}`
            );
            const organizationWithData =
              getOrganizationWithDataResponse.data.data;
            organizationsWithData.push(organizationWithData);
          }

          const calculatedValue = calculateMergeFieldValue(
            createdMergeField,
            parties,
            organizationsWithData
          );

          if (calculatedValue) {
            createdMergeField = await updateMergeField(
              { ...createdMergeField, displayValue: calculatedValue },
              isQuestionnaire ? "Agreement Property" : "Merge Field"
            ).catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
          }
        }
      }

      // If the questionnaire drawer is opened we use this to select the merge field we have just created.
      dispatch({
        type: "CREATED_MERGE_FIELD",
        payload: createdMergeField,
      });

      if (!isQuestionnaire) {
        handleSubmit(createdMergeField.editorMarkNodeId, createdMergeField);
      }
    }

    await loadDocumentMergeFields();
    await loadDocumentPartyRoles();

    close();
  };

  const handleCloseListOptionsDialog = () => {
    setOpenListOptionsDialog(false);
  };

  const handleSubmitListOptionsDialog = (
    /** @type {import("./constants").ListOption[]} */ listOptions,
    /** @type {boolean} */ allowSelectingMultipleListOptions
  ) => {
    setAllowSelectingMultipleListOptions(allowSelectingMultipleListOptions);

    if (listOptions.length) {
      const [firstListOption] = listOptions;
      setSelectedListOptionsIds([firstListOption.id]);
      setMergeFieldValue(firstListOption.mergeFieldValue);
      setIsList(true);
    } else {
      setSelectedListOptionsIds([]);
      setIsList(false);
    }

    setListOptions(listOptions);
    handleCloseListOptionsDialog();
  };

  const closeNewConditionDialog = () => {
    setOpenNewConditionDialog(false);
  };

  const handleNewConditionDialogSubmit = (
    /** @type {import("./dialogs/NewConditionDialog/condition").Condition} */ condition,
    /** @type {"create" | "update"} */ type
  ) => {
    switch (type) {
      case "create":
        setConditions((conditions) => [...conditions, condition]);
        break;

      case "update":
        const updatedConditions = [...conditions];
        let conditionToUpdateIndex = updatedConditions.findIndex(
          (x) => x.id === condition.id
        );

        if (conditionToUpdateIndex === -1) {
          throw new Error("Condition not found.");
        }

        updatedConditions[conditionToUpdateIndex] = condition;

        setConditions(updatedConditions);
        break;

      default:
        throw new Error("Invalid type.");
    }
  };

  const getConditionSummaryHtml = (
    /** @type {import("./dialogs/NewConditionDialog/condition").Condition} */ condition
  ) => {
    const { baseValueName, conditions, value } = getConditionSummary(condition);

    return (
      <>
        <span style={{ fontSize: "14px", wordBreak: "break-word" }}>
          When <b>{baseValueName}</b>{" "}
          {conditions.map((condition, index) => {
            return (
              <Fragment key={index}>
                {condition.operatorLabel}{" "}
                <b>
                  {(() => {
                    if (condition.comparisonValues instanceof Array) {
                      return condition.comparisonValues.join(", ");
                    }

                    return getMergeFieldDisplayValue(
                      condition.comparisonValues
                    );
                  })()}
                </b>{" "}
              </Fragment>
            );
          })}
          then the value is <b>"{value}"</b>.
        </span>
      </>
    );
  };

  const disableSetOrganizationAsRoleCheckbox = () => {
    const mergeField = documentMergeFields.find(
      (mf) => mf.setOrganizationAsRole
    );
    return mergeField;
  };

  useEffect(() => {
    if (existingMergeField) {
      setMergeField(existingMergeField);
    }
  }, [existingMergeField]);

  if (open) {
    if (isLoading && !isQuestionnaire) {
      return (
        <Grid
          container
          sx={{
            justifyContent: "center",
            width: "333px",
            position: "fixed",
            right: "20px",
            top: "75px",
            borderRadius: "30px",
            backgroundColor: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(10px)",
            zIndex,
            padding: "13px 0 8px 0",
            border: "1px solid" + theme.palette.grey[200],
            boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
          }}
        >
          <Box
            sx={{
              my: 12,
            }}
          >
            <CanveoCircularProgress />
          </Box>
        </Grid>
      );
    } else {
      return (
        <>
          <Grid
            container
            sx={
              isQuestionnaire
                ? {}
                : {
                    width: "333px",
                    position: "fixed",
                    right: "20px",
                    top: "75px",
                    borderRadius: "30px",
                    backgroundColor: "rgba(255,255,255,0.7)",
                    backdropFilter: "blur(10px)",
                    zIndex,
                    padding: "13px 0 8px 0",
                    border: "1px solid" + theme.palette.grey[200],
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 3px 24px 0px",
                  }
            }
          >
            {!isQuestionnaire && (
              <>
                <Grid container direction={"row"}>
                  <Grid item xs={10}></Grid>

                  <Grid item xs={1} sx={{ marginLeft: "15px" }}>
                    <IconButton onClick={() => close()}>
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                          fontSize: "14px",
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>

                <Grid container direction={"row"} alignItems={"center"}>
                  <Grid item xs={2} sx={{ marginLeft: "10px" }}>
                    <FontAwesomeIcon
                      icon={faFileImport}
                      style={{
                        fontSize: "22px",
                        padding: "9px 16px",
                        color: "#5B5B5B",
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sx={{ marginLeft: "35px" }}>
                    <b>Add Merge Field</b>
                  </Grid>
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>
              </>
            )}

            {step === "default" && (
              <>
                {devMode && `Current Step: ${step}`}
                <Grid container direction={"row"} justifyContent={"center"}>
                  <Autocomplete
                    sx={{ width: "286px" }}
                    options={mergeFields}
                    value={mergeFields.find(
                      (mergeField) =>
                        mergeField.label === existingMergeField?.name
                    )}
                    autoHighlight
                    openOnFocus={true}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    filterOptions={(options, params) => {
                      let filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value.
                      const isExisting = options.some(
                        (option) => inputValue === option.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered = filtered.filter(
                          (option) => option.type !== "action"
                        );
                        if (includeCreateAction) {
                          filtered.push({
                            label: `Create "${inputValue}" as new ${
                              isQuestionnaire
                                ? "Agreement Property"
                                : "Merge Field"
                            }`,
                            value: inputValue,
                            type: "action",
                            icon: faCirclePlus,
                          });
                        }
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          autoFocus
                          label={
                            isQuestionnaire
                              ? "Select Property"
                              : "Select Merge Field"
                          }
                          placeholder={
                            isQuestionnaire
                              ? "Property Name ..."
                              : "Merge Field Name ..."
                          }
                          variant="outlined"
                        />
                      </>
                    )}
                    renderOption={(props, option) => {
                      if (option.type === "action") {
                        return (
                          <Box
                            component="li"
                            {...props}
                            // @ts-ignore
                            key={props["data-option-index"]}
                          >
                            <FontAwesomeIcon
                              // @ts-ignore
                              icon={option.icon}
                            />
                            <b style={{ marginLeft: "13px" }}>{option.label}</b>
                          </Box>
                        );
                      }
                      return (
                        <Box
                          component="li"
                          {...props} // @ts-ignore
                          key={props["data-option-index"]}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#5B5B5B" }}
                            // @ts-ignore
                            icon={option.icon}
                          />
                          <div
                            style={{
                              marginLeft: "12px",
                              marginRight: "8px",
                            }}
                          >
                            {option.label}
                          </div>
                          {option.isTemplate && (
                            <FontAwesomeIcon
                              style={{ color: "#5B5B5B" }}
                              // @ts-ignore
                              icon={faSparkles}
                            />
                          )}
                        </Box>
                      );
                    }}
                    onChange={(_, option) => {
                      if (!option) {
                        setMergeFieldName("");
                        setPartyRole("");
                        setWizardQuestion("");
                        setWizardGuidance("");
                        setDefaultStepContinueButton("Next");
                      } else {
                        switch (option.type) {
                          case "regular":
                            // Regular Merge Fields always need to have an ID.
                            if (!option.id) {
                              throw new Error(
                                "Missing Merge Field ID on option."
                              );
                            }

                            setMergeFieldType("regular");

                            // If user has picked a regular Merge Field instance, we allow him to immediately
                            // submit.
                            if (!option.isTemplate) {
                              setMergeFieldOriginId(option.id);
                              setDefaultStepContinueButton("Submit");
                            }
                            // If user has picked a regular Merge Field template, we need to prefill all the
                            // fields with the content.
                            else {
                              setMergeFieldOriginId(option.id);
                              loadMergeField(option.id);
                              // Template needs to have a different name.
                              if (isQuestionnaire) {
                                // @ts-ignore
                                setMergeFieldName(option.mergeField.name);
                                setSaveMergeField(false);
                              } else {
                                setMergeFieldName("");
                              }

                              setStep("new");
                            }

                            break;

                          case "action":
                            setMergeFieldType("regular");
                            setMergeFieldName(option.value);
                            setWizardQuestion(`What is the ${option.value}?`);
                            setWizardGuidance(
                              `Please provide the ${option.value}.`
                            );
                            setStep("new");

                            if (option.value) {
                              setTimeout(() => {
                                if (
                                  mergeFieldTypeAutoCompleteInputField.current
                                ) {
                                  // @ts-ignore
                                  mergeFieldTypeAutoCompleteInputField.current.focus();
                                }
                              }, 50);
                            }

                            break;

                          case "partyInformation":
                            if (!option.isTemplate) {
                              // Party Information instance Merge Fields always need to have an ID.
                              if (!option.id) {
                                throw new Error(
                                  "Missing Merge Field ID on option."
                                );
                              }

                              setMergeFieldOriginId(option.id);
                              setDefaultStepContinueButton("Submit");
                            } else {
                              setMergeFieldType("partyInformation");
                              setMergeFieldName(option.value);
                            }

                            break;
                          default:
                            throw new Error("Invalid type.");
                        }
                      }
                    }}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                {mergeFieldName && (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Autocomplete
                        sx={{ width: "286px" }}
                        openOnFocus={true}
                        autoHighlight
                        value={{
                          title: partyRole,
                          inputValue: undefined,
                          disabled: false,
                        }}
                        autoFocus
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        freeSolo={documentIsTemplate}
                        getOptionDisabled={(option) => option.disabled}
                        options={
                          documentPartyRoles.map((role) => ({
                            title: role.label,
                            inputValue: undefined,
                            disabled: false,
                          })) || defaultPartyRoles
                        }
                        onChange={(_, newValue) => {
                          if (typeof newValue === "string") {
                            // Code should never reach this.
                          } else if (newValue?.inputValue) {
                            // Create a new value from the user input.
                            setPartyRole(newValue.inputValue);
                            const alreadyExists = documentMergeFields.find(
                              (x) =>
                                x.name === mergeFieldName &&
                                x.partyRole === newValue.inputValue
                            );
                            setDisplayPartyRoleError(Boolean(alreadyExists));
                          } else {
                            setPartyRole(newValue?.title || "");
                            const alreadyExists = documentMergeFields.find(
                              (x) =>
                                x.name === mergeFieldName &&
                                x.partyRole === newValue?.title
                            );

                            setDisplayPartyRoleError(Boolean(alreadyExists));
                          }
                        }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          const { inputValue } = params;
                          if (documentIsTemplate && inputValue === "") {
                            filtered.unshift({
                              disabled: true,
                              inputValue: inputValue,
                              title: `Start typing to create a role`,
                            });
                          }
                          // Suggest the creation of a new value.
                          const isExisting = options.some(
                            (option) => inputValue === option.title
                          );
                          if (inputValue !== "" && !isExisting) {
                            // We only allow creating new roles if we are in a template.
                            if (documentIsTemplate) {
                              filtered.push({
                                inputValue,
                                title: `Add "${inputValue}" role`,
                                system: true,
                              });
                            }
                          }

                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input.
                          if (typeof option === "string") {
                            return option;
                          }
                          // Add "xxx" option created dynamically.
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option.
                          return option.title;
                        }}
                        renderOption={(props, option) => {
                          return <li {...props}>{option.title}</li>;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            autoFocus
                            label="Party Role"
                            placeholder="Select Applicable Party ..."
                            variant="outlined"
                            error={displayPartyRoleError}
                            helperText={
                              displayPartyRoleError &&
                              `Merge Field "${mergeFieldName}" for role ${partyRole} already exists. Click "cancel" and select it from the list.`
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )}

                {mergeFieldName && partyRole && (
                  <>
                    {/* Commented out for the Octorber 2023 release. */}
                    {/* <Grid container direction={"row"}>
                      <Grid item xs={1}></Grid>

                      <Grid item xs={1}>
                        <Checkbox
                          id={
                            "ask-counterparty-to-complete-or-confirm-merge-field"
                          }
                          checked={askCounterPartyToCompleteOrConfirmMergeField}
                          onChange={() => {
                            setAskCounterPartyToCompleteOrConfirmMergeField(
                              !askCounterPartyToCompleteOrConfirmMergeField
                            );
                          }}
                        ></Checkbox>
                      </Grid>

                      <Grid item xs={1}></Grid>

                      <Grid item xs={8}>
                        <span
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() => {
                            document
                              .getElementById(
                                "ask-counterparty-to-complete-or-confirm-merge-field"
                              )
                              ?.click();
                          }}
                        >
                          Ask {partyRole} to complete or confirm this
                          {isQuestionnaire ? " question" : " merge field"}
                        </span>
                      </Grid>

                      <Grid item xs={1}></Grid>
                    </Grid> */}

                    {documentIsTemplate && (
                      <Grid container direction={"row"}>
                        <Grid item xs={1}></Grid>

                        <Grid item xs={1}>
                          <Checkbox
                            id={"set-org-as-party"}
                            disabled={Boolean(
                              disableSetOrganizationAsRoleCheckbox()
                            )}
                            checked={setOrganizationAsRole}
                            onChange={() => {
                              setSetOrganizationAsRole(!setOrganizationAsRole);
                            }}
                          />
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={8}>
                          <span
                            style={{
                              fontSize: "14px",
                              cursor: "pointer",
                              color: Boolean(
                                disableSetOrganizationAsRoleCheckbox()
                              )
                                ? theme.palette.grey[400]
                                : undefined,
                            }}
                            onClick={() => {
                              document
                                .getElementById("set-org-as-party")
                                ?.click();
                            }}
                          >
                            By default, set {organizationName} as the{" "}
                            {partyRole} when using this template
                            {Boolean(disableSetOrganizationAsRoleCheckbox())
                              ? ` (already set for ${
                                  disableSetOrganizationAsRoleCheckbox()
                                    ?.partyRole
                                })`
                              : ""}
                          </span>
                        </Grid>

                        <Grid item xs={1}></Grid>
                      </Grid>
                    )}

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )}

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    <Button onClick={close}>Cancel</Button>
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    <Button
                      disabled={
                        !(defaultStepContinueButton === "Submit") &&
                        !(
                          Boolean(mergeFieldName) &&
                          Boolean(partyRole) &&
                          !displayPartyRoleError
                        )
                      }
                      onClick={() => {
                        if (defaultStepContinueButton === "Submit") {
                          const mergeField = documentMergeFields.find(
                            (dmf) => dmf._id === mergeFieldOriginId
                          );

                          if (!mergeField) {
                            throw new Error("Merge field not found.");
                          }

                          handleSubmit(mergeField.editorMarkNodeId, mergeField);

                          close();
                        } else {
                          // if (canveoTier === "stable") {
                          if (true) {
                            submit();
                          } else {
                            setStep("submit");
                          }
                        }
                      }}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      {
                        // canveoTier === "stable"
                        true ? "Submit" : defaultStepContinueButton
                      }
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {step === "new" && (
              <>
                {devMode && `Current Step: ${step}`}
                <Grid container direction={"row"} justifyContent={"center"}>
                  <TextField
                    autoFocus={true}
                    value={mergeFieldName || ""}
                    sx={{ width: "286px" }}
                    label={
                      isQuestionnaire ? "Property Name" : "Merge Field Name"
                    }
                    variant="outlined"
                    error={displayMergeFieldNameError}
                    helperText={
                      displayMergeFieldNameError
                        ? `A ${
                            isQuestionnaire ? "Question" : "Merge Field"
                          } with that name already exists.`
                        : undefined
                    }
                    onChange={(event) => {
                      const found = documentMergeFields.find(
                        (x) =>
                          x.name === event.target.value &&
                          x.scope ===
                            (isQuestionnaire ? "questionnaire" : "document")
                      );
                      setDisplayMergeFieldNameError(Boolean(found));

                      setMergeFieldName(event.target.value || "");
                      if (!isEditing) {
                        setWizardQuestion(`What is the ${event.target.value}?`);
                        setWizardGuidance(
                          `Please provide the ${event.target.value}.`
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                {mergeFieldName && (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Autocomplete
                        sx={{ width: "286px" }}
                        openOnFocus={true}
                        options={mergeFieldValueTypes}
                        value={
                          mergeFieldValueTypes.find(
                            (x) => x.value === mergeFieldValueType
                          ) || null
                        }
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(_, option) => {
                          if (option) {
                            setMergeFieldValueType(option.value);
                          } else {
                            setMergeFieldValueType("");
                          }

                          setIsList(false);
                          setListOptions([]);
                          // Whenever we change the merge field type, we need to clear the conditions since they
                          // are dependent on this.
                          setConditions([]);
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              inputRef={mergeFieldTypeAutoCompleteInputField}
                              {...params}
                              label={
                                isQuestionnaire
                                  ? "Property Type"
                                  : "Merge Field Type"
                              }
                              placeholder="Select Type ..."
                              variant="outlined"
                            />
                          </>
                        )}
                        renderOption={(props, option) => {
                          return (
                            <Box component="li" {...props} sx={{}}>
                              <FontAwesomeIcon
                                style={{ color: "#5B5B5B" }}
                                // @ts-ignore
                                icon={option.icon}
                              />
                              <div
                                style={{
                                  marginLeft: "13px",
                                  marginRight: "13px",
                                }}
                              >
                                {option.label}
                              </div>
                            </Box>
                          );
                        }}
                      />
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )}

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    {!existingMergeField ? (
                      <Button
                        onClick={() => {
                          setStep("default");
                        }}
                        startIcon={
                          <FontAwesomeIcon
                            icon={faCircleArrowLeft}
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        }
                      >
                        Back
                      </Button>
                    ) : (
                      <Button onClick={close}>Cancel</Button>
                    )}
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    <Button
                      disabled={
                        !(
                          mergeFieldName &&
                          mergeFieldValueType &&
                          !displayMergeFieldNameError
                        )
                      }
                      onClick={() => {
                        if (
                          mergeFieldValueType !== existingMergeField?.valueType
                        ) {
                          switch (mergeFieldValueType) {
                            case "freeText": {
                              setMergeFieldValue({
                                type: "freeText",
                                value: mergeFieldText,
                              });
                              break;
                            }
                            case "date": {
                              let isoStringDate =
                                extractIsoStringDateFromText(mergeFieldText);

                              // If no date is found, by default we set the current date as the default.
                              if (!isoStringDate) {
                                // isoStringDate = new Date().toISOString();
                                isoStringDate = ""; // Empty value instead of current date.
                              }

                              setMergeFieldValue({
                                type: "date",
                                value: isoStringDate,
                              });
                              break;
                            }
                            case "duration": {
                              const number =
                                extractNumberFromText(mergeFieldText);
                              setMergeFieldValue({
                                type: "duration",
                                durationValue: number,
                                // Days is the default duration unit.
                                durationUnit: { label: "Days", value: "days" },
                              });
                              break;
                            }
                            case "number": {
                              const number =
                                extractNumberFromText(mergeFieldText);
                              setMergeFieldValue({
                                type: "number",
                                value: number,
                              });
                              break;
                            }
                            case "currency": {
                              const number =
                                extractNumberFromText(mergeFieldText);
                              setMergeFieldValue({
                                type: "currency",
                                currencyValue: number,
                                currencyUnit: {
                                  label: "",
                                  value: "",
                                },
                              });
                              break;
                            }
                            case "percentage": {
                              const number =
                                extractNumberFromText(mergeFieldText);
                              setMergeFieldValue({
                                type: "percentage",
                                value: number,
                              });
                              break;
                            }
                            default: {
                              throw new Error(
                                `Invalid Merge Field type: ${mergeFieldType}.`
                              );
                            }
                          }
                        }

                        if (isQuestionnaire) {
                          setStep("questionnaire");
                        } else {
                          setStep("currentValue");
                        }
                      }}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {step === "currentValue" && (
              <>
                {devMode && `Current Step: ${step}`}
                {isList ? (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      {!dontSetValueNow ? (
                        <>
                          {allowSelectingMultipleListOptions ? (
                            <>
                              <Autocomplete
                                multiple
                                sx={{ width: "286px" }}
                                options={listOptions}
                                value={
                                  listOptions.filter((option) =>
                                    selectedListOptionsIds.includes(option.id)
                                  ) || null
                                }
                                autoHighlight
                                getOptionLabel={(option) =>
                                  getMergeFieldDisplayValue(
                                    option.mergeFieldValue
                                  )
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    const selectedOptionsIds = option.map(
                                      (o) => o.id
                                    );
                                    setSelectedListOptionsIds(
                                      selectedOptionsIds
                                    );
                                  } else {
                                    setSelectedListOptionsIds([]);
                                    setMergeFieldValue(
                                      getMergeFieldDefaultValue(
                                        mergeFieldValueType
                                      )
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label={
                                        isQuestionnaire
                                          ? "Default Value"
                                          : "Current Value"
                                      }
                                      placeholder={
                                        isQuestionnaire
                                          ? "Select Default Value ..."
                                          : "Select Current Value ..."
                                      }
                                      variant="outlined"
                                    />
                                  </>
                                )}
                              />
                            </>
                          ) : (
                            <>
                              <Autocomplete
                                sx={{ width: "286px" }}
                                options={listOptions}
                                value={
                                  listOptions.find(
                                    (option) =>
                                      option.id === selectedListOptionsIds[0]
                                  ) || null
                                }
                                autoHighlight
                                getOptionLabel={(option) =>
                                  getMergeFieldDisplayValue(
                                    option.mergeFieldValue
                                  )
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    setSelectedListOptionsIds([option.id]);
                                    setMergeFieldValue(option.mergeFieldValue);
                                  } else {
                                    setSelectedListOptionsIds([]);
                                    setMergeFieldValue(
                                      getMergeFieldDefaultValue(
                                        mergeFieldValueType
                                      )
                                    );
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label={
                                        isQuestionnaire
                                          ? "Default Value"
                                          : "Current Value"
                                      }
                                      placeholder={
                                        isQuestionnaire
                                          ? "Select Default Value ..."
                                          : "Select Current Value ..."
                                      }
                                      variant="outlined"
                                    />
                                  </>
                                )}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <TextField
                          disabled={true}
                          placeholder={
                            isQuestionnaire
                              ? "Add an (optional) default value"
                              : ""
                          }
                          label={
                            isQuestionnaire ? "Default Value" : "Current Value"
                          }
                          value={"[value to be set later]"}
                          sx={{ width: "286px" }}
                          variant="outlined"
                        />
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    {mergeFieldValue.type === "freeText" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <TextField
                              placeholder={
                                isQuestionnaire
                                  ? "Add an (optional) default value"
                                  : ""
                              }
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              autoFocus={true}
                              value={mergeFieldValue.value}
                              sx={{ width: "286px" }}
                              variant="outlined"
                              onChange={(event) => {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  type: "freeText",
                                  value: event.target.value,
                                });
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}

                    {mergeFieldValue.type === "date" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <TextField
                              type="date"
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              placeholder={
                                isQuestionnaire
                                  ? "Add an (optional) default value"
                                  : ""
                              }
                              autoFocus={true}
                              value={
                                mergeFieldValue.value
                                  ? format(
                                      new Date(mergeFieldValue.value),
                                      "yyyy-MM-dd"
                                    )
                                  : ""
                              }
                              sx={{ width: "286px" }}
                              variant="outlined"
                              onChange={(event) => {
                                const date = new Date(event.target.value);
                                if (!isNaN(date.getDate())) {
                                  const isoStringDate = date.toISOString();
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: isoStringDate,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: "",
                                  });
                                }
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}

                    {mergeFieldValue.type === "duration" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <Grid
                              item
                              xs={isQuestionnaire ? 3 : 5}
                              sx={{ paddingLeft: "20px" }}
                            >
                              <TextField
                                type="number"
                                placeholder={
                                  isQuestionnaire
                                    ? "Add an (optional) default value"
                                    : ""
                                }
                                label={
                                  isQuestionnaire
                                    ? "Default Value"
                                    : "Current Value"
                                }
                                autoFocus={true}
                                value={mergeFieldValue.durationValue}
                                variant="outlined"
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationValue: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationValue: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={isQuestionnaire ? 5 : 7}
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <Autocomplete
                                options={durationUnits}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                value={
                                  durationUnits.find(
                                    (x) =>
                                      x.value ===
                                      mergeFieldValue.durationUnit.value
                                  ) || durationUnits[2]
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationUnit: option,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      durationUnit: {
                                        label: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      label="Duration Unit"
                                      placeholder="Select Duration Unit ..."
                                      variant="outlined"
                                    />
                                  </>
                                )}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}

                    {mergeFieldValue.type === "number" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <TextField
                              placeholder={
                                isQuestionnaire
                                  ? "Add an (optional) default value"
                                  : ""
                              }
                              type="number"
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              autoFocus={true}
                              value={mergeFieldValue.value}
                              sx={{ width: "286px" }}
                              variant="outlined"
                              onChange={(event) => {
                                const number = Number(event.target.value);
                                if (number) {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: event.target.value,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    value: "",
                                  });
                                }
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}

                    {mergeFieldValue.type === "currency" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <Grid
                              item
                              xs={isQuestionnaire ? 3 : 5}
                              sx={{ paddingLeft: "20px" }}
                            >
                              <Autocomplete
                                options={currencyCodes}
                                autoHighlight
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) =>
                                  option.value === value.value
                                }
                                value={
                                  currencyCodes.find(
                                    (x) =>
                                      x.value ===
                                      mergeFieldValue.currencyUnit.value
                                  ) || null
                                }
                                onChange={(_, option) => {
                                  if (option) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyUnit: option,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyUnit: {
                                        label: "",
                                        value: "",
                                      },
                                    });
                                  }
                                }}
                                renderInput={(params) => (
                                  <>
                                    <TextField
                                      {...params}
                                      autoFocus={true}
                                      label="Currency Unit"
                                      placeholder="Select Currency Unit ..."
                                      variant="outlined"
                                    />
                                  </>
                                )}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={isQuestionnaire ? 5 : 7}
                              sx={{
                                paddingLeft: "20px",
                                paddingRight: "20px",
                              }}
                            >
                              <TextField
                                placeholder={
                                  isQuestionnaire
                                    ? "Add an (optional) default value"
                                    : ""
                                }
                                type="number"
                                label={
                                  isQuestionnaire
                                    ? "Default Value"
                                    : "Current Value"
                                }
                                value={mergeFieldValue.currencyValue}
                                variant="outlined"
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyValue: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      currencyValue: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}

                    {mergeFieldValue.type === "percentage" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {!dontSetValueNow ? (
                          <>
                            <Grid
                              item
                              xs={isQuestionnaire ? 6 : 10}
                              sx={{ paddingLeft: "20px" }}
                            >
                              <TextField
                                placeholder={
                                  isQuestionnaire
                                    ? "Add an (optional) default value"
                                    : ""
                                }
                                type="number"
                                label={
                                  isQuestionnaire
                                    ? "Default Value"
                                    : "Current Value"
                                }
                                autoFocus={true}
                                value={mergeFieldValue.value}
                                variant="outlined"
                                sx={{ width: "250px" }}
                                onChange={(event) => {
                                  const number = Number(event.target.value);
                                  if (number) {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      value: event.target.value,
                                    });
                                  } else {
                                    setMergeFieldValue({
                                      ...mergeFieldValue,
                                      value: "",
                                    });
                                  }
                                }}
                              />
                            </Grid>

                            <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
                              <div>{"%"}</div>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <TextField
                              disabled={true}
                              label={
                                isQuestionnaire
                                  ? "Default Value"
                                  : "Current Value"
                              }
                              value={"[value to be set later]"}
                              sx={{ width: "286px" }}
                              variant="outlined"
                            />
                          </>
                        )}
                      </Grid>
                    )}
                  </>
                )}
                <Grid container direction={"row"}>
                  <br />
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      color: "#7243DD",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (!isList) {
                        if (!listOptions.length) {
                          const listOption = { id: uuid(), mergeFieldValue };
                          setListOptions([listOption]);
                          setSelectedListOptionsIds([listOption.id]);
                        }
                      }
                      setOpenListOptionsDialog(true);
                    }}
                  >
                    Turn into a list ...
                  </div>
                </Grid>
                <Grid container direction={"row"}>
                  <br />
                </Grid>
                {!isQuestionnaire && documentIsTemplate && (
                  <>
                    <Grid container direction={"row"}>
                      <Grid item xs={1}></Grid>

                      <Grid item xs={1}>
                        <Checkbox
                          id="dont-set-value-now"
                          checked={dontSetValueNow}
                          onChange={() => {
                            setDontSetValueNow(!dontSetValueNow);
                          }}
                        ></Checkbox>
                      </Grid>

                      <Grid item xs={1}></Grid>

                      <Grid item xs={8}>
                        <span
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() => {
                            document
                              .getElementById("dont-set-value-now")
                              ?.click();
                          }}
                        >
                          Don't insert value now (only insert when creating a
                          new agreement)
                        </span>
                      </Grid>

                      <Grid item xs={1}></Grid>
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )}

                {/* Commented out for the Octorber 2023 release. */}
                {/* {(isQuestionnaire || !documentIsTemplate) && (
                  <>
                    <Grid container direction={"row"} alignItems={"center"}>
                      <Grid item xs={isQuestionnaire ? 3 : 1}></Grid>

                      <>
                        <Grid item xs={1}>
                          <Checkbox
                            id="pull-value-from-connected-system"
                            checked={pullValueFromConnectedSystem}
                            onChange={() =>
                              setPullValueFromConnectedSystem(
                                !pullValueFromConnectedSystem
                              )
                            }
                          ></Checkbox>
                        </Grid>

                        {!isQuestionnaire && <Grid item xs={1}></Grid>}

                        <Grid item xs={isQuestionnaire ? 5 : 8}>
                          <span
                            style={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => {
                              document
                                .getElementById(
                                  "pull-value-from-connected-system"
                                )
                                ?.click();
                            }}
                          >
                            Fill with data from another system
                          </span>
                        </Grid>
                      </>

                      <Grid item xs={1}></Grid>
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )} */}

                {!isQuestionnaire &&
                  documentIsTemplate &&
                  canveoTier === "experimental" && (
                    <>
                      <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item xs={isQuestionnaire ? 3 : 1} />

                        <Grid item xs={1}>
                          <Checkbox
                            id="set-value-based-on-condition"
                            checked={valueBasedOnCondition}
                            onChange={() => {
                              setValueBasedOnCondition(!valueBasedOnCondition);
                            }}
                          />
                        </Grid>

                        {!isQuestionnaire && <Grid item xs={1} />}

                        <Grid item xs={isQuestionnaire ? 5 : 8}>
                          <span
                            style={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => {
                              document
                                .getElementById("set-value-based-on-condition")
                                ?.click();
                            }}
                          >
                            Change value depending on conditions
                          </span>
                        </Grid>

                        <Grid item xs={1} />
                      </Grid>

                      <Grid container direction={"row"}>
                        <br />
                      </Grid>
                    </>
                  )}

                {valueBasedOnCondition && (
                  <>
                    {!conditions.length ? (
                      <Grid container justifyContent={"center"}>
                        <div
                          onClick={() => {
                            setConditionToEdit(null);
                            setOpenNewConditionDialog(true);
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                            outline: "#7243dd dashed 1px",
                            borderRadius: "20px",
                            height: "60px",
                            width: "286px",
                            color: "#7243dd",
                            textAlign: "center",
                          }}
                        >
                          <Grid item xs={1} />

                          <Grid item xs={1}>
                            <FontAwesomeIcon
                              // @ts-ignore
                              icon={faCirclePlus}
                              style={{
                                fontSize: "24px",
                              }}
                            />
                          </Grid>

                          <Grid item xs={6} style={{ fontSize: "14px" }}>
                            <div>Add Condition ...</div>
                          </Grid>

                          <Grid item xs={4} />
                        </div>
                      </Grid>
                    ) : (
                      <>
                        {conditions.map((condition, index) => (
                          <Grid key={condition.id} container>
                            <Grid container justifyContent={"center"}>
                              <div
                                style={{
                                  padding: "20px 10px 5px 20px",
                                  outline: "#7243dd dashed 1px",
                                  borderRadius: "20px",
                                  width: "286px",
                                  color: "#7243dd",
                                }}
                              >
                                <Grid container direction="row">
                                  <div>
                                    {getConditionSummaryHtml(condition)}
                                  </div>
                                </Grid>

                                <Grid container direction="row">
                                  <Grid item xs={10} />

                                  <Grid item xs={1}>
                                    <IconButton
                                      onClick={() => {
                                        setConditionToEdit(condition);
                                        setOpenNewConditionDialog(true);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#7243dd"
                                        // @ts-ignore
                                        icon={faPen}
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      />
                                    </IconButton>
                                  </Grid>

                                  <Grid item xs={1}>
                                    <IconButton
                                      onClick={() => {
                                        setConditions([
                                          ...conditions.slice(0, index),
                                          ...conditions.slice(index + 1),
                                        ]);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#7243dd"
                                        // @ts-ignore
                                        icon={faCircleXmark}
                                        style={{
                                          fontSize: "14px",
                                        }}
                                      />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>

                            {index !== conditions.length - 1 ? (
                              <Grid
                                container
                                sx={{ marginTop: "10px", marginBottom: "10px" }}
                              >
                                <Grid
                                  item
                                  xs={5}
                                  sx={{
                                    paddingLeft: "38px",
                                  }}
                                >
                                  <hr
                                    style={{
                                      height: "1px",
                                      backgroundColor: "#ccc",
                                      border: "none",
                                    }}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={2}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                  }}
                                >
                                  OR
                                </Grid>

                                <Grid item xs={5} sx={{ paddingRight: "38px" }}>
                                  <hr
                                    style={{
                                      height: "1px",
                                      backgroundColor: "#ccc",
                                      border: "none",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        ))}

                        <Grid container>
                          <br />
                        </Grid>

                        {/* TODO: Check the limit of conditions. Capped at 3 for the time being. */}
                        {conditions.length < 3 && (
                          <Grid
                            container
                            direction="row"
                            justifyContent={"flex-end"}
                          >
                            <span
                              style={{
                                color: "#7243dd",
                                fontSize: "12px",
                                marginRight: "25px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setConditionToEdit(null);
                                setOpenNewConditionDialog(true);
                              }}
                            >
                              Add Condition ...
                            </span>
                          </Grid>
                        )}
                      </>
                    )}

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>

                    {openNewConditionDialog && (
                      <NewConditionDialog
                        isList={isList}
                        listOptions={listOptions}
                        condition={conditionToEdit}
                        submit={handleNewConditionDialogSubmit}
                        openNewConditionDialog={openNewConditionDialog}
                        closeNewConditionDialog={closeNewConditionDialog}
                        isLoading={isLoading}
                        docId={docId}
                        mergeFieldValueType={mergeFieldValueType}
                      />
                    )}
                  </>
                )}
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    <Button
                      onClick={() => {
                        setStep("new");
                      }}
                      startIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowLeft}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    <Button
                      disabled={
                        isQuestionnaire
                          ? false
                          : !(
                              mergeFieldName &&
                              (dontSetValueNow ||
                                mergeFieldValueIsValid(mergeFieldValue))
                            )
                      }
                      onClick={() => {
                        if (isQuestionnaire) {
                          setStep("visibility");
                        } else {
                          setStep(
                            conditions.length ? "visibility" : "questionnaire"
                          );
                        }
                      }}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
                {openListOptionsDialog && (
                  <ListOptionsDialog
                    open={openListOptionsDialog}
                    existingListOptions={listOptions}
                    mergeFieldValueType={mergeFieldValueType}
                    close={handleCloseListOptionsDialog}
                    submit={handleSubmitListOptionsDialog}
                    allowSelectingMultipleListOptionsOption={isQuestionnaire}
                    allowSelectingMultipleListOptions={
                      allowSelectingMultipleListOptions
                    }
                    setAllowSelectingMultipleListOptions={
                      setAllowSelectingMultipleListOptions
                    }
                  />
                )}
              </>
            )}

            {step === "questionnaire" && (
              <>
                {devMode && `Current Step: ${step}`}
                <Grid container direction={"row"} justifyContent={"center"}>
                  <TextField
                    autoFocus={true}
                    defaultValue={wizardQuestion}
                    sx={{ width: "286px" }}
                    label={
                      isQuestionnaire ? "Question Text" : "Wizard Question"
                    }
                    variant="outlined"
                    onChange={(event) => {
                      setWizardQuestion(event.target.value || "");
                    }}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                <Grid container direction={"row"} justifyContent={"center"}>
                  <TextField
                    multiline
                    defaultValue={wizardGuidance}
                    rows={2}
                    sx={{
                      width: "286px",
                    }}
                    label={
                      isQuestionnaire ? "Question Guidance" : "Wizard Guidance"
                    }
                    variant="outlined"
                    onChange={(event) => {
                      setWizardGuidance(event.target.value || "");
                    }}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                {documentIsTemplate && (
                  <>
                    {isQuestionnaire && (
                      <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        mb={4}
                      >
                        <Grid item xs={isQuestionnaire ? 3 : 1} />

                        <Grid item xs={1}>
                          <Checkbox
                            id="include-this-question-in-the-agreement-creation-flow"
                            // checked={saveMergeField}
                            // onChange={() => {
                            //   setSaveMergeField(!saveMergeField);
                            // }}
                          />
                        </Grid>

                        {!isQuestionnaire && <Grid item xs={1} />}

                        <Grid item xs={isQuestionnaire ? 6 : 8}>
                          <span
                            style={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => {
                              document
                                .getElementById(
                                  "include-this-question-in-the-agreement-creation-flow"
                                )
                                ?.click();
                            }}
                          >
                            Ask users to provide this information when creating
                            a new {agreement?.agrTypeID?.fullName?.at(0)}
                          </span>
                        </Grid>

                        <Grid item xs={1} />
                      </Grid>
                    )}
                  </>
                )}

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    <Button
                      onClick={() => {
                        setStep("currentValue");
                      }}
                      startIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowLeft}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    {documentIsTemplate ? (
                      <Button
                        disabled={!(wizardQuestion && wizardGuidance)}
                        onClick={() => {
                          if (isQuestionnaire) {
                            setStep("currentValue");
                          } else {
                            if (canveoTier === "stable") {
                              submit();
                            } else {
                              setStep("visibility");
                            }
                          }
                        }}
                        endIcon={
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        }
                      >
                        {canveoTier === "stable" ? "Submit" : "Next"}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          if (isQuestionnaire) {
                            setStep("currentValue");
                          } else {
                            submit();
                          }
                        }}
                        endIcon={
                          <FontAwesomeIcon
                            icon={faCircleArrowRight}
                            style={{
                              fontSize: "14px",
                            }}
                          />
                        }
                      >
                        {isQuestionnaire ? "Next" : "Submit"}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            {/* TODO: We will probably only want to display this step on an Agreement. */}
            {step === "visibility" && (
              <>
                {devMode && `Current Step: ${step}`}
                {/* Commented out for the Octorber 2023 release. */}
                {/* <Grid container direction={"row"} justifyContent={"center"}>
                  <Autocomplete
                    sx={{ width: "286px" }}
                    options={visibilities}
                    defaultValue={visibilities[0]}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    value={
                      visibilities.find(
                        (x) => x.value === visibilityForCounterparties
                      ) || null
                    }
                    onChange={(_, option) => {
                      if (option) {
                        setVisibilityForCounterparties(option.value);
                      } else {
                        setVisibilityForCounterparties("");
                      }
                    }}
                    renderInput={(params) => (
                      <>
                        <TextField
                          {...params}
                          autoFocus={true}
                          label="Visibility for Counterparties"
                          placeholder="Select visiblity ..."
                          variant="outlined"
                        />
                      </>
                    )}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                {visibilityForCounterparties ===
                  "visibleToSpecificCounterparty" && (
                  <>
                    <Grid container direction={"row"} justifyContent={"center"}>
                      <Autocomplete
                        sx={{ width: "286px" }}
                        openOnFocus={!isEditing}
                        options={defaultParties}
                        value={
                          defaultParties.find(
                            (x) => x.value === counterpartyName
                          ) || null
                        }
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        isOptionEqualToValue={(option, value) =>
                          option.value === value.value
                        }
                        onChange={(_, option) => {
                          if (option) {
                            setCounterpartyName(option.value);
                          } else {
                            setCounterpartyName("");
                          }
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              autoFocus={!isEditing}
                              {...params}
                              label="Counterparty Name"
                              placeholder="Counterparty name ..."
                              variant="outlined"
                            />
                          </>
                        )}
                      />
                    </Grid>

                    <Grid container direction={"row"}>
                      <br />
                    </Grid>
                  </>
                )}

                {visibilityForCounterparties ===
                  "visibleToSpecificCounterparty" &&
                  counterpartyName &&
                  !conditions.length && (
                    <>
                      <Grid container direction={"row"} alignItems={"center"}>
                        <Grid item xs={isQuestionnaire ? 3 : 1} />

                        <Grid item xs={1}>
                          <Checkbox
                            id={
                              "ask-counterparty-to-complete-or-confirm-merge-field"
                            }
                            checked={
                              askCounterPartyToCompleteOrConfirmMergeField
                            }
                            onChange={() => {
                              setAskCounterPartyToCompleteOrConfirmMergeField(
                                !askCounterPartyToCompleteOrConfirmMergeField
                              );
                            }}
                          />
                        </Grid>

                        {!isQuestionnaire && <Grid item xs={1}></Grid>}

                        <Grid item xs={isQuestionnaire ? 5 : 8}>
                          <span
                            style={{ fontSize: "14px", cursor: "pointer" }}
                            onClick={() => {
                              document
                                .getElementById(
                                  "ask-counterparty-to-complete-or-confirm-merge-field"
                                )
                                ?.click();
                            }}
                          >
                            Ask
                            {" " +
                              (defaultParties.find(
                                (x) => x.value === counterpartyName
                              )?.label || "") +
                              " "}
                            to complete or confirm
                            {isQuestionnaire
                              ? " the answer"
                              : " this Merge Field"}
                          </span>
                        </Grid>

                        <Grid item xs={1}></Grid>
                      </Grid>

                      <Grid container direction={"row"}>
                        <br />
                      </Grid>
                    </>
                  )} */}

                {!isQuestionnaire && canveoTier === "experimental" && (
                  <Grid container direction={"row"} alignItems={"center"}>
                    <Grid item xs={1} />

                    <Grid item xs={1}>
                      <Checkbox
                        id="field-contains-sensitive-information"
                        checked={containsSensitiveInformation}
                        onChange={() =>
                          setContainsSensitiveInformation(
                            !containsSensitiveInformation
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={1} />

                    <Grid item xs={8}>
                      <span
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          document
                            .getElementById(
                              "field-contains-sensitive-information"
                            )
                            ?.click();
                        }}
                      >
                        Field contains sensitive information
                      </span>
                    </Grid>

                    <Grid item xs={1} />
                  </Grid>
                )}

                <>
                  {documentIsTemplate && isQuestionnaire && (
                    <Grid
                      container
                      direction={"row"}
                      alignItems={"center"}
                      mb={4}
                    >
                      <Grid item xs={isQuestionnaire ? 3 : 1} />

                      <Grid item xs={1}>
                        <Checkbox
                          id={
                            "automatically-add-this-parameter-to-all-agreements"
                          }
                          defaultChecked={true}
                          // checked={saveMergeField}
                          // onChange={() => {
                          //   setSaveMergeField(!saveMergeField);
                          // }}
                        />
                      </Grid>

                      {!isQuestionnaire && <Grid item xs={1} />}

                      <Grid item xs={isQuestionnaire ? 5 : 8}>
                        <span
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() => {
                            document
                              .getElementById(
                                "automatically-add-this-parameter-to-all-agreements"
                              )
                              ?.click();
                          }}
                        >
                          Track this Property on all new{" "}
                          {agreement?.agrTypeID?.fullName?.at(0)} (whether they
                          are based on this template or uploaded files)
                        </span>
                      </Grid>

                      <Grid item xs={1} />
                    </Grid>
                  )}

                  <Grid container direction={"row"} alignItems={"center"}>
                    <Grid item xs={isQuestionnaire ? 3 : 1} />

                    <Grid item xs={1}>
                      <Checkbox
                        id={"save-merge-field"}
                        checked={saveMergeField}
                        onChange={() => {
                          setSaveMergeField(!saveMergeField);
                        }}
                      ></Checkbox>
                    </Grid>

                    {!isQuestionnaire && <Grid item xs={1} />}

                    <Grid item xs={isQuestionnaire ? 5 : 8}>
                      <span
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("save-merge-field")?.click();
                        }}
                      >
                        Save {isQuestionnaire ? " Property " : " Merge Field "}{" "}
                        as a template (for reuse in other agreements)
                      </span>
                    </Grid>

                    <Grid item xs={1} />
                  </Grid>

                  <Grid container direction={"row"} alignItems={"center"}>
                    <Grid item xs={isQuestionnaire ? 3 : 1} />

                    <Grid item xs={1}>
                      <Checkbox
                        id="set-paramter-task"
                        checked={setTask}
                        onChange={() => {
                          setSetTask(!setTask);
                        }}
                      />
                    </Grid>

                    {!isQuestionnaire && <Grid item xs={1} />}

                    <Grid item xs={isQuestionnaire ? 5 : 8}>
                      <span
                        style={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={() => {
                          document.getElementById("set-paramter-task")?.click();
                        }}
                      >
                        Set Reminder
                      </span>
                    </Grid>

                    <Grid item xs={1} />
                  </Grid>

                  {setTask && (
                    <>
                      <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        mt={2}
                      >
                        <Grid item xs={isQuestionnaire ? 3 : 1} />

                        <Grid item xs={6}>
                          <Autocomplete
                            options={assignees}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.email === value.email
                            }
                            renderInput={(params) => (
                              <>
                                <TextField
                                  {...params}
                                  label="Assignee"
                                  placeholder="Select Assignee ..."
                                  variant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                />
                              </>
                            )}
                            value={
                              assignees.find((x) => x._id === assignee?._id) ||
                              null
                            }
                            onChange={(_, value) => {
                              setAssignee(value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={1} />
                      </Grid>

                      <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        mt={2}
                      >
                        <Grid item xs={isQuestionnaire ? 3 : 1} />

                        <Grid item xs={6}>
                          <TextField
                            type="date"
                            label="Reminder Date"
                            placeholder="Reminder"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={reminderDate}
                            onChange={(event) => {
                              setReminderDate(event.target.value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={1} />
                      </Grid>
                    </>
                  )}
                </>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    <Button
                      onClick={() => {
                        conditions.length
                          ? setStep("currentValue")
                          : setStep("questionnaire");
                      }}
                      startIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowLeft}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    <Button
                      disabled={!Boolean(visibilityForCounterparties)}
                      onClick={submit}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      {existingMergeField ? "Update" : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {step === "submit" && (
              <>
                {devMode && `Current Step: ${step}`}
                <Grid container direction={"row"} justifyContent={"center"}>
                  <TextField
                    label="Current Value"
                    autoFocus={true}
                    defaultValue={getMergeFieldValue(mergeFieldValue)}
                    sx={{ width: "286px" }}
                    variant="outlined"
                    // TODO: We don't allow changing this value ATM.
                    // onChange={(event) => {
                    //   setMergeFieldCurrentValue(event.target.value);
                    // }}
                  />
                </Grid>

                <Grid container direction={"row"}>
                  <br />
                </Grid>

                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item sx={{ marginLeft: "20px" }}>
                    <Button
                      onClick={() => {
                        setStep("default");
                      }}
                      startIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowLeft}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Back
                    </Button>
                  </Grid>

                  <Grid item sx={{ marginRight: "20px" }}>
                    <Button
                      onClick={() => {
                        submit();
                      }}
                      endIcon={
                        <FontAwesomeIcon
                          icon={faCircleArrowRight}
                          style={{
                            fontSize: "14px",
                          }}
                        />
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </>
      );
    }
  }

  return <></>;
}
