import {
  faCheckCircle,
  faCircleArrowRight,
  faCircleLeft,
  faCircleRight,
  faClipboard,
  faComments,
  faEllipsisV,
  faRotateLeft,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $dfs } from "@lexical/utils";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import * as dayjs from "dayjs";
import { $getSelection, $isRangeSelection } from "lexical";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import useWorkFlowService from "../hooks/useWorkFlowService";
import { globalStore } from "../state/store";
import theme from "../theme/theme";
import DialogConfirmDelete from "./dialogs/DialogConfirmDelete";
import { REMOVE_MARK, REMOVE_WORKFLOW } from "./editor/commands";
import { $isMarkNode } from "./editor/nodes/MarkNode";
import { createComment } from "./editor/plugins/commenting";
import { PlainTextEditor } from "./editor/plugins/commenting/PlainTextEditor";
import { getClauseHTML, getClauseNode, useOnChange } from "./editor/utils";

/**
 * @typedef {object} OpenIssueCommentMenuProps
 * @property {import("./editor/plugins/OpenIssuesPlugin").OpenIssue} currentOpenIssue
 * @property {boolean} isTemplate
 * @property {string} docID
 * @property {boolean} isInEffect
 * @property {() => void} handleNextIssue
 * @property {() => void} handlePreviousIssue
 * @property {boolean} canMakeChanges
 */

/**
 * @param {OpenIssueCommentMenuProps} props
 */
export default function OpenIssueCommentMenu({
  currentOpenIssue,
  isTemplate,
  docID,
  isInEffect,
  handleNextIssue,
  handlePreviousIssue,
  canMakeChanges,
}) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [editor] = useLexicalComposerContext();

  const [mainAnchorElement, setMainAnchorElement] = useState(
    /** @type {* | null} */ (null)
  );
  const [commentAnchorElement, setCommentAnchorElement] = useState(
    /** @type {* | null} */ (null)
  );
  const [confirmDeleteThread, setConfirmDeleteThread] = useState(false);
  const [editModeIndex, setEditModeIndex] = useState(-1);
  const [commentText, setCommentText] = useState("");
  const [selectedComment, setSelectedComment] = useState(
    /** @type {* | null} */ (null)
  );
  const [confirmDeleteComment, setConfirmDeleteComment] = useState(
    /** @type {* | null} */ (null)
  );
  const [workflow, setWorkflow] = useState(
    /** @type {* | undefined} */ (undefined)
  );
  const [isResolved, setIsResolved] = useState(false);

  const { updateWorkFlow } = useWorkFlowService();

  const isPrivate = currentOpenIssue.openIssueType === "internalComment";

  useEffect(() => {
    const workflow = state.workflows.find(
      (/** @type {{ lid: string; }} */ wf) => currentOpenIssue.lid === wf.lid
    );

    workflow.comments = workflow.comments.sort(
      // @ts-ignore
      (a, b) => new Date(a.date) - new Date(b.date)
    );

    const isResolved = workflow.wfStatus === "resolved";

    setIsResolved(isResolved);
    setSubscribers(workflow.subscribers);
    setWorkflow(workflow);
    setMainAnchorElement(null);
    setCommentAnchorElement(null);
    setReplying(false);
  }, [currentOpenIssue.lid, state.workflows, isPrivate]);

  const doc = isTemplate
    ? state.templates.filter(
        (/** @type {{ _id: string; }} */ t) => t._id === docID
      )[0]
    : state.agrs.filter(
        (/** @type {{ _id: string; }} */ a) => a._id === docID
      )[0];

  const [replying, setReplying] = useState(false);

  const [content, setContent] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [subscribers, setSubscribers] = useState([]);

  const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);

  /**
   * @param {*} event
   */
  const onEscape = (event) => {
    event.preventDefault();
    return true;
  };

  const generateClauseHTML = () => {
    return editor.getEditorState().read(() => {
      const selection = $getSelection();
      if (!$isRangeSelection(selection)) return;
      const anchor = selection.anchor;
      const clauseNode = Boolean(anchor)
        ? getClauseNode(anchor.getNode())
        : null;
      // @ts-ignore
      const html = Boolean(clauseNode) ? getClauseHTML(clauseNode) : null;
      return Boolean(html) ? html : null;
    });
  };

  const submitReply = async () => {
    const newComment = createComment(content, state.user);
    const thisSub = {
      email: state.user.email,
      orgID: state.user.orgID,
      partyID: state.user.partyID,
      _id: state.user._id,
    };
    const updatedWorkFlow = {
      ...workflow,
      comments: [...workflow.comments, newComment],
      subscribers: [
        ...new Set([...workflow.subscribers, ...subscribers, thisSub]),
      ],
    };

    const emailRecipients = subscribers
      .filter((/** @type {*} */ sub) =>
        state.users.find((/** @type {*} */ u) => u._id === sub._id)
      )
      .map((/** @type {*} */ sub) => {
        const { password, role } = state.users.find(
          (/** @type {*} */ u) => u._id === sub._id
        );
        return {
          doc,
          recipient: { ...sub, role, password },
          content,
          isInternal: isPrivate,
          isTemplating: isTemplate,
          clauseHTML: generateClauseHTML(),
        };
      });

    const result = await updateWorkFlow(updatedWorkFlow, emailRecipients);
    if (!result) return;

    setWorkflow(result);
    setReplying(false);
  };

  /**
   *
   * @param {*} newStatus
   * @returns
   */
  const changeWorkFlowStatus = (newStatus) => {
    if (!["resolved", "deleted", "open"].includes(newStatus)) return;

    const comments = [...workflow.comments];

    if (newStatus === "deleted") {
      const commentMessage = "===DELETED===";

      const deleteComment = createComment(commentMessage, state.user);
      comments.push(deleteComment);
    } else if (!isPrivate) {
      editor.update(() => {
        const depthFirstSearch = $dfs();
        const markNodes = depthFirstSearch.filter(
          (x) =>
            $isMarkNode(x.node) &&
            x.node.getMarkType() === "publicComment" &&
            x.node.getIDs().includes(workflow.lid)
        );

        for (const { node } of markNodes) {
          let resolvedIds = node.getResolvedIds();

          if (newStatus === "resolved") {
            resolvedIds.push(workflow.lid);
          } else {
            resolvedIds = resolvedIds.filter(
              (/** @type {string} */ id) => id !== workflow.lid
            );
          }

          node.setResolvedIds(resolvedIds);
        }
      });
    }
    const updatedWorkFlow = {
      ...workflow,
      comments: comments,
      wfStatus: newStatus,
    };
    /** @type {*[]} */
    let emailRecipients = [];

    if (["open", "resolved"].includes(newStatus)) {
      const clauseHTML = generateClauseHTML();
      subscribers
        .filter(
          (/** @type {{ _id: string }} */ sub) =>
            state.users.find(
              (/** @type {{ _id: string; }} */ u) => u._id === sub._id
            ) && sub._id !== state.user._id //don't send to self
        ) // TODO: MISSING COUNTERPARTIES ON STATE.USERS (must include if mentioned).
        .forEach((/** @type {*} */ s) => {
          const { role, password } = state.users.find(
            (/** @type {{ _id: string; }} */ u) => u._id === s._id
          );

          // Prevent sending to the same recipient more than once.
          if (emailRecipients.some((x) => x.recipient.email === s.email)) {
            return;
          }

          emailRecipients.push({
            doc,
            recipient: { ...s, role, password },
            content: newStatus === "open" ? "===REJECTED===" : "===RESOLVED===",
            isInternal: "ithread" === workflow.wfType,
            isTemplating: !state.drawerVersions.versions.find(
              (/** @type {*} */ v) => v._id === state.drawerVersions.active._id
            )?.agrID,
            isApproval: true,
            isApprover: false,
            clauseHTML,
            recentComments: workflow.comments
              .sort((/** @type {*} */ a, /** @type {*} */ b) =>
                a.date > b.date ? -1 : 1
              )
              .slice(0, 3),
          });
        });
    }
    return updateWorkFlow(updatedWorkFlow, emailRecipients);
  };

  const resolveThread = async () => {
    await changeWorkFlowStatus("resolved");

    handleNextIssue();
  };

  const openThread = async () => {
    await changeWorkFlowStatus("open");
  };

  /**
   * @param {*} comment
   */
  const removeComment = async (comment) => {
    const comments = workflow.comments.filter(
      (/** @type {{ id: string; }} */ c) => c.id !== comment.id
    );

    const updatedWorkFlow = { ...workflow, comments };

    const result = await updateWorkFlow(updatedWorkFlow);

    if (result) setWorkflow(result);
    setConfirmDeleteComment(null);
  };

  const removeThread = async () => {
    // if (doc.owner !== state.org._id && !isTemplate) return;

    await changeWorkFlowStatus("deleted");

    removeIssue();
    handleNextIssue();
    setConfirmDeleteThread(false);
  };

  const removeIssue = () => {
    if (isPrivate) {
      editor.dispatchCommand(REMOVE_WORKFLOW, {
        key: currentOpenIssue.node.getKey(),
        wfid: workflow.lid,
      });
    } else editor.dispatchCommand(REMOVE_MARK, workflow.lid);
  };

  /**
   * @param {*} comment
   * @returns {boolean}
   */
  function canEditComment(comment) {
    if (comment?.creator?.orgID === state.org._id) return true;

    return false;
  }

  const canDeleteThread = () => {
    if (isPrivate) return workflow?.creator?.uid === state.user._id;

    return (
      (workflow?.orgID === state.org._id || doc.owner === state.org._id) &&
      !isInEffect
    );
  };

  /**
   * @param {*} comment
   */
  const canDeleteReply = (comment) => {
    if (isPrivate) return comment?.creator?.uid === state.user._id;

    return (
      (comment?.creator?.orgID === state.org._id ||
        doc.owner === state.org._id) &&
      !isInEffect
    );
  };

  const canReply = () => {
    if (
      isResolved ||
      (!isPrivate && isInEffect) ||
      state.drawerVersions?.active?._id !==
        state.drawerVersions.versions[0]?._id
    ) {
      return false;
    }
    if (isPrivate) return true;

    return (
      (state.user.role.name === "Counterparty" &&
        state.drawerVersions.active.editMode !== "read") ||
      state.user.role.name !== "Counterparty"
    );
  };

  return (
    <>
      <Grid container direction={"row"} justifyContent="center">
        <Grid item>
          <Tooltip title="Previous Change" placement={"top"}>
            <IconButton
              sx={{
                color: theme.palette.grey[700],
                fontSize: "22px",
              }}
              onClick={handlePreviousIssue}
            >
              <FontAwesomeIcon icon={faCircleLeft} />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Next Change" placement={"top"}>
            <IconButton
              sx={{
                color: theme.palette.grey[700],
                fontSize: "22px",
              }}
              onClick={handleNextIssue}
            >
              <FontAwesomeIcon icon={faCircleRight} />
            </IconButton>
          </Tooltip>
        </Grid>

        <Grid item>
          {!isResolved && (
            <Tooltip title="Resolve Thread" placement={"top"}>
              <span>
                <IconButton
                  sx={{
                    color: theme.palette.success.main,
                    fontSize: "22px",
                  }}
                  onMouseDown={resolveThread}
                  disabled={
                    (canMakeChanges && !isPrivate) ||
                    (!isPrivate && isInEffect) ||
                    state.drawerVersions?.active?._id !==
                      state.drawerVersions.versions[0]?._id
                  }
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {isResolved && (
            <Tooltip title="Reopen Thread" placement={"top"}>
              <span>
                <IconButton
                  sx={{
                    color: theme.palette.grey[700],
                    fontSize: "22px",
                  }}
                  onMouseDown={openThread}
                  disabled={
                    (!isPrivate && isInEffect) ||
                    (state.user.role.name === "Counterparty" &&
                      state.drawerVersions.active.editMode === "read") ||
                    state.drawerVersions?.active?._id !==
                      state.drawerVersions.versions[0]?._id
                  }
                >
                  <FontAwesomeIcon icon={faRotateLeft} />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <Tooltip title="Reject Change" placement={"top"}>
            <span>
              <IconButton
                sx={{
                  color: theme.palette.error.main,
                  fontSize: "22px",
                }}
                disabled
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>

      <Box
        mt={1}
        p={2}
        pb={0}
        sx={{
          width: "100%",
          borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
          opacity: isResolved ? "0.5" : "1",
        }}
      >
        <Grid container alignItems={"center"}>
          <Grid container item xs={2} justifyContent={"center"}>
            <FontAwesomeIcon
              icon={!isPrivate ? faComments : faClipboard}
              style={{
                fontSize: "22px",
                color: "#5B5B5B",
              }}
            />
          </Grid>

          <Grid item xs={8} alignItems={"center"} sx={{ paddingLeft: "10px" }}>
            <b>{!isPrivate ? "Public Comment" : "Private Note"}</b>
          </Grid>

          {canMakeChanges ||
            (canDeleteThread() && !isResolved && (
              <Grid
                item
                xs={2}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <IconButton
                  onClick={(event) => {
                    setMainAnchorElement(event.currentTarget);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsisV}
                    style={{
                      fontSize: "18px",
                      color: "#5B5B5B",
                    }}
                  />
                </IconButton>
              </Grid>
            ))}

          <Menu
            id="main-menu"
            anchorEl={mainAnchorElement}
            open={!!mainAnchorElement}
            onClose={() => setMainAnchorElement(null)}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            disableEnforceFocus
            disableRestoreFocus
          >
            {canEditComment(workflow?.comments[0]) && (
              <MenuItem
                onClick={() => {
                  setEditModeIndex(0);
                  setCommentText(workflow?.comments[0].content);
                  setMainAnchorElement(null);
                }}
              >
                Edit comment
              </MenuItem>
            )}
            {canDeleteThread() && (
              <MenuItem
                onClick={() => {
                  setConfirmDeleteThread(true);
                  setMainAnchorElement(null);
                }}
              >
                Delete Thread
              </MenuItem>
            )}
          </Menu>
        </Grid>

        {isPrivate && (
          <Grid
            container
            item
            sx={{
              maxHeight: "150px",
              overflow: "auto",
              userSelect: "text",
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
            }}
          >
            <Grid item xs={2}></Grid>
            <Grid
              container
              item
              xs={10}
              sx={{ gap: "8px", paddingLeft: "10px" }}
            >
              <Typography
                sx={{ color: theme.palette.grey[500] }}
                variant="subtitle2"
              >
                Visible to:
              </Typography>
              {workflow?.visibleTo.map((/** @type {string} */ orgID) => {
                const party =
                  state.parties.find(
                    (/** @type {{ orgID: string; }} */ p) => p.orgID === orgID
                  ) ?? state.subs[0];

                return (
                  <Tooltip
                    title={
                      party.myClient
                        ? `${party.shortName} (Client)`
                        : party.shortName
                    }
                    placement={"bottom"}
                    key={orgID}
                  >
                    <Avatar
                      src={party.logoURL}
                      sx={{
                        backgroundColor: "transparent",
                        height: "20px",
                        width: "20px",
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Grid>
          </Grid>
        )}

        <Grid
          container
          item
          my={2}
          sx={{
            maxHeight: "150px",
            overflow: "auto",
            userSelect: "text",
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={10}>
            <Typography
              sx={{ color: theme.palette.grey[500], paddingLeft: "10px" }}
            >
              "{currentOpenIssue.text}"
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            maxHeight: "350px",
            overflow: "auto",
          }}
        >
          {workflow?.comments.map(
            (/** @type {*} */ comment, /** @type {number} */ index) => {
              const party =
                state.parties.find(
                  (/** @type {{ orgID: string; }} */ p) =>
                    p.orgID === comment.creator.orgID
                ) ?? state.subs[0];

              return (
                <Box
                  key={`${comment.id}${index}`}
                  py={1}
                  sx={{
                    borderBottom: "1px dotted rgba(0, 0, 0, 0.26)",
                    "&:last-of-type": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Grid container alignItems={"center"}>
                    <Grid item xs={2}>
                      <Avatar
                        src={party.logoURL}
                        sx={{
                          backgroundColor: "transparent",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      alignItems={"center"}
                      sx={{ paddingLeft: "10px" }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 700, lineHeight: "1.5" }}
                      >
                        {comment.creator.displayName}
                      </Typography>
                      <Typography variant="subtitle2">
                        {party.myClient
                          ? `${party.shortName} (Client)`
                          : party.shortName}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: theme.palette.grey[700],
                          lineHeight: "1.25",
                        }}
                      >
                        {dayjs(comment.date).format("D MMM YYYY, h:mm A")}
                      </Typography>
                    </Grid>
                    {index !== 0 && canDeleteReply(comment) && !isResolved && (
                      <Grid
                        item
                        xs={2}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <IconButton
                          onClick={(event) => {
                            setCommentAnchorElement(event.currentTarget);
                            setSelectedComment({ comment, index });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisV}
                            style={{
                              fontSize: "18px",
                              color: "#5B5B5B",
                            }}
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container alignItems={"center"} pt={1}>
                    <Grid item xs={2}></Grid>
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      sx={{
                        paddingLeft: "10px",
                        userSelect: "text",
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {editModeIndex === index ? (
                        <>
                          <TextField
                            autoFocus
                            value={commentText}
                            onChange={(event) =>
                              setCommentText(event.target.value)
                            }
                          />
                          <Grid container mt={1}>
                            <Grid item>
                              <Button
                                variant="text"
                                onClick={() => {
                                  setEditModeIndex(-1);
                                  setCommentText("");
                                }}
                              >
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={async () => {
                                  const updatedWorkflow = { ...workflow };
                                  updatedWorkflow.comments[index].content =
                                    commentText;

                                  if (index === 0) {
                                    updatedWorkflow.note = commentText;
                                  }

                                  const result = await updateWorkFlow(
                                    updatedWorkflow
                                  );
                                  if (!result) return;

                                  setWorkflow(result);
                                  setEditModeIndex(-1);
                                  setCommentText("");
                                }}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <Typography variant="body1">
                          {comment.content}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            }
          )}

          {selectedComment && (
            <Menu
              id="comment-menu"
              anchorEl={commentAnchorElement}
              open={!!commentAnchorElement}
              onClose={() => {
                setCommentAnchorElement(null);
                setSelectedComment(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              disableEnforceFocus
              disableRestoreFocus
            >
              {canEditComment(selectedComment.comment) && (
                <MenuItem
                  onClick={() => {
                    setEditModeIndex(selectedComment.index);
                    setCommentText(selectedComment.comment.content);
                    setCommentAnchorElement(null);
                  }}
                >
                  Edit Comment
                </MenuItem>
              )}

              {canDeleteReply(selectedComment.comment) && (
                <MenuItem
                  onClick={() => {
                    setConfirmDeleteComment(selectedComment.comment);
                    setCommentAnchorElement(null);
                  }}
                >
                  Delete Comment
                </MenuItem>
              )}
            </Menu>
          )}
        </Box>
      </Box>

      {canReply() && (
        <Box
          px={3}
          pt={replying ? 3 : 1}
          sx={{
            width: "100%",
            borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
          }}
        >
          {replying && (
            <PlainTextEditor
              onEscape={onEscape}
              onChange={onChange}
              placeholder="Use @ to address other users"
              isTemplate={isTemplate}
              docID={docID}
              visibleTo={workflow.visibleTo}
              sx={{
                border: "1px solid" + theme.palette.grey[400],
                borderRadius: "15px",
                height: "85px",
              }}
              autoFocus={undefined}
              editorRef={undefined}
              isReply={undefined}
              isApproval={undefined}
              editing={undefined}
            />
          )}

          <Grid container direction="row-reverse" pt={replying ? 1 : 0}>
            {!replying && (
              <Button
                sx={{ marginLeft: "auto" }}
                onClick={() => setReplying(true)}
              >
                Reply
              </Button>
            )}
            {replying && (
              <Button
                sx={{ marginLeft: "auto" }}
                onClick={submitReply}
                disabled={!canSubmit}
                endIcon={
                  <FontAwesomeIcon
                    icon={faCircleArrowRight}
                    style={{
                      fontSize: "14px",
                    }}
                  />
                }
              >
                Submit
              </Button>
            )}
          </Grid>
        </Box>
      )}

      {confirmDeleteThread && (
        <DialogConfirmDelete
          open={confirmDeleteThread}
          title={"Delete Thread"}
          message={"Are you sure you want to delete this thread?"}
          handleClose={() => setConfirmDeleteThread(false)}
          handleConfirm={removeThread}
        />
      )}

      {confirmDeleteComment && (
        <DialogConfirmDelete
          open={confirmDeleteComment}
          title={"Delete Comment"}
          message={"Are you sure you want to delete this comment?"}
          handleClose={() => setConfirmDeleteComment(null)}
          handleConfirm={() => removeComment(confirmDeleteComment)}
        />
      )}
    </>
  );
}
