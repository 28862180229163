import { $generateNodesFromSerializedNodes } from "@lexical/clipboard";
import { $isTextNode } from "lexical";
import { $createClauseNode } from "../../../../../../nodes/ClauseNode";
import { $createCustomParagraphNode } from "../../../../../../nodes/CustomParagraphNode";
import { $createRedlineNode } from "../../../../../../nodes/RedlineNode";
import { defaultClauseNodeSettings } from "../../../../../CanveoPlugin/constants/defaultClauseNodeSettings";

/**
 * Returns a list of Lexical nodes from the clipboard data. It will first try to see if there is content for the
 * `application/x-lexical-editor` format which means that the content was copied from the Lexical editor. If not,
 * it defaults to the `text/plain` format and we assume it was copied from outside Canveo.
 *
 * @param {DataTransfer} clipboardData
 * @param {RangeSelection} selection
 * @param {RedlineData} defaultRedlineData
 * @returns {LexicalNode[]}
 */
export function getCopiedNodesFromClipboard(
  clipboardData,
  selection,
  defaultRedlineData,
  generateClauseNode = true
) {
  /**
   * @type {LexicalNode[]}
   */
  const copiedNodes = [];

  // If the clipboard contains content for the format "application/x-lexical-editor", that means the content was
  // copied from the Lexical editor.
  const lexicalClipboardData = clipboardData.getData(
    "application/x-lexical-editor"
  );
  if (lexicalClipboardData) {
    const parsedClipboardData = JSON.parse(lexicalClipboardData);
    const generatedNodes = $generateNodesFromSerializedNodes(
      parsedClipboardData.nodes
    );
    copiedNodes.push(...generatedNodes);
  }
  // If there is no Lexical clipboard data then we default to the "text/plain" format and assume the content is
  // being copied from outside.
  else {
    const plainTextClipboardData = clipboardData.getData("text/plain");
    console.log(plainTextClipboardData);
    // If there is a newline character in the string it means we are dealing with blocks and need to map them to
    // our own node hierarchy comprised of: ClauseNode > CustomParagraphNode > RedlineNode.
    if (plainTextClipboardData.includes("\n")) {
      const blocks = plainTextClipboardData.split("\n");
      for (const blockText of blocks) {
        const redlineNode = $createRedlineNode({
          ...defaultRedlineData,
          text: blockText,
          redlineType: "add",
        });

        const paragraphNode = $createCustomParagraphNode();
        paragraphNode.append(redlineNode);

        if (generateClauseNode) {
          const clauseNode = $createClauseNode(defaultClauseNodeSettings);
          clauseNode.append(paragraphNode);
          copiedNodes.push(clauseNode);
        } else {
          copiedNodes.push(paragraphNode);
        }
      }
    } else {
      const selectionNode = selection.focus.getNode();
      const redlineToInsert = $createRedlineNode({
        ...defaultRedlineData,
        text: plainTextClipboardData,
        redlineType: "add",
      });
      if ($isTextNode(selectionNode)) {
        redlineToInsert.setStyle(selectionNode.getStyle());
        redlineToInsert.setFormat(selectionNode.getFormat());
      }

      copiedNodes.push(redlineToInsert);
    }
  }

  return copiedNodes;
}
