import {
  Avatar as ChatAvatar,
  ChatContainer,
  MainContainer,
  Message,
  MessageList,
} from "@chatscope/chat-ui-kit-react";
import {
  faArrowLeft,
  faPaperPlane,
  faTimes,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import axios from "axios";
import { $getRoot } from "lexical";
import React, { useContext, useRef, useState } from "react";
import { globalStore } from "../../../../state/store";
import theme from "../../../../theme/theme";
import CanveoCircularProgress from "../../../CanveoCircularProgress";
import { PlainTextEditor } from "../../../editor/plugins/commenting/PlainTextEditor";
import { useOnChange } from "../../../editor/utils";
import DisplayApprovalList from "../../../reviewsAndApprovals/components/DisplayApprovalList";

/**
 * @typedef {object} ApprovalTicketDetailsTabProps
 * @property {() => void} close
 * @property {{ ents: { entID: string, partyID: string }[], agrTitle: string, _id: string }} agreement
 * @property {ApprovalTicket} approvalTicket
 * @property {(approvalTicket: ApprovalTicket) => void} updateApprovalTicket
 * @property {(approvalTicketId: string) => void} deleteApprovalTicket
 * @property {() => void} onEditApprover
 * @property {boolean} [isTemplate]
 * @property {string} [messageListHeight]
 * @property {boolean} [hideBackButton]
 */

/**
 * @param {ApprovalTicketDetailsTabProps} props
 * @returns {JSX.Element}
 */
export default function ApprovalTicketDetailsTab({
  close,
  agreement,
  approvalTicket,
  updateApprovalTicket,
  deleteApprovalTicket,
  onEditApprover,
  isTemplate = false,
  messageListHeight = "400px",
  hideBackButton = false,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /** @type {StateUser} */
  const currentUser = state.user;
  /** @type {StateUser[]} */
  const currentOrganizationUsers = state.users;
  const mentionUsers = currentOrganizationUsers.filter(
    (user) => user._id !== currentUser._id
  );

  /** @type {TicketChatMessage[]} */
  const messages = approvalTicket.approvalChain.messages.map((message) => ({
    message: message.text,
    direction: currentUser._id === message.senderId ? "outgoing" : "incoming",
    position: "single",
    sender: message.senderDisplayName,
    sentTime: message.sentTime,
    senderPhotoUrl: message.senderPhotoUrl,
  }));

  const ticketIsInEditableState = !["Approved", "Cancelled"].includes(
    approvalTicket.status
  );
  const userIsRequestor = approvalTicket.requestor._id === currentUser._id;
  // Users who have the "Admin" or "Legal" role have access to the same edit actions as a requestor, even on
  // tickets where they are neither requestors or approvers.
  const userHasEditRole = ["Admin", "Legal"].includes(currentUser.role.name);
  const currentUserApprovalListItem =
    /** @type {ApprovalListUserItem | undefined} */ (
      approvalTicket.approvalChain.approvalList.find(
        (approvalListItem) =>
          approvalListItem.type === "user" &&
          approvalListItem.role === "approver" &&
          approvalListItem._id === currentUser._id
      )
    );
  const userIsApprover = currentUserApprovalListItem?.role === "approver";
  const entity = agreement.ents.find((entity) => entity.partyID === "party1");
  const counterpartyEntity = state.cpents.find(
    (/** @type {{ _id: string; }} */ counterpartyEntity) =>
      counterpartyEntity?._id === entity?.entID
  );
  const counterpartyLegalName = counterpartyEntity?.legalName;

  const editorRef = useRef(/** @type {LexicalEditor | null} */ (null));

  const [isLoading, setIsLoading] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [openCancelApprovalTicketDialog, setOpenCancelApprovalTicketDialog] =
    useState(false);
  const [openDeleteApprovalTicketDialog, setOpenDeleteApprovalTicketDialog] =
    useState(false);
  const [initialComment, setInitialComment] = useState("");

  const [content, setContent] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [subscribers, setSubscribers] = useState(/** @type {*[]} */ ([]));

  const onEscape = (/** @type {{ preventDefault: () => void; }} */ event) => {
    event.preventDefault();
    return true;
  };

  const onChange = useOnChange(setContent, setCanSubmit, setSubscribers);

  /**
   * @param {string} textContent
   * @param {TicketMessageSubscriber[]} mentions
   * @returns {Promise<void>}
   */
  async function sendMessage(
    textContent,
    mentions = [],
    isInitialMessage = false
  ) {
    try {
      setIsLoading(true);

      /** @type {TicketMessage} */
      const message = {
        text: textContent,
        senderId: currentUser._id,
        senderDisplayName: currentUser.displayName,
        senderPhotoUrl: currentUser.photoURL,
        sentTime: new Date().toISOString(),
      };

      const messages = isInitialMessage
        ? [message]
        : [...approvalTicket.approvalChain.messages, message];

      const putAddTaskMessageResponse = await axios
        .put(
          state.settings.api +
            `task/${approvalTicket._id}/messages?isInitialMessage=${isInitialMessage}`,
          {
            messages,
            mentions,
          }
        )
        .catch((error) => {
          console.error(error);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message:
                "Unable to send message, try again or contact Canveo Support if the issue persists.",
              severity: "error",
            },
          });
        });
      if (!putAddTaskMessageResponse) return;

      /** @type {TicketMessage[]} */
      const newMessages = putAddTaskMessageResponse.data.data;

      /** @type {ApprovalTicket} */
      const updatedApprovalTicket = {
        ...approvalTicket,
        approvalChain: {
          ...approvalTicket.approvalChain,
          messages: newMessages,
        },
      };

      updateApprovalTicket(updatedApprovalTicket);
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * @param {ApprovalListUserItem["approvalStatus"]} approvalStatus
   * @param {string} action
   * @returns {Promise<void>}
   */
  async function updateApproverApprovalStatus(approvalStatus, action) {
    try {
      const updatedApprovalList = [
        ...approvalTicket.approvalChain.approvalList,
      ];
      const item = updatedApprovalList.find(
        (listItem) =>
          listItem.type === "user" &&
          listItem.role === "approver" &&
          listItem._id === currentUser._id
      );
      if (!item || item.type !== "user") return;

      item.approvalStatus = approvalStatus;

      /** @type {ApprovalTicket["approvalChain"]} */
      const updatedChain = {
        ...approvalTicket.approvalChain,
        approvalList: updatedApprovalList,
      };

      const putUpdateApprovalChainResponse = await axios.put(
        state.settings.api + `task/${approvalTicket._id}/chain`,
        {
          chain: updatedChain,
          action,
        }
      );
      if (!putUpdateApprovalChainResponse) return;

      /** @type {ApprovalTicket} */
      const updatedApprovalTicket = putUpdateApprovalChainResponse.data.data;

      updateApprovalTicket(updatedApprovalTicket);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to update approval request chain, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    }
  }

  /**
   * @param {ReviewTicket["status"]} status
   * @returns {Promise<void>}
   */
  async function updateApprovalTicketStatus(status) {
    const putUpdateApprovalTicketStatusResponse = await axios
      .put(state.settings.api + `task/${approvalTicket._id}/status`, { status })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to update approval request, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!putUpdateApprovalTicketStatusResponse) return;

    /** @type {ApprovalTicket} */
    const updatedApprovalTicket =
      putUpdateApprovalTicketStatusResponse.data.data;

    updateApprovalTicket(updatedApprovalTicket);
    setOpenCancelApprovalTicketDialog(false);
  }

  /**
   * @returns {Promise<void>}
   */
  async function handleDeleteApprovalTicket() {
    const deleteApprovalTicketResponse = await axios
      .delete(state.settings.api + `task/${approvalTicket._id}`)
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to delete approval request, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!deleteApprovalTicketResponse) return;

    deleteApprovalTicket(approvalTicket._id);

    setOpenDeleteApprovalTicketDialog(false);
    close();
  }

  /**
   * @param {string} initialComment
   * @returns {Promise<void>}
   */
  async function handleSubmitForApproval(initialComment) {
    if (initialComment) await sendMessage(initialComment, [], true);
    await updateApprovalTicketStatus("In Progress");

    close();
  }

  async function submitComment() {
    await sendMessage(content, subscribers).finally(() => {
      setContent("");
      setSubscribers([]);

      if (editorRef.current) {
        editorRef.current.update(() => {
          const root = $getRoot();
          root.clear();
        });
      }
    });
  }

  /**
   * Refreshes agreement data including agreement versions.
   *
   * @param {string} agreementId
   * @returns {Promise<void>}
   */
  async function refreshAgreement(agreementId) {
    const getAgreementDetailsResponse = await axios
      .get(`${state.settings.api}agr/agrdetails/${agreementId}`)
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to refresh agreement, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!getAgreementDetailsResponse) return;

    dispatch({
      type: "INIT_AGRS",
      payload: getAgreementDetailsResponse.data.data.ags,
    });
    dispatch({
      type: "INIT_AVS",
      payload: getAgreementDetailsResponse.data.data.avs,
    });
    dispatch({
      type: "INIT_PARTIES",
      payload: getAgreementDetailsResponse.data.data.parties,
    });
    dispatch({
      type: "UPDATE_AGREXEC",
      payload: getAgreementDetailsResponse.data.data.agrExec,
    });
    dispatch({
      type: "INIT_WORKFLOWS",
      payload: getAgreementDetailsResponse.data.data.workflows,
    });
    dispatch({
      type: "INIT_PARAMS_DOC",
      payload: getAgreementDetailsResponse.data.data.params,
    });

    const getAgreementVersionsResponse = await axios
      .get(`${state.settings.api}agrv/${agreementId}`)
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to refresh agreement versions, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!getAgreementVersionsResponse) return;

    const versions = getAgreementVersionsResponse.data.data;
    const [firstVersion] = versions;
    const editMode = firstVersion.owner.find(
      (/** @type {{orgID: string}} */ owner) => owner.orgID === state.org._id
    )?.editMode;

    dispatch({
      type: "INIT_VERSIONS",
      payload: {
        versions: versions,
        active: { _id: firstVersion._id, editMode },
      },
    });
  }

  /**
   * Refreshes template data including template versions.
   *
   * @param {string} templateId
   * @returns {Promise<void>}
   */
  async function refreshTemplate(templateId) {
    const getTemplateDetailsResponse = await axios
      .get(state.settings.api + "template/" + templateId)
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to refresh agreement, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!getTemplateDetailsResponse) return;

    dispatch({
      type: "INIT_WORKFLOWS",
      payload: getTemplateDetailsResponse.data.data.workflows,
    });
    dispatch({
      type: "INIT_PARAMS_DOC",
      payload: getTemplateDetailsResponse.data.data.params,
    });
    dispatch({
      type: "INIT_TEMPLATE",
      payload: getTemplateDetailsResponse.data.data.templates,
    });

    const getTemplateVersionsResponse = await axios
      .get(`${state.settings.api}template/versions/${templateId}`)
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message:
              "Unable to refresh template, try again or contact Canveo Support if the issue persists.",
            severity: "error",
          },
        });
      });
    if (!getTemplateVersionsResponse) return;

    const versions = getTemplateVersionsResponse.data.data;
    const [firstVersion] = versions;

    dispatch({
      type: "INIT_WORKFLOWS",
      payload: firstVersion.workflows,
    });
    dispatch({
      type: "INIT_VERSIONS",
      payload: {
        versions,
        active: { _id: firstVersion._id, editMode: "full" },
      },
    });
  }

  async function handleApprovedApprovalTicket() {
    try {
      setIsApproving(true);
      await updateApproverApprovalStatus("Approved", "Approve");

      if (isTemplate) {
        await refreshTemplate(agreement._id);
      } else {
        await refreshAgreement(agreement._id);
      }
    } finally {
      setIsApproving(false);
    }
  }

  return (
    <>
      {isApproving ? (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={20}
          mb={30}
        >
          <Grid item>
            <CanveoCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <DialogContent sx={{ paddingX: 4 }}>
            <DisplayApprovalList approvalTicket={approvalTicket} />

            <Grid container mt={4} justifyContent="center">
              {counterpartyLegalName && (
                <span>
                  <b>Counterparty:</b>{" "}
                  {`${counterpartyLegalName ? counterpartyLegalName : "n/a"}`}
                </span>
              )}
            </Grid>

            <Grid container justifyContent="center">
              <span>
                <b>Agreement:</b> {`${agreement.agrTitle}`}
              </span>
            </Grid>

            {!isTemplate && approvalTicket.status === "Pending Assignment" && (
              <Grid container direction="row" mt={4}>
                <TextField
                  disabled={!approvalTicket.isDefaultRequest}
                  defaultValue={
                    approvalTicket.isDefaultRequest
                      ? approvalTicket.approvalChain.messages.at(0)?.text
                      : undefined
                  }
                  fullWidth
                  multiline
                  rows={8}
                  label="Initial Comment..."
                  onChange={(event) => setInitialComment(event.target.value)}
                />
              </Grid>
            )}

            {isTemplate && approvalTicket.isTemplateConfiguration && (
              <Grid container direction="row" mt={4}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  rows={8}
                  label="Initial Comment..."
                  defaultValue={
                    approvalTicket.approvalChain.messages.at(0)?.text
                  }
                  onChange={(event) => setInitialComment(event.target.value)}
                />
              </Grid>
            )}

            {approvalTicket.status !== "Pending Assignment" && (
              <Box mt={4}>
                <MainContainer
                  style={{
                    border: "none",
                  }}
                >
                  <ChatContainer>
                    <MessageList
                      style={{ height: messageListHeight }}
                      scrollBehavior="auto"
                      autoScrollToBottomOnMount
                      autoScrollToBottom
                      loading={isLoading}
                    >
                      {messages.map((message, index) => {
                        return (
                          <Message key={index} model={message}>
                            <ChatAvatar
                              src={message.senderPhotoUrl}
                              title={message.sender}
                              style={{
                                height: "5px",
                                width: "5px",
                                minHeight: "20px",
                                minWidth: "20px",
                              }}
                            />
                            <Message.Header
                              sender={message.sender}
                              sentTime={new Date(
                                message.sentTime
                              ).toLocaleString()}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent={
                                  message.direction === "outgoing"
                                    ? "end"
                                    : "start"
                                }
                              >
                                <Grid item>
                                  {message.sender +
                                    ", " +
                                    new Date(message.sentTime).toLocaleString()}
                                </Grid>
                              </Grid>
                            </Message.Header>
                          </Message>
                        );
                      })}
                    </MessageList>
                  </ChatContainer>
                </MainContainer>

                <Divider variant="middle" />

                <Grid
                  container
                  direction="row"
                  mt={4}
                  alignItems="center"
                  gap={2}
                >
                  <Grid item xs={1} />
                  <Grid item xs={8}>
                    <PlainTextEditor
                      onEscape={onEscape}
                      onChange={onChange}
                      placeholder="Insert Comment..."
                      sx={{
                        border: "1px solid" + theme.palette.grey[400],
                        borderRadius: "15px",
                        height: "75px",
                      }}
                      isTemplate={isTemplate}
                      docID={agreement._id}
                      editorRef={editorRef}
                      users={mentionUsers}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton onClick={submitComment} disabled={!canSubmit}>
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        color={
                          canSubmit ? theme.palette.primary.main : undefined
                        }
                        style={{
                          fontSize: "25px",
                        }}
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            {!hideBackButton && (
              <Button sx={{ marginRight: "auto" }} onClick={close}>
                <FontAwesomeIcon icon={faArrowLeft} />
                &nbsp;&nbsp;Back
              </Button>
            )}

            {isTemplate && approvalTicket.status === "Pending Assignment" && (
              <Button
                variant="outlined"
                disableElevation
                color="primary"
                onClick={() => setOpenDeleteApprovalTicketDialog(true)}
              >
                Delete ...
              </Button>
            )}

            {!approvalTicket.isTemplateConfiguration &&
              ticketIsInEditableState &&
              (userIsRequestor || userHasEditRole) && (
                <>
                  <Button
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={() => setOpenCancelApprovalTicketDialog(true)}
                  >
                    Cancel ...
                  </Button>
                </>
              )}

            {ticketIsInEditableState &&
              (userIsRequestor || userIsApprover || userHasEditRole) && (
                <Button
                  variant="outlined"
                  disableElevation
                  color="primary"
                  onClick={onEditApprover}
                >
                  Edit ...
                </Button>
              )}

            {userIsApprover && !approvalTicket.isTemplateConfiguration && (
              <>
                {currentUserApprovalListItem.approvalStatus === "Pending" &&
                  approvalTicket.status !== "Pending Assignment" && (
                    <>
                      <Button
                        variant="contained"
                        disableElevation
                        color="success"
                        sx={{ backgroundColor: "#088339", color: "white" }}
                        onClick={handleApprovedApprovalTicket}
                      >
                        Approve
                      </Button>
                    </>
                  )}

                {ticketIsInEditableState &&
                  currentUserApprovalListItem.approvalStatus === "Approved" && (
                    <>
                      <Button
                        variant="outlined"
                        disableElevation
                        color="error"
                        sx={{ color: "#FF0000", borderColor: "#FF0000" }}
                        onClick={() =>
                          updateApproverApprovalStatus(
                            "Pending",
                            "Withdraw Approval"
                          )
                        }
                      >
                        Withdraw Approval
                      </Button>
                    </>
                  )}
              </>
            )}

            {!isTemplate && approvalTicket.status === "Pending Assignment" && (
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={() => handleSubmitForApproval(initialComment)}
              >
                Submit
              </Button>
            )}
          </DialogActions>

          <Dialog
            open={openCancelApprovalTicketDialog}
            onClose={() => setOpenCancelApprovalTicketDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
              <IconButton
                onClick={() => setOpenCancelApprovalTicketDialog(false)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ padding: "4px 7px", fontSize: "20px" }}
                />
              </IconButton>
            </Box>

            <DialogTitle>Cancel Approval Request</DialogTitle>

            <DialogContent>
              Are you sure you want to cancel the approval request?
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={() => updateApprovalTicketStatus("Cancelled")}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openDeleteApprovalTicketDialog}
            onClose={() => setOpenDeleteApprovalTicketDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
              <IconButton
                onClick={() => setOpenDeleteApprovalTicketDialog(false)}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ padding: "4px 7px", fontSize: "20px" }}
                />
              </IconButton>
            </Box>

            <DialogTitle>Delete Approval Request</DialogTitle>

            <DialogContent>
              Are you sure you want to delete the approval request?
            </DialogContent>

            <DialogActions>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={handleDeleteApprovalTicket}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
