/**
 * Removes all cross-references and mail merge fields from an SFDT. Mutates the object passed as an argument.
 *
 * This function recursively searches the SFDT for "inlines" which can show inside blocks, rows, cells, etc.,
 * and then checks whether any of the inlines are cross-reference or mail merge definitions/instances. If they
 * are then they are removed.
 *
 * @param {import("../../types/sfdt").Sfdt | *} obj
 * @returns {void}
 */
export function removeAllCrossReferencesAndMailMergeFieldsFromSfdt(obj) {
  if (typeof obj === "object" && obj !== null) {
    for (let key in obj) {
      // TODO: Write unit test to validate that we are not altering the headers and footers objects.
      // We do not want to make changes to the headers and footers.
      if (key === "headersFooters") return;

      if (key === "inlines") {
        const inlines = obj[key];

        for (let index = 0; index < inlines.length; index++) {
          const inline = inlines[index];
          if (!inline) continue;

          // Ignore weird inlines related to Microsoft Word cross-references.
          if (
            inline?.text?.includes(" \\* MERGEFORMAT ") ||
            // Captures any string starting with REF followed by any amount of spaces, followed by _REF, followed
            // by any amount of digits, followed by any characters e.g., "REF _Ref81313091".
            /REF\s+_Ref\d+.*$/g.test(inline?.text ?? "")
          ) {
            // Remove cross-reference objects.
            inlines.splice(index, 1);

            // Adjust the loop counter to account for the removed item.
            index--;

            continue;
          }

          // Match cross-reference definition which appears on the SFDT in the following way:
          //
          // {
          //   "name": "_Ref170212976",
          //   "bookmarkType": 0
          // },
          // {
          //   "text": "This is a heading."
          // },
          // {
          //   "name": "_Ref170212976",
          //   "bookmarkType": 1
          // }
          if (
            (inline?.bookmarkType === 0 || inline?.bookmarkType === 1) &&
            inline?.name &&
            inline?.name.startsWith("_Ref")
          ) {
            // Remove cross-reference objects.
            inlines.splice(index, 1);

            // Adjust the loop counter to account for the removed item.
            index--;

            continue;
          }

          const nextInline = inlines[index + 1];
          if (!nextInline) continue;

          // Match cross-reference instance which can appear on the SFDT in some of the following ways:
          //
          // {
          //   "hasFieldEnd": true,
          //   "fieldType": 0
          // },
          // {
          //   "text": " REF _Ref170214396 \\r \\h "
          // },
          // {
          //   "fieldType": 2
          // },
          // {
          //   "text": "‎",
          //   "characterFormat": {
          //     "complexScript": true
          //   }
          // },
          // {
          //   "text": "1"
          // },
          // {
          //   "fieldType": 1
          // }
          //
          // Another example:
          //
          // {
          //   "hasFieldEnd": true,
          //   "characterFormat": {
          //     "fontSize": 9,
          //     "fontColor": "#000000FF",
          //     "fontSizeBidi": 9
          //   },
          //   "fieldType": 0
          // },
          // {
          //   "text": "  REF _Ref447868871 \\n \\h \\* MERGEFORMAT ",
          //   "characterFormat": {
          //     "fontSize": 9,
          //     "fontColor": "#000000FF",
          //     "fontSizeBidi": 9
          //   }
          // },
          // {
          //   "characterFormat": {
          //     "fontSize": 9,
          //     "fontColor": "#000000FF",
          //     "fontSizeBidi": 9
          //   },
          //   "fieldType": 2
          // },
          // {
          //   "text": "4.1",
          //   "characterFormat": {
          //     "fontSize": 9,
          //     "fontColor": "#000000FF",
          //     "fontSizeBidi": 9
          //   }
          // },
          // {
          //   "characterFormat": {
          //     "fontSize": 9,
          //     "fontColor": "#000000FF",
          //     "fontSizeBidi": 9
          //   },
          //   "fieldType": 1
          // }
          //
          // We need to remove all the inlines between fieldType 0 and fieldType 2 (including themselves).
          // Afterwards, comes the text that we want to preserve until we find a fieldType 1 which we remove.
          // This last part is handled by the if statement below.
          if (inline?.hasFieldEnd === true && inline?.fieldType === 0) {
            let innerWhileIndex = index;
            let innerWhileInline = inline;

            // Remove all the inlines between fieldType 0 and fieldType 2 (including themselves).
            while (innerWhileInline?.fieldType !== 2) {
              inlines.splice(innerWhileIndex, 1);
              innerWhileInline = inlines[innerWhileIndex];
            }

            // Remove fieldType 2.
            inlines.splice(innerWhileIndex, 1);

            continue;
          }

          // Remove fieldType 1.
          if (inline?.fieldType === 1) {
            // Remove cross-reference objects.
            inlines.splice(index, 1);

            // Adjust the loop counter to account for the removed item.
            index--;

            continue;
          }
        }
      }

      // Recursive call.
      removeAllCrossReferencesAndMailMergeFieldsFromSfdt(obj[key]);
    }
  } else if (Array.isArray(obj)) {
    for (let item of obj) {
      // Recursive call.
      removeAllCrossReferencesAndMailMergeFieldsFromSfdt(item);
    }
  }
}
