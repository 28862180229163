import { Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Route, Routes, useNavigate } from "react-router-dom";
import logo from "./assets/img/ct-logo.png";
import { globalStore } from "./state/store";
import { getCanveoTier } from "./utils/getCanveoTier";
import {
  Account,
  Admin,
  Agreement,
  Agreements,
  Dashboard,
  Login,
  SuperAdmin,
  Tasks,
  Templates,
} from "./views";
import CreateAgreement from "./views/CreateAgreement";
import NewPlaybook from "./views/NewPlaybook";
import NewTicket from "./views/NewTicket";
import PlaybookDetails from "./views/PlaybookDetails";

export default function ApplicationRoutes() {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const isLoggedIn = Boolean(state?.user?._id);
  const matchesMediaQuery = useMediaQuery("(min-width: 769px)");

  if (isMobile || isTablet || !matchesMediaQuery) {
    return (
      <>
        <Grid container justifyContent="center" mt="50px">
          <Grid item>
            <img
              alt="canveo logo"
              src={logo}
              style={{
                marginBottom: "30px",
                height: "80px",
              }}
            />
          </Grid>

          <Grid item px={4} mb="50px">
            <Typography variant="h5" align="center">
              Canveo is not yet optimized for mobile devices - please stay tuned
              for updates and access Canveo from your laptop or desktop computer
              for now
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Routes>
      {isLoggedIn && (
        <>
          <Route path="/account" element={<Account />} />
          {!!state?.user?.role?.name &&
            state.user.role.name !== "Counterparty" && (
              <Route path="/admin/:section" element={<Admin />} />
            )}
          <Route path="/agreement/:aid" element={<Agreement />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:tweak" element={<Dashboard />} />
          {!!state?.user?.role?.name &&
            state.user.role.name !== "Counterparty" && (
              <>
                <Route path="/agreements" element={<Agreements />} />
                <Route path="/tasks" element={<Tasks />} />
                {getCanveoTier(state?.user?.email) === "experimental" && (
                  <>
                    <Route
                      path="/templates/playbooks/:playbookId"
                      element={<PlaybookDetails />}
                    />
                    <Route
                      path="/templates/playbook/new"
                      element={<NewPlaybook />}
                    />
                  </>
                )}
                <Route path="/templates/:tid" element={<Templates />} />
              </>
            )}
          <Route path="/new/:type?" element={<CreateAgreement />} />
          <Route path="/new-ticket" element={<NewTicket />} />
          {!!state?.user?.role?.name &&
            state.user.role.name !== "Counterparty" &&
            ["dbuser@getcanveo.com", "owner1@getcanveo.com"].includes(
              state.user.email
            ) && <Route path="/superadmin" element={<SuperAdmin />} />}
        </>
      )}

      {/* Public routes */}
      <Route path="/createpassword/:createstring" element={<Login />} />
      <Route
        path="/loginp/:reroutetype/:uid/:id/:modifier"
        element={<Login />} /* reroute login */
      />
      <Route
        path="/loginc/:customurl/:uid/:aid"
        element={<Login />} /* customlink login */
      />
      <Route path="/" element={<Login />} />

      {/* Fallback route. If nothing matches the previous routes we redirect to the dashboard or login page. */}
      <Route
        path="*"
        element={<RedirectComponent isLoggedIn={Boolean(isLoggedIn)} />}
      />
    </Routes>
  );
}

/**
 * @param {{ isLoggedIn: boolean; }} props
 * @returns {JSX.Element}
 */
function RedirectComponent({ isLoggedIn }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(isLoggedIn ? "/dashboard" : "/");
  });

  return <></>;
}
