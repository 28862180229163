/**
 * @param {ApprovalList} approvalList
 */
export function getApprovalsAssignees(approvalList) {
  const approvers = approvalList.filter(
    (item) => item.type === "user" && item.role === "approver"
  );

  const assignees = [];
  for (const reviewer of approvers) {
    if (reviewer.type !== "user") continue;

    assignees.push({
      _id: reviewer._id,
      displayName: reviewer.displayName,
      photoURL: reviewer.photoUrl,
      title: reviewer.title,
    });
  }

  return assignees;
}
