import { Box, Grid } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CanveoCircularProgress, Editor } from "../components";
import { globalStore } from "../state/store";

const Agreement = () => {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { aid } = useParams();
  if (!aid) throw new Error("Agreement ID is required.");

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  useEffect(
    () => {
      setLoading(true);

      axios
        .get(state.settings.api + "agr/agrdetails/" + aid)
        .then((resAgDetail) => {
          if (resAgDetail.data.success) {
            dispatch({ type: "INIT_AGRS", payload: resAgDetail.data.data.ags });
            dispatch({ type: "INIT_AVS", payload: resAgDetail.data.data.avs });
            dispatch({
              type: "INIT_PARTIES",
              payload: resAgDetail.data.data.parties,
            });
            dispatch({
              type: "UPDATE_AGREXEC",
              payload: resAgDetail.data.data.agrExec,
            });
            dispatch({
              type: "INIT_WORKFLOWS",
              payload: resAgDetail.data.data.workflows,
            });
            dispatch({
              type: "INIT_PARAMS_DOC",
              payload: resAgDetail.data.data.params,
            });
            setLoading(false);
          } else {
            console.log("todo: err handling1");
          }
        })
        .catch((err) => {
          console.log(
            "err while loading! todo err handling and signalling",
            err
          );
          navigate("/dashboard");
        });
    },
    // Runs only when `aid` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aid]
  );

  return (
    <div>
      {loading ||
      state.agrs.length === 0 ||
      !state.agrs.some((/** @type {{ _id: string; }} */ a) => a._id === aid) ||
      state.avs.length === 0 ||
      !state.avs.some(
        (/** @type {{ agrID: string; }} */ av) => av.agrID === aid
      ) ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100vh" }}
        >
          <Grid item sx={{ mb: 1 }}>
            <CanveoCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ mt: 10, mb: 6, mx: 0 }}>
          {/* @ts-ignore */}
          <Editor page="Agreement" aid={aid} />
        </Box>
      )}
    </div>
  );
};

export default Agreement;
