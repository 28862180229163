import {
  faBuilding,
  faCity,
  faEnvelope,
  faLandmark,
  faMapMarkedAlt,
  faMapSigns,
  faRoad,
  faSign,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import * as Yup from "yup";
import { companySizeRanges, countries, industries } from "../../assets/static";
import { globalStore } from "../../state/store";
import theme from "../../theme/theme";

const styles = {
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  showLink: {
    margin: "-10px 0px -5px 0px",
    fontWeight: "600",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  mapIcon: {
    color: theme.palette.secondary.main,
    marginRight: "12px",
  },
};

const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

/**
 * @typedef {*} EntityFormProps
 */

/**
 * @param {*} props
 * @returns {React.JSX.Element}
 */
export default function EntityForm(props) {
  // @ts-ignore
  const [state] = useContext(globalStore);
  const [entityCategory, setEntityCategory] = React.useState(
    /** @type {* | null} */ (null)
  );
  const [hideAddress2, setHideAddress2] = React.useState(
    !props.initialValues.address2
  );

  const [showAddressFields] = React.useState(props.showAddressFields);

  useEffect(() => {
    setEntityCategory(
      props.initialValues.entityCategory !== undefined
        ? props.initialValues.entityCategory
        : "Company"
    );
  }, [props.initialValues]);

  /**
   * @param {*} event
   */
  const handleCategoryChange = (event) => {
    setEntityCategory(event.target.value);
  };

  function getFormSchema() {
    let /** @type {*} */ valSchema = Yup.object({
        // BASE SCHEMA
        shortName: Yup.string()
          .min(2, "The organization name is too short")
          .max(40, "The organization name is too long")
          .required("Organization name is required"),
        legalName: Yup.string()
          .min(4, "The legal entity name is too short")
          .max(50, "The legal entity name is too long")
          .required("Legal entity name is required"),
        streetAndNo: Yup.string()
          .min(4, "The address line is too short")
          .max(50, "The address is too long")
          .required("Address is required"),
        address2: Yup.string()
          .min(3, "The address line is too short")
          .max(50, "The address is too long"),
        city: Yup.string()
          .min(3, "The city is too short")
          .max(40, "The city is too long")
          .required("City is required"),
        zip: Yup.string()
          .min(3, "The ZIP is too short")
          .max(15, "The ZIP is too long")
          .required("ZIP is required"),
        state: Yup.string()
          .min(1, "The State is too short")
          .max(30, "The State is too long"),
        country: Yup.object().nullable().required("Country is required"),
        size: Yup.string().nullable().required("Company Size is required"),
        industries: Yup.string().nullable().required("Industry is required"),
        domain: Yup.string()
          .nullable()
          // .required("Domain is required")
          .matches(urlRegex, "Enter correct domain"),
        defaultLegalEntity: Yup.boolean(),
      });

    if (entityCategory === "Person") {
      valSchema = valSchema.shape({
        // TWEAK SCHEMA FOR PERSON
        firstName: Yup.string()
          .min(2, "The first name is too short")
          .max(40, "The first name is too long")
          .required("First name is required"),
        lastName: Yup.string()
          .min(2, "The last name is too short")
          .max(40, "The last name is too long")
          .required("Last name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email address is required"),
        title: Yup.string()
          .min(2, "The title is too short")
          .max(40, "The title is too long")
          .required("A title is required"),
        shortName: Yup.string().notRequired(),
        legalName: Yup.string().notRequired(),
        size: Yup.string().nullable().notRequired(),
        industries: Yup.string().nullable().notRequired(),
        domain: Yup.string().nullable().notRequired(),
      });
    }
    if (props.optionalAddress) {
      // TODO: Tweak schema and make address optional.

      valSchema = valSchema.shape({
        // TODO: Tweak schema for person.
        streetAndNo: Yup.string().notRequired(),
        address2: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        zip: Yup.string().notRequired(),
      });
    }

    if (props.hideFields.includes("size")) {
      valSchema = valSchema.shape({
        size: Yup.string().nullable().notRequired(),
      });
    }

    if (props.hideFields.includes("industries")) {
      valSchema = valSchema.shape({
        industries: Yup.string().nullable().notRequired(),
      });
    }

    if (props.hideFields.includes("domain")) {
      valSchema = valSchema.shape({
        domain: Yup.string().nullable().notRequired(),
      });
    }
    return valSchema;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      entityCategory: entityCategory,
      shortName: props.initialValues.shortName,
      legalName: props.initialValues.legalName,
      streetAndNo: props.initialValues.streetAndNo,
      address2: props.initialValues.address2,
      city: props.initialValues.city,
      zip: props.initialValues.zip,
      state: props.initialValues.state,
      firstName: props.initialValues.firstName,
      lastName: props.initialValues.lastName,
      email: props.initialValues.email,
      title: props.initialValues.title,
      country:
        countries.find((c) => c.code === props.initialValues.country) ?? null,
      size: props.initialValues.size,
      industries: props.initialValues.industries,
      domain: props.initialValues.domain,
      defaultLegalEntity: props.initialValues.defaultLegalEntity || false,
    },
    validationSchema: getFormSchema(),
    onSubmit: (/** @type {*} */ values) => {
      values.country = props.isSignup ? null : values.country.code;
      values.entityCategory = entityCategory;
      values.industries = [values.industries]; // Have it as a list in the db for future proofing the data
      values.displayName =
        ["Person"].includes(entityCategory) || props.isSignup
          ? values.firstName + " " + values.lastName
          : "";
      values.shortName = ["Person"].includes(entityCategory)
        ? values.displayName
        : values.shortName;
      values.legalName = ["Person"].includes(entityCategory)
        ? values.displayName
        : values.legalName;

      // TODO: If Person and creating NEW => check if the email does not already exist.
      props.handleSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        {!props.hideFields.includes("entityCategory") ? (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl component="fieldset">
              <RadioGroup
                row
                name="entityCategory"
                value={entityCategory}
                onChange={handleCategoryChange}
              >
                <FormControlLabel
                  value="Company"
                  control={<Radio color="primary" />}
                  label="Company"
                />
                <FormControlLabel
                  value="Person"
                  control={<Radio color="primary" />}
                  label="Person"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : (
          ""
        )}

        {(["Person"].includes(entityCategory) || props.isSignup) &&
        !props.hideFields.includes("firstName") ? (
          <Grid item container xs={12}>
            <Grid item container xs={6} style={{ paddingRight: "20px" }}>
              <TextField
                required
                disabled={props.disableFields.includes("firstName")}
                fullWidth
                variant="outlined"
                id="firstName"
                label={"First Name(s)"}
                placeholder={"e.g. Jonathan Robert"}
                // @ts-ignore
                name="firstName"
                autoComplete="firstName"
                {...formik.getFieldProps("firstName")}
                error={!!(formik.touched.firstName && formik.errors.firstName)}
                // @ts-ignore
                helperText={
                  formik.touched.firstName && formik.errors.firstName
                    ? formik.errors.firstName
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faUser} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item container xs={6}>
              <TextField
                required
                disabled={props.disableFields.includes("lastName")}
                fullWidth
                variant="outlined"
                id="lastName"
                label={"Last Name"}
                placeholder={"e.g. Williams"}
                // @ts-ignore
                name="lastName"
                autoComplete="lastName"
                {...formik.getFieldProps("lastName")}
                error={!!(formik.touched.lastName && formik.errors.lastName)}
                // @ts-ignore
                helperText={
                  formik.touched.lastName && formik.errors.lastName
                    ? formik.errors.lastName
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faUser} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {entityCategory === "Company" &&
        !props.hideFields.includes("shortName") ? (
          <Grid item container xs={12}>
            <TextField
              required
              disabled={props.disableFields.includes("shortName")}
              fullWidth
              variant="outlined"
              id="shortName"
              label={"Organization Name"}
              placeholder={"Organization name (e.g. ABC)"}
              // @ts-ignore
              name="shortName"
              autoComplete="sname"
              {...formik.getFieldProps("shortName")}
              error={!!(formik.touched.shortName && formik.errors.shortName)}
              // @ts-ignore
              helperText={
                formik.touched.shortName && formik.errors.shortName
                  ? formik.errors.shortName
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBuilding} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {entityCategory === "Company" &&
        !props.hideFields.includes("legalName") ? (
          <Grid item container xs={12}>
            <TextField
              required
              disabled={props.disableFields.includes("legalName")}
              fullWidth
              variant="outlined"
              id="legalName"
              label={"Legal Entity Name"}
              placeholder={"Legal entity name (e.g. ABC LLC)"}
              // @ts-ignore
              name="legalName"
              autoComplete="legalname"
              {...formik.getFieldProps("legalName")}
              error={!!(formik.touched.legalName && formik.errors.legalName)}
              // @ts-ignore
              helperText={
                formik.touched.legalName && formik.errors.legalName
                  ? formik.errors.legalName
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLandmark} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {(["Person"].includes(entityCategory) || props.isSignup) &&
        !props.hideFields.includes("email") ? (
          <Grid item container xs={12}>
            <TextField
              required
              disabled={props.disableFields.includes("email")}
              fullWidth
              variant="outlined"
              id="email"
              label={"Email Address"}
              placeholder={"name@domain.com"}
              // @ts-ignore
              name="email"
              autoComplete="email"
              {...formik.getFieldProps("email")}
              error={!!(formik.touched.email && formik.errors.email)}
              // @ts-ignore
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {(["Person"].includes(entityCategory) || props.isSignup) &&
        !props.hideFields.includes("title") ? (
          <Grid item container xs={12}>
            <TextField
              required
              disabled={props.disableFields.includes("title")}
              fullWidth
              variant="outlined"
              id="title"
              label={"Title"}
              placeholder={"Corporate title"}
              // @ts-ignore
              name="title"
              autoComplete="title"
              {...formik.getFieldProps("title")}
              error={!!(formik.touched.title && formik.errors.title)}
              // @ts-ignore
              helperText={
                formik.touched.title && formik.errors.title
                  ? formik.errors.title
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faLandmark} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {/*Done on October cleanup */}
        {/*{props.optionalAddress ? (*/}
        {/*  <Button*/}
        {/*    variant="text"*/}
        {/*    disableElevation*/}
        {/*    sx={{ mt: 2 }}*/}
        {/*    onClick={() => setShowAddressFields(!showAddressFields)}*/}
        {/*  >*/}
        {/*    {showAddressFields ? "Hide" : "Complete"} address&nbsp;&nbsp;*/}
        {/*    <FontAwesomeIcon*/}
        {/*      icon={showAddressFields ? faChevronCircleUp : faChevronCircleDown}*/}
        {/*    />*/}
        {/*  </Button>*/}
        {/*) : (*/}
        {/*  ""*/}
        {/*)}*/}

        {!props.hideFields.includes("streetAndNo") &&
        (!props.optionalAddress || showAddressFields) ? (
          <Grid item container xs={12}>
            <TextField
              disabled={props.disableFields.includes("streetAndNo")}
              fullWidth
              variant="outlined"
              id="streetAndNo"
              label="Address Line 1"
              placeholder="Street and number"
              // @ts-ignore
              name="streetAndNo"
              autoComplete="streetAndNo"
              {...formik.getFieldProps("streetAndNo")}
              error={
                !!(formik.touched.streetAndNo && formik.errors.streetAndNo)
              }
              // @ts-ignore
              helperText={
                formik.touched.streetAndNo && formik.errors.streetAndNo
                  ? formik.errors.streetAndNo
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faRoad} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {!props.hideFields.includes("address2") &&
        (!props.optionalAddress || showAddressFields) ? (
          <Grid item container xs={12}>
            {hideAddress2 ? (
              <Box width="100%">
                <Typography
                  variant="body2"
                  align="right"
                  onClick={() => setHideAddress2(false)}
                  style={styles.showLink}
                >
                  + Address Line 2
                </Typography>
              </Box>
            ) : (
              <TextField
                disabled={props.disableFields.includes("address2")}
                fullWidth
                variant="outlined"
                id="address2"
                label="Address Line 2"
                placeholder="Address line 2"
                // @ts-ignore
                name="address2"
                autoComplete="address2"
                {...formik.getFieldProps("address2")}
                error={!!(formik.touched.address2 && formik.errors.address2)}
                // @ts-ignore
                helperText={
                  formik.touched.address2 && formik.errors.address2
                    ? formik.errors.address2
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSign} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
        ) : (
          ""
        )}

        {!props.hideFields.includes("city") &&
        (!props.optionalAddress || showAddressFields) ? (
          <Grid item container xs={12}>
            <TextField
              disabled={props.disableFields.includes("city")}
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              placeholder="City"
              // @ts-ignore
              name="city"
              autoComplete="city"
              {...formik.getFieldProps("city")}
              error={!!(formik.touched.city && formik.errors.city)}
              // @ts-ignore
              helperText={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : ""
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faCity} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ) : (
          ""
        )}

        {!props.hideFields.includes("zip") &&
        (!props.optionalAddress || showAddressFields) ? (
          <Grid item container xs={12}>
            <Grid direction="row" container>
              <Grid item xs={6} style={{ paddingRight: "8px" }}>
                <TextField
                  disabled={props.disableFields.includes("zip")}
                  fullWidth
                  variant="outlined"
                  id="zip"
                  label="ZIP Code"
                  placeholder="ZIP code"
                  // @ts-ignore
                  name="zip"
                  autoComplete="zip"
                  {...formik.getFieldProps("zip")}
                  error={!!(formik.touched.zip && formik.errors.zip)}
                  // @ts-ignore
                  helperText={
                    formik.touched.zip && formik.errors.zip
                      ? formik.errors.zip
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faMapSigns} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ paddingLeft: "8px" }}>
                <TextField
                  disabled={props.disableFields.includes("state")}
                  fullWidth
                  variant="outlined"
                  id="state"
                  label="State/Province"
                  placeholder="State / Province / Region"
                  // @ts-ignore
                  name="state"
                  autoComplete="state"
                  {...formik.getFieldProps("state")}
                  error={!!(formik.touched.state && formik.errors.state)}
                  // @ts-ignore
                  helperText={
                    formik.touched.state && formik.errors.state
                      ? formik.errors.state
                      : ""
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}

        {!props.hideFields.includes("country") && (
          <Grid item container xs={12}>
            <Autocomplete
              sx={{ width: "100%" }}
              disabled={props.disableFields.includes("country")}
              options={countries}
              value={formik.values.country}
              autoHighlight
              // @ts-ignore
              onChange={(e, value) => formik.setFieldValue("country", value)}
              getOptionLabel={(option) =>
                option.label !== undefined ? option.label : ""
              }
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <span>{countryToFlag(option.code)}</span>
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={"Country"}
                  variant="outlined"
                  error={!!(formik.touched.country && formik.errors.country)}
                  // @ts-ignore
                  helperText={
                    formik.touched.country && formik.errors.country
                      ? formik.errors.country
                      : ""
                  }
                  inputProps={{
                    // To avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious.
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Grid>
        )}

        {!props.hideFields.includes("size") && entityCategory !== "Person" && (
          <Grid item container xs={12}>
            <Autocomplete
              sx={{ width: "100%" }}
              options={companySizeRanges}
              value={formik.values.size}
              autoHighlight
              // @ts-ignore
              onChange={(e, value) => formik.setFieldValue("size", value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={"Company Size"}
                  variant="outlined"
                  error={!!(formik.touched.size && formik.errors.size)}
                  // @ts-ignore
                  helperText={
                    formik.touched.size && formik.errors.size
                      ? formik.errors.size
                      : ""
                  }
                  inputProps={{
                    // To avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious.
                    ...params.inputProps,
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </Grid>
        )}

        {!props.hideFields.includes("industries") &&
          entityCategory !== "Person" && (
            <Grid item container xs={12}>
              <Autocomplete
                sx={{ width: "100%" }}
                options={industries}
                value={formik.values.industries}
                autoHighlight
                // @ts-ignore
                onChange={(e, value) =>
                  formik.setFieldValue("industries", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={"Industry"}
                    variant="outlined"
                    error={
                      !!(formik.touched.industries && formik.errors.industries)
                    }
                    // @ts-ignore
                    helperText={
                      formik.touched.industries && formik.errors.industries
                        ? formik.errors.industries
                        : ""
                    }
                    inputProps={{
                      // To avoid autosuggest leakage, include this in the text field and remove "id" or make it non-obvious.
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
              />
            </Grid>
          )}

        {!props.hideFields.includes("domain") &&
          entityCategory !== "Person" && (
            <Grid item container xs={12}>
              <TextField
                // required
                disabled={props.disableFields.includes("domain")}
                fullWidth
                variant="outlined"
                id="domain"
                label="Domain"
                placeholder='For example "canveo.com"'
                // @ts-ignore
                name="domain"
                autoComplete="domain"
                {...formik.getFieldProps("domain")}
                error={!!(formik.touched.domain && formik.errors.domain)}
                // @ts-ignore
                helperText={
                  formik.touched.domain && formik.errors.domain
                    ? formik.errors.domain
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">https://</InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}

        {!props.showAddressFields && state.user.orgID === props.orgId && (
          <Grid item container xs={12}>
            <Grid container>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.getFieldProps("defaultLegalEntity").value}
                      onChange={() => {
                        formik.setFieldValue(
                          "defaultLegalEntity",
                          !formik.getFieldProps("defaultLegalEntity").value
                        );
                      }}
                    />
                  }
                  label="Default legal entity"
                />
              </FormGroup>
            </Grid>
            <Grid container>
              <Typography variant="body2">
                Automatically add this entity as a party to any new agreement
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item container xs={12}>
          <Button
            fullWidth
            disableElevation
            sx={{ fontSize: "17px", fontWeight: "600", padding: "7px 20px" }}
            type={"submit"}
            variant="contained"
            color="primary"
            disabled={props.submitting}
          >
            {props.buttonName}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

/**
 * @param {string} isoCode
 * @returns {string}
 */
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined" && isoCode !== undefined
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
