import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@mui/material";
import React from "react";
import theme from "../theme/theme";

const styles = {
  pillStd: {
    width: "200px",
    fontWeight: "500",
  },
  pillTiny: {
    width: "120px",
    fontWeight: "500",
  },
  pillTinyLegal: {
    width: "120px",
    // @ts-ignore
    backgroundColor: theme.palette.tertiary.main,
    // @ts-ignore
    color: theme.palette.tertiary.contrastText,
  },
  pillTinyCpty: {
    width: "120px",
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText,
  },
  pillLegal: {
    width: "200px",
    // @ts-ignore
    backgroundColor: theme.palette.tertiary.main,
    // @ts-ignore
    color: theme.palette.tertiary.contrastText,
  },
  pillCpty: {
    width: "200px",
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText,
  },
  iconUser: {
    // @ts-ignore
    color: theme.palette.tertiary.contrastText,
    marginLeft: "8px",
    fontSize: "11px",
  },
};

/**
 * @typedef {object} ChipUserTypeProps
 * @property {string} type
 * @property {"Admin" | "Legal" | "Counterparty" | string & {}} role
 * @property {boolean} [readOnly]
 */

/**
 * @param {ChipUserTypeProps} props
 * @returns {JSX.Element}
 */
export default function ChipUserType({ type, role, readOnly }) {
  return (
    <Chip
      size={type === "tiny" ? "small" : "medium"}
      label={
        <>
          {role}
          {readOnly ? (
            <FontAwesomeIcon icon={faEye} style={styles.iconUser} />
          ) : (
            ""
          )}
        </>
      }
      color={role === "Admin" ? "secondary" : "primary"}
      style={
        type === "tiny" && role === "Legal"
          ? styles.pillTinyLegal
          : type !== "tiny" && role === "Legal"
          ? styles.pillLegal
          : type === "tiny" && role === "Counterparty"
          ? styles.pillTinyCpty
          : type !== "tiny" && role === "Counterparty"
          ? styles.pillCpty
          : type === "tiny"
          ? styles.pillTiny
          : styles.pillStd
      }
    />
  );
}
