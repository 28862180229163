import { faEllipsisV, faFileImport } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import useMergeFieldService from "../hooks/useMergeFieldService";
import { globalStore } from "../state/store";
import { getCanveoTier } from "../utils/getCanveoTier";
import { currencyCodes, durationUnits } from "./MergeFieldMenu/constants";
import {
  getMergeFieldDefaultValue,
  getMergeFieldDisplayValue,
  getMergeFieldValue,
  mergeFieldValueIsValid,
} from "./MergeFieldMenu/utils";

/**
 * @typedef {object} OpenIssueMergeFieldMenuProps
 * @property {import("./editor/plugins/OpenIssuesPlugin").OpenIssue} openIssue
 * @property {MergeField} mergeField
 * @property {boolean} isInEffect
 * @property {boolean} isTemplate
 * @property {boolean} templateIsActive
 * @property {boolean} notVersionOwner
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenDeleteMergeFieldDialog
 * @property {(editorMarkNodeId: string, mergeField: MergeField) => void} handleMergeFieldUpdate
 */

/**
 * @param {OpenIssueMergeFieldMenuProps} props
 * @returns {JSX.Element}
 */
export default function OpenIssueMergeFieldMenu({
  openIssue,
  mergeField,
  isInEffect,
  isTemplate,
  templateIsActive,
  notVersionOwner,
  setOpenDeleteMergeFieldDialog,
  handleMergeFieldUpdate,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const { updateMergeField } = useMergeFieldService();

  const canveoTier = getCanveoTier(state?.user?.email);

  const [mergeFieldValue, setMergeFieldValue] = useState(mergeField.value);

  const [selectedListOptionsIds, setSelectedListOptionsIds] = useState(
    mergeField.selectedListOptionsIds
  );
  useEffect(() => {
    if (mergeField.selectedListOptionsIds.length === 0) return;

    setSelectedListOptionsIds(mergeField.selectedListOptionsIds);
  }, [mergeField.selectedListOptionsIds]);

  useEffect(() => {
    if (!mergeField.value) return;

    setMergeFieldValue(mergeField.value);
  }, [mergeField.value]);

  //   const [anchorEl, setAnchorEl] = useState(null);
  //   const open = Boolean(anchorEl);
  //   const handleRefreshMergeFieldClick = (/** @type {*} **/ event) => {
  //     setAnchorEl(event.currentTarget);
  //   };
  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  const [anchorElement, setAnchorElement] = useState(null);
  const openOperationsMenu = Boolean(anchorElement);
  const handleMergeFieldOperationsMenuClick = (/** @type {*} */ event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleMergeFieldOperationsMenuClose = () => {
    setAnchorElement(null);
  };

  /**
   * @returns {void}
   */
  function openMergeFieldMenu() {
    dispatch({
      type: "OPEN_MERGE_FIELD_MENU",
      payload: openIssue?.mergeField,
    });
  }

  /**
   * @returns {boolean}
   */
  function displayMergeFieldOperationsEllipsis() {
    if (notVersionOwner) return false;

    if (isInEffect) return false;

    if (isTemplate && templateIsActive) return false;

    if (state.user.role.name === "Counterparty") return false;

    const [latestVersion] = state.drawerVersions.versions;
    if (!latestVersion) return false;

    if (latestVersion?._id !== state.drawerVersions?.active?._id) {
      return false;
    }

    if (latestVersion.basedOnApproved || latestVersion.basedOnReviewed) {
      return false;
    }

    return true;
  }

  async function submitMergeFieldValueChanges() {
    const updatedMergeField = await updateMergeField(
      {
        ...mergeField,
        value: mergeFieldValue,
        selectedListOptionsIds,
        displayValue: getMergeFieldDisplayValue(mergeFieldValue),
      },
      "Merge Field"
    ).catch((error) => {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message: "An error occurred while updating the merge field value.",
          severity: "error",
        },
      });
    });

    handleMergeFieldUpdate(
      updatedMergeField.editorMarkNodeId,
      updatedMergeField
    );
  }

  function canModifyMergeFieldValue() {
    // Do not allow modifying the merge field value if there is no current version or if the version is a
    // "basedOn" or approved/reviewed version.
    const [latestVersion] = state.drawerVersions.versions;
    if (
      !latestVersion ||
      latestVersion.approved ||
      latestVersion.basedOnApproved ||
      latestVersion.reviewed ||
      latestVersion.basedOnReviewed
    ) {
      return false;
    }

    // Do not allow modifying the merge field value if the active version is different from the latest version.
    if (latestVersion?._id !== state?.drawerVersions?.active?._id) {
      return false;
    }

    // Do not allow modifying the merge field value if the we are on a template and the merge field has been
    // configured to set its value later.
    if (isTemplate && mergeField.setValueLater) return false;

    // If we have reached this far then the merge field value can be modified!
    return true;
  }

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: "10px",
          borderTop: "1px dotted rgba(0, 0, 0, 0.26)",
        }}
      />

      <br />

      <div style={{ padding: "10px", width: "100%" }}>
        <Grid container direction={"row"} alignItems={"center"}>
          <Grid container item xs={2} justifyContent={"center"}>
            <FontAwesomeIcon
              icon={faFileImport}
              style={{
                fontSize: "22px",
                color: "#5B5B5B",
              }}
            />
          </Grid>

          <Grid item xs={8} alignItems={"center"} sx={{ paddingLeft: "10px" }}>
            <b>Merge Field</b>
          </Grid>

          {displayMergeFieldOperationsEllipsis() && (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                onClick={(event) => handleMergeFieldOperationsMenuClick(event)}
              >
                <FontAwesomeIcon
                  icon={faEllipsisV}
                  style={{
                    fontSize: "18px",
                    color: "#5B5B5B",
                  }}
                />
              </IconButton>
            </Grid>
          )}

          <Menu
            id="operations-menu"
            anchorEl={anchorElement}
            open={openOperationsMenu}
            onClose={handleMergeFieldOperationsMenuClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(isTemplate || canveoTier === "experimental") && (
              <MenuItem
                onClick={() => {
                  openMergeFieldMenu();
                  handleMergeFieldOperationsMenuClose();
                }}
              >
                <span>Edit</span>
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                setOpenDeleteMergeFieldDialog(true);
                handleMergeFieldOperationsMenuClose();
              }}
            >
              Remove
            </MenuItem>
          </Menu>
        </Grid>

        <Grid container direction={"row"}>
          <br />
        </Grid>

        <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={2} />
          <Grid item xs={10} alignItems={"center"} sx={{ paddingLeft: "10px" }}>
            <div>
              <span
                style={{
                  color: "#989898",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                Name
              </span>
            </div>

            <div>
              <b>
                <div
                  style={{
                    wordBreak: "break-word",
                    maxWidth: "200px",
                    whiteSpace: "break-spaces",
                  }}
                >
                  {mergeField.name}
                </div>

                {mergeField.partyRole ? ` (${mergeField.partyRole})` : ""}
              </b>
            </div>
          </Grid>
        </Grid>

        <Grid container direction={"row"}>
          <br />
        </Grid>

        {mergeField.type !== "partyInformation" && (
          <>
            <Grid container direction={"row"} alignItems={"center"}>
              <Grid item xs={2} />
              <Grid
                item
                xs={10}
                alignItems={"center"}
                sx={{ paddingLeft: "10px" }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Current value
                </span>
              </Grid>
            </Grid>

            <Grid container direction={"row"} alignItems={"center"} mt={1}>
              <Grid item xs={2} />
              <Grid
                item
                xs={10}
                alignItems={"center"}
                sx={{ paddingLeft: "10px" }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  <div>
                    <span
                      style={{
                        color: "#292929",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {mergeField.wizardQuestion}
                    </span>
                  </div>
                </span>
              </Grid>
            </Grid>
          </>
        )}

        {mergeField.type === "partyInformation" && (
          <>
            <Grid container direction={"row"} alignItems={"center"}>
              <Grid item xs={2} />
              <Grid
                item
                xs={10}
                alignItems={"center"}
                sx={{ paddingLeft: "10px" }}
              >
                <span
                  style={{
                    color: "#989898",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Related party
                </span>
              </Grid>
            </Grid>

            <Grid container direction={"row"} alignItems={"center"} mb={2}>
              <Grid item xs={2} />
              <Grid
                item
                xs={10}
                alignItems={"center"}
                sx={{ paddingLeft: "10px" }}
              >
                <span style={{ color: "#292929", fontWeight: "bold" }}>
                  {mergeField.organizationName} ({mergeField.partyRole})
                </span>
              </Grid>
            </Grid>
          </>
        )}

        <Grid container direction={"row"} alignItems={"center"} mt={2}>
          <Grid item xs={2} />
          <Grid item xs={10} alignItems={"center"} sx={{ paddingLeft: "10px" }}>
            <i>
              "
              {mergeField.setValueLater
                ? "[No value set yet]"
                : mergeField.displayValue
                ? mergeField.displayValue
                : getMergeFieldValue(mergeField.value)}
              "
            </i>

            {canModifyMergeFieldValue() && (
              <>
                {mergeField.isList ? (
                  <Grid
                    container
                    direction={"row"}
                    justifyContent={"start"}
                    mt={2}
                  >
                    {mergeField.allowSelectingMultipleListOptions ? (
                      <Autocomplete
                        multiple
                        sx={{ width: "188px" }}
                        options={mergeField.listOptions}
                        value={
                          mergeField.listOptions.filter((option) =>
                            selectedListOptionsIds.includes(option.id)
                          ) || null
                        }
                        autoHighlight
                        getOptionLabel={(option) =>
                          getMergeFieldDisplayValue(option.mergeFieldValue)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(_, option) => {
                          if (option) {
                            const selectedOptionsIds = option.map((o) => o.id);
                            setSelectedListOptionsIds(selectedOptionsIds);
                          } else {
                            setSelectedListOptionsIds([]);
                            setMergeFieldValue(
                              getMergeFieldDefaultValue(mergeFieldValue.type)
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="List Value"
                              placeholder="List Value"
                              variant="outlined"
                            />
                          </>
                        )}
                      />
                    ) : (
                      <Autocomplete
                        sx={{ width: "188px" }}
                        options={mergeField.listOptions}
                        value={
                          mergeField.listOptions.find(
                            (option) => option.id === selectedListOptionsIds[0]
                          ) || null
                        }
                        autoHighlight
                        getOptionLabel={(option) =>
                          getMergeFieldDisplayValue(option.mergeFieldValue)
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        onChange={(_, option) => {
                          if (option) {
                            setSelectedListOptionsIds([option.id]);
                            setMergeFieldValue(option.mergeFieldValue);
                          } else {
                            setSelectedListOptionsIds([]);
                            setMergeFieldValue(
                              getMergeFieldDefaultValue(mergeFieldValue.type)
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              label="List Value"
                              placeholder="List Value"
                              variant="outlined"
                            />
                          </>
                        )}
                      />
                    )}
                  </Grid>
                ) : (
                  <>
                    {mergeFieldValue.type === "freeText" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="start"
                        mt={2}
                      >
                        <Grid item>
                          <TextField
                            value={mergeFieldValue.value}
                            autoFocus
                            sx={{ width: "188px" }}
                            variant="outlined"
                            placeholder="Free Text Value"
                            label="Free Text Value"
                            onChange={(event) =>
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: event.target.value,
                              })
                            }
                          />
                        </Grid>
                      </Grid>
                    )}

                    {mergeFieldValue.type === "date" && (
                      <>
                        <Grid
                          container
                          direction={"row"}
                          justifyContent={"start"}
                          mt={2}
                        >
                          <TextField
                            type="date"
                            autoFocus
                            value={
                              mergeFieldValue.value
                                ? format(
                                    new Date(mergeFieldValue.value),
                                    "yyyy-MM-dd"
                                  )
                                : ""
                            }
                            placeholder="Date Value"
                            label="Date Value"
                            sx={{ width: "188px" }}
                            variant="outlined"
                            onChange={(event) => {
                              const date = new Date(event.target.value);
                              if (!isNaN(date.getDate())) {
                                const isoStringDate = date.toISOString();
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: isoStringDate,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: "",
                                });
                              }
                            }}
                          />
                        </Grid>
                      </>
                    )}

                    {mergeFieldValue.type === "duration" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"start"}
                        mt={2}
                        gap={2}
                      >
                        <Grid item>
                          <TextField
                            type="number"
                            autoFocus={true}
                            value={mergeFieldValue.durationValue}
                            variant="outlined"
                            placeholder="Duration Value"
                            label="Duration Value"
                            onChange={(event) => {
                              const number = Number(event.target.value);
                              if (number) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationValue: event.target.value,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationValue: "",
                                });
                              }
                            }}
                            sx={{ width: "188px" }}
                          />
                        </Grid>

                        <Grid item>
                          <Autocomplete
                            options={durationUnits}
                            autoHighlight
                            fullWidth
                            getOptionLabel={(option) => option.label}
                            value={
                              durationUnits.find(
                                (x) =>
                                  x.value === mergeFieldValue.durationUnit.value
                              ) || durationUnits[2]
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(_, option) => {
                              if (option) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationUnit: option,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  durationUnit: {
                                    label: "",
                                    value: "",
                                  },
                                });
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Duration Unit"
                                placeholder="Select Duration Unit ..."
                                variant="outlined"
                                fullWidth
                              />
                            )}
                            sx={{ width: "188px" }}
                          />
                        </Grid>
                      </Grid>
                    )}

                    {mergeFieldValue.type === "number" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent={"start"}
                        mt={2}
                      >
                        <TextField
                          type="number"
                          autoFocus
                          placeholder="Number Value"
                          label="Number Value"
                          value={mergeFieldValue.value}
                          sx={{ width: "188px" }}
                          variant="outlined"
                          onChange={(event) => {
                            const number = Number(event.target.value);
                            if (number) {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: event.target.value,
                              });
                            } else {
                              setMergeFieldValue({
                                ...mergeFieldValue,
                                value: "",
                              });
                            }
                          }}
                        />
                      </Grid>
                    )}

                    {mergeFieldValue.type === "currency" && (
                      <>
                        <Grid
                          container
                          direction="row"
                          justifyContent="start"
                          mt={2}
                          gap={2}
                        >
                          <Grid item>
                            <Autocomplete
                              options={currencyCodes}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              isOptionEqualToValue={(option, value) =>
                                option.value === value.value
                              }
                              sx={{ width: "188px" }}
                              value={
                                currencyCodes.find(
                                  (x) =>
                                    x.value ===
                                    mergeFieldValue.currencyUnit.value
                                ) || null
                              }
                              onChange={(_, option) => {
                                if (option) {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    currencyUnit: option,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    currencyUnit: {
                                      label: "",
                                      value: "",
                                    },
                                  });
                                }
                              }}
                              renderInput={(params) => (
                                <>
                                  <TextField
                                    {...params}
                                    autoFocus={true}
                                    label="Currency Unit"
                                    placeholder="Select Currency Unit ..."
                                    variant="outlined"
                                  />
                                </>
                              )}
                            />
                          </Grid>

                          <Grid item>
                            <TextField
                              type="number"
                              label="Currency Value"
                              placeholder="Currency Value"
                              value={mergeFieldValue.currencyValue}
                              variant="outlined"
                              sx={{ width: "188px" }}
                              onChange={(event) => {
                                const number = Number(event.target.value);
                                if (number) {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    currencyValue: event.target.value,
                                  });
                                } else {
                                  setMergeFieldValue({
                                    ...mergeFieldValue,
                                    currencyValue: "",
                                  });
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {mergeFieldValue.type === "percentage" && (
                      <Grid
                        container
                        direction={"row"}
                        justifyContent="start"
                        mt={2}
                        alignItems={"center"}
                      >
                        <Grid item>
                          <TextField
                            placeholder="Percentage Value"
                            type="number"
                            label="Percentage Value"
                            autoFocus={true}
                            value={mergeFieldValue.value}
                            variant="outlined"
                            sx={{ width: "156px" }}
                            onChange={(event) => {
                              const number = Number(event.target.value);
                              if (number) {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: event.target.value,
                                });
                              } else {
                                setMergeFieldValue({
                                  ...mergeFieldValue,
                                  value: "",
                                });
                              }
                            }}
                          />
                        </Grid>

                        <Grid item xs={2} sx={{ paddingLeft: "10px" }}>
                          <div>{"%"}</div>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}

                <Grid container direction="row" justifyContent="end" mt={2}>
                  <Grid item>
                    <Button>Cancel</Button>
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={!mergeFieldValueIsValid(mergeFieldValue)}
                      onClick={submitMergeFieldValueChanges}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* Commented out for the October 2023 release. */}
        {/* <Grid container direction={"row"} alignItems={"center"}>
          <Grid item xs={10} />
          {!isInEffect &&
            state.user.role.name !== "Counterparty" &&
            state.drawerVersions?.active?._id ===
              state.drawerVersions.versions[0]?._id && (
              <Grid item xs={1} sx={{ marginRight: "12px" }}>
                <IconButton
                  onClick={(event) => {
                    handleRefreshMergeFieldClick(event);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faRefresh}
                    style={{
                      fontSize: "18px",
                      color: "#7243DD",
                    }}
                  />
                </IconButton>
              </Grid>
            )}
        </Grid>

        <Menu
          sx={{
            maxWidth: "230px",
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>
            <span>Refresh this merge field</span>
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              whiteSpace: "unset",
              wordBreak: "break-word",
            }}
          >
            Refresh all "{openIssueMergeField.name} (
            {openIssueMergeField.partyRole})" Merge Fields
          </MenuItem>
          <MenuItem
            onClick={handleClose}
            sx={{
              whiteSpace: "unset",
              wordBreak: "break-word",
            }}
          >
            <span>Refresh all merge fields in this agreement</span>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <span>Push to source ...</span>
          </MenuItem>
        </Menu> */}
      </div>
    </>
  );
}
