import theme from "../../../../theme/theme";

const circleWidth = "50px";
const circleHeight = circleWidth;
const iconSize = "20px";

export function getFilledCircleStyles(disabled = false) {
  return {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: circleWidth,
    height: circleHeight,
    backgroundColor:
      theme.palette.primary.main /* Background color of the circle */,
    color: disabled ? "gray" : "white" /* Color of the icon */,
    borderRadius: "50%" /* Makes the container a circle */,
    fontSize: iconSize /* Adjust the size of the icon */,
  };
}

export function getOutlinedCircleStyles(disabled = false) {
  return {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: circleWidth,
    height: circleHeight,
    backgroundColor: "white" /* Background color of the circle */,
    color: disabled
      ? "gray"
      : theme.palette.primary.main /* Color of the icon */,
    borderRadius: "50%" /* Makes the container a circle */,
    fontSize: iconSize /* Adjust the size of the icon */,
    borderColor: theme.palette.primary.main,
    border: "1px solid",
  };
}

export function getIconDashStyles(disabled = false) {
  return {
    width: "50px",
    height: "5px",
    backgroundColor: disabled ? "gray" : theme.palette.primary.main,
    borderRadius: "25px",
  };
}
