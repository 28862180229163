import axios from "axios";
import { useContext } from "react";
import { globalStore } from "../state/store";

export default function useWorkFlowService() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  /**
   * @param {*} updatedWorkFlow
   * @param {*} emailRecipients
   * @returns {Promise<any>}
   */
  async function updateWorkFlow(updatedWorkFlow, emailRecipients = []) {
    const result = await axios
      .put(state.settings.api + "workflow/" + updatedWorkFlow._id, {
        workflow: updatedWorkFlow,
      })
      .catch(() => {
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while updating the comment thread",
            severity: "error",
          },
        });
      });

    if (result && result.data.success) {
      dispatch({ type: "UPDATE_WORKFLOWS", payload: result.data.data });

      emailRecipients.forEach((/** @type {*} */ r) => {
        axios.post(state.settings.api + "mail/informcomm", {
          doc: r.doc,
          whiteLabel: r.isTemplating ? null : null, // TODO: Get this from the agreement.
          partyFullString: r.isTemplating ? null : getPartyFullString(),
          recipient: r.recipient,
          isPublic: !r.isInternal,
          isTemplating: r.isTemplating,
          isApproval: r.isApproval,
          isApprover: r.isApprover,
          comment: r.content,
          clauseHTML: r.clauseHTML,
          recentComments: r.recentComments,
          wfid: updatedWorkFlow._id,
          lid: updatedWorkFlow.lid,
        });
      });

      return result.data.data;
    } else {
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "An error occurred while updating the comment thread - refresh your browser",
          severity: "error",
        },
      });
    }
  }

  // TODO: Move this into another file so that it can be reused across the app.
  function getPartyFullString() {
    let partyFullString;
    const legalNames = state.parties.map(
      (/** @type {{ legalName: string; }} */ p) => p.legalName
    );
    if (state.parties.length >= 2) {
      partyFullString =
        state.parties.length === 2
          ? legalNames.join(" and ")
          : legalNames.join(", ");
    } else {
      partyFullString = state.parties[0]?.legalName;
    }

    return partyFullString;
  }

  return { updateWorkFlow };
}
