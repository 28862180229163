/**
 * @param {ReviewList} reviewList
 */
export function getReviewListAssignees(reviewList) {
  const reviewers = reviewList.filter(
    (item) => item.type === "user" && item.role === "reviewer"
  );

  const assignees = [];
  for (const reviewer of reviewers) {
    if (reviewer.type !== "user") continue;

    assignees.push({
      _id: reviewer._id,
      displayName: reviewer.displayName,
      photoURL: reviewer.photoUrl,
      title: reviewer.title,
    });
  }

  return assignees;
}
