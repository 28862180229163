import { Grid, Typography } from "@mui/material";
import React from "react";
import NewApprovalTicketButton from "./NewApprovalTicketButton";

/**
 * @typedef {object} NewApprovalTicketButtonProps
 * @property {"default" | "left"} [buttonPosition]
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setOpenDialogCreateApprovalTicket
 * @property {string} [title]
 * @property {string} [subtitle]
 */

/**
 * @param {NewApprovalTicketButtonProps} props
 * @returns {JSX.Element}
 */
export function NewApprovalTicketButtonPanel({
  buttonPosition = "default",
  setOpenDialogCreateApprovalTicket,
  title = "Approval Requests",
  subtitle = "Get approvals from senior stakeholders",
}) {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={1}>
          {buttonPosition === "left" && (
            <NewApprovalTicketButton
              setOpenDialogCreateApprovalTicket={
                setOpenDialogCreateApprovalTicket
              }
            />
          )}
        </Grid>

        <Grid item>
          <Typography variant="h4">{title}</Typography>
        </Grid>

        <Grid item xs={1} />
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Grid item xs={1} />

        <Grid item px={20}>
          <Typography textAlign="center">{subtitle}</Typography>
        </Grid>

        <Grid item xs={1} />
      </Grid>

      {buttonPosition === "default" && (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={4}
        >
          <Grid item xs={1} />

          <Grid item>
            <NewApprovalTicketButton
              setOpenDialogCreateApprovalTicket={
                setOpenDialogCreateApprovalTicket
              }
            />
          </Grid>

          <Grid item xs={1} />
        </Grid>
      )}
    </>
  );
}
