import { faStar as faStarOutlined } from "@fortawesome/pro-light-svg-icons";
import { faPaperPlane, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Grid,
  Rating,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useState } from "react";
import { CanveoCircularProgress } from ".";
import { globalStore } from "../state/store";
import theme from "../theme/theme";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    width: "230px",
    borderRadius: "20px 0px 0px 20px",
  },
});

/**
 * @returns {JSX.Element}
 */
export default function ThankYouScreen() {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [loading] = useState(false);
  const [rating, setRating] = useState(/** @type {number | null} */ (null));
  const [message, setMessage] = useState("");
  const [emailValue, setEmailValue] = useState(
    /** @type {string} */ (state?.user?.email)
  );
  const [submittedExperience, setSubmittedExperience] = useState(false);

  const agreementId =
    state.agrs &&
    state.agrs.length &&
    state.agrs
      .filter(
        (/** @type {{ parentID: string; }} */ agreement) => !agreement.parentID
      )
      ?.at(0)
      ? state.agrs
          .filter(
            (/** @type {{ parentID: string; }} */ agreement) =>
              !agreement.parentID
          )
          ?.at(0)?._id
      : "";
  if (!agreementId || submittedExperience) {
    return (
      <Grid container direction="column" alignItems="center">
        <Grid item mt={10}>
          <Typography variant="h3" style={{ fontWeight: "700" }}>
            Thanks for using Canveo
            <span style={{ color: theme.palette.secondary.main }}>.</span>
          </Typography>
        </Grid>

        <Grid item mt={2}>
          <Typography variant="h6" fontWeight="400">
            Thank you! Your message has been submitted.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  /**
   * @param {number | null} ratingValue
   */
  async function handleRatingChange(ratingValue) {
    // We only want to persist the rating if it is different from null.
    if (ratingValue !== null) {
      await axios
        .post(state.settings.api + "rating", {
          agrID: agreementId,
          rating: ratingValue,
        })
        .catch((error) => {
          console.error(error);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "An error occurred while saving the rating.",
              severity: "error",
            },
          });
        });
    }

    setRating(ratingValue);
    dispatch({
      type: "NEW_SNACKBAR",
      payload: {
        message: "Your rating has been saved.",
        severity: "success",
      },
    });
  }

  async function handleSubmitExperience() {
    await axios
      .post(state.settings.api + "mail/submitExperience", {
        agreementId,
        email: emailValue,
        rating: rating || undefined,
        message,
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: "NEW_SNACKBAR",
          payload: {
            message: "An error occurred while submitting your experience.",
            severity: "error",
          },
        });
      });

    setSubmittedExperience(true);

    dispatch({
      type: "NEW_SNACKBAR",
      payload: {
        message: "Your submission has been sent.",
        severity: "success",
      },
    });
  }

  if (loading) {
    return (
      <Grid container direction="column" alignItems="center" sx={{ mt: 25 }}>
        <Grid item>
          <CanveoCircularProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ mt: 10 }}>
          <Typography variant="h3" style={{ fontWeight: "700" }}>
            Thanks for using Canveo
            <span style={{ color: theme.palette.secondary.main }}>.</span>
          </Typography>
        </Grid>

        <Grid item sx={{ mt: 9, mb: 4 }}>
          <Typography variant="h5" style={{ fontWeight: "500" }}>
            Rate your experience
            <span style={{ color: theme.palette.secondary.main }}>.</span>
          </Typography>
        </Grid>

        <Grid item>
          <Rating
            name="canveo-counterparty-experience-rating"
            value={rating}
            onChange={(_event, newValue) => handleRatingChange(newValue)}
            precision={1}
            icon={
              <FontAwesomeIcon
                icon={faStar}
                style={{ fontSize: "40px", padding: "4px" }}
              />
            }
            emptyIcon={
              <FontAwesomeIcon
                icon={faStarOutlined}
                style={{ fontSize: "40px", padding: "4px" }}
              />
            }
          />
        </Grid>

        {rating && rating < 3 && (
          <>
            <Grid item sx={{ mt: 9, mb: 4 }}>
              <Typography variant="h5" style={{ fontWeight: "500" }}>
                Tell us how we can improve
                <span style={{ color: theme.palette.secondary.main }}>.</span>
              </Typography>
            </Grid>

            <Grid item sx={{ width: "320px" }}>
              <TextField
                id="outlined-textarea"
                placeholder="Share how we can improve ..."
                multiline
                autoFocus
                rows={4}
                style={{ width: "100%" }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  fullWidth
                  onClick={handleSubmitExperience}
                >
                  Submit&nbsp;&nbsp;
                  <FontAwesomeIcon icon={faPaperPlane} />
                </Button>
              </Box>
            </Grid>
          </>
        )}

        {(rating === null || rating > 2) && (
          <>
            <Grid item sx={{ mt: 9, mb: 4 }}>
              <Typography variant="h5" style={{ fontWeight: "500" }}>
                Try Canveo for free
                <span style={{ color: theme.palette.secondary.main }}>.</span>
              </Typography>
            </Grid>

            <Grid item>
              <CssTextField
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />

              <Button
                sx={{
                  borderRadius: "0px 20px 20px 0px",
                  fontSize: "16px",
                  padding: "16px 15px",
                }}
                variant="contained"
                color="secondary"
                disableElevation
                onClick={handleSubmitExperience}
              >
                Submit&nbsp;&nbsp;
                <FontAwesomeIcon icon={faPaperPlane} />
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
