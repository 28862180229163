import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import axios from "axios";
import { $getRoot } from "lexical";
import { useContext, useEffect, useState } from "react";
import getFileNameFromPathBuilder from "../components/PathBuilder/utils/getFilenameFromPathBuilder";
import $convertLexicalToSfdt from "../components/editor/converters/convertLexicalToSfdt";
import { globalStore } from "../state/store";
import { getAddressForEntity } from "../utils";
import useCreateUser from "./useCreateUser";

const COLLABORATOR_TYPE = "collaborator";
const SIGNER_TYPE = "signer";

/**
 * @param {*} agreement
 * @param {*} open
 * @param {*} isSigning
 * @param {*} selectedOrgID
 */
export default function useAgreementData(
  agreement,
  open = false,
  isSigning = false,
  selectedOrgID = ""
) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);

  const [editor] = useLexicalComposerContext();

  const [roles, setRoles] = useState(agreement.roles);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(/** @type {string | null} */ (""));
  const [allUsers, setAllUsers] = useState(/** @type {*[]} */ ([]));
  const [originalCollaborators, setOriginalCollaborators] = useState(
    /** @type {*[]} */ ([])
  );
  const [collaborators, setCollaborators] = useState(
    /** @type {{ _id: string, email: string, orgID: string }[]} */ ([])
  );
  const [newCollaborators, setNewCollaborators] = useState(
    /** @type {*[]} */ ([])
  );
  const [signingOrder, setSigningOrder] = useState(false);
  const [signers, setSigners] = useState(/** @type {*[]} */ ([]));
  // const [stagedForInvitationEmail, setStagedForInvitationEmail] = useState(
  //   /** @type {*[]} */ ([])
  // );
  const [partyFullString, setPartyFullString] = useState("");
  const [oidsForWhichYouCanAddUser, setOidsForWhichYouCanAddUser] = useState(
    /** @type {*[]} */ ([])
  );
  const [agreementUpdate, setAgreementUpdate] = useState(false);
  const [currentParty, setCurrentParty] = useState(
    /** @type {* | null} */ (null)
  );
  const [mainAgreement, setMainAgreement] = useState(
    /** @type {* | null} */ (null)
  );
  const [mainAgreementVersion, setMainAgreementVersion] = useState(
    /** @type {* | null} */ (null)
  );
  const [canSign, setCanSign] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [message, setMessage] = useState("");
  const [readyToSign, setReadyToSign] = useState(isSigning);

  const [selectedOrganizationID, setSelectedOrganizationID] =
    useState(selectedOrgID);
  const [userCreationType, setUserCreationType] = useState(
    /** @type {string | null} */ ("")
  );

  const [reminders, setReminders] = useState(/** @type {* | null} */ (null));
  const [attachWordDocumentToEmail, setAttachWordDocumentToEmail] =
    useState(false);
  const [attachWordDocumentToEmailType, setAttachWordDocumentToEmailType] =
    useState("leaveIn");
  const [attachPdfFileToEmail, setAttachPdfFileToEmail] = useState(false);
  const [attachPdfFileToEmailType, setAttachPdfFileToEmailType] =
    useState("clean");
  const [wordEditAuthorization, setWordEditAuthorization] =
    useState("trackedChanges");
  const [comparisonVersion, setComparisonVersion] = useState(
    /** @type {* | undefined} */ (undefined)
  );

  const { verifyCreateUser } = useCreateUser(allUsers);

  useEffect(
    () => {
      //if there are parties and the dialog is open
      if (!state.parties?.length || !open) return;
      setLoading(true);
      // Define orgs for which you can add a user
      const canAddUserOrgs = state.parties.reduce(
        (
          /** @type {any} */ accumulator,
          /** @type {{ orgID: any; ownerOrgID: any; }} */ current
        ) => {
          if (
            (state.org._id === current.orgID && state.org.orgType === "cpty") ||
            state.org._id === current.ownerOrgID
          ) {
            return [...accumulator, current.orgID];
          }

          return accumulator;
        },
        []
      );

      // Now pull all users for counterparties
      // TODO: Need to consider performance if user bases are starting to grow - can't pull ALL users for ALL parties
      const organizationId = state.parties
        .flatMap((/** @type {{ orgID: any; }} */ p) =>
          p.orgID !== state.org._id ? p.orgID : []
        )
        .join(",");

      if (organizationId) {
        getAllUsers(organizationId, true);
      } else {
        buildUserArrays([...state.users], true);
      }

      // Define the "partyFullString" constant, relevant for emails (e.g. share agr or adding collab)
      const legalNames = state.parties.map(
        (/** @type {{ legalName: any; }} */ p) => p.legalName
      );
      if (state.parties.length >= 2) {
        const partyFullString =
          state.parties.length === 2
            ? legalNames.join(" and ")
            : legalNames.join(", ");
        setPartyFullString(partyFullString);
      } else {
        setPartyFullString(state.parties[0]?.legalName);
      }
      setLoading(false);
      setSigningOrder(
        agreement.signers.some((/** @type {{ order: any; }} */ s) => !!s.order)
      );
      setOidsForWhichYouCanAddUser(canAddUserOrgs);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, state.parties, agreement.signers, agreement.collabs]
  );

  useEffect(
    () => {
      const currentParty = state.parties?.find(
        (/** @type {{ orgID: any; }} */ p) => p.orgID === state.org._id
      );

      // main Agreement Version (needs testing)
      const mainAgreement = state.agrs.find(
        (/** @type {{ parentID: any; }} */ agr) => !agr.parentID
      );
      const mainAgrVersion = state.avs.find(
        (/** @type {{ agrID: any; }} */ av) => av.agrID === mainAgreement._id
      );

      const mainAgreementVersion =
        state.drawerVersions.versions.find(
          (/** @type {{ agrID: any; }} */ dv) => dv.agrID === mainAgreement._id
        ) ?? mainAgrVersion;

      const isOwner = state.org._id === agreement.owner;

      // Until we have automatic signing enabled, only the owner of the
      // agreement can initiate the signing flow.
      const canSign = isOwner;

      setIsOwner(isOwner);
      setCurrentParty(currentParty);
      setMainAgreementVersion(mainAgreementVersion);
      setMainAgreement(mainAgreement);
      setCanSign(canSign);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.drawerVersions.versions,
      state.agrs,
      state.avs,
      state.org,
      state.parties,
    ]
  );

  /**
   * @param {*} users
   * @param {*} initial
   */
  const buildUserArrays = (users, initial = false) => {
    const collaborators = agreement.collabs.reduce(
      (
        /** @type {any} */ accumulator,
        /** @type {{ uid: any; }} */ current
      ) => {
        const collaborator = users.find(
          (/** @type {{ _id: string; }} */ u) => u._id === current.uid
        );
        if (!collaborator) return accumulator;

        return [...accumulator, collaborator];
      },
      []
    );

    const signers = agreement.signers.reduce(
      (
        /** @type {*[]} */ accumulator,
        /** @type {{ uid: string; partyID: string; order: string; entityName: string; }} */ current
      ) => {
        const signer = users.find(
          (/** @type {{ _id: string; }} */ u) => u._id === current.uid
        );
        if (!signer) return accumulator;

        return [
          ...accumulator,
          {
            ...signer,
            partyID: current.partyID,
            order: current.order,
            entityName: current.entityName,
          },
        ];
      },
      []
    );

    setAllUsers(users);
    setCollaborators(collaborators);
    if (initial) setOriginalCollaborators(collaborators);
    setSigners(signers);
  };

  /**
   * @param {string} organizationId
   * @param {boolean} initial
   */
  const getAllUsers = (organizationId, initial = false) => {
    axios
      .get(`${state.settings.api}user/org/${organizationId}`)
      .then((resUsers) => {
        if (!resUsers.data.success) return;

        const users = [...state.users, ...resUsers.data.data];

        buildUserArrays(users, initial);
      })
      .catch(() => {
        setErrorMsg(
          "Unable to retrieve all users - please reload or contact Canveo Support if the issue persists"
        );
      });
  };

  const reInitialize = () => {
    setLoading(false);
    setAllUsers([]);
    setAgreementUpdate(false);
    setNewCollaborators([]);
    setMessage("");
    setReadyToSign(isSigning);
    setSigningOrder(false);
    setErrorMsg("");
  };

  const setSignerCreation = () => setUserCreationType(SIGNER_TYPE);
  const setCollaboratorCreation = () => setUserCreationType(COLLABORATOR_TYPE);

  /**
   * @param {*[]} collaboratorList
   * @param {*} orgID
   * @param {*} isNew
   * @returns
   */
  const addCollaborators = (collaboratorList = [], orgID, isNew) => {
    const existingCollaborators = originalCollaborators.filter(
      (c) => c.orgID === orgID
    );

    // Create a Set for fast look-up of emails in the first array.
    const existingCollaboratorsEmails = new Set(
      existingCollaborators.map((item) => item.email)
    );

    // Filter elements in the second array that are not in the first array.
    const newCollaborators = collaboratorList.filter(
      (item) => !existingCollaboratorsEmails.has(item.email)
    );

    setNewCollaborators(newCollaborators);

    if (isNew) {
      setCollaborators((previous) => [...previous, ...collaboratorList]);
      setAllUsers((previous) => [...previous, ...collaboratorList]);
      return;
    }

    const difference = collaborators.filter((c) => c.orgID !== orgID);
    setCollaborators([...difference, ...collaboratorList]);
    setAgreementUpdate(true);
  };

  /**
   * @param {*} newSigner
   * @param {*} isNew
   */
  const addSigner = (newSigner, isNew) => {
    const party = state.parties.find(
      (/** @type {{ orgID: string; }} */ p) => p.orgID === newSigner.orgID
    );
    newSigner = {
      ...newSigner,
      partyID: party?.partyID,
      order: signingOrder ? signers.length : null,
      entityName: party?.legalName || "ENTITY NAME",
    };

    setSigners((previous) => [...previous, newSigner]);
    if (isNew) setAllUsers((previous) => [...previous, newSigner]);
    setAgreementUpdate(true);
  };

  /**
   * @param {*} changedSigners
   */
  const changeSigners = (changedSigners) => {
    setSigners(changedSigners);
    setAgreementUpdate(true);
  };

  const closeUserSections = () => {
    setUserCreationType("");
    setSelectedOrganizationID("");
    setErrorMsg("");
  };

  /**
   * @param {*} userData
   * @returns {Promise<boolean>}
   */
  const handleEditUserForm = async (userData) => {
    setLoading(true);
    setErrorMsg("");
    const resUser = await axios
      .put(`${state.settings.api}user/${userData._id}`, { user: userData })
      .catch((error) => {
        console.error(error);
        setErrorMsg("An error occurred while updating the user");
        setLoading(false);
      });

    if (!resUser || !resUser.data.success) {
      setErrorMsg(
        "An error occurred while updating the user - refresh your browser"
      );
      setLoading(false);
      return false;
    }

    /**
     * @param {*} user
     * @param {*} data
     * @returns {boolean}
     */
    const newUser = (user, data) => ({
      ...user,
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: `${data.firstName} ${data.lastName}`,
      title: data.title,
    });

    const changesUsers = allUsers.map((u) => {
      if (u._id === userData._id) return newUser(u, userData);
      return u;
    });
    const changedCollaborators = collaborators.map((u) => {
      if (u._id === userData._id) return newUser(u, userData);
      return u;
    });
    const changedSigners = signers.map((u) => {
      if (u._id === userData._id) return newUser(u, userData);
      return u;
    });

    setAllUsers([...changesUsers]);
    // @ts-ignore
    setCollaborators([...changedCollaborators]);
    setSigners([...changedSigners]);
    setLoading(false);

    return true;
  };

  /**
   * @param {*} child
   * @returns {Promise<void>}
   */
  const handleSubmitUserForm = async (child) => {
    if (!selectedOrganizationID) {
      return setErrorMsg(
        "Please complete all fields" +
          (state.parties.length >= 2 ? " and select a counterparty" : "")
      );
    }
    setErrorMsg(null);
    setLoading(true);

    try {
      const createdUser = await verifyCreateUser(child, selectedOrganizationID);

      if (userCreationType === COLLABORATOR_TYPE) {
        addCollaborators([createdUser], selectedOrganizationID, true);
      }
      if (userCreationType === SIGNER_TYPE) addSigner(createdUser, true);

      if (createdUser.orgID === state.org._id) {
        // Add to state is of the current Org
        dispatch({ type: "ADD_USERS", payload: createdUser });
        // setStagedForInvitationEmail((stagedForInv) => [
        //   ...stagedForInv,
        //   createdUser,
        // ]);
      }

      closeUserSections();
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) setErrorMsg(error.message);
      setLoading(false);
    }
  };

  /**
   * @returns {void}
   */
  const handleSigningOrderChange = () => {
    let newSigners;

    if (signingOrder) {
      // Changing from Signing Order to no Signing order - remove the order fields
      newSigners = signers.map((signer) => ({ ...signer, order: null }));
    } else {
      // Changing from no Signing Order to Signing order - define the default order fields
      newSigners = signers
        .sort((a, b) => (a.orgID > b.orgID ? 1 : a.orgID < b.orgID ? -1 : 0))
        .map((signer, index) => ({ ...signer, order: index }));
    }

    setSigners(newSigners);
    setAgreementUpdate(true);
    setSigningOrder(!signingOrder);
  };

  /**
   * @param {"AddParty" | "RemoveParty" | "ChangeEntity" | "UpdateEntity" | "UpdateRole"} reason
   * @param {*} value
   * @returns {void}
   */
  const handlePartyChange = (reason, value) => {
    let newParties = [...state.parties];

    if (reason === "AddParty") {
      const addingHiddenParty = state.parties.find(
        (/** @type {{ role: any; orgID: any; }} */ p) =>
          !p.role && p.orgID === value.orgID
      );
      const baseParties = addingHiddenParty
        ? state.parties.filter(
            (/** @type {{ orgID: any; }} */ p) => p.orgID !== value.orgID
          )
        : [...state.parties];
      newParties = [...baseParties, value];
    }

    if (reason === "RemoveParty") {
      const removingOwner = value.orgID === agreement.owner;
      if (!removingOwner) {
        newParties = state.parties.filter(
          (/** @type {{ _id: any; }} */ p) => p._id !== value._id
        );
        // @ts-ignore
        addCollaborators([], value.orgID); // clear collaborators list of removed party
      } else {
        newParties = state.parties.map((/** @type {{ _id: any; }} */ p) => {
          if (p._id === value._id) return { ...p, role: undefined };
          return p;
        });
      }

      changeSigners(signers.filter((s) => s.orgID !== value.orgID));
    }

    if (reason === "ChangeEntity" || reason === "UpdateEntity") {
      newParties = state.parties.map(
        (/** @type {{ orgID: any; role: any; }} */ party) => {
          if (party.orgID === value.orgID) {
            return { ...value, role: party.role };
          }
          return party;
        }
      );
    }

    if (reason === "UpdateRole") {
      newParties = state.parties.map((/** @type {{ orgID: any; }} */ party) => {
        if (party.orgID === value.orgID) {
          return { ...party, role: value.role };
        }
        return party;
      });
    }

    dispatch({ type: "INIT_PARTIES", payload: newParties });
    setAgreementUpdate(true);
  };

  /**
   * @param {{ _id: string }} user
   * @returns {void}
   */
  const handleRevokeUser = (user) => {
    const newSigners = signers.filter((s) => s._id !== user._id);
    const newCollaborators = collaborators.filter((s) => s._id !== user._id);

    setCollaborators(newCollaborators);
    setSigners(newSigners);
    setAgreementUpdate(true);
  };

  const getDbSigners = () => {
    return signers.reduce((accumulator, signer) => {
      const party = state.parties.find(
        (/** @type {{ orgID: string; }} */ p) => p.orgID === signer.orgID
      );
      if (!party) return accumulator;

      return [
        ...accumulator,
        {
          uid: signer._id,
          email: signer.email,
          order: signer.order,
          partyID: party.partyID,
          entityName: signer.entityName,
        },
      ];
    }, []);
  };

  const getDbCollaborators = () => {
    // @ts-ignore
    return collaborators.reduce((accumulator, current) => {
      const party = state.parties.find(
        (/** @type {{ orgID: string; }} */ p) => p.orgID === current.orgID
      );
      if (!party) return accumulator;

      return [
        ...accumulator,
        {
          uid: current._id,
          email: current.email,
          orgID: current.orgID,
          partyID: party.partyID,
        },
      ];
    }, []);
  };

  const getDbEntities = () => {
    return state.parties.map(
      (
        /** @type {{ _id: string; role: string; partyID: string; myClient: boolean; }} */ party
      ) => ({
        entID: party._id,
        role: party.role,
        partyID: party.partyID,
        myClient: party.myClient,
      })
    );
  };

  function sendUserInvitations() {
    // if (stagedForInvitationEmail.length) {
    //   // Send invitation email for users when you close the dialog without sending the agreement
    //   let mailConfig = {
    //     type: "newCollab",
    //     whiteLabel: agreement.whiteLabel,
    //     agr: agreement,
    //     senderLegalName: currentParty.legalName,
    //     toEntString: "",
    //     partyFullString: partyFullString,
    //     readyToSign: false,
    //     requestComment: null,
    //   };
    //   // Send "newCollab" email
    //   stagedForInvitationEmail.forEach((recipient) => {
    //     mailConfig.recipient = recipient;
    //     axios.post(state.settings.api + "mail/informagr", mailConfig);
    //   });
    // }
  }

  const updateAgreement = (shouldUpdate = false, shouldSendEmails = false) => {
    if (agreementUpdate || shouldUpdate) {
      // If collabs or signers have been updated, ensure to update the DB and trigger a refresh.

      const newMainAg = agreement;
      newMainAg.collabs = getDbCollaborators();
      newMainAg.signers = getDbSigners();
      newMainAg.ents = getDbEntities();
      newMainAg.lastUpdateBy = state.user._id;
      newMainAg.lastUpdateDate = new Date().toISOString();
      newMainAg.roles = roles;

      axios
        .put(state.settings.api + "agr/" + newMainAg._id, { agr: newMainAg })
        .then((res) => {
          dispatch({ type: "UPDATE_AGR", payload: res.data.data }); // Update state with new collab/signer array

          if (shouldSendEmails) {
            const mailConfig = {
              type: "informCptyUser",
              whiteLabel: agreement.whiteLabel,
              agr: agreement,
              senderLegalName: currentParty.legalName,
              toEntString: "",
              partyFullString: partyFullString,
              readyToSign: readyToSign,
              requestComment: null,
            };

            // const recipientsTo = collaborators.filter(
            //   (c) => c.orgID === state.user.orgID
            // );

            const recipientsTo = newCollaborators.filter(
              (obj, index, self) =>
                index === self.findIndex((o) => o.email === obj.email)
            );
            if (recipientsTo.length > 0) {
              sendEmails(recipientsTo, mailConfig);
            }
          }

          reInitialize();
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Agreement access updated",
              severity: "success",
            },
          });
        })
        .catch(() => {
          setErrorMsg("Unable to update the collaborators / signers");
          setLoading(false);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message: "Error updating agreement access",
              severity: "error",
            },
          });
        });
    }
  };

  /**
   * @typedef {object} Owner
   * @property {string} [_id]
   * @property {string} orgID
   * @property {"full" | "none" | "edit" | "comment" | "copy" | "read"} editMode
   */

  /**
   * @param {boolean} signable
   */
  function getExtraData(signable) {
    // Determine the new owner(s) i.e., who is CC'ed.
    /** @type {Owner[]} */
    const newOwners = [];
    /** @type {Owner[]} */
    const newCopied = [];
    let toEntString = "";
    let ccEntString = "";

    state.parties.forEach((/** @type {*} */ p) => {
      if (readyToSign && signable) {
        // If you're initiating signing -- all parties will be owner of the last version
        newOwners.push({ orgID: p.orgID, editMode: "full" });
      } else if (["copy"].includes(p.editMode)) {
        newCopied.push({ orgID: p.orgID, editMode: "copy" });
        ccEntString = ccEntString + p.legalName + ", ";
      } else if (["read", "comment", "edit", "full"].includes(p.editMode)) {
        newOwners.push({ orgID: p.orgID, editMode: p.editMode });
        toEntString = toEntString + p.legalName + ", ";
      }
    });
    if (ccEntString !== "") {
      ccEntString = ccEntString.slice(0, -2);
    }
    toEntString = toEntString.slice(0, -2);

    return { newOwners, newCopied, toEntString, ccEntString };
  }

  /**
   * @param {boolean} signable
   */
  function getNewVersionData(signable) {
    // DETERMINE THE NEW AGREEMENT STATUS
    let newAgrStatus =
      readyToSign && signable
        ? "Execution" // both are marking as ready to sign - status change to Execution
        : agreement.agrStatus === "Draft"
        ? "Review" // sending to Cpty from "Draft"
        : "Negotiation"; // Any other scenario

    // DETERMINE THE NEW READY TO SIGN FLAGS
    const primReady = currentParty?.side === "primary" ? readyToSign : signable;
    const secReady =
      currentParty?.side === "secondary" ? readyToSign : signable;

    return {
      ...getExtraData(signable),
      newAgrStatus,
      primReady,
      secReady,
    };
  }

  /**
   * @param {Owner[]} recipients
   * @returns {void}
   */
  const addReminders = (recipients) => {
    const interval = parseInt(reminders);
    if (!interval) return;
    let reminderUpdateDate = new Date().toISOString();

    const reminderList = recipients.map((r) => ({
      reminderType: "penTurn",
      creationBy: state.user._id,
      creationDate: reminderUpdateDate,
      agreementID: agreement._id,
      recipient: r._id,
      interval: { amt: interval, duration: "days" },
      endReason: "none",
    }));

    if (reminderList.length) {
      axios.post(state.settings.api + "reminder/reminders", {
        reminders: reminderList,
      });
    }
  };

  async function getAgreementSfdt() {
    const agrId = mainAgreement._id;
    const agrVersion = mainAgreementVersion;
    const documentCommentsResponse = await axios({
      url: `${state.settings.api}document/${agrId}/comments`,
      method: "GET",
    });

    const documentComments = documentCommentsResponse.data.data;

    const editorState = editor.getEditorState();

    const listsStructure = state.avs[0].contentMetadata?.listsStructure || [];

    // TODO: The party ID and entity ID need to belong to the counterparty to whom
    // the agreement is being sent, otherwise uploading a new version will not work
    // correctly.
    const partyId = "party1";
    const counterparty = state.parties.find(
      (/** @type {{ partyID: string; }} */ party) => party.partyID === "party1"
    );
    if (!counterparty) throw new Error("Counterparty does not exist.");
    const entityId = counterparty._id;
    const orgId = counterparty.orgID;

    const sfdt = editorState.read(() => {
      const root = $getRoot();
      const sfdt = $convertLexicalToSfdt(
        root,
        state.avs[0].sfdt,
        {
          agreementId: agrVersion.agrID,
          entityId,
          orgId,
          partyId,
        },
        documentComments,
        listsStructure
      );

      return sfdt;
    });

    const filename = await getFileNameFromPathBuilder(
      mainAgreement,
      state,
      false
    );

    return { filename, sfdt };
  }

  /**
   * Generates the SFDT of an agreement version. Can optionally include metadata so that the SFDT
   * can then be reimported as a new version of an existing agreement.
   *
   * @param {string} versionId
   * @param {{ agreementId: string, entityId: string, orgId: string, partyId: PartyId} | {}} metadata
   */
  async function getSfdtFromVersionId(versionId, metadata = {}) {
    const response = await axios.get(
      `${state.settings.api}agrv/${versionId}/full`
    );

    const document = response?.data?.data;

    const commentsResponse = await axios.get(
      `${state.settings.api}document/${document._id}/comments`
    );

    const sfdt = editor.parseEditorState(document.content).read(() => {
      const root = $getRoot();
      const documentSfdt = document.sfdt;

      const comments = commentsResponse?.data?.data;
      const listsStructure = document?.contentMetadata?.listsStructure || [];

      const generatedSfdt = $convertLexicalToSfdt(
        root,
        documentSfdt,
        metadata,
        comments,
        listsStructure
      );
      return generatedSfdt;
    });

    return sfdt;
  }

  /**
   * @param {*} recipients
   * @param {*} mailConfig
   * @param {MailFiles} files
   */
  async function sendEmails(
    recipients,
    mailConfig,
    files = { wordFiles: [], pdfFile: null }
  ) {
    const versionsToCompare = {
      leftSfdt: null,
      rightSfdt: null,
    };

    if (attachPdfFileToEmailType === "comparison") {
      if (mainAgreementVersion?._id && comparisonVersion?.version?._id) {
        // @ts-ignore
        versionsToCompare.leftSfdt = await getSfdtFromVersionId(
          comparisonVersion.version._id
        );
        // @ts-ignore
        versionsToCompare.rightSfdt = await getSfdtFromVersionId(
          mainAgreementVersion._id
        );
      }
    }

    recipients.forEach((/** @type {*} */ recipient) => {
      mailConfig.recipient = recipient;

      axios.post(state.settings.api + "mail/informagr", {
        ...mailConfig,
        attachWordDocumentToEmail,
        attachWordDocumentToEmailType,
        wordEditAuthorization,
        attachPdfFileToEmail,
        attachPdfFileToEmailType,
        versionsToCompare,
        files,
      });
    });
  }

  /**
   * @typedef {object} WordFile
   * @property {string} agreementId
   * @property {string} filename
   * @property {string} fileKey
   */

  /**
   * @typedef {object} PdfFile
   * @property {string} filename
   * @property {string} fileKey
   */

  /**
   * @typedef {object} MailFiles
   * @property {WordFile[]} wordFiles
   * @property {PdfFile?} pdfFile
   */

  /**
   * @param {{ attachPdfFileToEmail?: boolean, attachWordDocumentToEmail?: boolean, directSign?: boolean }} [options]
   * @returns {Promise<void>}
   */
  const sendAgreement = async (
    {
      attachPdfFileToEmail = false,
      attachWordDocumentToEmail = false,
      directSign = false,
    } = {
      attachPdfFileToEmail: false,
      attachWordDocumentToEmail: false,
      directSign: false,
    }
  ) => {
    if (!mainAgreement) {
      return setErrorMsg(
        "Unable to retrieve the correct agreement, please reload and try again"
      );
    }
    if (!mainAgreementVersion) {
      return setErrorMsg(
        "Unable to retrieve the correct agreement version, please reload and try again"
      );
    }

    setLoading(true);

    const canForceSign = isSigning && isOwner;
    const signable = canSign || canForceSign;

    const {
      newOwners,
      newCopied,
      toEntString,
      newAgrStatus,
      primReady,
      secReady,
    } = getNewVersionData(signable);

    /** @type {{ agreementId: string; agreementVersionId: string}[]} */
    const currentAgreementPartsIds = [];

    // Loop through all agreement parts (main body + exhibits), update them and create new versions.
    for (const ag of state.agrs) {
      // Update the agreement.
      let newAg = ag;
      if (ag._id === agreement._id) {
        // Update key parameters for the main agreement part.
        newAg.agrStatus = newAgrStatus;
        newAg.collabs = getDbCollaborators();
        newAg.signers = getDbSigners();
        newAg.ents = getDbEntities();
      }

      const avOwners = newOwners.map((o) => o.orgID);
      /** @type {Owner[]} */
      const filteredOwners = ag.avOwners.filter(
        (/** @type {*} */ o) => o !== state.org._id
      );
      newAg.avOwners = [
        ...Array.from(new Set([...filteredOwners, ...avOwners])),
      ];
      newAg.visibleTo = [
        ...Array.from(new Set([...newAg.visibleTo, ...avOwners])),
      ];
      newAg.lastUpdateBy = state.user._id;
      newAg.orgs = state.parties.map((/** @type {*} */ p) => p.orgID);

      // Update the agreement/exhibit in the database.
      await axios
        .put(state.settings.api + "agr/" + newAg._id, {
          agr: newAg,
        })
        .then((res) => {
          dispatch({ type: "UPDATE_AGR", payload: res.data.data }); // Update the agreement/exhibit in state.
        })
        .catch(() => {
          // TODO: Serious issue - one of the agreements was not updated succesfully.
        });

      const newAvDetail = {
        owner: newOwners,
        primReady: primReady,
        secReady: secReady,
      };

      const version = state.avs.find(
        (/** @type {*} */ av) => av.agrID === ag._id
      );

      // Duplicate the agreement version with the provided characteristics.
      if (state.user.role.name === "Counterparty") {
        axios.post(state.settings.api + "agrv/return", {
          oldAvid:
            mainAgreement._id === ag._id
              ? mainAgreementVersion._id
              : version._id,
          newAvDetail: newAvDetail,
        });
      } else {
        if (directSign) {
          // If the agreement is sent with the direct signing flow we change the edit mode of all the
          // counterparties to be "read".
          for (const owner of newAvDetail.owner) {
            if (owner.orgID !== state.user.orgID) {
              owner.editMode = "read";
            }
          }
        }

        const currentAgreementId = ag._id;
        const currentAgreementVersionId =
          mainAgreement._id === ag._id ? mainAgreementVersion._id : version._id;

        currentAgreementPartsIds.push({
          agreementId: currentAgreementId,
          agreementVersionId: currentAgreementVersionId,
        });

        try {
          await axios.post(state.settings.api + "agrv/send", {
            oldAvid: currentAgreementVersionId,
            newAvDetail: newAvDetail,
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: "NEW_SNACKBAR",
            payload: {
              message:
                "There was an error sending the agreement, try again or contact Canveo Support if the issue persists.",
              severity: "error",
            },
          });
        }
      }
    }

    /** @type {MailFiles} */
    const files = { wordFiles: [], pdfFile: null };

    // We only want to generate attachments if the user is not from a counterparty.
    if (state.user.role.name !== "Counterparty") {
      // For each part of the agreement (main body and exhibits) we need to generate a word or pdf file
      // (according to what the user has chosen in the UI) of the current version of those documents.
      const filename = await getFileNameFromPathBuilder(
        mainAgreement,
        state,
        false
      );

      /** @type {{ agreementId: string, pdfFileKey: string }[]} */
      const pdfFiles = [];

      // Value is hardcoded for now but will need to change when we implement multiparty agreements.
      const counterpartyPartyId = "party1";
      const counterparty = state.parties.find(
        (/** @type {{ partyID: string; }} */ party) =>
          party.partyID === counterpartyPartyId
      );
      const counterpartyEntity = mainAgreement.ents.find(
        (/** @type {{ partyID: string; }} */ entity) =>
          entity.partyID === counterparty.partyID
      );

      for (const {
        agreementId,
        agreementVersionId,
      } of currentAgreementPartsIds) {
        // When we implement multiparty agreements we will have to generate files for each counterparty so that
        // they have their own metadata and can be uploaded as new versions.
        const sfdt = await getSfdtFromVersionId(agreementVersionId, {
          // This is the ID of the main agreement regardless if the current version is from the main agreement
          // body or an exhibit.
          agreementId,
          entityId: counterpartyEntity.entID,
          orgId: counterparty.orgID,
          partyId: counterpartyPartyId,
        });

        if (attachWordDocumentToEmail) {
          const downloadFileResponse = await axios.post(
            `${state.settings.api}document/export?returnFileKey=true`,
            {
              sfdt,
              isReadonly: false,
              attachWordDocumentToEmail,
              attachWordDocumentToEmailType,
              wordEditAuthorization,
            }
          );
          const downloadFileResponseData = await downloadFileResponse.data;
          const fileKey = downloadFileResponseData.data.fileKey;
          files.wordFiles.push({
            agreementId,
            filename,
            fileKey,
          });
        }

        if (attachPdfFileToEmail) {
          const downloadFileResponse = await axios.post(
            `${state.settings.api}document/exportPdf?returnFileKey=true`,
            { sfdt }
          );
          const downloadFileResponseData = await downloadFileResponse.data;
          const pdfFileKey = downloadFileResponseData.data.fileKey;
          pdfFiles.push({ agreementId, pdfFileKey });
        }
      }

      if (pdfFiles.length === 1) {
        files.pdfFile = { fileKey: pdfFiles[0].pdfFileKey, filename };
      }
      // If the length is greater than 1, that means we have at least one exhibit and we need to assemble all
      // the different PDF parts into one.
      else if (pdfFiles.length > 1) {
        const downloadFileResponse = await axios.post(
          `${state.settings.api}document/assembleExhibitsPdfs`,
          { pdfFiles }
        );
        const downloadFileResponseData = await downloadFileResponse.data;
        const fileKey = downloadFileResponseData.data.fileKey;
        files.pdfFile = { fileKey: fileKey, filename };
      }
    }

    // Initiate signing.
    if (readyToSign && signable) {
      const party1 = state.parties.find(
        (/** @type {{ partyID: string; }} */ p) => p.partyID === "party1"
      );
      const address = getAddressForEntity(party1, "billto").country;

      const paper = ["CA", "US"].includes(address) ? "LETTER" : "A4";

      const response = await axios.get(
        `${state.settings.api}agr/exportDetails/${agreement._id}`
      );

      const agreements = response?.data?.data;

      if (!agreements?.length) {
        throw new Error("Error getting agreement details.");
      }

      for (const agreement of agreements) {
        // Only convert to SFDT if the agreement has Lexical content.
        if (agreement.content) {
          const agrvid = state.drawerVersions.active._id; //must exist
          if (!agrvid) throw new Error("Couldn't find agreement version.");

          const commentsResponse = await axios.get(
            `${state.settings.api}document/${agrvid}/comments`
          );
          const comments = commentsResponse?.data?.data;

          const editorState = editor.parseEditorState(agreement.content);
          agreement.sfdt = editorState.read(() => {
            const root = $getRoot();

            const listsStructure =
              agreement.contentMetadata?.listsStructure || [];

            const sfdt = $convertLexicalToSfdt(
              root,
              agreement.sfdt,
              undefined,
              comments,
              listsStructure
            );
            return sfdt;
          });
        }
      }

      const versionsToCompare = {
        leftSfdt: null,
        rightSfdt: null,
      };

      if (attachPdfFileToEmailType === "comparison") {
        if (!mainAgreementVersion?._id) {
          throw new Error("Main agreement ID is required for PDF comparison.");
        }

        if (!comparisonVersion?.version?._id) {
          throw new Error(
            "Comparison verion ID is required for PDF comparison."
          );
        }

        // @ts-ignore
        versionsToCompare.leftSfdt = await getSfdtFromVersionId(
          comparisonVersion.version._id
        );
        // @ts-ignore
        versionsToCompare.rightSfdt = await getSfdtFromVersionId(
          mainAgreementVersion._id
        );
      }

      const mailConfig = {
        type: directSign ? "signRequest" : "informCptyUserReadyToSign",
        whiteLabel: agreement.whiteLabel,
        agr: agreement,
        senderLegalName: currentParty.legalName,
        toEntString: toEntString,
        partyFullString: partyFullString,
        readyToSign: readyToSign,
        requestComment: message,
      };

      // Send request to backend to create a signature request.
      axios
        .post(state.settings.api + "signing/request", {
          agreements,
          mainAid: agreement._id,
          avv: mainAgreementVersion.version,
          paper: paper, // Paper Format for the PDF
          provider: agreement.sigConfig?.provider ?? "skribble", // sigProvider
          quality: agreement.sigConfig?.quality ?? "SES", // sigQuality
          signFlow: directSign ? "signDirectly" : "reviewAndSign",
          customPdf: attachPdfFileToEmailType === "comparison",
          versionsToCompare,
          // TODO: Only store this if Docusign.
          sendReadyToSignEmailsMetadata: {
            signers,
            ...mailConfig,
            attachWordDocumentToEmail,
            attachWordDocumentToEmailType,
            wordEditAuthorization,
            attachPdfFileToEmail,
            attachPdfFileToEmailType,
            versionsToCompare,
            files,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (agreement.sigConfig?.provider !== "docusign") {
              // @ts-ignore
              sendEmails(signers, mailConfig, files);
            }

            const currentUserId = state.user._id;

            // Get all the IDs from the signers array for easier comparison.
            const signerIds = signers.map((signer) => signer._id);

            // Filter collaborators to remove any users that are either in the signers array or match the
            // current user's identifier.
            const collaboratorsWithoutSignersAndCurrentUser =
              collaborators.filter(
                (collaborator) =>
                  !signerIds.includes(collaborator._id) &&
                  collaborator._id !== currentUserId
              );

            sendEmails(collaboratorsWithoutSignersAndCurrentUser, {
              ...mailConfig,
              type: "signRequestFYI",
              signers,
            });

            addReminders(signers);

            if (agreement.sigConfig?.provider !== "docusign") {
              dispatch({ type: "UPDATE_AGREXEC", payload: res.data.data });
              reInitialize();
            }

            if (res?.data?.data?.senderViewUrl) {
              window.location.href = res?.data?.data?.senderViewUrl;
            }
          } else {
            setErrorMsg("Unable to execute Sign Request");
            setLoading(false);
          }
        })
        .catch(() => {
          setErrorMsg("Unable to create Sign Request");
          setLoading(false);
        });
    }
    // Prepare emails to be sent.
    else {
      /** @type {Owner[]} */
      // @ts-ignore
      const recipientsTo = collaborators.filter((c) => {
        const newOwner = newOwners.find((el) => el.orgID === c.orgID); // is collab
        const signer = signers.find((s) => s._id === c._id); //is signer
        const party = state.parties.find(
          (/** @type {{ partyID: string; }} */ p) =>
            p.partyID === signer?.partyID
        ); // get party to check involvement
        return newOwner && (signer ? party && party.editMode !== "none" : true); //it's signer and collab and party is involved
      });
      /** @type {Owner[]} */
      // @ts-ignore
      const recipientsCC = collaborators.filter((c) =>
        newCopied.find((el) => el.orgID === c.orgID)
      );
      /** @type {Owner[]} */
      // @ts-ignore
      const recipientsOwnFYI = collaborators.filter(
        (c) => currentParty.orgID === c.orgID && state.user._id !== c._id
      );
      // Send to counterparty.
      const mailConfig = {
        type: newAgrStatus === "Review" ? "informCptyUser" : "penTurn",
        whiteLabel: agreement.whiteLabel,
        agr: agreement,
        senderLegalName: currentParty.legalName,
        toEntString: toEntString,
        partyFullString: partyFullString,
        readyToSign: readyToSign,
        requestComment: message,
      };

      // SHARE AGR
      // @ts-ignore
      sendEmails(recipientsTo, mailConfig, files);
      // SHARE A COPY OF AGR
      mailConfig.type = "informCopyRecipient";
      // @ts-ignore
      mailConfig.requestComment = null;
      // @ts-ignore
      sendEmails(recipientsCC, mailConfig, files);
      // INFORM COLLEAGUES OF SHARING
      mailConfig.type =
        newAgrStatus === "Review" ? "informCptyUserFyi" : "penTurnFyi";
      mailConfig.requestComment = message;
      // @ts-ignore
      sendEmails(recipientsOwnFYI, mailConfig, files);
      //Create reminder information
      addReminders(recipientsTo);
      setLoading(false);
      reInitialize();
    }
  };

  return {
    loading,
    errorMsg,
    setErrorMsg,
    allUsers,
    originalCollaborators,
    collaborators,
    addCollaborators,
    signingOrder,
    setSigningOrder,
    signers,
    setSigners,
    addSigner,
    changeSigners,
    partyFullString,
    setPartyFullString,
    oidsForWhichYouCanAddUser,
    canSign,
    isOwner,
    message,
    setMessage,
    readyToSign,
    setReadyToSign,
    handleSigningOrderChange,
    setSelectedOrganizationID,
    setSignerCreation,
    setCollaboratorCreation,
    closeUserSections,
    selectedOrganizationID,
    userCreationType,
    handleEditUserForm,
    handleSubmitUserForm,
    updateAgreement,
    sendUserInvitations,
    sendAgreement,
    reminders,
    setReminders,
    handleRevokeUser,
    handlePartyChange,
    setUserCreationType,
    agreementUpdate,
    roles,
    setRoles,
    setAgreementUpdate,
    attachWordDocumentToEmail,
    setAttachWordDocumentToEmail,
    attachWordDocumentToEmailType,
    setAttachWordDocumentToEmailType,
    setAttachPdfFileToEmail,
    attachPdfFileToEmail,
    attachPdfFileToEmailType,
    setAttachPdfFileToEmailType,
    wordEditAuthorization,
    setWordEditAuthorization,
    comparisonVersion,
    setComparisonVersion,
    getAgreementSfdt,
    mainAgreementVersion,
    getSfdtFromVersionId,
  };
}
