import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { globalStore } from "../../../state/store";
import CanveoCircularProgress from "../../CanveoCircularProgress";
import ApprovalTicketDetailsTab from "./tabs/ApprovalTicketDetailsTab";
import EditApproverTab from "./tabs/EditApproverTab";

/**
 * @typedef {object} DialogApprovalTicketDetailsProps
 * @property {boolean} open
 * @property {() => void} close
 * @property {boolean} [displayOnlyDialogContent]
 * @property {{ ents: { entID: string, partyID: string }[], agrTitle: string, _id: string }} agreement
 * @property {ApprovalTicket} approvalTicket
 * @property {(approvalTicket: ApprovalTicket) => void} updateApprovalTicket
 * @property {(approvalTicketId: string) => void} deleteApprovalTicket
 * @property {boolean} [isTemplate]
 */

/**
 * @param {DialogApprovalTicketDetailsProps} props
 * @returns {JSX.Element}
 */
export default function DialogApprovalTicketDetails({
  open,
  close,
  displayOnlyDialogContent = false,
  agreement,
  approvalTicket,
  updateApprovalTicket,
  deleteApprovalTicket,
  isTemplate = false,
}) {
  // @ts-ignore
  const [state, dispatch] = useContext(globalStore);
  const [isLoading, setIsLoading] = useState(false);
  const [tab, setTab] = useState(
    /** @type {"ticketDetails" | "editApprover"} */ ("ticketDetails")
  );

  useEffect(
    () => {
      loadApprovalTicket(approvalTicket._id);
    },
    // Runs only once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  /**
   * @param {string} approvalTicketId
   * @returns {Promise<ApprovalTicket | undefined>}
   */
  async function loadApprovalTicket(approvalTicketId) {
    try {
      setIsLoading(true);

      const getApprovalTicketByIdResponse = await axios.get(
        `${state.settings.api}task/${approvalTicketId}`
      );
      /** @type {ApprovalTicket} */
      const approvalTicket = getApprovalTicketByIdResponse.data.data;

      updateApprovalTicket(approvalTicket);

      return approvalTicket;
    } catch (error) {
      console.error(error);
      dispatch({
        type: "NEW_SNACKBAR",
        payload: {
          message:
            "Unable to load the approval request, try again or contact Canveo Support if the issue persists.",
          severity: "error",
        },
      });
    } finally {
      setIsLoading(false);
    }
  }

  if (displayOnlyDialogContent) {
    return (
      <>
        {isLoading ? (
          <DialogContent sx={{ paddingX: 10 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <CanveoCircularProgress />
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <>
            {tab === "ticketDetails" && (
              <ApprovalTicketDetailsTab
                close={close}
                agreement={agreement}
                approvalTicket={approvalTicket}
                updateApprovalTicket={updateApprovalTicket}
                deleteApprovalTicket={deleteApprovalTicket}
                onEditApprover={() => setTab("editApprover")}
                isTemplate={isTemplate}
                messageListHeight="150px"
                hideBackButton
              />
            )}

            {tab === "editApprover" && (
              <EditApproverTab
                close={() => setTab("ticketDetails")}
                approvalTicket={approvalTicket}
                updateApprovalTicket={(approvalTicket) => {
                  setTab("ticketDetails");
                  updateApprovalTicket(approvalTicket);
                }}
              />
            )}
          </>
        )}
      </>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={false}
      sx={{
        "& .MuiPaper-root": {
          width: "750px",
        },
      }}
    >
      <Box sx={{ position: "absolute", top: "11px", right: "12px" }}>
        <IconButton onClick={close}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ padding: "4px 7px", fontSize: "20px" }}
          />
        </IconButton>
      </Box>

      <DialogTitle>
        <Grid container direction="row" alignItems="center" gap={2}>
          Approval Request Details
        </Grid>
      </DialogTitle>

      {isLoading ? (
        <DialogContent sx={{ paddingX: 10 }}>
          <Grid container justifyContent="center">
            <Grid item>
              <CanveoCircularProgress />
            </Grid>
          </Grid>
        </DialogContent>
      ) : (
        <>
          {tab === "ticketDetails" && (
            <ApprovalTicketDetailsTab
              close={close}
              agreement={agreement}
              approvalTicket={approvalTicket}
              updateApprovalTicket={updateApprovalTicket}
              deleteApprovalTicket={deleteApprovalTicket}
              onEditApprover={() => setTab("editApprover")}
              isTemplate={isTemplate}
            />
          )}

          {tab === "editApprover" && (
            <EditApproverTab
              close={() => setTab("ticketDetails")}
              approvalTicket={approvalTicket}
              updateApprovalTicket={(approvalTicket) => {
                setTab("ticketDetails");
                updateApprovalTicket(approvalTicket);
              }}
            />
          )}
        </>
      )}
    </Dialog>
  );
}
