/**
 * @param {boolean} isTemplate
 * @param {*} state
 */
export function getAgreementData(isTemplate, state) {
  let firstCounterpartyLegalName = "";
  let agreementTypeName = "";

  if (!isTemplate) {
    firstCounterpartyLegalName = state?.cpents?.find(
      (/** @type {{ _id: string; }} */ counterpartyEntity) =>
        counterpartyEntity?._id ===
        state?.agrs
          ?.at(0)
          ?.ents?.find(
            (/** @type {{ partyID: string; }} */ entity) =>
              entity?.partyID === "party1"
          )?.entID
    )?.legalName;

    agreementTypeName = state.agrTypes
      .find(
        (/** @type {{ _id: string; }} */ agrType) =>
          agrType?._id === state?.agrs?.at(0)?.agrTypeID
      )
      ?.fullName?.at(0);
  }
  // Templates do not have counterparties.
  else {
    agreementTypeName = state.agrTypes
      .find(
        (/** @type {{ _id: string; }} */ agrType) =>
          agrType?._id === state?.template?.at(0)?.agrTypeID?._id
      )
      ?.fullName?.at(0);
  }

  return { firstCounterpartyLegalName, agreementTypeName };
}
