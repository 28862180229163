import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid } from "@mui/material";
import React from "react";
import theme from "../../../../theme/theme";

/**
 * @typedef {object} CannotSendOrSignInfoPanelProps
 * @property {"send" | "sign"} variant
 * @property {(tabIndex: number) => void} changeTab
 */

/**
 * @param {CannotSendOrSignInfoPanelProps} props
 * @returns {JSX.Element}
 */
export default function CannotSendOrSignInfoPanel({ variant, changeTab }) {
  return (
    <Box
      mt={10}
      mx={18}
      sx={{
        backgroundColor: "rgba(98, 0, 238, 0.07)",
        borderRadius: "20px",
        textAlign: "center",
      }}
    >
      <Grid container direction="column" alignItems="center" py={4}>
        <Grid item>
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            icon={faCircleInfo}
            fontSize={25}
          />
        </Grid>

        <Grid item mt={3}>
          The latest version of the agreement is still pending reviews or
          approvals.
        </Grid>

        <Grid item mt={3} px={12}>
          Please complete any pending{" "}
          <Box
            component="span"
            color={theme.palette.primary.main}
            fontWeight="bold"
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={(_event) => changeTab(0)}
          >
            Reviews
          </Box>{" "}
          or{" "}
          <Box
            component="span"
            color={theme.palette.primary.main}
            fontWeight="bold"
            sx={{
              "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
              },
            }}
            onClick={(_event) => changeTab(1)}
          >
            Approvals
          </Box>{" "}
          <br />
          (or submit new ones).
        </Grid>
        <Grid item mt={2} px={12}>
          Once completed, you will be able to{" "}
          {variant === "send" && <>share the agreement externally</>}
          {variant === "sign" && <>send the agreement for signature</>}.
        </Grid>
      </Grid>
    </Box>
  );
}
