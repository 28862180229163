import {
  faCaretRight,
  faCheck,
  faCheckCircle,
  faCircleX,
  faHourglassStart,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Box, Grid } from "@mui/material";
import React from "react";
import { getStatusTaskStyles } from "../../../views/Tasks/getStatusTaskStyles";
import { avatarSize } from "../constants/avatarSize";
import { avatarStyles } from "../constants/avatarStyles";
import { badgeStyles } from "../constants/badgeStyles";
import ChainItem from "./ChainItem";

/**
 * @typedef {object} DisplayReviewListProps
 * @property {ApprovalTicket} approvalTicket
 */

/**
 * @param {DisplayReviewListProps} props
 * @returns {JSX.Element}
 */
export default function DisplayApprovalList({ approvalTicket }) {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      gap={2}
      mt={2}
    >
      {approvalTicket.approvalChain.approvalList.map(
        (approvalListItem, index) => {
          switch (approvalListItem.type) {
            case "user": {
              switch (approvalListItem.approvalStatus) {
                case "Pending": {
                  return (
                    <Grid item key={index}>
                      <ChainItem
                        primaryText={approvalListItem.displayName}
                        secondaryText={approvalListItem.title}
                      >
                        <Avatar
                          src={approvalListItem.photoUrl}
                          sx={{ ...avatarStyles }}
                        />
                      </ChainItem>
                    </Grid>
                  );
                }

                case "Approved": {
                  return (
                    <Badge
                      key={index}
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <Box marginLeft="10px">
                          <Box
                            sx={{
                              ...badgeStyles,
                              // Background color of the circle.
                              backgroundColor: "green",
                              // Color of the icon.
                              color: "white",
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} />
                          </Box>
                        </Box>
                      }
                    >
                      <ChainItem
                        primaryText={approvalListItem.displayName}
                        secondaryText={approvalListItem.title}
                      >
                        <Avatar
                          src={approvalListItem.photoUrl}
                          sx={{ ...avatarStyles }}
                        />
                      </ChainItem>
                    </Badge>
                  );
                }

                default: {
                  return (
                    <Grid item key={index}>
                      <ChainItem
                        primaryText={approvalListItem.displayName}
                        secondaryText={approvalListItem.title}
                      >
                        <Avatar
                          src={approvalListItem.photoUrl}
                          sx={{ ...avatarStyles }}
                        />
                      </ChainItem>
                    </Grid>
                  );
                }
              }
            }

            case "addApprover": {
              return (
                <Grid item key={index} mb={4}>
                  <FontAwesomeIcon icon={faCaretRight} color="#ABA9B8" />
                </Grid>
              );
            }

            case "check": {
              const styles = getStatusTaskStyles(approvalTicket.status);

              switch (approvalTicket.status) {
                case "Cancelled": {
                  return (
                    <Grid item key={index}>
                      <ChainItem primaryText={approvalTicket.status}>
                        <FontAwesomeIcon
                          icon={faCircleX}
                          color="#ABA9B8"
                          fontSize={avatarSize}
                        />
                      </ChainItem>
                    </Grid>
                  );
                }

                case "Approved": {
                  return (
                    <Grid item key={index}>
                      <ChainItem primaryText={approvalTicket.status}>
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          color={"green"}
                          fontSize={avatarSize}
                        />
                      </ChainItem>
                    </Grid>
                  );
                }

                default: {
                  return (
                    <Grid item key={index}>
                      <ChainItem primaryText={approvalTicket.status}>
                        <Box
                          sx={{
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            // Makes the container a circle.
                            borderRadius: "50%",
                            ...avatarStyles,
                            // Background color of the circle.
                            backgroundColor: "#ABA9B8",
                            // Color of the icon.
                            color: "white",
                            ...styles,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faHourglassStart}
                            fontSize={avatarSize - 30}
                          />
                        </Box>
                      </ChainItem>
                    </Grid>
                  );
                }
              }
            }

            default: {
              throw new Error("Invalid type.");
            }
          }
        }
      )}
    </Grid>
  );
}
